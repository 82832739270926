import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'fixed',
    height: '50px',
    width: '35%',
    backgroundColor: '#29696A',
    // zIndex: 1202,
    bottom: '0.5%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    margin: '0 auto',
    borderRadius: '5px',
    color: 'white',
    paddingRight: '16px',
    paddingLeft: '16px',
    gap: '16px'
  },
  buttonStatus: {
    color: 'white'
  }
}));

export default useStyles;
