import React, { FC, useState, useCallback, useEffect } from 'react';
import axios, { CancelTokenSource } from 'axios';
import useRouter from 'hooks/useRouter';
import { Paper, Tabs, Tab, makeStyles, Theme } from '@material-ui/core';
import { dummyDriver } from 'constants/DummyData';
import { INDIVIDUAL_DRIVER_URL } from 'constants/url';
import DriverInformation from './DriverInformation';
import MainTemplate from 'components/Template/MainTemplate';
import TabPanel, { a11yProps } from 'components/TabPanel';
import JobTaskTable from 'pages/JobsPage/JobTaskTable';

const useStyles = makeStyles((theme: Theme) => ({
  tabPanel: {
    paddingTop: '32px'
  },
  tabPanelList: {
    paddingTop: '32px'
  },
  cardTab: {
    marginTop: '16px',
    padding: '16px 24px 16px 24px',
    borderRadius: '5px',
    border: '1px solid rgba(46, 91, 255, 0.08)',
    boxShadow: '8px 4px 24px rgba(0, 0, 0, 0.08)'
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  tab: {
    textTransform: 'none',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.15px',
    color: theme.palette.black.A100
  }
}));

const DriverDetailPage: FC = () => {
  const classes = useStyles();
  const { match } = useRouter();
  const id = Number(match.params.id);

  const [value, setValue] = useState(0);
  const [driver, setDriver] = useState<DriverModel>(dummyDriver);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

  const fetchData = useCallback(async () => {
    if (!id) {
      return;
    }

    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    setIsLoadingData(true);

    try {
      const { data } = await axios.get(INDIVIDUAL_DRIVER_URL(id), { cancelToken: cancelTokenSource.token });
      if (data) {
        setDriver(data);
      }
    } catch (err) {
      console.error('err: ', err);
    }
    setIsLoadingData(false);
    return () => cancelTokenSource.cancel();
  }, [id]);

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    fetchData();
  }, [id, fetchData]);

  return (
    <MainTemplate title='Owner Lists' subtitle={driver.Merchant.User.displayName} breadcrumb={true} refreshButton={false} primaryButton={false}>
      <Paper elevation={0} className={classes.cardTab}>
        <Tabs indicatorColor='primary' value={value} onChange={handleChangeTab} className={classes.tabs}>
          <Tab label='General Information' {...a11yProps(0)} className={classes.tab} />
          <Tab label='Jobs List' {...a11yProps(1)} className={classes.tab} />
        </Tabs>

        <TabPanel value={value} index={0}>
          <div className={classes.tabPanel}>
            <DriverInformation driver={driver} isLoadingData={isLoadingData} />
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className={classes.tabPanelList}>
            {/* <JobsPage noTitle={true} driverId={driver.id} merchantId={driver.MerchantId} /> */}
            <JobTaskTable driverId={driver.id} merchantId={driver.MerchantId} />
          </div>
        </TabPanel>
      </Paper>
    </MainTemplate>
  );
};

export default DriverDetailPage;
