import React, { FC, useState, useEffect, useCallback } from 'react';
import {
  Theme,
  makeStyles,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableContainer,
  InputAdornment,
  Typography,
  Divider
} from '@material-ui/core';

import HeaderRow from 'components/HeaderRow';
import BodyRow from './components/BodyRow';

import { dummyJobTask } from 'constants/DummyData';
import { Search } from '@material-ui/icons';
import JobStatus from 'typings/enum/JobStatus';
interface Props {
  isOwnedJob?: boolean;
  tasks?: JobTask[];
  isLoadingData: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  rootTabs: {
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
    borderRadius: '5px'
  },
  title: {
    padding: '16px'
  }
}));

const DriverTable: FC<Props> = props => {
  const classes = useStyles();
  const { tasks, isLoadingData } = props;

  const [currentTasks, setCurrentTasks] = useState<JobTask[]>([]);
  const [queryId, setQueryId] = useState<string>('');
  const [queryName, setQueryName] = useState<string>('');
  const [queryVehicleNumber, setQueryVehicleNumber] = useState<string>('');
  const [querySupervisor, setQuerySupervisor] = useState<string>('');
  const [queryStatus, setQueryStatus] = useState<string>('');

  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('createdAt');

  const handleQueryIdChange = (text: string) => {
    setQueryId(text);
  };

  const handleQueryNameChange = (text: string) => {
    setQueryName(text);
  };

  const handleQueryVehicleChange = (text: string) => {
    setQueryVehicleNumber(text);
  };

  const handleQuerySupervisorChange = (text: string) => {
    setQuerySupervisor(text);
  };

  const handleQueryStatusChange = (text: string) => {
    setQueryStatus(text);
  };

  const filter = useCallback(() => {
    if (!tasks || tasks.length < 1) {
      return;
    }

    let filteredTasks = tasks;
    if (queryId) {
      filteredTasks = filteredTasks.filter(task => String(task.id).match(new RegExp(queryId, 'gi')));
    }

    if (queryName) {
      filteredTasks = filteredTasks.filter(task => String(task.Driver?.User?.displayName).match(new RegExp(queryName, 'gi')));
    }

    if (queryVehicleNumber) {
      filteredTasks = filteredTasks.filter(task => String(task.Driver?.vehicleNumber).match(new RegExp(queryVehicleNumber, 'gi')));
    }

    if (querySupervisor) {
      filteredTasks = filteredTasks.filter(task => String(task.Supervisor?.User?.displayName).match(new RegExp(querySupervisor, 'gi')));
    }

    if (queryStatus) {
      filteredTasks = filteredTasks.filter(task => String(task.jobTaskStatus).match(queryStatus));
    }

    setCurrentTasks(filteredTasks);
  }, [tasks, queryId, queryName, queryVehicleNumber, querySupervisor, queryStatus]);

  const handleSortTasks = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    if (property === 'id') {
      setCurrentTasks(currentTasks.sort((a, b) => (!isAsc ? b.id - a.id : a.id - b.id)));
    } else if (property === 'amount') {
      setCurrentTasks(currentTasks.sort((a, b) => (!isAsc ? b.taskEarn - a.taskEarn : a.taskEarn - b.taskEarn)));
    } else if (property === 'status') {
      // eslint-disable-next-line
      setCurrentTasks(
        currentTasks.sort((a, b) => (!isAsc ? (a.jobTaskStatus > b.jobTaskStatus && -1) || 1 : (a.jobTaskStatus > b.jobTaskStatus && 1) || -1))
      );
    } else if (property === 'driver') {
      // eslint-disable-next-line
      setCurrentTasks(
        currentTasks.sort((a, b) => {
          if (!a.Driver) return 1;
          if (!b.Driver) return -1;
          if (a.Driver === b.Driver) return 0;
          // eslint-disable-next-line
          return !isAsc
            ? (a.Driver.User?.displayName > b.Driver.User?.displayName && -1) || 1
            : (a.Driver.User?.displayName > b.Driver.User?.displayName && 1) || -1;
        })
      );
    } else if (property === 'vehicle') {
      // eslint-disable-next-line
      setCurrentTasks(
        currentTasks.sort((a, b) => {
          if (!a.Driver) return 1;
          if (!b.Driver) return -1;
          if (a.Driver === b.Driver) return 0;
          // eslint-disable-next-line
          return !isAsc ? (a.Driver.vehicleNumber > b.Driver.vehicleNumber && -1) || 1 : (a.Driver.vehicleNumber > b.Driver.vehicleNumber && 1) || -1;
        })
      );
    } else if (property === 'supervisor') {
      // eslint-disable-next-line
      setCurrentTasks(
        currentTasks.sort((a, b) => {
          if (!a.Supervisor) return 1;
          if (!b.Supervisor) return -1;
          if (a.Supervisor === b.Supervisor) return 0;
          // eslint-disable-next-line
          return !isAsc
            ? (a.Supervisor.User?.displayName > b.Supervisor.User?.displayName && -1) || 1
            : (a.Supervisor.User?.displayName > b.Supervisor.User?.displayName && 1) || -1;
        })
      );
    }
  };

  useEffect(() => {
    filter();
  }, [filter]);

  useEffect(() => {
    if (!tasks || tasks.length < 1) {
      return;
    }

    setCurrentTasks(tasks);
  }, [tasks]);

  const taskStatus: Select[] = [
    { id: JobStatus.AVAILABLE, name: JobStatus.AVAILABLE },
    { id: JobStatus.ASSIGNED, name: JobStatus.ASSIGNED },
    { id: JobStatus.IN_PROGRESS, name: JobStatus.IN_PROGRESS },
    { id: JobStatus.COMPLETED, name: JobStatus.COMPLETED },
    { id: JobStatus.CANCELLED, name: JobStatus.CANCELLED }
  ];

  return (
    <Paper elevation={0} className={classes.rootTabs}>
      <Typography variant='h4' className={classes.title}>
        Task Details
      </Typography>
      <Divider />
      <TableContainer style={{ boxShadow: 'none' }}>
        <Table size='small'>
          <TableHead>
            <HeaderRow
              height={'0px'}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleSortTasks}
              headers={[
                { id: 'sub', label: '', pT: '16px', pB: '16px' },
                { id: 'id', label: 'ID', sort: true, pT: '16px', pB: '16px' },
                { id: 'driver', label: 'Driver', sort: true, pT: '16px', pB: '16px' },
                { id: 'vehicle', label: 'Vehicle No', sort: true, pT: '16px', pB: '16px' },
                { id: 'supervisor', label: 'Supervisor', sort: true, pT: '16px', pB: '16px' },
                { id: 'status', label: 'Task Status', sort: true, pT: '16px', pB: '16px' }
              ]}
            />

            <HeaderRow
              height={'0px'}
              headers={[
                { id: 'check' },
                {
                  id: 'findId',
                  isInputText: true,
                  value: queryId,
                  handleInputChange: handleQueryIdChange,
                  pT: '0px',
                  pB: '0px',
                  endAdornment: (
                    <InputAdornment position='start'>
                      <Search fontSize='small' color='disabled' />
                    </InputAdornment>
                  )
                },
           
                {
                  id: 'findName',
                  isInputText: true,
                  value: queryName,
                  handleInputChange: handleQueryNameChange,
                  pT: '0px',
                  pB: '0px',
                  endAdornment: (
                    <InputAdornment position='start'>
                      <Search fontSize='small' color='disabled' />
                    </InputAdornment>
                  )
                },
                {
                  id: 'findVehicle',
                  isInputText: true,
                  value: queryVehicleNumber,
                  handleInputChange: handleQueryVehicleChange,
                  pT: '0px',
                  pB: '0px',
                  endAdornment: (
                    <InputAdornment position='start'>
                      <Search fontSize='small' color='disabled' />
                    </InputAdornment>
                  )
                },
                {
                  id: 'findSupervisor',
                  isInputText: true,
                  value: querySupervisor,
                  handleInputChange: handleQuerySupervisorChange,
                  pT: '0px',
                  pB: '0px',
                  endAdornment: (
                    <InputAdornment position='start'>
                      <Search fontSize='small' color='disabled' />
                    </InputAdornment>
                  )
                },
                {
                  id: 'findStatus',
                  isSelect: true,
                  value: queryStatus,
                  selectOption: taskStatus,
                  handleOptionChange: handleQueryStatusChange,
                  pT: '0px',
                  pB: '0px',
                  label: 'Select Status'
                }
              ]}
            />
          </TableHead>

          <TableBody>
            {isLoadingData ? (
              [0, 1, 2, 3].map(index => <BodyRow index={index} key={index} task={dummyJobTask} isLoadingData={isLoadingData} />)
            ) : currentTasks && currentTasks.length > 0 ? (
              currentTasks.map((value, index) => <BodyRow index={index} key={index} task={value} isLoadingData={isLoadingData} />)
            ) : (
              <TableRow>
                <TableCell align='center' colSpan={6}>
                  Data Not Available.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default DriverTable;
