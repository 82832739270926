import React, { FC } from 'react';
import { Table, TableBody, TableHead, TableFooter, TableRow, TableCell, TableContainer } from '@material-ui/core';

import HeaderRow from 'components/HeaderRow';
import TablePagination from 'components/TablePagination';
import BodyRow from './BodyRow';

import TableLoading from 'components/Table/TableLoading';
import { UseTablePagination } from 'hooks/useTablePagination';

export interface Props extends UseTablePagination<LampPost> {
  onOpen: (streetName: string) => () => void;
  onDelete: (id: number) => () => void;
}

const LampPostTable: FC<Props> = ({ onDelete, onOpen, ...props }) => {
  return (
    <TableContainer style={{ boxShadow: 'none', borderRadius: 0 }}>
      <Table size='small'>
        <TableHead>
          <HeaderRow
            order={props.order}
            orderBy={props.orderBy}
            onRequestSort={(_, value) => {
              props.resetPage();
              props.onOrder(value);
            }}
            height={'0px'}
            headers={[
              {
                id: 'streetName',
                label: 'Street Name',
                pT: '16px',
                pB: '16px',
                cellProps: {
                  style: {
                    whiteSpace: 'nowrap'
                  }
                }
              }
              // {
              //   id: 'action',
              //   label: '',
              //   cellProps: {
              //     padding: 'checkbox'
              //   }
              // }
            ]}
          />

          <HeaderRow
            height={'0px'}
            headers={[
              {
                id: 'findMaterial',
                isInputText: true,
                value: props.query('streetName'),
                handleInputChange: (value: any) => props.setQuery({ streetName: value }),
                pT: '0px',
                pB: '0px',
                pL: '16px',
                pR: '8px'
              }
              // {
              //   id: 'findAction'
              // }
            ]}
          />
        </TableHead>

        <TableBody>
          {props.loading ? (
            <TableLoading col={1} />
          ) : props.isEmpty ? (
            <TableRow>
              <TableCell align='center'>Data Not Available.</TableCell>
            </TableRow>
          ) : (
            props.data.map((lp, index) => <BodyRow key={index} onDelete={onDelete(lp.id || 0)} onOpen={onOpen(lp.streetName)} {...lp} />)
          )}
        </TableBody>
        <TableFooter>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            count={props.count}
            rowsPerPage={props.perPage}
            page={props.page}
            onChangePage={props.onPageChange}
            onChangeRowsPerPage={props.onRowsChange}
          />
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default LampPostTable;
