import React, { FC, useState, useEffect } from 'react';
import axios from 'axios';
import { green } from '@material-ui/core/colors';
import { Theme, makeStyles, Button, Dialog, DialogActions, DialogContent, Typography, Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { EXPORT_INVOICE_URL, SEND_INVOICE_URL } from 'constants/url';
import { StandardConfirmationDialog } from 'components/AppDialog';
import ActionSnackbar from 'components/ActionSnackbar';

interface Props {
  invoiceId: number;
  invoiceNo: string;
  isGst: boolean;
  open: boolean;
  handleClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  imageFile: {
    maxWidth: '80%',
    margin: 'auto 0'
  },
  loadingRoot: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  },
  framePdf: {
    minHeight: '150px'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

const InvoiceFileModal: FC<Props> = props => {
  const classes = useStyles();
  const cancelToken = axios.CancelToken.source();
  const { invoiceId, invoiceNo, isGst, open, handleClose } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingEmail, setLoadingEmail] = useState<boolean>(false);
  const [openEmailDialog, setOpenEmailDialog] = useState<boolean>(false);
  const [fileUrl, setFileUrl] = useState<string>('');

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');

  const getInvoice = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(EXPORT_INVOICE_URL(invoiceId), {
        responseType: 'blob',
        headers: {
          Accept: 'application/octet-stream'
        },
        cancelToken: cancelToken.token
      });

      const objectBlob = new Blob([data], { type: 'application/pdf' });
      const url = URL.createObjectURL(objectBlob);
      setFileUrl(url);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', `${isGst ? 'tax-' : ''}invoice-${invoiceNo}.pdf`);
    document.body.appendChild(link);
    link.click();
  };

  const handleSendEmail = async () => {
    try {
      setLoadingEmail(true);
      await axios.get(SEND_INVOICE_URL(invoiceId));
      handleCloseEmailDialog();
      handleOpenSnackbar('success', 'Success send email.');
    } catch (error) {
      console.error(error);
      handleOpenSnackbar('error', 'Failed send email.');
    } finally {
      setLoadingEmail(false);
    }
  };

  const handleSendEmailConfirm: React.MouseEventHandler<HTMLButtonElement> = () => {
    handleSendEmail();
  };

  const handleOpenEmailDialog = () => {
    setOpenEmailDialog(true);
  };

  const handleCloseEmailDialog = () => {
    setOpenEmailDialog(false);
  };

  const handleOpenSnackbar = (type: 'success' | 'error', message: string) => {
    setOpenSnackbar(true);
    setSnackbarMessage(message);
    setSnackbarVarient(type);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
    setSnackbarMessage('');
    setSnackbarVarient('success');
  };

  useEffect(() => {
    if (!invoiceId || !open) {
      return;
    }

    getInvoice();

    return () => {
      cancelToken.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId, open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
          setFileUrl('');
          cancelToken.cancel();
        }}
        fullWidth={true}
        maxWidth='md'
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogContent style={{ padding: loading ? '24px' : 0 }}>
          {loading ? (
            <Box display={'flex'} justifyContent={'center'} flexDirection={'row'} style={{ gap: 8 }}>
              <Box>
                <CircularProgress color='secondary' size={25} />
              </Box>

              <Typography component={'div'} style={{ fontStyle: 'italic' }}>
                Loading Invoice...
              </Typography>
            </Box>
          ) : fileUrl ? (
            <iframe title='file' className={classes.framePdf} style={{ width: '100%', minHeight: '500px' }} src={fileUrl}></iframe>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
              setFileUrl('');
              cancelToken.cancel();
            }}
            color='primary'
          >
            Close
          </Button>
          <Button disabled={loading} onClick={handleDownload} color='primary'>
            Download
          </Button>
          <div className={classes.wrapper}>
            <Button
              disabled={loading}
              onClick={handleOpenEmailDialog}
              disableElevation
              color='primary'
              variant='contained'
              style={{ minHeight: '24px' }}
            >
              {loadingEmail ? <CircularProgress size={24} className={classes.buttonProgress} /> : 'Send to Email'}
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      <StandardConfirmationDialog
        variant='warning'
        titleMessage='Confirmation'
        message='Are you sure want to re-send Invoice to Merchant?'
        confirmButtonText='Send Now'
        open={openEmailDialog}
        loading={loadingEmail}
        handleClose={handleCloseEmailDialog}
        onConfirm={handleSendEmailConfirm}
      />

      <ActionSnackbar variant={snackbarVarient} message={snackbarMessage} open={openSnackbar} handleClose={handleCloseSnackbar} />
    </div>
  );
};

export default InvoiceFileModal;
