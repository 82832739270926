import React, { FC } from 'react';
import { Table, TableBody, TableHead, TableFooter, TableRow, TableCell, TableContainer } from '@material-ui/core';

import HeaderRow, { Header } from 'components/HeaderRow';
import TablePagination from 'components/TablePagination';
import BodyRow from './BodyRow';

import TableLoading from 'components/Table/TableLoading';
import { UseTablePagination } from 'hooks/useTablePagination';

interface Props extends UseTablePagination<TransactionList> {
  onGetResponse: (id: number) => () => void;
  onProceessFazz: (data: { type: string; id: number }) => () => void;
}

const headers = (isShow: boolean): Header[] => {
  let header: Header[] = [
    {
      id: 'id',
      label: 'ID',
      sort: true,
      pT: '16px',
      pB: '16px',
      cellProps: {
        style: {
          width: '10%',
          minWidth: '10%'
        }
      }
    },
    {
      id: 'description',
      label: 'Date Time',
      cellProps: {
        style: {
          whiteSpace: 'nowrap',
          width: '10%',
          minWidth: '10%'
        }
      }
    },
    {
      id: 'dateTime',
      label: 'Description',
      cellProps: {
        style: {
          whiteSpace: 'nowrap',
          width: '10%',
          minWidth: '10%'
        }
      }
    },
    {
      id: 'paymentMethod',
      label: 'Payment Method',
      sort: true,
      align: 'center',
      cellProps: {
        style: {
          width: '10%',
          minWidth: '10%',
          whiteSpace: 'nowrap'
        }
      }
    },
    {
      id: 'amount',
      label: 'Amount',
      sort: true,
      align: 'right',
      cellProps: {
        style: {
          width: '10%',
          minWidth: '10%'
        }
      }
    },
    {
      id: 'status',
      label: 'Status',
      align: 'center',
      sort: true,
      cellProps: {
        style: {
          width: '10%',
          minWidth: '10%'
        }
      }
    }
  ];

  if (isShow) {
    header = [
      ...header,
      {
        id: 'action',
        label: 'Action',
        align: 'center',
        sort: true,
        cellProps: {
          style: {
            width: '10%',
            minWidth: '10%'
          }
        }
      }
    ];
  }

  return header;
};

const TransactionTable: FC<Props> = ({ onGetResponse, onProceessFazz, ...props }) => {
  return (
    <TableContainer style={{ boxShadow: 'none', borderRadius: 0 }}>
      <Table size='small'>
        <TableHead>
          <HeaderRow
            order={props.order}
            orderBy={props.orderBy}
            onRequestSort={(_, value) => {
              props.resetPage();
              props.onOrder(value);
            }}
            height={'0px'}
            headers={headers(process.env.REACT_APP_TRX_PROCESS_BUTTONS === 'show')}
          />
        </TableHead>

        <TableBody>
          {props.loading ? (
            <TableLoading col={process.env.REACT_APP_TRX_PROCESS_BUTTONS === 'show' ? 7 : 6} />
          ) : props.isEmpty ? (
            <TableRow>
              <TableCell align='center' colSpan={process.env.REACT_APP_TRX_PROCESS_BUTTONS === 'show' ? 7 : 6}>
                Data Not Available.
              </TableCell>
            </TableRow>
          ) : (
            props.data.map((trx, index) => (
              <BodyRow key={index} {...trx} onGetResponse={onGetResponse(trx.id)} onProceessFazz={onProceessFazz({ type: trx.type, id: trx.id })} />
            ))
          )}
        </TableBody>
        <TableFooter>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            count={props.count}
            rowsPerPage={props.perPage}
            page={props.page}
            onChangePage={props.onPageChange}
            onChangeRowsPerPage={props.onRowsChange}
          />
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default TransactionTable;
