import React, { FC } from 'react';
import { Table, TableBody, TableHead, TableFooter, TableRow, TableCell, InputAdornment, IconButton, TableContainer } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';

import HeaderRow, { Header } from 'components/HeaderRow';
import TablePagination from 'components/TablePagination';
import BodyRow from './BodyRow';

import TableLoading from 'components/Table/TableLoading';
import { UseTablePagination } from 'hooks/useTablePagination';
import { getGroupJobTypeSelect, getJobStatusSelect, getJobTypeSelect, getPaymentMethodSelect, getVehicleTypeSelect, getWorkTypeSelect } from 'utils';
import JobType from 'typings/enum/JobType';

interface Props extends UseTablePagination<JobList> {
  dateRange: string;
  completeDateRange: string;

  handleCalendar: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleCompleteCalendar: (event: React.MouseEvent<HTMLButtonElement>) => void;
}
const ow = [JobType.OU_WORKS_DISPOSAL, JobType.OU_WORKS_PURCHASE, `${JobType.OU_WORKS}_ALL`];
const JobTable: FC<Props> = ({ dateRange, completeDateRange, handleCalendar, handleCompleteCalendar, ...props }) => {
  let filter = [
    {
      id: 'findId',
      isInputText: true,
      value: props.query('id'),
      handleInputChange: (value: any) => props.setQuery({ id: value }),
      pT: '0px',
      pB: '0px',
      pL: '16px',
      pR: '8px'
    },
    {
      id: 'findJobOwner',
      isInputText: true,
      value: props.query('jobTitle'),
      handleInputChange: (value: any) => props.setQuery({ jobTitle: value }),
      pT: '0px',
      pB: '0px',
      pL: '16px',
      pR: '8px'
    },
    {
      id: 'findJobType',
      label: 'Select Type',
      value: props.query('jobType') === JobType.OU_WORKS ? `${JobType.OU_WORKS}_${props.query('workType')}` : props.query('jobType'),
      isGroupSelect: true,
      selectOption: getGroupJobTypeSelect(),
      handleOptionChange: (value: any) => {
        if (ow.includes(value)) {
          const [_, wt] = value.split('OU_WORKS_');
          props.setQuery({ jobType: JobType.OU_WORKS, workType: wt });
        } else {
          props.setQuery({ jobType: value, workType: null });
        }
      },
      pT: '0px',
      pB: '0px',
      pL: '16px',
      pR: '8px',
      cellProps: {
        style: {
          minWidth: '175px'
        }
      }
    },

    {
      id: 'findTruckType',
      label: 'Select Type',
      value: props.query('truckType'),
      isSelect: true,
      selectOption: getVehicleTypeSelect(),
      handleOptionChange: (value: any) => props.setQuery({ truckType: value }),
      pT: '0px',
      pB: '0px',
      pL: '16px',
      pR: '8px',
      cellProps: {
        style: {
          minWidth: '175px'
        }
      }
    },
    {
      id: 'findJobTime',
      isInputText: true,
      value: dateRange,
      readOnly: true,
      pT: '0px',
      pB: '0px',
      pL: '16px',
      pR: '8px',
      cellProps: {
        style: { minWidth: '200px' }
      },
      endAdornment: (
        <InputAdornment position='end'>
          <IconButton aria-label='toggle date-time-picker-range' onClick={handleCalendar}>
            <DateRangeIcon fontSize='inherit' />
          </IconButton>
        </InputAdornment>
      )
    },
    {
      id: 'findCompleteDate',
      isInputText: true,
      value: completeDateRange,
      readOnly: true,
      pT: '0px',
      pB: '0px',
      pL: '16px',
      pR: '8px',
      cellProps: {
        style: { minWidth: '200px' }
      },
      endAdornment: (
        <InputAdornment position='end'>
          <IconButton aria-label='toggle date-time-picker-range' onClick={handleCompleteCalendar}>
            <DateRangeIcon fontSize='inherit' />
          </IconButton>
        </InputAdornment>
      )
    },
    {
      id: 'findJobStatus',
      isSelect: true,
      label: 'Select Status',
      value: props.query('jobStatus'),
      selectOption: getJobStatusSelect(),
      handleOptionChange: (value: any) => props.setQuery({ jobStatus: value }),
      pT: '0px',
      pB: '0px',
      pL: '16px',
      pR: '8px',
      cellProps: {
        style: {
          minWidth: '175px'
        }
      }
    },
    {
      id: 'findTotalPrice',
      isInputText: true,
      value: props.query('price'),
      handleInputChange: (value: any) => props.setQuery({ price: value }),
      pT: '0px',
      pB: '0px',
      cellProps: {
        style: { minWidth: '175px' }
      }
    },
    {
      id: 'findPaymentMethod',
      isSelect: true,
      label: 'Select Payment',
      value: props.query('paymentMethod'),
      selectOption: getPaymentMethodSelect(),
      handleOptionChange: (value: any) => props.setQuery({ paymentMethod: value }),
      pT: '0px',
      pB: '0px',
      pL: '16px',
      pR: '8px',
      cellProps: {
        style: {
          minWidth: '175px'
        }
      }
    }
  ];

  let head: Header[] = [
    {
      id: 'id',
      label: 'ID',
      sort: true,
      pT: '16px',
      pB: '16px',
      cellProps: {
        style: {
          width: '10%',
          minWidth: '10%',
          whiteSpace: 'nowrap'
        }
      }
    },
    {
      id: 'jobOwner',
      label: 'Job Owner',
      cellProps: {
        style: {
          whiteSpace: 'nowrap',
          width: '25%',
          minWidth: '40%'
        }
      }
    },
    {
      id: 'jobType',
      label: 'Job Type',
      sort: true,
      pT: '16px',
      pB: '16px',
      cellProps: {
        style: {
          width: '10%',
          minWidth: '10%',
          whiteSpace: 'nowrap'
        }
      }
    },

    {
      id: 'truckType',
      label: 'Vehicle Type',
      sort: true,
      pT: '16px',
      pB: '16px',
      cellProps: {
        style: {
          width: '10%',
          minWidth: '10%',
          whiteSpace: 'nowrap'
        }
      }
    },
    {
      id: 'jobTime',
      label: 'Project Date & Time',
      sort: true,
      pT: '16px',
      pB: '16px',
      cellProps: {
        style: {
          width: '10%',
          minWidth: '10%',
          whiteSpace: 'nowrap'
        }
      }
    },
    {
      id: 'completeDate',
      label: 'Date Completed',
      sort: true,
      pT: '16px',
      pB: '16px',
      cellProps: {
        style: {
          width: '10%',
          minWidth: '10%',
          whiteSpace: 'nowrap'
        }
      }
    },
    {
      id: 'jobStatus',
      label: 'Job Status',
      sort: true,
      pT: '16px',
      pB: '16px',
      cellProps: {
        style: {
          width: '10%',
          minWidth: '10%',
          whiteSpace: 'nowrap'
        }
      }
    },

    {
      id: 'price',
      label: 'Total Price',
      sort: true,
      pT: '16px',
      pB: '16px',
      cellProps: {
        style: {
          width: '10%',
          minWidth: '10%',
          whiteSpace: 'nowrap'
        }
      }
    },
    {
      id: 'paymentMethod',
      label: 'Payment Method',
      sort: true,
      pT: '16px',
      pB: '16px',
      cellProps: {
        style: {
          width: '10%',
          minWidth: '10%',
          whiteSpace: 'nowrap'
        }
      }
    }
  ];

  if (!!props.query('jobStatus') && !!!props.query('hasRequestQuote')) {
    head = head.filter(v => v.id !== 'jobStatus');
    filter = filter.filter(v => v.id !== 'findJobStatus');
  }

  return (
    <TableContainer style={{ boxShadow: 'none', borderRadius: 0 }}>
      <Table size='small'>
        <TableHead>
          <HeaderRow
            order={props.order}
            orderBy={props.orderBy}
            onRequestSort={(_, value) => {
              props.resetPage();
              props.onOrder(value);
            }}
            height={'0px'}
            headers={head}
          />

          <HeaderRow height={'0px'} headers={filter} />
        </TableHead>

        <TableBody>
          {props.loading ? (
            <TableLoading col={head.length} />
          ) : props.isEmpty ? (
            <TableRow>
              <TableCell align='center' colSpan={head.length}>
                Data Not Available.
              </TableCell>
            </TableRow>
          ) : (
            props.data.map((job, index) => (
              <BodyRow visibleStatus={!!props.query('jobStatus') && !!!props.query('hasRequestQuote')} key={index} {...job} />
            ))
          )}
        </TableBody>
        <TableFooter>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            count={props.count}
            rowsPerPage={props.perPage}
            page={props.page}
            onChangePage={props.onPageChange}
            onChangeRowsPerPage={props.onRowsChange}
          />
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default JobTable;
