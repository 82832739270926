import React, { FC, useState } from 'react';
import axios from 'axios';
import {
  Theme,
  makeStyles,
  createStyles,
  withStyles,
  WithStyles,
  Button,
  Dialog,
  CircularProgress,
  IconButton,
  Typography,
  TextField
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import { green } from '@material-ui/core/colors';
import { WEB_TONNAGE_BASE_URL } from 'constants/url';
import useSnackbar from 'hooks/useSnackbar';
import Snackbar from 'components/Snackbar';
import { dummyTonnage } from 'constants/DummyData';
import NumberFormat from 'react-number-format';

interface Props {
  open: boolean;
  data: TonnageTemplate;
  setData: React.Dispatch<React.SetStateAction<TonnageTemplate>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  reload: (cb?: ((key: { [key: string]: any }) => any) | undefined) => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700]
    }
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
      prefix='$'
    />
  );
}

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h4'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const TonnageForm: FC<Props> = props => {
  const classes = useStyles();
  const { open, setOpen, data, setData } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState({
    tonnageName: ''
  });

  const { openSnacbar, snackbar } = useSnackbar();

  const handleSubmit = async () => {
    try {
      setError({ tonnageName: '' });
      if (!validation()) return;
      setLoading(true);
      await axios[!!data.id ? 'put' : 'post'](WEB_TONNAGE_BASE_URL, data);
      openSnacbar(!!data.id ? 'Tonnage successfully updated' : 'Tonnage successfull added');
      handleClose();
      props.reload();
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    handleResetInput();
  };

  const handleResetInput = () => {
    setError({ tonnageName: '' });
    setData(dummyTonnage);
  };

  const validation = () => {
    let valid = true;

    if (data.tonnageName === '' || !data.tonnageName.trim()) {
      setError(state => ({ ...state, material: 'Name is required' }));
      valid = false;
    }

    return valid;
  };

  return (
    <div>
      <Dialog fullWidth={true} maxWidth='sm' onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
        <DialogTitle id='customized-dialog-title' onClose={handleClose}>
          {!!data.id ? 'Edit' : 'Create New'} Tonnage
        </DialogTitle>
        <DialogContent dividers style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <TextField
            variant='outlined'
            required
            type='text'
            label='Tonnage Name'
            value={data.tonnageName || ''}
            onChange={e => {
              e.persist();
              setData(prev => ({ ...prev, tonnageName: e.target.value as string }));
            }}
            error={error.tonnageName !== ''}
            helperText={error.tonnageName}
          />

          <TextField
            variant='outlined'
            label='Unit Price'
            value={data.price}
            onChange={e => setData(prev => ({ ...prev, price: +e.target.value }))}
            InputProps={{
              inputComponent: NumberFormatCustom as any
            }}
          />

          <TextField
            variant='outlined'
            label='Overtime Price'
            value={data.overtimePrice}
            onChange={e => setData(prev => ({ ...prev, overtimePrice: +e.target.value }))}
            InputProps={{
              inputComponent: NumberFormatCustom as any
            }}
          />

          <TextField
            variant='outlined'
            label='Order'
            value={data.order || 0}
            onChange={e => {
              e.persist();
              if (Number.isInteger(+e.target.value)) {
                setData(prev => ({ ...prev, order: +e.target.value }));
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' color='primary' disabled={loading} onClick={handleClose}>
            Cancel
          </Button>
          <div className={classes.wrapper}>
            <Button variant='contained' color='primary' disabled={loading} disableElevation onClick={handleSubmit}>
              {!!data.id ? 'Save change' : 'Add New'}
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </DialogActions>
      </Dialog>

      <Snackbar {...snackbar} />
    </div>
  );
};

export default TonnageForm;
