import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  table: {
    borderWidth: '1px',
    borderColor: theme.palette.divider,
    borderStyle: 'solid',
    borderRadius: '16px'
  },
  box: {
    gap: '16px'
  },
  '@keyframes tilt-shaking': {
    '0%': { transform: 'rotate(0deg)' },
    '25%': { transform: 'rotate(5deg)' },
    '50%': { transform: 'rotate(0eg)' },
    '75%': { transform: 'rotate(-5deg)' },
    '100%': { transform: 'rotate(0deg)' }
  },
  infoIcon: {
    animation: '$tilt-shaking 0.3s infinite',
    fontSize: '13px'
  }
}));

export default useStyles;
