import React, { useState, useEffect, useRef, FC } from 'react';
import axios from 'axios';
import { makeStyles, Theme, Paper, Button, Grid, Box, Badge, ClickAwayListener } from '@material-ui/core';
import { WEB_JOB_BASE_URL, WEB_JOB_TOTAL_QUOTE_BASE_URL } from 'constants/url';
import JobTable from './components/JobTable';
import MainTemplate from 'components/Template/MainTemplate';
import clsx from 'clsx';
import useTablePagination from 'hooks/useTablePagination';
import JobType from 'typings/enum/JobType';
import JobStatus from 'typings/enum/JobStatus';
import PositionedPopper from 'components/PositionedPopper';
import DateTimeRangePicker from 'components/DateTimeRangePicker';
import ExportJob from './components/ExportJob';
import { snackCaseToSentence } from 'utils';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    borderRadius: '5px',
    boxShadow: '8px 4px 24px rgba(0, 0, 0, 0.08)'
  },
  button: {
    padding: '8px 16px 8px 16px',
    marginRight: '16px',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 400,
    borderRadius: '5px',
    color: theme.palette.black[50],
    '&.MuiButton-outlined:hover': {
      backgroundColor: '#FEF1D2',
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main
    }
  },
  buttonBackground: {
    backgroundColor: '#FEF1D2',
    color: theme.palette.primary.main
  },
  badgePending: {
    width: '24px',
    height: '24Px',
    top: '-0.4em',
    right: '-1.0em',
    borderRadius: '100px',
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '20px',
    backgroundColor: theme.palette.error.main
  }
}));

const filterButton = [
  JobStatus.DRAFT,
  JobStatus.AVAILABLE,
  JobStatus.ASSIGNED,
  JobStatus.IN_PROGRESS,
  JobStatus.COMPLETED,
  JobStatus.CANCELLED,
];

const JobList:FC = () => {
  const classes = useStyles();
  const clearRef = useRef<HTMLButtonElement | null>(null);
  const closeRef = useRef<HTMLButtonElement | null>(null);

  const completeClearRef = useRef<HTMLButtonElement | null>(null);
  const completeCloseRef = useRef<HTMLButtonElement | null>(null);

  const [loadingQuote, setLoadingQuote] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [dateRange, setDateRange] = useState('');
  const [dateBy, setDateBy] = useState('');
  const [totalQuote, setTotalQuote] = useState(0);

  const [completeAnchorEl, setCompleteAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [openCompleteCalendar, setOpenCompleteCalendar] = useState(false);
  const [dateCompleteRange, setDateCompleteRange] = useState('');
  const [completeDateBy, setCompleteDateBy] = useState('');

  const table = useTablePagination<JobList>(WEB_JOB_BASE_URL, {
    selector: data => data.jobs,
    sort: {
      order: 'desc',
      orderBy: 'createdAt'
    }
  });

  const handleTimeChange = (query: string) => {
    const splitted = query.split('|');
    const removedTime = splitted.map(dt => dt.substring(0, 10));
    setDateRange(removedTime.join(' ~ '));
    table.setQuery({ startDate: removedTime[0], endDate: removedTime[1] });
  };

  const handleCalendar = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenCalendar(true);
  };

  const handleCompleteCalendar = (event: React.MouseEvent<HTMLButtonElement>) => {
    setCompleteAnchorEl(event.currentTarget);
    setOpenCompleteCalendar(true);
  };

  const handleCompleteTimeChange = (query: string) => {
    const splitted = query.split('|');
    const removedTime = splitted.map(dt => dt.substring(0, 10));
    setDateCompleteRange(removedTime.join(' ~ '));
    table.setQuery({ completedStart: removedTime[0], completedEnd: removedTime[1] });
  };

  const refreshList = () => {
    table.clear();
    setDateRange('')
    setDateCompleteRange('')
    setDateBy('');
    setCompleteDateBy('')
    clearRef.current?.click();
    closeRef.current?.click();
    completeClearRef.current?.click()
    completeCloseRef.current?.click();
  };

  useEffect(() => {
    (async () => {
      try {
        setLoadingQuote(true);
        const { data } = await axios.get(WEB_JOB_TOTAL_QUOTE_BASE_URL);
        setTotalQuote(data?.total || 0);
      } catch (err) {
        console.error('err: ', err);
      } finally {
        setLoadingQuote(false);
      }
    })();
  }, []);

  return (
    <MainTemplate
      title='Job List'
      subtitle='Display the data of either Job Owner OR Drivers'
      refreshButtonProps={{ onClick: refreshList }}
      primaryButton={
        <Button variant='contained' disableElevation color='primary' onClick={() => setOpenExport(true)}  style={{ textTransform: 'none', padding: '8px 16px 8px 16px' }}>
          Export Job
        </Button>
      }
    >
      <Grid container direction='row' spacing={3} justify='space-between'>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Box display='flex' justifyContent='flex-start' alignItems='center' style={{ gap: '8px' }}>
            <div  style={{flexGrow :1}}>
              <Button
              fullWidth
                variant='outlined'
                onClick={() => {
                  table.clear();
                }}
                color={!!!table.query('hasRequestQuote') && !!!table.query('jobStatus') ? 'primary' : 'default'}
                className={clsx(classes.button, { [classes.buttonBackground]: !!!table.query('hasRequestQuote') && !!!table.query('jobStatus') })}
              >
                All Jobs
              </Button>
            </div>

            <div style={{flexGrow :1}}>
              <Button
              fullWidth
                variant='outlined'
                color={!!table.query('hasRequestQuote') ? 'primary' : 'default'}
                disabled={loadingQuote || totalQuote === 0}
                onClick={() => {
                  table.setQuery({ jobType: JobType.TRIPWORKS, jobStatus: JobStatus.DRAFT, hasRequestQuote: 1 });
                }}
                className={clsx(classes.button, { [classes.buttonBackground]: !!table.query('hasRequestQuote') })}
              >
                <Badge badgeContent={totalQuote} color='primary' classes={{ badge: classes.badgePending }}>
                  Request for Quote
                </Badge>
              </Button>
              </div>

            {filterButton.map((v) =>  <div style={{flexGrow: 1}} key={v}>
              <Button
              fullWidth
                variant='outlined'
                color={!!!table.query('hasRequestQuote') && table.query('jobStatus')  === v ? 'primary' : 'default' }
                onClick={() => {
                  table.setQuery({ jobStatus: v, hasRequestQuote: 0 });
                }}
                className={clsx(classes.button, { [classes.buttonBackground]: !!!table.query('hasRequestQuote') && table.query('jobStatus')  === v })}
              >
                {snackCaseToSentence(v)}
              </Button>
            </div>)}
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={0} className={classes.wrapper}>
            <JobTable {...table} dateRange={dateRange} completeDateRange={dateCompleteRange} handleCalendar={handleCalendar} handleCompleteCalendar={handleCompleteCalendar} />
            <ClickAwayListener onClickAway={() => setOpenCalendar(false)} mouseEvent='onMouseDown' touchEvent='onTouchStart'>
              <div>
                <PositionedPopper
                  open={openCalendar}
                  anchorEl={anchorEl}
                  placement='bottom'
                  containerWidth={200}
                  fadeTransition={350}
                  keepMounted={openCalendar}
                >
                  <DateTimeRangePicker
                    mode='date'
                    currentValue={dateRange}
                    dateBy={dateBy}
                    clearRef={clearRef}
                    closeRef={closeRef}
                    disabledCustomDate={dateBy !== '5'}
                    setOpenPopper={setOpenCalendar}
                    options={[
                      { key: '1', label: 'Today' },
                      { key: '2', label: 'Tomorrow' },
                      { key: '3', label: 'This Week' },
                      { key: '4', label: 'This Month' },
                      { key: '5', label: 'Custom Date' }
                    ]}
                    onChange={handleTimeChange}
                    onSelectedChange={value => setDateBy(value)}
                    onClear={() => {
                      setDateBy('');
                    }}
                  />
                </PositionedPopper>
              </div>
            </ClickAwayListener>

            <ClickAwayListener onClickAway={() => setOpenCompleteCalendar(false)} mouseEvent='onMouseDown' touchEvent='onTouchStart'>
              <div>
                <PositionedPopper
                  open={openCompleteCalendar}
                  anchorEl={completeAnchorEl}
                  placement='bottom'
                  containerWidth={200}
                  fadeTransition={350}
                  keepMounted={openCompleteCalendar}
                >
                  <DateTimeRangePicker
                    mode='date'
                    currentValue={dateCompleteRange}
                    dateBy={completeDateBy}
                    clearRef={completeClearRef}
                    closeRef={completeCloseRef}
                    disabledCustomDate={completeDateBy !== '5'}
                    setOpenPopper={setOpenCompleteCalendar}
                    options={[
                      { key: '1', label: 'Today' },
                      { key: '2', label: 'Tomorrow' },
                      { key: '3', label: 'This Week' },
                      { key: '4', label: 'This Month' },
                      { key: '5', label: 'Custom Date' }
                    ]}
                    onChange={handleCompleteTimeChange}
                    onSelectedChange={value => setCompleteDateBy(value)}
                    onClear={() => {
                      setCompleteDateBy('');
                    }}
                  />
                </PositionedPopper>
              </div>
            </ClickAwayListener>
          </Paper>
        </Grid>
      </Grid>

      <ExportJob open={openExport} onClose={() => setOpenExport(false)} />
    </MainTemplate>
  );
};

export default JobList;
