import React, { FC, useState, useCallback, useEffect } from 'react';
import useRouter from 'hooks/useRouter';
import axios, { CancelTokenSource } from 'axios';

import { dummyJob } from 'constants/DummyData';
import { INDIVIDUAL_JOB_URL } from 'constants/url';

import InfoCard from './components/InfoCard';
import JobInformation from './components/JobInfomation';
import TripsDetail from './components/TripsDetail';

import MainTemplate from 'components/Template/MainTemplate';
import AdditionalTable from './components/AdditionalTable';
import TruckType from 'typings/enum/TruckType';
import PriceTable from './components/PriceTable';
import { allTaskOneDriverType } from 'utils';

const JobDriverDetailPage: FC = () => {
  const { match } = useRouter();
  const id = Number(match.params.id);
  const merchantId = Number(match.params.merchant);
  const [job, setJob] = useState<JobModel>(dummyJob);
  const [tasks, setTasks] = useState<JobTask[]>([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [additionalItems, setAdditionalItems] = useState<JobAdditionalItem[]>([]);

  const fetchData = useCallback(async () => {
    if (!id) {
      return;
    }

    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    setIsLoadingData(true);

    try {
      const { data } = await axios.get(`${INDIVIDUAL_JOB_URL(id)}/${merchantId}/driver`, { cancelToken: cancelTokenSource.token });

      if (data) {
        setJob(data);
        setTasks(allTaskOneDriverType.includes(data.truckType) ? (data.JobTasks.length > 0 ? [data.JobTasks[0]] : []) : data.JobTasks);
        setAdditionalItems(
          data.JobAdditionals.filter((v: JobAdditional) => v.status === 'CONFIRM').flatMap((v: JobAdditional) => v.JobAdditionalItems)
        );
      }
    } catch (err) {
      console.error('err: ', err);
    }
    setIsLoadingData(false);
    return () => cancelTokenSource.cancel();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const refreshPage = () => {
    fetchData();
  };

  useEffect(() => {
    fetchData();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, fetchData]);

  return (
    <MainTemplate
      title='Job Details'
      breadcrumb={true}
      refreshButtonProps={{
        onClick: refreshPage
      }}
      primaryButton={false}
      links={[{ click: true, name: 'All Jobs', links: 'goback' }, { click: false, name: `#${id}`, links: '' }]}
    >
      <InfoCard job={job} isLoading={isLoadingData} />
      <JobInformation loading={isLoadingData} job={job} />
      {allTaskOneDriverType.includes(job.truckType as TruckType) ? (
        <AdditionalTable isLoadingData={isLoadingData} additionalItems={additionalItems} job={job} />
      ) : null}
      <PriceTable job={job} />

      <TripsDetail tasks={tasks} isLoadingData={isLoadingData} />
    </MainTemplate>
  );
};

export default JobDriverDetailPage;
