const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const LOGIN_URL = `${BASE_URL}/login`;
export const LOGOUT_URL = `${BASE_URL}/logout`;
export const GET_TOKEN = `${BASE_URL}/access-token`;
export const FORGOT_PASSWORD_URL = `${BASE_URL}/forgotpassword`;
export const ACTIVATION_URL = `${BASE_URL}/activation`;
export const RESET_PASSWORD_URL = `${BASE_URL}/resetpassword`;
export const CHECK_TOKEN_RESET_PASSWORD_URL = `${BASE_URL}/checkresettoken`;
export const CHANGE_PASSWORD_URL = `${BASE_URL}/changepassword`;
export const CHECK_USER_FORGOT_PASSWORD_URL = `${BASE_URL}/checkuserforgot`;
export const SETTINGS_URL = `${BASE_URL}/settings`;
export const APPLOG_BASE_URL = `${BASE_URL}/applog`;

export const USER_BASE_URL = `${BASE_URL}/users`;
export const GET_CURRENT_USER_URL = `${USER_BASE_URL}/current`;
export const GET_CHECK_USER_URL = `${USER_BASE_URL}/check`;
export const GET_CURRENT_USER_PASSWORD_URL = `${USER_BASE_URL}/currentpassword`;
export const GET_EDIT_USER_URL = (userId: number) => `${USER_BASE_URL}/${userId}`;
export const GET_DEACTIVATE_USER_URL = (userId: number) => `${USER_BASE_URL}/${userId}/unactivate`;
export const GET_ACTIVATE_USER_URL = (userId: number) => `${USER_BASE_URL}/${userId}/activate`;
export const GET_USER_BY_ROLE_URL = `${USER_BASE_URL}/search`;
export const UPDATE_TOKEN = `${USER_BASE_URL}/account/device`;
export const DELETE_USER_URL = (userId: number[]) => `${USER_BASE_URL}/${userId}`;

export const MERCHANTS_BASE_URL = `${BASE_URL}/merchants`;
export const GET_MANUAL_TOPUP_MERCHANTS_BASE_URL = (id: number) => `${MERCHANTS_BASE_URL}/${id}/manual-topup`;
export const INDIVIDUAL_MERCHANT_URL = (id: number) => `${MERCHANTS_BASE_URL}/${id}`;
export const INDIVIDUAL_STATUS_MERCHANT_URL = (id: number) => `${MERCHANTS_BASE_URL}/${id}/status`;
export const EXPORT_MERCHANT_BALANCES = `${MERCHANTS_BASE_URL}/export-balances`;
export const MERCHANTS_TASKS_CHECK_BASE_URL = `${MERCHANTS_BASE_URL}/tasks-check`;

export const DRIVERS_BASE_URL = `${BASE_URL}/drivers`;
export const INDIVIDUAL_DRIVER_URL = (id: number) => `${DRIVERS_BASE_URL}/${id}`;
export const INDIVIDUAL_STATUS_DRIVER_URL = (id: number) => `${DRIVERS_BASE_URL}/${id}/status`;

export const JOBS_TASK_BASE_URL = `${BASE_URL}/job-tasks`;
export const JOBS_TASK_DRIVER_BASE_URL = (id: number) => `${JOBS_TASK_BASE_URL}/${id}/driver`;
export const JOBS_BASE_URL = `${BASE_URL}/jobs`;
export const INDIVIDUAL_JOB_URL = (id: number) => `${JOBS_BASE_URL}/${id}`;
export const EXPORT_JOB_URL = (id: number) => `${INDIVIDUAL_JOB_URL(id)}/export`;

export const INVOICES_BASE_URL = `${BASE_URL}/invoices`;
export const EXPORT_INVOICE_URL = (id: number) => `${INVOICES_BASE_URL}/${id}/pdf`;
export const SEND_INVOICE_URL = (id: number) => `${INVOICES_BASE_URL}/${id}/send-pdf`;

export const BANKS_BASE_URL = `${BASE_URL}/banks`;
export const SUPERVISORS_BASE_URL = `${BASE_URL}/supervisors`;

export const TRANSACTIONS_BASE_URL = `${BASE_URL}/transactions`;
export const TRANSACTIONS_WEB_BASE_URL = `${TRANSACTIONS_BASE_URL}/webs`;
export const TRANSACTIONS_CREATE_TOPUP_BASE_URL = `${TRANSACTIONS_BASE_URL}/create-manual-pay`;
export const TRANSACTIONS_CONFIRM_TOPUP_BASE_URL = `${TRANSACTIONS_BASE_URL}/confirm-manual-pay`;
export const CAPTURE_TRANSACTIONS_URL = `${TRANSACTIONS_BASE_URL}/capture`;
export const PAID_TRANSACTIONS_URL = `${TRANSACTIONS_BASE_URL}/paid`;
export const PAYOUT_LIST_URL = `${TRANSACTIONS_BASE_URL}/payout`;
export const GET_TRANSACTION_DETAIL = (id: number) => `${TRANSACTIONS_BASE_URL}/${id}`;
export const PROCESS_EXFERS_TRX_URL = (id: number) => `${TRANSACTIONS_BASE_URL}/${id}/process`;
export const EXPORT_ALL_TRX = `${TRANSACTIONS_BASE_URL}/export-all`;

export const ADDITIONAL_REQUEST_BASE_URL = `${BASE_URL}/additional-templates`;
export const GET_ADDITIONAL_REQUEST_BASE_URL = (id: number) => `${ADDITIONAL_REQUEST_BASE_URL}/${id}/detail`;
export const DELETE_ADDITIONAL_REQUEST_BASE_URL = (id: number) => `${ADDITIONAL_REQUEST_BASE_URL}/${id}`;

export const GET_SETTING_BY_CODE_URL = (code: string) => `${SETTINGS_URL}/code/${code}`;

export const WEB_BASE_URL = `${BASE_URL}/web`;
export const WEB_JOB_BASE_URL = `${WEB_BASE_URL}/jobs`;
export const WEB_JOB_EXPORT_BASE_URL = `${WEB_BASE_URL}/jobs/export`;
export const WEB_JOB_TOTAL_QUOTE_BASE_URL = `${WEB_BASE_URL}/jobs/quote`;
export const WEB_JOB_DOWNLOAD_SPENDING_BASE_URL = `${WEB_BASE_URL}/jobs/spending`;
export const WEB_JOB_CREDIT_PROGRESS_BASE_URL = (id: number) => `${WEB_JOB_BASE_URL}/${id}/credit-progress`;

export const WEB_JOB_TASK_BASE_URL = `${WEB_BASE_URL}/job-tasks`;
export const WEB_JOB_TASK_DOWNLOAD_EARNING_BASE_URL = `${WEB_JOB_TASK_BASE_URL}/earning`;
export const WEB_JOB_DRIVER_TASK_LIST_BASE_URL = (id: number) => `${WEB_JOB_TASK_BASE_URL}/${id}/driver`;
export const WEB_JOB_TASK_EDIT_STATUS_BASE_URL = (id: number) => `${WEB_JOB_TASK_BASE_URL}/${id}/status`;

export const WEB_TRANSACTION_BASE_URL = `${WEB_BASE_URL}/transactions`;
export const WEB_TRANSACTION_CREACT_CREDIT_URL = `${WEB_TRANSACTION_BASE_URL}/credit`;
export const WEB_TRANSACTION_CHECK_CREDIT_URL = `${WEB_TRANSACTION_BASE_URL}/check-credit`;
export const WEB_TRANSACTION_PAYMENT_CREDIT_URL = `${WEB_TRANSACTION_BASE_URL}/credit-payment`;
export const WEB_TRANSACTION_EXPORT_ALL_URL = `${WEB_TRANSACTION_BASE_URL}/all`;
export const WEB_TRANSACTION_CREDIT_DETAIL_URL = (id: number) => `${WEB_TRANSACTION_BASE_URL}/${id}/credit`;

export const WEB_MERCHANT_BASE_URL = `${WEB_BASE_URL}/merchants`;
export const WEB_EXPORT_MERCHANT_BALANCE_URL = `${WEB_MERCHANT_BASE_URL}/export-balances`;
export const WEB_GET_MERCHANT_COUNT_URL = `${WEB_MERCHANT_BASE_URL}/count`;
export const WEB_MERCHANT_DETAIL_URL = (id: number) => `${WEB_MERCHANT_BASE_URL}/${id}`;
export const WEB_INDIVIDUAL_STATUS_MERCHANT_URL = (id: number) => `${WEB_MERCHANT_BASE_URL}/${id}/status`;
export const WEB_GET_MERCHANT_CREDIT_URL = (id: number) => `${WEB_MERCHANT_BASE_URL}/${id}/credit`;

export const WEB_WEBHOOK_BASE_URL = `${WEB_BASE_URL}/webhook`;
export const WEB_WEBHOOK_DETAIL_LIST_BASE_URL = (id: number) => `${WEB_WEBHOOK_BASE_URL}/${id}`;

export const WEB_BALANCE_LOG_BASE_URL = `${WEB_BASE_URL}/balance-log`;
export const WEB_BALANCE_LOG_DETAIL_BASE_URL = (id: number) => `${WEB_BALANCE_LOG_BASE_URL}/${id}`;
export const WEB_BALANCE_LOG_MERCHANT_BASE_URL = (id: number) => `${WEB_BALANCE_LOG_BASE_URL}/${id}/merchant`;

export const WEB_MATERIAL_BASE_URL = `${WEB_BASE_URL}/materials`;
export const WEB_MATERIAL_NUMBERING_BASE_URL = `${WEB_MATERIAL_BASE_URL}/order`;
export const WEB_DELETE_MATERIAL_BASE_URL = (id: number) => `${WEB_MATERIAL_BASE_URL}/materials/${id}`;

export const WEB_TONNAGE_BASE_URL = `${WEB_BASE_URL}/tonnages`;
export const WEB_TONNAGE_NUMBERING_BASE_URL = `${WEB_TONNAGE_BASE_URL}/order`;
export const WEB_DELETE_TONNAGE_BASE_URL = (id: number) => `${WEB_TONNAGE_BASE_URL}/tonnages/${id}`;

export const WEB_EQUIPMENT_BASE_URL = `${WEB_BASE_URL}/equipments`;
export const WEB_EQUIPMENT_NUMBERING_BASE_URL = `${WEB_EQUIPMENT_BASE_URL}/order`;
export const WEB_DELETE_EQUIPMENT_BASE_URL = (id: number) => `${WEB_EQUIPMENT_BASE_URL}/equipments/${id}`;

export const WEB_INVOICES_BASE_URL = `${WEB_BASE_URL}/invoices`;

export const WEB_ADDITIONAL_CONTACT_BASE_URL = `${WEB_BASE_URL}/additional-contact`;
export const WEB_ADDITIONAL_CONTACT_CHECK_BASE_URL = `${WEB_ADDITIONAL_CONTACT_BASE_URL}/check`;

export const WEB_LAMPPOST_BASE_URL = `${WEB_BASE_URL}/lamp-post`;
export const WEB_LAMPPOST_DELETE_BASE_URL = (id: number) => `${WEB_LAMPPOST_BASE_URL}/${id}`;
