import React, { FC, useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  createStyles,
  withStyles,
  WithStyles,
  makeStyles,
  Theme,
  Grid,
  Typography,
  Button,
  Link,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

import { StandardConfirmationDialog } from 'components/AppDialog';
import StatusPrompModal from 'components/StatusPrompModal';
import FileModal from 'components/FileModal';
import UserRemarksStatusHistory from 'pages/UserPage/UserRemarksStatusHistory';
import UserStatus from 'typings/enum/UserStatus';
import { dummyUserStatus } from 'constants/DummyData';

interface Props {
  open: boolean;
  driver?: DriverModel;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenFormEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedId: React.Dispatch<React.SetStateAction<number>>;
  handleStatusOption: (status: string, remarks?: string, address?: string) => Promise<() => void>;
};

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 4
  },
  itemText: {
    color: '#828282',
    fontSize: '13px'
  },
  itemSecondary: {
    fontWeight: 600,
    fontSize: '13px'
  },
  linkText: {
    color: theme.palette.link.main
  }
}));

const dummyUserStatusHistories = [dummyUserStatus];

const dialogStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
});

export interface DialogTitleProps extends WithStyles<typeof dialogStyles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(dialogStyles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DriverDetailModal: FC<Props> = props => {
  const classes = useStyles();
  const { driver, open, setOpen, setSelectedId, setOpenFormEdit, handleStatusOption } = props;

  const [merchantId, setMerchantId] = useState<number>(0);
  const [vehicleNumber, setVehicleNumber] = useState<string>('');
  const [vehicleLogCard, setVehicleLogCard] = useState<string>('');
  const [vehicleFileUrl, setVehicleFileUrl] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const [companyUen, setCompanyUen] = useState<string>('');
  const [companyFile, setCompanyFile] = useState<string>('');
  const [companyFileUrl, setCompanyFileUrl] = useState<string>('');
  const [bankName, setBankName] = useState<string>('');
  const [bankAccountName, setBankAccountName] = useState<string>('');
  const [bankAccountNumber, setBankAccountNumber] = useState<string>('');
  const [displayName, setDisplayName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [contactNumber, setContactNumber] = useState<string>('');
  const [userStatus, setUserStatus] = useState<string>('');

  const [typePromp, setTypePromp] = useState<string>('');
  const [titlePromp, setTitlePromp] = useState<string>('');
  const [remarksPromp, setRemarksPromp] = useState<string>('');
  const [addressPromp, setAddressPromp] = useState<string>('');
  const [openPromp, setOpenPromp] = useState<boolean>(false);
  const [openEnableDialog, setOpenEnableDialog] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [openFile, setOpenFile] = useState<boolean>(false);
  const [titleFile, setTitleFile] = useState<string>('');
  const [currentFile, setCurrentFile] = useState<string>('');
  const [currentFileUrl, setCurrentFileUrl] = useState<string>('');
  const [userStatusHistory, setUserStatusHistory] = useState<UserStatusHistoriesModel[]>(dummyUserStatusHistories);

  const handleClose = () => {
    setSelectedId(0);
    setOpen(false);
    setLoading(false);
  };

  const handleEdit = () => {
    setOpen(false);
    setOpenFormEdit(true);
  };

  const handleOpenCompanyFile = () => {
    setOpenFile(true);
    setTitleFile('ACRA Company file');
    setCurrentFile(companyFile);
    setCurrentFileUrl(companyFileUrl);
  };

  const handleOpenVehicleFile = () => {
    setOpenFile(true);
    setTitleFile('Vehicle log card file');
    setCurrentFile(vehicleLogCard);
    setCurrentFileUrl(vehicleFileUrl);
  };

  const handleReject = () => {
    setOpenPromp(true);
    setTitlePromp('reject');
    setTypePromp(UserStatus.REJECTED);
  };

  const handleApprove = () => {
    setOpenPromp(true);
    setTitlePromp('approve');
    setTypePromp(UserStatus.APPROVED);
  };

  const handleDisable = () => {
    setOpenPromp(true);
    setTitlePromp('disable');
    setTypePromp(UserStatus.DISABLED);
  };

  const handleEnabledConfirm: React.MouseEventHandler<HTMLButtonElement> = async () => {
    setLoading(true);
    await handleStatusOption(UserStatus.ENABLED);

    setOpenEnableDialog(false);
    setLoading(false);
    handleClose();
  };

  const handleEnable = () => {
    setOpenEnableDialog(true);
  };

  const handleCloseEnableDialog = () => {
    setOpenEnableDialog(false);
  };
 
  const handleClosePromp = () => {
    setOpenPromp(false);
    setLoading(false);
    setTitlePromp('');
    setRemarksPromp('');
    setAddressPromp('');
  };

  const handleConfirmPromp = async () => {
    setLoading(true);
    await handleStatusOption(typePromp, remarksPromp, addressPromp);

    handleClosePromp();
    handleClose();
  };

  useEffect(() => {
    if (!driver) {
      return;
    }

    const {
      MerchantId,
      individualCompanyName,
      individualCompanyUen,
      individualCompanyFile,
      vehicleNumber,
      vehicleLogCard,
      bankName,
      bankAccountName,
      bankAccountNumber,
      vehicleFileUrl,
      companyFileUrl,
      User,
      UserStatus
    } = driver;
    const { displayName, contactNumber, email, address, status } = User;

    setVehicleNumber(vehicleNumber);
    setVehicleLogCard(vehicleLogCard);
    setMerchantId(MerchantId || 0);
    setCompanyName(individualCompanyName);
    setCompanyUen(individualCompanyUen);
    setCompanyFile(individualCompanyFile);
    setCompanyFileUrl(companyFileUrl || '');
    setVehicleFileUrl(vehicleFileUrl || '');
    setAddress(address || '');
    setBankName(bankName);
    setBankAccountName(bankAccountName);
    setBankAccountNumber(bankAccountNumber);
    setDisplayName(displayName);
    setEmail(email);
    setContactNumber(contactNumber);
    setUserStatus(status);
    setUserStatusHistory(UserStatus!);
  }, [driver]);

  return (
    <div>
      <Dialog fullWidth maxWidth='sm' onClose={handleClose} open={open}>
        <DialogTitle id='dialogTitle' onClose={handleClose}>
          <Typography variant='h4'>Driver Details</Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container direction='row' justify='space-between'>
            <Grid container direction='row' item xs={12} sm={12} md={12} lg={12} alignItems='center' spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant='h5' color='primary'>
                  Personal Information
                </Typography>
                <List component='div' dense={true}>
                  <ListItem>
                    <ListItemText className={classes.itemText}>Driver Name</ListItemText>
                    <ListItemSecondaryAction className={classes.itemSecondary}>{displayName}</ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemText className={classes.itemText}>Email</ListItemText>
                    <ListItemSecondaryAction className={classes.itemSecondary}>{email}</ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemText className={classes.itemText}>Phone No.</ListItemText>
                    <ListItemSecondaryAction className={classes.itemSecondary}>+65 {contactNumber}</ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemText className={classes.itemText}>Address</ListItemText>
                    <ListItemSecondaryAction className={classes.itemSecondary} style={{ width: 200, textAlign: 'right', lineHeight: 'initial' }}>
                      {address}
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant='h5' color='primary'>
                  Vehicle {merchantId > 0 ? '' : '& Company'} Information
                </Typography>
                {merchantId > 0 ? (
                  <List component='div' dense={true}>
                    <ListItem>
                      <ListItemText className={classes.itemText}>Vehicle No</ListItemText>
                      <ListItemSecondaryAction className={classes.itemSecondary}>{vehicleNumber || '-'}</ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                      <ListItemText className={classes.itemText}>Vehicle Log Card</ListItemText>
                      <ListItemSecondaryAction className={classes.itemSecondary}>
                        {vehicleLogCard ? (
                          <Link href='#' onClick={() => handleOpenVehicleFile()} className={classes.linkText}>
                            {vehicleLogCard}
                          </Link>
                        ) : (
                          '-'
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                ) : (
                  <List component='div' dense={true}>
                    <ListItem>
                      <ListItemText className={classes.itemText}>Company Name</ListItemText>
                      <ListItemSecondaryAction className={classes.itemSecondary}>{companyName || '-'}</ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                      <ListItemText className={classes.itemText}>Company UEN No.</ListItemText>
                      <ListItemSecondaryAction className={classes.itemSecondary}>{companyUen || '-'}</ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                      <ListItemText className={classes.itemText}>ACRA Company</ListItemText>
                      <ListItemSecondaryAction className={classes.itemSecondary}>
                        {companyFile ? (
                          <Link href='#' onClick={() => handleOpenCompanyFile()} className={classes.linkText}>
                            {companyFile}
                          </Link>
                        ) : (
                          '-'
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                      <ListItemText className={classes.itemText}>Vehicle No</ListItemText>
                      <ListItemSecondaryAction className={classes.itemSecondary}>{vehicleNumber || '-'}</ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                      <ListItemText className={classes.itemText}>Vehicle Log Card</ListItemText>
                      <ListItemSecondaryAction className={classes.itemSecondary}>
                        {vehicleLogCard ? (
                          <Link href='#' onClick={() => handleOpenVehicleFile()} className={classes.linkText}>
                            {vehicleLogCard}
                          </Link>
                        ) : (
                          '-'
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant='h5' color='primary'>
                  Bank Account
                </Typography>
                <List component='div' dense={true}>
                  <ListItem>
                    <ListItemText className={classes.itemText}>Bank Name</ListItemText>
                    <ListItemSecondaryAction className={classes.itemSecondary}>{bankName || '-'}</ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemText className={classes.itemText}>Bank Account Name</ListItemText>
                    <ListItemSecondaryAction className={classes.itemSecondary}>{bankAccountName || '-'}</ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemText className={classes.itemText}>Bank Account No.</ListItemText>
                    <ListItemSecondaryAction className={classes.itemSecondary}>{bankAccountNumber || '-'}</ListItemSecondaryAction>
                  </ListItem>
                </List>
              </Grid>
              {merchantId > 0 ? (
                ''
              ) : (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography variant='h6'>Remarks Status History</Typography>
                  <UserRemarksStatusHistory userStatus={userStatusHistory} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        {merchantId > 0 ? (
          <DialogActions>
            <Button variant='contained' color='primary'>
              See Job
            </Button>
          </DialogActions>
        ) : userStatus === UserStatus.DISABLED || userStatus === UserStatus.REJECTED ? (
          <DialogActions>
            <Button variant='outlined' color='primary' onClick={() => handleEdit()}>
              Edit Data
            </Button>
            <Button variant='contained' color='primary' onClick={() => (userStatus === UserStatus.REJECTED ? handleApprove() : handleEnable())}>
              {userStatus === UserStatus.REJECTED ? 'Approve' : 'Enable'}
            </Button>
          </DialogActions>
        ) : userStatus === UserStatus.PENDING ? (
          <DialogActions>
            <Button variant='outlined' color='primary' onClick={() => handleReject()}>
              Reject
            </Button>
            <Button variant='contained' color='primary' onClick={() => handleApprove()}>
              Approve
            </Button>
          </DialogActions>
        ) : userStatus === UserStatus.APPROVED ? (
          <DialogActions>
            <Button variant='outlined' color='primary' onClick={() => handleDisable()}>
              Disabled
            </Button>
            <Button variant='contained' color='primary' onClick={() => handleEdit()}>
              Edit Data
            </Button>
          </DialogActions>
        ) : (
          ''
        )}
      </Dialog>

      <StatusPrompModal
        title={titlePromp}
        message={displayName}
        confirmText={titlePromp}
        open={openPromp}
        loading={loading}
        handleClose={handleClosePromp}
        handleConfirm={handleConfirmPromp}
        remarksPromp={remarksPromp}
        setRemarksPromp={setRemarksPromp}
        addressPromp={addressPromp}
        setAddressPromp={setAddressPromp}
      />

      <StandardConfirmationDialog
        variant='warning'
        titleMessage='Are you sure to enable this account?'
        message='You can make changes at any time'
        confirmButtonText='Enable'
        open={openEnableDialog}
        loading={loading}
        handleClose={handleCloseEnableDialog}
        onConfirm={handleEnabledConfirm}
      />

      <FileModal title={titleFile} open={openFile} setOpen={setOpenFile} fileUrl={currentFileUrl} fileName={currentFile} />
    </div>
  );
};

export default DriverDetailModal;
