import { AlertProps } from 'components/DialogAlert/Alert';
import React from 'react';

const initialState:AlertProps = {
  open: false,
  title: '',
  message: '',
  type: 'notice',
  loading: false,
  close: undefined,
  confirm: {
    text: 'Confirm',
    onClick: () => {}
  }
};

const useAlert = () => {
  const [alert, dispatch] = React.useState<AlertProps>(initialState);

  const set = (value: Partial<typeof alert>) => {
    dispatch(state => ({ ...state, ...value }));
  };

  const reset = () => {
    dispatch(initialState);
  };

  return {
    alert,
    set,
    reset
  };
};

export default useAlert;
