import React, { FC } from 'react';
import { allTaskOneDriverType, formatCurrency, getPercent, getHours, getAppFee, rounding } from 'utils';
import { Theme, makeStyles, Table, TableBody, TableHead, TableRow, TableCell, TableContainer, Chip } from '@material-ui/core';
import clsx from 'clsx';
import JobType from 'typings/enum/JobType';
import JobStatus from 'typings/enum/JobStatus';
import TruckType from 'typings/enum/TruckType';
import { dummyJobTask } from 'constants/DummyData';

interface Props {
  job: JobModel;
  merchantId: number;
  taskId: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  subtotal: {
    paddingRight: 0,
    width: '10%',
    whiteSpace: 'nowrap'
  },
  unitPrice: {
    width: '20%',
    whiteSpace: 'nowrap'
  },
  qty: {
    width: '15%',
    whiteSpace: 'nowrap'
  },
  disabledBorderBottom: {
    borderBottom: 'none'
  },
  orangeTitle: {
    color: theme.palette.primary.main
  }
}));

const PriceTable: FC<Props> = ({ job, merchantId, taskId }) => {
  const classes = useStyles();
  let isDriverGst = !!job.gstAmount;
  const priceDetails: PriceData[] = [];
  const status = [JobStatus.ASSIGNED, JobStatus.IN_PROGRESS, JobStatus.COMPLETED];
  const gstPercent = getPercent(job.gstAmount || 0, job.originalAmount || 0);
  let price = job.price * (job.jobType === JobType.DAYWORKS ? job.hours / 60 : 1);
  let originalPrice = job.price;
  let totalGstAmount = 0;
  let subtotal = 0;
  let percentAppFee = 0;
  let originalEarn = 0;
  let netEarn = 0;
  let hours = job.hours / 60;
  let appFee = 0;
  let originalGstTask = 0;
  let totalOriginalAppFee = 0;

  let tasks = (job.JobTasks || [])
    .filter(v => v.Driver && v.Driver.MerchantId === merchantId)
    .filter(v => status.includes(v.jobTaskStatus as JobStatus));
  let qty = 1;

  if (tasks.length > 0) {
    const task = tasks.find(_task => _task.id === taskId) || dummyJobTask;
    appFee = task.originalAppFee || 0;

    if (task.isDriverGst !== undefined) {
      isDriverGst = !!job.gstAmount && task.isDriverGst;
    }

    if (allTaskOneDriverType.includes(job.truckType as TruckType)) {
      netEarn = tasks.reduce((a, b) => a + (b.netEarn || 0), 0);
      originalEarn = tasks.reduce((a, b) => a + (b.originalEarn || 0), 0);
      totalGstAmount = tasks.reduce((a, b) => a + (b.gstTaskAmount || 0), 0);
      qty = job.totalTrips || 0;
      totalOriginalAppFee = tasks.reduce((a, b) => a + (b.appFee || 0), 0);
      percentAppFee = getPercent(task.originalAppFee || 0, task.originalEarn || 0);
    } else {
      totalGstAmount = task.gstTaskAmount || 0;
      percentAppFee = getPercent(task.originalAppFee || 0, task.originalEarn || 0);
      netEarn = task.netEarn || 0;
      originalEarn = task.originalEarn || 0;
      totalOriginalAppFee = task.appFee || 0;
    }

    originalGstTask = task.originalGstTask || 0;

    priceDetails.push({
      type: 'data',
      qty: job.jobType === JobType.DAYWORKS ? `${qty}(${hours === 4 ? 'Half Day' : 'Full Day'})` : String(qty),
      price: `${formatCurrency(price)} ${job.jobType === JobType.DAYWORKS ? `(${formatCurrency(originalPrice)}/hours)` : ''}`,
      appFee: formatCurrency(appFee || 0),
      subtotal: formatCurrency(originalEarn),
      description: `Total ${job.jobType === JobType.DAYWORKS ? 'Tasks' : 'Trips'}`,
      gst: formatCurrency(originalGstTask),
      totalGst: `(+${formatCurrency(originalGstTask * qty)})`
    });
    subtotal += originalEarn;

    const editHours: JobTask[] = tasks.filter(v => v.additionalStatus && ['CONFIRM', 'PENDING'].includes(v.additionalStatus));
    const additionals: JobAdditional[] = (job.JobAdditionals || []).filter((v: JobAdditional) => ['CONFIRM', 'PENDING'].includes( v.status));
    const hasAdditional = additionals.some(v => v.hours > 0);

    if (hasAdditional && allTaskOneDriverType.includes(job.truckType as TruckType) ) {
      const taskPercentFee = getPercent(task.originalAppFee || 0, task.originalEarn || 0);
      additionals
        .filter(v => v.hours > 0)
        .forEach(adt => {
          const perHour = getHours(adt.hours);
          const otFee = rounding((getAppFee((task.originalEarn || 0) + adt.totalPrice, taskPercentFee) - appFee) / perHour);

          priceDetails.push({
            type: 'data',
            appFee: formatCurrency(otFee),
            qty: `${perHour} hour(s)`,
            subtotal: formatCurrency(adt.totalPrice),
            price: formatCurrency(adt.hourPrice),
            description: 'Over Time(s)',
            gst: formatCurrency(adt.gstTotalPrice),
            totalGst: `(+${formatCurrency(adt.gstTotalPrice)})`,
            status: adt.status
          });
        });
      subtotal += job.JobAdditionalSummary?.totalOverTimePrice || 0;
     
    }

    if (job.JobAdditionalSummary?.totalItemPrice && job.JobAdditionalSummary?.totalItemPrice > 0) {
      netEarn -= job.JobAdditionalSummary?.totalItemPrice || 0;
      if (isDriverGst) {
        totalGstAmount += job.JobAdditionalSummary?.totalGSTItemPrice;
      }
    }

    if (job.JobAdditionalSummary?.totalGSTOvertime && job.JobAdditionalSummary?.totalGSTOvertime > 0 && isDriverGst) {
      totalGstAmount += job.JobAdditionalSummary.totalGSTOvertime;
    }

    if (editHours.length > 0) {
      priceDetails.push({ type: 'title', qty: '', subtotal: '', appFee: '0', price: '', description: 'Edit Hour(s)', gst: '', totalGst: '' });

      editHours
        .filter(v => v.id === taskId)
        .forEach(v => {
          priceDetails.push({
            type: 'additional',
            qty: `${(v.additionalHour || 0) / 60} Hour(s)`,
            appFee: `${formatCurrency(0)}`,
            subtotal: formatCurrency(v.additionalAmount || 0),
            price: formatCurrency(v.additionalHour && v.additionalHour > 0 ? v.additionalPrice || 0 : v.additionalAmount || 0),
            description: `Task #${v.id} ${v.additionalHour && v.additionalHour > 0 ? 'Add Hour(s)' : 'Reduce Hour(s)'}`,
            gst: formatCurrency(v.additionalGstAmount || 0),
            totalGst: `(${v.additionalGstAmount && v.additionalGstAmount > 0 ? '+' : ''} ${formatCurrency(v.additionalGstAmount || 0)})`,
            status: task.additionalStatus
          });

          if (v.additionalStatus === 'CONFIRM') {
            subtotal += v.additionalAmount || 0;
          }
        });
    }
  }

  return (
    <TableContainer style={{ boxShadow: 'none' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell component='th'>Description</TableCell>

            <TableCell component='th' align='center' className={classes.unitPrice}>
              Unit Price
            </TableCell>

            <TableCell component='th' align='center' className={classes.qty}>
              QTY
            </TableCell>

            <TableCell component='th' align='right' className={classes.subtotal}>
              Subtotal
            </TableCell>

            <TableCell component='th' align='center' className={classes.unitPrice}>
              GST {getPercent(job.gstAmount || 0, job.originalAmount || 0)}%
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {priceDetails.map((v, i) =>
            v.type === 'title' ? (
              <TableRow key={i + '-key'}>
                <TableCell size='small' colSpan={5} style={{ borderBottom: 0 }}>
                  {v.description}
                </TableCell>
              </TableRow>
            ) : (
              <TableRow key={i + '-key'}>
                <TableCell
                  size={v.type === 'additional' ? 'small' : 'medium'}
                  style={{ ...(v.type === 'additional' && i !== priceDetails.length - 1 ? { borderBottom: 0 } : {}), whiteSpace: 'nowrap' }}
                >
                  {v.description}{' '}
                  {v.status && v.status === 'PENDING' ? <Chip label={v.status} variant='outlined' size='small' color='primary' /> : null}
                </TableCell>

                <TableCell
                  size={v.type === 'additional' ? 'small' : 'medium'}
                  style={{ ...(v.type === 'additional' && i !== priceDetails.length - 1 ? { borderBottom: 0 } : {}), whiteSpace: 'nowrap' }}
                  align='right'
                >
                  {v.price}
                </TableCell>

                <TableCell
                  size={v.type === 'additional' ? 'small' : 'medium'}
                  style={{ ...(v.type === 'additional' && i !== priceDetails.length - 1 ? { borderBottom: 0 } : {}), whiteSpace: 'nowrap' }}
                  align='center'
                >
                  {v.qty}
                </TableCell>

                <TableCell
                  size={v.type === 'additional' ? 'small' : 'medium'}
                  style={{ ...(v.type === 'additional' && i !== priceDetails.length - 1 ? { borderBottom: 0 } : {}), whiteSpace: 'nowrap' }}
                  align='right'
                  className={classes.subtotal}
                >
                  {v.subtotal}
                </TableCell>

                <TableCell
                  size={v.type === 'additional' ? 'small' : 'medium'}
                  style={{
                    textAlign: 'right',
                    ...(v.type === 'additional' && i !== priceDetails.length - 1 ? { borderBottom: 0 } : {}),
                    whiteSpace: 'nowrap'
                  }}
                >
                  {isDriverGst ? v.totalGst : null}
                </TableCell>
              </TableRow>
            )
          )}

          <TableRow>
            <TableCell size='small' colSpan={2} align='left' style={{ whiteSpace: 'nowrap' }} className={clsx(classes.disabledBorderBottom)}>
              Note: Driver <span> {isDriverGst ? `With` : 'Without'}</span> GST Company
            </TableCell>
            <TableCell size='small' align='right'>
              Subtotal
            </TableCell>
            <TableCell align='right' size='small' className={clsx(classes.orangeTitle, classes.subtotal)}>
              <strong>{formatCurrency(subtotal)}</strong>
            </TableCell>
            <TableCell align='right' size='small' style={{ whiteSpace: 'nowrap' }} className={clsx(classes.orangeTitle)}>
              {isDriverGst ? <strong>(+{formatCurrency(totalGstAmount - (job.JobAdditionalSummary?.totalGSTItemPrice || 0))})</strong> : null}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell colSpan={3} size='small' align='right'>
              Platform Fee {percentAppFee}%
            </TableCell>
            <TableCell align='right' size='small' style={{ color: 'red' }} className={clsx(classes.subtotal)}>
              <strong>-{formatCurrency(totalOriginalAppFee)}</strong>
            </TableCell>
            <TableCell size='small'></TableCell>
          </TableRow>

          {allTaskOneDriverType.includes(job.truckType as TruckType) ? (
            <TableRow>
              <TableCell size='small' colSpan={2} className={clsx(classes.disabledBorderBottom)}>
                &nbsp;
              </TableCell>
              <TableCell size='small' align='right' style={{ whiteSpace: 'nowrap' }}>
                Total Additional Item {isDriverGst ? `+ GST ${gstPercent}%` : ''}
              </TableCell>
              <TableCell align='right' size='small' className={clsx(classes.subtotal)}>
                <strong> {formatCurrency(job.JobAdditionalSummary?.totalItemPrice || 0)}</strong>
              </TableCell>
              <TableCell align='right' size='small' style={{ whiteSpace: 'nowrap' }} className={clsx(classes.subtotal)}>
                {isDriverGst ? <strong>(+{formatCurrency(job.JobAdditionalSummary?.totalGSTItemPrice || 0)})</strong> : null}
              </TableCell>
            </TableRow>
          ) : null}

          <TableRow>
            <TableCell size='small' colSpan={2} className={clsx(classes.disabledBorderBottom)}>
              &nbsp;
            </TableCell>
            <TableCell align='right' size='small' style={{ whiteSpace: 'nowrap' }}>
              Total Earn
            </TableCell>
            <TableCell align='right' size='small' className={clsx(classes.disabledBorderBottom, classes.orangeTitle, classes.subtotal)}>
              <strong> {formatCurrency(netEarn + (job.JobAdditionalSummary?.totalItemPrice || 0))}</strong>
            </TableCell>
            <TableCell
              align='right'
              size='small'
              style={{ whiteSpace: 'nowrap' }}
              className={clsx(classes.disabledBorderBottom, classes.orangeTitle, classes.subtotal)}
            >
              {isDriverGst ? <strong>(+{formatCurrency(totalGstAmount || 0)})</strong> : null}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PriceTable;
