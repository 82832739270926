import React, { FC, useState, useCallback, useEffect } from 'react';
import axios, { CancelTokenSource } from 'axios';

import SupervisorTable from './SupervisorTable';
import SupervisorDetailModal from './SupervisorDetailModal';
import { dummySupervisor } from 'constants/DummyData';
import { SUPERVISORS_BASE_URL } from 'constants/url';

interface Props {
  noTitle?: boolean;
  merchantId?: number;
  jobId?: number;
}

const SupervisorsPage: FC<Props> = props => {
  const { merchantId, jobId } = props;

  const [supervisors, setSupervisors] = useState<SupervisorModel[]>([dummySupervisor]);
  const [count, setCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('createdAt');
  const [queryId, setQueryId] = useState<string>();
  const [queryName, setQueryName] = useState<string>('');
  const [queryEmail, setQueryEmail] = useState<string>('');
  const [queryContact, setQueryContact] = useState<string>('');
  const [type, setType] = useState<string>('');

  const [selectedSupervisor, setSelectedSupervisor] = useState<SupervisorModel>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleClickDetail = (supervisor: SupervisorModel) => {
    setSelectedSupervisor(supervisor);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const fetchData = useCallback(async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    setIsLoadingData(true);

    const params = new URLSearchParams();
    params.append('s', (currentPage * rowsPerPage).toString());
    params.append('l', rowsPerPage.toString());

    if (merchantId) {
      params.append('merchantId', String(merchantId));
    }

    if (jobId) {
      params.append('jobId', String(jobId));
      params.append('withDeleted', 'true');
    }

    if (queryId) {
      params.append('queryId', queryId);
    }

    if (queryName) {
      params.append('queryName', queryName);
    }

    if (queryEmail) {
      params.append('queryEmail', queryEmail);
    }

    if (queryContact) {
      params.append('queryContact', queryContact);
    }

    if (type) {
      params.append('type', type);
    }

    if (orderBy) {
      params.append('orderBy', orderBy);
    }

    if (order) {
      params.append('order', order.toUpperCase());
    }

    try {
      const { data } = await axios.get(`${SUPERVISORS_BASE_URL}${merchantId ? `/${merchantId}` : ''}?${params.toString()}`, {
        cancelToken: cancelTokenSource.token
      });

      setSupervisors(data.supervisors);
      setCount(data.count);
    } catch (err) {
      console.error('err: ', err);
    }
    setIsLoadingData(false);
    return () => cancelTokenSource.cancel();
  }, [rowsPerPage, currentPage, order, orderBy, type, merchantId, jobId, queryId, queryName, queryEmail, queryContact]);

  const handleTypeChange = (selectedType: string) => {
    performActionAndRevertPage(setType, selectedType);
  };

  const handleQueryIdChange = (query: string) => {
    performActionAndRevertPage(setQueryId, query);
  };

  const handleQueryNameChange = (query: string) => {
    performActionAndRevertPage(setQueryName, query);
  };

  const handleQueryEmailChange = (query: string) => {
    performActionAndRevertPage(setQueryEmail, query);
  };

  const handleQueryContactChange = (query: string) => {
    performActionAndRevertPage(setQueryContact, query);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setCurrentPage(0);
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const performActionAndRevertPage = (action: React.Dispatch<React.SetStateAction<any>>, actionParam: any) => {
    setCurrentPage(0);
    action(actionParam);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <SupervisorTable
        isLoadingData={isLoadingData}
        count={count}
        jobId={jobId}
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
        order={order}
        orderBy={orderBy}
        queryId={queryId}
        queryName={queryName}
        queryEmail={queryEmail}
        queryContact={queryContact}
        type={type}
        supervisors={supervisors}
        handleTypeChange={handleTypeChange}
        handleQueryIdChange={handleQueryIdChange}
        handleQueryNameChange={handleQueryNameChange}
        handleQueryEmailChange={handleQueryEmailChange}
        handleQueryContactChange={handleQueryContactChange}
        handleRequestSort={handleRequestSort}
        handleChangePage={(event, page) => setCurrentPage(page)}
        handleChangeRowsPerPage={event => performActionAndRevertPage(setRowsPerPage, +event.target.value)}
        handleDetailClick={handleClickDetail}
      />

      <SupervisorDetailModal supervisor={selectedSupervisor} open={isModalOpen} onClose={handleCloseModal} />
    </>
  );
};

export default SupervisorsPage;
