import React, { FC, useState, useEffect, useCallback } from 'react';
import {
  Theme,
  makeStyles,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableContainer,
  InputAdornment,
  Typography,
  Divider
} from '@material-ui/core';

import HeaderRow from 'components/HeaderRow';
import BodyRow from './components/BodyRow';

import { Search } from '@material-ui/icons';
import { dummyJobAdditionalItems } from 'constants/DummyData';
import { formatCurrency, getPercent } from 'utils';

interface Props {
  job: JobModel;
  additionalItems: JobAdditionalItem[];
  isLoadingData: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  rootTabs: {
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
    borderRadius: '5px',
    marginBottom: '24px'
  },
  title: {
    padding: '16px'
  }
}));

const AdditionalTable: FC<Props> = props => {
  const classes = useStyles();
  const { additionalItems, isLoadingData, job } = props;

  const [items, setItems] = useState<JobAdditionalItem[]>([]);
  const [queryId, setQueryId] = useState<string>('');
  const [queryName, setQueryName] = useState<string>('');

  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('createdAt');

  const handleQueryIdChange = (text: string) => {
    setQueryId(text);
  };

  const handleQueryNameChange = (text: string) => {
    setQueryName(text);
  };

  const filter = useCallback(() => {
    if (!items || items.length < 1) {
      return;
    }

    let filteredItems = additionalItems;
    if (queryId) {
      filteredItems = filteredItems.filter(item => String(item.id).match(new RegExp(queryId, 'gi')));
    }

    if (queryName) {
      filteredItems = filteredItems.filter(item => String(item.name).match(new RegExp(queryName, 'gi')));
    }

    setItems(filteredItems);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, queryId, queryName]);

  const handleSortTasks = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    if (property === 'id') {
      setItems(state => state.sort((a, b) => (!isAsc ? b.id - a.id : a.id - b.id)));
    } else if (property === 'name') {
      setItems(state => state.sort((a, b) => (!isAsc ? (a.name > b.name && -1) || 1 : (a.name > b.name && 1) || -1)));
    } else if (property === 'description') {
      setItems(state => state.sort((a, b) => (!isAsc ? (a.description > b.description && -1) || 1 : (a.description > b.description && 1) || -1)));
    } else if (property === 'price') {
      setItems(state => state.sort((a, b) => (!isAsc ? b.price - a.price : a.price - b.price)));
    } else if (property === 'totalItem') {
      setItems(state => state.sort((a, b) => (!isAsc ? b.totalItem - a.totalItem : a.totalItem - b.totalItem)));
    } else if (property === 'supervisor') {
      setItems(state => state.sort((a, b) => (!isAsc ? b.totalPrice - a.totalPrice : a.totalPrice - b.totalPrice)));
    }
  };

  useEffect(() => {
    filter();
  }, [filter]);

  useEffect(() => {
    if (!additionalItems || additionalItems.length < 1) {
      return;
    }

    setItems(additionalItems);
  }, [additionalItems]);

  const isDriverGst = !!job.gstAmount && (job.JobTasks || []).some(v => v.Driver && v.Driver.Merchant && v.Driver.Merchant.companyGst);

  return (
    <Paper elevation={0} className={classes.rootTabs}>
      <Typography variant='h4' className={classes.title}>
        Additional Items
      </Typography>
      <Divider />
      <TableContainer style={{ boxShadow: 'none' }}>
        <Table size='small'>
          <TableHead>
            <HeaderRow
              height={'0px'}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleSortTasks}
              headers={[
                { id: 'id', label: 'ID', sort: true, pT: '16px', pB: '16px', cellProps: { style: { whiteSpace: 'nowrap', width: '10%' } } },
                { id: 'name', label: 'Item Name', sort: true, pT: '16px', pB: '16px', cellProps: { style: { whiteSpace: 'nowrap' } } },
                { id: 'description', label: 'Description', sort: true, pT: '16px', pB: '16px' },
                {
                  id: 'qty',
                  label: 'QTY',
                  sort: true,
                  pT: '16px',
                  pB: '16px',
                  align: 'center',
                  cellProps: { padding: 'checkbox', style: { whiteSpace: 'nowrap' } }
                },
                {
                  id: 'price',
                  label: 'Unit Price',
                  sort: true,
                  pT: '16px',
                  pB: '16px',
                  align: 'center',
                  cellProps: { padding: 'checkbox', style: { whiteSpace: 'nowrap' } }
                },
                {
                  id: 'totalPrice',
                  label: 'Total Price',
                  sort: true,
                  pT: '16px',
                  pB: '16px',
                  align: 'right',
                  cellProps: { padding: 'checkbox', style: { whiteSpace: 'nowrap' } }
                },
                {
                  id: 'emptyCell',
                  label: '',
                  cellProps: { padding: 'checkbox', style: { width: '5%' } }
                }
              ]}
            />

            <HeaderRow
              height={'0px'}
              headers={[
                {
                  id: 'findId',
                  isInputText: true,
                  value: queryId,
                  handleInputChange: handleQueryIdChange,
                  pT: '0px',
                  pB: '0px',
                  endAdornment: (
                    <InputAdornment position='start'>
                      <Search fontSize='small' color='disabled' />
                    </InputAdornment>
                  )
                },
                {
                  id: 'findName',
                  isInputText: true,
                  value: queryName,
                  handleInputChange: handleQueryNameChange,
                  pT: '0px',
                  pB: '0px',
                  endAdornment: (
                    <InputAdornment position='start'>
                      <Search fontSize='small' color='disabled' />
                    </InputAdornment>
                  )
                },
                {
                  id: 'findVehicle'
                },
                {
                  id: 'findSupervisor'
                },
                {
                  id: 'findStatus'
                },
                {
                  id: 'findTotalPrice'
                },
                {
                  id: 'empty'
                }
              ]}
            />
          </TableHead>

          <TableBody>
            {isLoadingData ? (
              [0, 1, 2, 3].map(index => <BodyRow key={index} items={dummyJobAdditionalItems} isLoadingData={isLoadingData} />)
            ) : items && items.length > 0 ? (
              items
                .map((value, index) => <BodyRow key={index} items={value} isLoadingData={isLoadingData} />)
                .concat(
                  <TableRow key={items.length}>
                    <TableCell align='right' colSpan={5} style={{ paddingRight: 0 }}>
                      Total Additional Item {isDriverGst ? `+ GST ${getPercent(job.gstAmount || 0, job.originalAmount || 0)}%` : ''}
                    </TableCell>
                    <TableCell align='right' style={{ paddingRight: 0 }}>
                      <strong> {formatCurrency(job.JobAdditionalSummary?.totalItemPrice || 0)}</strong>
                    </TableCell>
                    <TableCell align='left'>
                      {isDriverGst ? (
                        <>
                          <strong>(+{formatCurrency(job.JobAdditionalSummary?.totalGSTItemPrice || 0)})</strong>
                        </>
                      ) : null}
                    </TableCell>
                  </TableRow>
                )
            ) : (
              <TableRow>
                <TableCell align='center' colSpan={6}>
                  Data Not Available.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default AdditionalTable;
