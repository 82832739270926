import React, { FC } from 'react';
import { Table, TableBody, TableHead, TableFooter, TableRow, TableCell, InputAdornment, TableContainer } from '@material-ui/core';

import HeaderRow from 'components/HeaderRow';
import TablePagination from 'components/TablePagination';
import BodyRow from './BodyRow';
import Role from 'typings/enum/Role';
import { dummySupervisor } from 'constants/DummyData';
import { Search } from '@material-ui/icons';

interface Props {
  supervisors: SupervisorModel[];
  count: number;
  currentPage: number;
  rowsPerPage: number;
  order: 'asc' | 'desc';
  orderBy: string;
  isLoadingData: boolean;
  queryId?: string;
  queryName: string;
  queryEmail: string;
  queryContact: string;
  type: string;
  jobId?: number;
  handleTypeChange: (type: string) => void;
  handleQueryIdChange: (type: string) => void;
  handleQueryNameChange: (type: string) => void;
  handleQueryEmailChange: (type: string) => void;
  handleQueryContactChange: (type: string) => void;
  handleRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
  handleChangeRowsPerPage: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  handleDetailClick: (supervisor: SupervisorModel) => void;
}

const supervisorType: Select[] = [
  { id: Role.SUPERVISOR_TIER_001, name: Role.SUPERVISOR_TIER_001 },
  { id: Role.SUPERVISOR_TIER_002, name: Role.SUPERVISOR_TIER_002 },
  { id: Role.SUPERVISOR_TIER_003, name: Role.SUPERVISOR_TIER_003 }
];

const SupervisorTable: FC<Props> = props => {
  const { supervisors, isLoadingData, count, currentPage, rowsPerPage, order, orderBy } = props;
  const {
    handleTypeChange,
    handleQueryIdChange,
    handleQueryNameChange,
    handleQueryEmailChange,
    handleQueryContactChange,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage
  } = props;

  const { queryId, queryName, queryEmail, queryContact, type, jobId, handleDetailClick } = props;

  return (
    <TableContainer style={{ boxShadow: 'none', borderRadius: 0 }}>
      <Table size='small'>
        <TableHead>
          <HeaderRow
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            height={'unset'}
            headers={
              jobId
                ? [
                    { id: 'collapse', label: '', sort: false },
                    { id: 'id', label: 'ID', sort: true },
                    { id: 'displayName', label: 'Display Name', sort: true },
                    { id: 'type', label: 'Account Type', sort: true },
                    { id: 'email', label: 'Email', sort: true },
                    { id: 'contactNumber', label: 'Phone', sort: true }
                  ]
                : [
                    { id: 'id', label: 'ID', sort: true },
                    { id: 'displayName', label: 'Display Name', sort: true },
                    { id: 'type', label: 'Account Type', sort: true },
                    { id: 'email', label: 'Email', sort: true },
                    { id: 'contactNumber', label: 'Phone', sort: true }
                  ]
            }
          />

          <HeaderRow
            height='0px'
            headers={
              jobId
                ? [
                    { id: 'collapse' },
                    {
                      id: 'findId',
                      isInputText: true,
                      value: queryId,
                      handleInputChange: handleQueryIdChange,
                      pT: '0px',
                      pB: '0px',
                      pL: '16px',
                      pR: '8px',
                      endAdornment: (
                        <InputAdornment position='start'>
                          <Search fontSize='small' color='disabled' />
                        </InputAdornment>
                      )
                    },
                    {
                      id: 'findDisplayName',
                      isInputText: true,
                      value: queryName,
                      handleInputChange: handleQueryNameChange,
                      pT: '0px',
                      pB: '0px',
                      pL: '16px',
                      pR: '8px',
                      endAdornment: (
                        <InputAdornment position='start'>
                          <Search fontSize='small' color='disabled' />
                        </InputAdornment>
                      )
                    },
                    {
                      id: 'findType',
                      label: 'Select Type',
                      isSelect: true,
                      value: type,
                      selectOption: supervisorType,
                      handleOptionChange: handleTypeChange,
                      pT: '0px',
                      pB: '0px',
                      pL: '16px',
                      pR: '8px',
                      endAdornment: (
                        <InputAdornment position='start'>
                          <Search fontSize='small' color='disabled' />
                        </InputAdornment>
                      )
                    },
                    {
                      id: 'findEmail',
                      isInputText: true,
                      value: queryEmail,
                      handleInputChange: handleQueryEmailChange,
                      pT: '0px',
                      pB: '0px',
                      pL: '16px',
                      pR: '8px',
                      endAdornment: (
                        <InputAdornment position='start'>
                          <Search fontSize='small' color='disabled' />
                        </InputAdornment>
                      )
                    },
                    {
                      id: 'findContactNumber',
                      isInputText: true,
                      value: queryContact,
                      handleInputChange: handleQueryContactChange,
                      pT: '0px',
                      pB: '0px',
                      pL: '16px',
                      pR: '8px',
                      endAdornment: (
                        <InputAdornment position='start'>
                          <Search fontSize='small' color='disabled' />
                        </InputAdornment>
                      )
                    }
                  ]
                : [
                    {
                      id: 'findId',
                      isInputText: true,
                      value: queryId,
                      handleInputChange: handleQueryIdChange,
                      pT: '0px',
                      pB: '0px',
                      pL: '16px',
                      pR: '8px',
                      endAdornment: (
                        <InputAdornment position='start'>
                          <Search fontSize='small' color='disabled' />
                        </InputAdornment>
                      )
                    },
                    {
                      id: 'findDisplayName',
                      isInputText: true,
                      value: queryName,
                      handleInputChange: handleQueryNameChange,
                      pT: '0px',
                      pB: '0px',
                      pL: '16px',
                      pR: '8px',
                      endAdornment: (
                        <InputAdornment position='start'>
                          <Search fontSize='small' color='disabled' />
                        </InputAdornment>
                      )
                    },
                    {
                      id: 'findType',
                      label: 'Select Type',
                      isSelect: true,
                      value: type,
                      selectOption: supervisorType,
                      handleOptionChange: handleTypeChange,
                      pT: '0px',
                      pB: '0px',
                      pL: '16px',
                      pR: '8px'
                    },
                    {
                      id: 'findEmail',
                      isInputText: true,
                      value: queryEmail,
                      handleInputChange: handleQueryEmailChange,
                      pT: '0px',
                      pB: '0px',
                      pL: '16px',
                      pR: '8px',
                      endAdornment: (
                        <InputAdornment position='start'>
                          <Search fontSize='small' color='disabled' />
                        </InputAdornment>
                      )
                    },
                    {
                      id: 'findContactNumber',
                      isInputText: true,
                      value: queryContact,
                      handleInputChange: handleQueryContactChange,
                      pT: '0px',
                      pB: '0px',
                      pL: '16px',
                      pR: '8px',
                      endAdornment: (
                        <InputAdornment position='start'>
                          <Search fontSize='small' color='disabled' />
                        </InputAdornment>
                      )
                    }
                  ]
            }
          />
        </TableHead>
        <TableBody>
          {isLoadingData ? (
            [0, 1, 2, 3].map(index => (
              <BodyRow
                index={index}
                key={index}
                supervisor={dummySupervisor}
                jobId={jobId}
                isLoadingData={isLoadingData}
                handleDetailClick={handleDetailClick}
              />
            ))
          ) : supervisors && supervisors.length > 0 ? (
            supervisors.map((value, index) => (
              <BodyRow
                index={index}
                key={index}
                supervisor={value}
                jobId={jobId}
                isLoadingData={isLoadingData}
                handleDetailClick={handleDetailClick}
              />
            ))
          ) : (
            <TableRow>
              <TableCell align='center' colSpan={7}>
                Data Not Available.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            count={count}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default SupervisorTable;
