import React, { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import NumberFormat from 'react-number-format';
import { formatDistanceToNow } from 'date-fns';
import { Paper, makeStyles, Theme, Grid, Typography } from '@material-ui/core';

import JobType from 'typings/enum/JobType';
import JobStatus from 'typings/enum/JobStatus';
import { jobTypeLabel, truckTypeLabel, ucwords } from 'utils';
import JobChip from 'components/JobChip';
import StatusChip from 'components/StatusChip';
import TruckChip from 'components/TruckChip';
import TruckType from 'typings/enum/TruckType';
import JobPayment from 'components/JobPayment';
import TransactionPaymentMethod from 'typings/enum/TransactionPaymentMethod';

interface Props {
  job: JobModel;
  isLoading: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  rootTabs: {
    backgroundColor: 'white',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
    borderRadius: '5px',
    height: '130px',
    padding: '16px'
  },
  bodyInfo: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  subTitle: {
    color: theme.palette.subtitle.main,
    ...theme.typography.body2
  },
  chipDiv: {
    float: 'right',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  chipType: {
    minWidth: '110px'
  },
  otTitle: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '18px',
    letter: '0.4px',
    marginLeft: 4
  }
}));

const InfoCard: FC<Props> = props => {
  const classes = useStyles();

  const { job, isLoading } = props;
  const { jobType, jobStatus, hours, price, totalAmount, totalDriver, totalTask, totalCompletedTask, totalDriverCancel, updatedAt, truckType } = job;
  let additionals: JobAdditional[] = [];
  let totalHours = 0;

  if (job.JobAdditionals && job.JobAdditionals.length > 0) {
    additionals = job.JobAdditionals.filter((v: JobAdditional) => v.status === 'CONFIRM');
    totalHours = additionals.map((v: JobAdditional) => v.hours).reduce((a: number, b: number) => a + b, 0) / 60;
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <Paper className={classes.rootTabs} elevation={0}>
          <Typography variant='h6'>
            Total Price
            <span className={classes.chipDiv}>
              <JobChip type={jobType as JobType} workType={job.workType} minWidth='110px' />
            </span>
          </Typography>
          <Typography variant='h3' className={classes.bodyInfo}>
            {isLoading ? (
              <Skeleton width={100} />
            ) : (
              <NumberFormat
                value={totalAmount}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            )}
          </Typography>
          <div className={classes.subTitle}>
            {isLoading ? (
              <Skeleton width={100} />
            ) : (
              <div>
                <NumberFormat value={price} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                {`/${jobType === JobType.DAYWORKS ? `hours/vehicle` : 'trip'}`}

                <span
                  className={classes.otTitle}
                  style={{ display: totalHours > 0 && truckType === TruckType.LORRY_CRANE ? 'inline-block' : 'none' }}
                >
                  + ({job.overtimePrice || 0}/Hour/Over Time)
                </span>
              </div>
            )}
          </div>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={12} md={3} lg={3}>
        <Paper className={classes.rootTabs} elevation={0}>
          <Typography variant='h6'>
            Total Driver
            <span className={classes.chipDiv}>
              <TruckChip label={truckTypeLabel(truckType)} minWidth='110px' />
              <JobPayment type={job.useCredit ? TransactionPaymentMethod.CREDIT_WALLET : TransactionPaymentMethod.JUSTGO_WALLET} minWidth='110px' />
            </span>
          </Typography>
          <Typography variant='h3' className={classes.bodyInfo}>
            {isLoading ? 0 : totalDriver} Driver
          </Typography>
          <Typography variant='body2' className={classes.subTitle}></Typography>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={12} md={3} lg={3}>
        <Paper className={classes.rootTabs} elevation={0}>
          <Typography variant='h6'>
            Job Progress
            <span className={classes.chipDiv}>
              <StatusChip status={jobStatus as JobStatus} label={ucwords(jobStatus)} minWidth='110px' />
            </span>
          </Typography>
          <Typography variant='h3' className={classes.bodyInfo}>
            {totalCompletedTask} {`${jobType === JobType.DAYWORKS ? 'Vehicles' : 'Trips'} Completed`}
          </Typography>
          <Typography variant='body2' className={classes.subTitle}>
            {totalTask} {`${jobType === JobType.DAYWORKS ? `Vehicles Required/${hours / 60} hours` : 'Trips Required'}`}
            <span className={classes.otTitle} style={{ display: totalHours > 0 ? 'inline-block' : 'none' }}>
              + {totalHours} Hour(s) Over Time{' '}
            </span>
          </Typography>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={12} md={3} lg={3}>
        <Paper className={classes.rootTabs} elevation={0}>
          <Typography variant='h6'>Driver Cancelled</Typography>
          <Typography variant='h3' className={classes.bodyInfo}>
            {isLoading ? 0 : totalDriverCancel} Drivers
          </Typography>
          <Typography variant='body2' className={classes.subTitle}>
            Updated on {formatDistanceToNow(new Date(updatedAt))} ago
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default InfoCard;
