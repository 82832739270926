import React, { FC, useEffect, useState, useCallback } from 'react';
import axios, { CancelTokenSource } from 'axios';
import {
  Theme,
  makeStyles,
  createStyles,
  withStyles,
  WithStyles,
  Button,
  Dialog,
  CircularProgress,
  IconButton,
  Typography,
  Stepper,
  Step,
  StepLabel
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import { green } from '@material-ui/core/colors';

import BankStep from './components/BankStep';
import CompanyStep from './components/CompanyStep';
import {
  WEB_MERCHANT_BASE_URL,
  BANKS_BASE_URL,
  GET_CHECK_USER_URL,
  WEB_INDIVIDUAL_STATUS_MERCHANT_URL,
  WEB_ADDITIONAL_CONTACT_CHECK_BASE_URL
} from 'constants/url';
import { isValidEmail, isStrongPassword } from 'utils';
import UserStatus from 'typings/enum/UserStatus';
import MerchantType from 'typings/enum/MerchantType';
import { Props as SnackbarProps } from 'components/Snackbar';

interface Props {
  open: boolean;
  editedMerchant?: MerchantModel;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedId: React.Dispatch<React.SetStateAction<number>>;
  handleOpenSnackbar: (message: string, options?: SnackbarProps) => void;
  refresh: (cb?: ((key: { [key: string]: any }) => any) | undefined) => void;
  clear: (cb?: () => void, cbp?: ((key: { [key: string]: any }) => any) | undefined) => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700]
    }
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h4'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const MerchantFormModal: FC<Props> = props => {
  const classes = useStyles();
  const { open, setOpen, setSelectedId, editedMerchant, refresh, handleOpenSnackbar } = props;

  const steps = ['Company information', 'Bank Account'];
  const [loading, setLoading] = useState<boolean>(false);
  const [isLoadingEmail, setLoadingEmail] = useState<boolean>(false);
  const [isLoadingContactNumber, setLoadingContactNumber] = useState<boolean>(false);
  const [isLoadingUen, setLoadingUen] = useState<boolean>(false);
  const [isLoadingGst, setLoadingGst] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [banks, setBanks] = useState<Select[]>([]);
  const [uploadCompanyFile, setUploadCompanyFile] = useState<string>('');

  const [inputMerchantType, setInputMerchantType] = useState<string>(MerchantType.SUPER_MERCHANT);
  const [inputCompanyName, setInputCompanyName] = useState<string>('');
  const [inputCompanyUen, setInputCompanyUen] = useState<string>('');
  const [inputCompanyGst, setInputCompanyGst] = useState<string>('');
  const [inputCompanyFile, setInputCompanyFile] = useState<string>('');
  const [inputDisplayName, setInputDisplayName] = useState<string>('');
  const [inputEmail, setInputEmail] = useState<string>('');
  const [inputContactNumber, setInputContactNumber] = useState<string>('');
  const [inputPassword, setInputPassword] = useState<string>('');
  const [inputConfirmPassword, setInputConfirmPassword] = useState<string>('');
  const [inputBankId, setInputBankId] = useState<number>(0);
  const [inputBankAccountName, setInputBankAccountName] = useState<string>('');
  const [inputBankAccountNumber, setInputBankAccountNumber] = useState<string>('');
  const [inputCompanyType, setInputCompanyType] = useState<string[]>([]);
  const [inputAdditionalEmail, setInputAdditionalEmail] = useState<string[]>([]);
  const [inputAllowSend, setInputAllowSend] = useState(false);

  const [errorCompanyName, setErrorCompanyName] = useState<string>('');
  const [errorCompanyUen, setErrorCompanyUen] = useState<string>('');
  const [errorCompanyGst, setErrorCompanyGst] = useState<string>('');
  const [errorCompanyFile, setErrorCompanyFile] = useState<string>('');
  const [errorDisplayName, setErrorDisplayName] = useState<string>('');
  const [errorEmail, setErrorEmail] = useState<string>('');
  const [errorContactNumber, setErrorContactNumber] = useState<string>('');
  const [errorPassword, setErrorPassword] = useState<string>('');
  const [errorConfirmPassword, setErrorConfirmPassword] = useState<string>('');
  const [errorBankName, setErrorBankName] = useState<string>('');
  const [errorBankAccountName, setErrorBankAccountName] = useState<string>('');
  const [errorBankAccountNumber, setErrorBankAccountNumber] = useState<string>('');
  const [errorAdditionalEmail, setErrorAdditionalEmail] = useState<string[]>([]);

  const getStepContent = (step: number) => {
    switch (step) {
      case 1:
        return (
          <BankStep
            banks={banks}
            merchant={editedMerchant}
            inputBankId={inputBankId}
            inputBankAccountName={inputBankAccountName}
            inputBankAccountNumber={inputBankAccountNumber}
            errorBankName={errorBankName}
            errorBankAccountName={errorBankAccountName}
            errorBankAccountNumber={errorBankAccountNumber}
            setInputBankId={setInputBankId}
            setInputBankAccountName={setInputBankAccountName}
            setInputBankAccountNumber={setInputBankAccountNumber}
            setErrorBankName={setErrorBankName}
            handleBlurBankAccountName={handleBlurBankAccountName}
            handleBlurBankAccountNumber={handleBlurBankAccountNumber}
            handleInvalidBankName={handleInvalidBankName}
          />
        );
      default:
        return (
          <CompanyStep
            merchant={editedMerchant}
            inputMerchantType={inputMerchantType}
            inputCompanyName={inputCompanyName}
            inputCompanyUen={inputCompanyUen}
            inputCompanyGst={inputCompanyGst}
            inputCompanyFile={inputCompanyFile}
            inputDisplayName={inputDisplayName}
            inputEmail={inputEmail}
            inputContactNumber={inputContactNumber}
            inputPassword={inputPassword}
            inputConfirmPassword={inputConfirmPassword}
            inputCompanyType={inputCompanyType}
            inputAdditionalEmail={inputAdditionalEmail}
            inputAllowSend={inputAllowSend}
            setInputAdditionalEmail={setInputAdditionalEmail}
            setInputAllowSend={setInputAllowSend}
            setInputMerchantType={setInputMerchantType}
            setInputCompanyName={setInputCompanyName}
            setInputCompanyUen={setInputCompanyUen}
            setInputCompanyGst={setInputCompanyGst}
            setInputCompanyFile={setInputCompanyFile}
            setInputDisplayName={setInputDisplayName}
            setInputEmail={setInputEmail}
            setInputContactNumber={setInputContactNumber}
            setInputPassword={setInputPassword}
            setInputConfirmPassword={setInputConfirmPassword}
            setErrorCompanyFile={setErrorCompanyFile}
            setErrorAdditionalEmail={setErrorAdditionalEmail}
            setUploadCompanyFile={setUploadCompanyFile}
            setInputCompanyType={setInputCompanyType}
            errorCompanyName={errorCompanyName}
            errorCompanyUen={errorCompanyUen}
            errorCompanyGst={errorCompanyGst}
            errorCompanyFile={errorCompanyFile}
            errorDisplayName={errorDisplayName}
            errorEmail={errorEmail}
            errorContactNumber={errorContactNumber}
            errorPassword={errorPassword}
            errorConfirmPassword={errorConfirmPassword}
            errorAdditionalEmail={errorAdditionalEmail}
            handleBlurName={handleBlurName}
            handleBlurEmail={handleBlurEmail}
            handleBlurContactNumber={handleBlurContactNumber}
            handleBlurCompanyName={handleBlurCompanyName}
            handleBlurCompanyUen={handleBlurCompanyUen}
            handleBlurCompanyGst={handleBlurCompanyGst}
            handleBlurPassword={handleBlurPassword}
            handleBlurConfirmPassword={handleBlurConfirmPassword}
            handleBlurAdditionalEmail={handleBlurAdditionalEmail}
            isLoadingEmail={isLoadingEmail}
            isLoadingContactNumber={isLoadingContactNumber}
            isLoadingUen={isLoadingUen}
            isLoadingGst={isLoadingGst}
          />
        );
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleNext = async () => {
    const valid = await handleCompanyFormValidation();
    if (!valid) {
      return;
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleSubmit: any = async () => {
    if (!handleBankFormValidation()) {
      return;
    }

    setLoading(true);
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    try {
      const valueBank = banks!.find(bank => bank.id === inputBankId);
      const newMerchant: any = {
        merchantType: MerchantType.SUPER_MERCHANT,
        companyName: inputCompanyName,
        companyUen: inputCompanyUen,
        companyGst: inputCompanyGst || '',
        companyFile: inputCompanyFile,
        bankName: valueBank ? valueBank.name : '',
        bankAccountName: inputBankAccountName,
        bankAccountNumber: inputBankAccountNumber,
        displayName: inputDisplayName,
        contactNumber: inputContactNumber,
        email: inputEmail,
        password: inputPassword,
        types: inputCompanyType,
        additionalContact: inputAdditionalEmail.map(v => ({
          value: v,
          allowSend: inputAllowSend
        }))
      };

      if (uploadCompanyFile) {
        const putUrl = await fecthPutFileUrl(inputCompanyFile);
        const fileName = inputCompanyFile ? inputCompanyFile : '';
        const fileExtension = fileName ? fileName.split('.').pop()! : '';

        const myHeaders = new Headers();
        if (fileExtension === 'pdf') {
          myHeaders.append('Content-Type', `application/${fileExtension}`);
        } else {
          myHeaders.append('Content-Type', `image/${fileExtension}`);
        }

        const config = {
          method: 'PUT',
          headers: myHeaders,
          body: uploadCompanyFile
        };

        const fileUpload = await fetch(putUrl, config);

        if (!fileUpload.ok) {
          handleOpenSnackbar('Failed upload file!', { variant: 'error' });
          setLoading(false);
          return;
        }
      }

      if (editedMerchant) {
        await axios.put(WEB_INDIVIDUAL_STATUS_MERCHANT_URL(editedMerchant.id), newMerchant, { cancelToken: cancelTokenSource.token });
        refresh();
        handleOpenSnackbar('Owner account has been updated!');
      } else {
        await axios.post(WEB_MERCHANT_BASE_URL, newMerchant, { cancelToken: cancelTokenSource.token });
        // clear(undefined, () => ({ status: UserStatus.APPROVED }));
        refresh(prev => ({ ...prev, status: UserStatus.APPROVED }));
        handleOpenSnackbar('Owner account has been created!');
      }

      handleClose();
      setLoading(false);
    } catch (err) {
      handleOpenSnackbar(err?.data.message, { variant: 'error' });
      setLoading(false);
    }

    return () => cancelTokenSource.cancel();
  };

  const handleClose = () => {
    setOpen(false);
    setActiveStep(0);
    setSelectedId(0);
    handleResetInput();
    handleCompanyFormClearError();
    handleBankFormClearError();
  };

  const handleResetInput = useCallback(() => {
    setActiveStep(0);
    handleCompanyFormClearError();
    handleBankFormClearError();

    setInputMerchantType(MerchantType.MERCHANT);
    setInputCompanyName('');
    setInputCompanyUen('');
    setInputCompanyGst('');
    setInputCompanyFile('');
    setInputDisplayName('');
    setInputEmail('');
    setInputContactNumber('');
    setInputBankAccountName('');
    setInputBankAccountNumber('');
    setInputBankId(0);
    setInputCompanyType([]);
    setInputAdditionalEmail([]);
    setInputAllowSend(false);
  }, []);

  const handleResetInputEdited = useCallback(() => {
    if (!editedMerchant) {
      return;
    }

    const {
      merchantType,
      companyName,
      companyUen,
      companyGst,
      companyFile,
      bankAccountName,
      bankAccountNumber,
      User,
      AdditionalContacts
    } = editedMerchant;
    const { displayName, contactNumber, email } = User;

    setInputMerchantType(merchantType);
    setInputCompanyName(companyName);
    setInputCompanyUen(companyUen);
    setInputCompanyGst(companyGst || '');
    setInputCompanyFile(companyFile);
    setInputDisplayName(displayName);
    setInputEmail(email);
    setInputContactNumber(contactNumber);
    setInputBankAccountName(bankAccountName);
    setInputBankAccountNumber(bankAccountNumber);
    setInputAdditionalEmail((AdditionalContacts || []).map(v => v.value));
    setInputAllowSend(!!AdditionalContacts?.length && AdditionalContacts.every(v => v.allowSend));

    setActiveStep(0);
    handleCompanyFormClearError();
    handleBankFormClearError();
  }, [editedMerchant]);

  const handleBankFormClearError = () => {
    setErrorBankName('');
    setErrorBankAccountName('');
    setErrorBankAccountNumber('');
  };

  const handleCompanyFormClearError = () => {
    setErrorCompanyName('');
    setErrorCompanyUen('');
    setErrorCompanyGst('');
    setErrorCompanyFile('');
    setErrorDisplayName('');
    setErrorEmail('');
    setErrorContactNumber('');
    setErrorPassword('');
    setErrorConfirmPassword('');
    setErrorAdditionalEmail([]);
  };

  const handleCompanyFormValidation = async () => {
    let valid = true;

    if (!inputEmail || !isValidEmail(inputEmail)) {
      valid = false;
      handleInvalidEmail();
    }

    if (!inputDisplayName) {
      valid = false;
      handleInvalidDisplayName();
    }

    if (inputContactNumber.length < 8) {
      valid = false;
      handleInvalidContactNumber();
    }

    if (!inputCompanyName) {
      valid = false;
      handleInvalidCompanyName();
    }

    if (!inputCompanyUen) {
      valid = false;
      handleInvalidCompanyUen();
    }

    if (inputCompanyUen.length < 9) {
      valid = false;
      handleCompanyUenLessThan9Chars();
    }

    // if (!inputCompanyFile) {
    //   valid = false;
    //   handleInvalidCompanyFile();
    // }

    if (valid && !editedMerchant && !!inputEmail) {
      setLoadingEmail(true);
      const isExistEmail = await isExistValidation('EMAIL', inputEmail);
      if (isExistEmail) {
        valid = false;
        setErrorEmail('Email has been registered');
      }

      setLoadingEmail(false);

      setLoadingContactNumber(true);
      const isExistContact = await isExistValidation('CONTACT', inputContactNumber);
      if (isExistContact) {
        valid = false;
        setErrorContactNumber('Contact number has been registered');
      }

      setLoadingContactNumber(false);

      setLoadingUen(true);
      const isExistUen = await isExistValidation('UEN', inputCompanyUen);
      if (isExistUen) {
        valid = false;
        setErrorCompanyUen('UEN number has been registered');
      }

      setLoadingUen(false);

      // NOTE : v1.4 possible non-gst
      if (inputCompanyGst) {
        setLoadingGst(true);
        const isExistGst = await isExistValidation('GST', inputCompanyGst);
        if (isExistGst) {
          valid = false;
          setErrorCompanyGst('GST number has been registered');
        }

        setLoadingGst(false);
      }
    }

    return valid;
  };

  const handleBankFormValidation = () => {
    let valid = true;

    // if (!inputBankId || inputBankId < 1) {
    //   valid = false;
    //   handleInvalidBankName();
    // }

    // if (!inputBankAccountName) {
    //   valid = false;
    //   handleInvalidBankAccountName();
    // }

    // if (!inputBankAccountNumber) {
    //   valid = false;
    //   handleInvalidBankAccountNumber();
    // }

    return valid;
  };

  const handleBlurEmail = async (inputed: string) => {
    setErrorEmail('');
    if (!inputed || inputed === '' || !isValidEmail(inputEmail)) {
      handleInvalidEmail();
    }

    if (!!inputEmail) {
      setLoadingEmail(true);
      const isExist = await isExistValidation('EMAIL', inputed);
      if (isExist) {
        setErrorEmail('Email has been registered');
      }
      setLoadingEmail(false);
    }
  };

  const handleBlurAdditionalEmail = (index: number) => async (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    let inputed = e.target.value;
    setErrorAdditionalEmail(p => {
      p[index] = '';
      return [...p]

    });

    const valid = isValidEmail(inputed);

    if (!inputed || inputed === '' || !valid) {
      setErrorAdditionalEmail(p => {
        p[index] =  'Please enter valid email';
        return [...p]
      });
    }

    // if (valid) {
    //   setErrorAdditionalEmail(p => {
    //     p[index] =  '';
    //     return [...p]
    //   });
    //   axios.post(WEB_ADDITIONAL_CONTACT_CHECK_BASE_URL, { id: 0, email: inputed }).then(({ data }) => {

    //     if(data?.exists){
    //       setErrorAdditionalEmail(p => {
    //         p[index] =  'Email has been registered';
    //         return [...p]
    //       });
    //     }
    //   });
    // }
  };

  const handleBlurName = (inputed: string) => {
    setErrorDisplayName('');
    if (!inputed || inputed === '') {
      handleInvalidDisplayName();
    }
  };

  const handleBlurContactNumber = async (inputed: string) => {
    setErrorContactNumber('');
    if (inputed.length < 8) {
      handleInvalidContactNumber();
    }

    setLoadingContactNumber(true);
    const isExist = await isExistValidation('CONTACT', inputed);
    if (isExist) {
      setErrorContactNumber('Contact number has been registered');
    }

    setLoadingContactNumber(false);
  };

  const handleBlurCompanyName = (inputed: string) => {
    setErrorCompanyName('');
    if (!inputed || inputed === '') {
      handleInvalidCompanyName();
    }
  };

  const handleBlurCompanyUen = async (inputed: string) => {
    setErrorCompanyUen('');
    if (!inputed || inputed === '') {
      handleInvalidCompanyUen();
    }

    setLoadingUen(true);
    const isExist = await isExistValidation('UEN', inputed);
    if (isExist) {
      setErrorCompanyUen('UEN number has been registered');
    }

    setLoadingUen(false);
  };

  const handleBlurCompanyGst = async (inputed: string) => {
    setErrorCompanyGst('');

    if (inputed) {
      setLoadingGst(true);
      const isExist = await isExistValidation('GST', inputed);
      if (isExist) {
        setErrorCompanyGst('GST number has been registered');
      }

      setLoadingGst(false);
    }
  };

  const handleBlurPassword = (inputed: string) => {
    setErrorPassword('');
    if (!inputed || inputed === '' || !isStrongPassword(inputed)) {
      handleInvalidPassword();
    }

    if (inputConfirmPassword) {
      setErrorConfirmPassword('');
      if (inputed !== inputConfirmPassword) {
        handleInvalidConfirmPassword();
      }
    }
  };

  const handleBlurConfirmPassword = (inputed: string) => {
    setErrorConfirmPassword('');
    if (!inputed || inputed === '' || inputed !== inputPassword) {
      handleInvalidConfirmPassword();
    }
  };

  const handleBlurBankAccountName = (inputed: string) => {
    setErrorBankAccountName('');
    if (!inputed || inputed === '') {
      handleInvalidBankAccountName();
    }
  };

  const handleBlurBankAccountNumber = (inputed: string) => {
    setErrorBankAccountNumber('');
    if (!inputed || inputed === '') {
      handleInvalidBankAccountNumber();
    }
  };

  const handleInvalidEmail = () => {
    setErrorEmail('Please enter valid email');
  };

  const handleInvalidDisplayName = () => {
    setErrorDisplayName('Please enter display name');
  };

  const handleInvalidContactNumber = () => {
    setErrorContactNumber('Please enter contact number 8 digits');
  };

  const handleInvalidCompanyName = () => {
    setErrorCompanyName('Please enter company name');
  };

  const handleInvalidCompanyUen = () => {
    setErrorCompanyUen('Please enter company UEN number');
  };

  const handleCompanyUenLessThan9Chars = () => {
    setErrorCompanyUen('Company UEN min length is 9');
  };

  // const handleInvalidCompanyFile = () => {
  //   setErrorCompanyFile('Please select ACRA file');
  // };

  const handleInvalidPassword = () => {
    setErrorPassword('Please enter password at least 8 digits containing 1 uppercase letter, 1 number and 1 symbol');
  };

  const handleInvalidConfirmPassword = () => {
    setErrorConfirmPassword('Please enter valid confirm password');
  };

  const handleInvalidBankName = () => {
    // setErrorBankName('Please select bank');
  };

  const handleInvalidBankAccountName = () => {
    // setErrorBankAccountName('Please enter bank account name');
  };

  const handleInvalidBankAccountNumber = () => {
    // setErrorBankAccountNumber('Please enter bank account number');
  };

  const fetchDataBanks = useCallback(async () => {
    if (!open) {
      return;
    }

    try {
      const { data } = await axios.get(BANKS_BASE_URL);

      setBanks(data.Banks);
    } catch (err) {
      console.error('err: ', err);
    }

    return;
  }, [open]);

  const fecthPutFileUrl = async (filename: string) => {
    try {
      const { data } = await axios.get(`${WEB_MERCHANT_BASE_URL}/put-company-file-url?file=${filename}`);

      return data.companyFile;
    } catch (err) {
      console.error('err: ', err);
    }

    return;
  };

  const isExistValidation = async (field: string, value: string) => {
    let exist = false;
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    try {
      const userId = editedMerchant ? `&userId = ${editedMerchant.id}` : '';
      const { data } = await axios.get(`${GET_CHECK_USER_URL}?field=${field}&params=${value}${userId}`, {
        cancelToken: cancelTokenSource.token
      });
      exist = data.isExist;
    } catch (err) {
      handleOpenSnackbar(err?.data.message, { variant: 'error' });
    }

    return exist;
  };

  useEffect(() => {
    fetchDataBanks();
  }, [fetchDataBanks]);

  useEffect(() => {
    if (!open) {
      return;
    }

    if (!editedMerchant) {
      handleResetInput();
      return;
    }

    handleResetInputEdited();
  }, [open, editedMerchant, handleResetInput, handleResetInputEdited]);

  return (
    <div>
      <Dialog fullWidth={true} maxWidth='md' onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
        <DialogTitle id='customized-dialog-title' onClose={handleClose}>
          {editedMerchant ? 'Edit' : 'Create New'} Owner
        </DialogTitle>
        <DialogContent dividers>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: { optional?: React.ReactNode } = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <div>{getStepContent(activeStep)}</div>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' color='primary' disabled={loading} onClick={activeStep === 0 ? handleClose : handleBack}>
            {activeStep === 0 ? 'Cancel' : 'Back'}
          </Button>
          <div className={classes.wrapper}>
            <Button variant='contained' color='primary' disabled={loading} onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MerchantFormModal;
