import { responsiveFontSizes, createMuiTheme } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

declare module '@material-ui/core/styles/' {
  interface Theme {
    border: {
      primary: string;
    };
  }
  interface ThemeOptions {
    border?: {
      primary?: string;
    };
  }
}

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    subtitle: Palette['primary'];
    link: Palette['primary'];
    black: Palette['grey'];
    orange: Palette['grey'];
  }
  interface PaletteOptions {
    subtitle: PaletteOptions['primary'];
    link: PaletteOptions['primary'];
    black: PaletteOptions['grey'];
    orange: PaletteOptions['grey'];
  }
}

let theme = createMuiTheme({
  border: {
    primary: grey[300]
  },
  palette: {
    primary: {
      main: '#F6891F',
      contrastText: '#fff'
    },
    secondary: {
      main: '#29696A',
      contrastText: '#fff'
    },
    link: {
      main: '#2D9CDB'
    },
    subtitle: {
      main: '#7c7d7e'
    },
    black: {
      '50': '#848484',
      A100: '#333333'
    },
    orange: {
      '100': '#FEF1D2',
      '500': '#F6891F'
    }
  },

  typography: {
    h1: {
      fontSize: '24px',
      fontWeight: 700
    },
    h2: {
      fontSize: '28px',
      fontWeight: 500
    },
    h3: {
      fontSize: '24px',
      fontWeight: 500
    },
    h4: {
      fontSize: '20px',
      fontWeight: 700
    },
    h5: {
      fontSize: '16px',
      fontWeight: 700
    },
    h6: {
      fontSize: '14px',
      fontWeight: 400
    },
    body1: {
      fontSize: '13px'
    },
    body2: {
      fontSize: '14px',
      fontWeight: 400
    },
    subtitle1: {
      fontSize: '13px',
      fontWeight: 500
    },
    button: {
      fontSize: '13px'
    },
    overline: {
      fontSize: '13px',
      lineHeight: '13px'
    },
    caption: {
      fontSize: '11px',
      lineHeight: '11px'
    }
  },
  overrides: {
    MuiOutlinedInput: {
      adornedStart: {
        paddingLeft: 0,
        marginLeft: 0
      }
    },
    MuiTypography: {
      h1: {
        color: '#000000',
        lineHeight: '150%'
      },
      h4: {
        color: '#333333',
        lineHeight: '150%',
        letterSpacing: '0.15px',
        fontStyle: 'normal'
      },
      h5: {
        color: '#000000',
        lineHeight: '150%',
        letterSpacing: '0.15px',
        fontStyle: 'normal'
      },
      body2: {
        color: '#848484',
        lineHeight: '150%'
      }
    },
    MuiTableContainer: {
      root: {
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08)',
        borderRadius: '5px'
      }
    },
    MuiButton: {
      root: {
        textTransform: 'none'
      }
    }
  }
});

theme = responsiveFontSizes(theme);

export default theme;
