import React, { FC, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import { MuiPickersUtilsProvider, DateTimePicker, MaterialUiPickersDate, DatePicker } from '@material-ui/pickers';
import { makeStyles, withStyles } from '@material-ui/styles';
import { Button, Grid, MenuItem, TextField, Theme } from '@material-ui/core';

interface Option {
  key: string;
  label: string;
}

interface Props {
  setOpenPopper: React.Dispatch<React.SetStateAction<boolean>>;
  options: Option[];
  clearRef?: ((instance: HTMLButtonElement | null) => void) | React.RefObject<HTMLButtonElement> | null;
  closeRef?: ((instance: HTMLButtonElement | null) => void) | React.RefObject<HTMLButtonElement> | null;
  onChange: (dateRange: string) => void;
  onSelectedChange: (value: string) => void;
  onClear?: () => void;
  mode: 'datetime' | 'date';
  currentValue?: string;
  dateBy: string;
  disabledCustomDate?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  clearAndCloseButton: {
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.dark
    },
    padding: theme.spacing(0)
  },
  textFieldFont: {
    fontSize: '12px',
    height: 18
  }
}));

const ClearButton = withStyles({
  label: {
    textTransform: 'capitalize',
    marginRight: 25
  }
})(Button);

const CloseButton = withStyles({
  label: {
    textTransform: 'capitalize',
    marginLeft: 25
  }
})(Button);

const DateTimeRangePicker: FC<Props> = props => {
  const classes = useStyles(props);
  const { setOpenPopper, options, onChange, mode = 'datetime', currentValue, dateBy, onSelectedChange } = props;
  const PickerComponent = mode === 'datetime' ? DateTimePicker : DatePicker;
  let currentStartDate: null | Date = null;
  let currentEndDate: null | Date = null;

  if (currentValue) {
    const [startDate, endDate] = currentValue.split('~');
    currentStartDate = new Date(startDate);
    currentEndDate = new Date(endDate);
  }

  const [selectedStartDate, setSelectedStartDate] = useState<MaterialUiPickersDate>(currentStartDate);
  const [selectedEndDate, setSelectedEndDate] = useState<MaterialUiPickersDate>(currentEndDate);

  const clearValue = () => {
    if (props.onClear) props.onClear();
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    onChange('');
  };

  const functionChangeStartDate = (date: MaterialUiPickersDate) => {
    setSelectedStartDate(date);
  };

  const handleFilterByChange = (event: any) => {
    if (event.target.value === '5' || event.target.value === 'termStart' || event.target.value === 'termEnd') {
      onSelectedChange(event.target.value as string);
    } else if (event.target.value === '1') {
      setSelectedStartDate(new Date());
      setSelectedEndDate(new Date());
      onSelectedChange(event.target.value as string);
    } else if (event.target.value === '2') {
      onSelectedChange(event.target.value as string);
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(0, 0, 0, 0);
      setSelectedStartDate(tomorrow);
      setSelectedEndDate(tomorrow);
    } else if (event.target.value === '3') {
      onSelectedChange(event.target.value as string);
      const weekStart = startOfWeek(new Date());
      const weekEnd = endOfWeek(new Date());
      setSelectedStartDate(weekStart);
      setSelectedEndDate(weekEnd);
    } else if (event.target.value === '4') {
      onSelectedChange(event.target.value as string);
      const current = new Date();
      const monthStart = new Date(current.getFullYear(), current.getMonth(), 1);
      monthStart.setUTCHours(monthStart.getUTCHours() + 8);
      const monthEnd = new Date(current.getFullYear(), current.getMonth() + 1, 0);
      monthEnd.setUTCHours(monthEnd.getUTCHours() + 8);
      setSelectedStartDate(monthStart);
      setSelectedEndDate(monthEnd);
    } else {
      onSelectedChange(event.target.value as string);
    }
  };

  const handleStartDateChange = (date: MaterialUiPickersDate) => {
    functionChangeStartDate(date);
  };

  const handleEndDateChange = (date: MaterialUiPickersDate) => {
    setSelectedEndDate(date);
  };

  const handleClickClearButton = () => {
    clearValue();
  };

  const handleCloseCalendarPopper = () => {
    setOpenPopper(false);
  };

  const handleDoneCalendarPopper = () => {
    const startDate = selectedStartDate ? selectedStartDate.toISOString() : '';
    const endDate = selectedEndDate ? selectedEndDate.toISOString() : '';
    onChange(startDate === '' && endDate === '' ? '' : `${startDate}|${endDate}`);
    handleCloseCalendarPopper();
  };

  // const disablePrevDates = (startDate: Date) => {
  //   const startSeconds = Date.parse(startDate.toDateString());
  //   return (date: any) => {
  //     return Date.parse(date) < startSeconds;
  //   };
  // };

  return (
    <>
      <Grid container direction='row' justify='space-between' alignItems='flex-start'>
        <ClearButton ref={props.clearRef} size='small' className={classes.clearAndCloseButton} onClick={handleClickClearButton} disableRipple>
          Clear
        </ClearButton>
        <CloseButton ref={props.closeRef} size='small' className={classes.clearAndCloseButton} onClick={handleCloseCalendarPopper} disableRipple>
          Close
        </CloseButton>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <TextField
            select
            margin='dense'
            fullWidth
            id='option'
            label='Choose'
            value={dateBy}
            onChange={event => handleFilterByChange(event)}
            variant='outlined'
            autoComplete='off'
            InputProps={{
              classes: {
                input: classes.textFieldFont
              }
            }}
            InputLabelProps={{
              className: classes.textFieldFont,
              shrink: dateBy === '' ? false : true
            }}
          >
            <MenuItem key={'None'} value=''>
              <em>None</em>
            </MenuItem>
            {options.map(option => (
              <MenuItem key={option.key} value={option.key}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item xs={6}>
            <PickerComponent
              fullWidth
              autoOk
              allowKeyboardControl
              disabled={props.disabledCustomDate && props.disabledCustomDate}
              margin='dense'
              id='startDate'
              label='Start Date'
              value={selectedStartDate || new Date()}
              variant='inline'
              inputVariant='outlined'
              format='dd/MM/yyyy'
              onChange={handleStartDateChange}
              InputProps={{
                classes: {
                  input: classes.textFieldFont
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <PickerComponent
              fullWidth
              autoOk
              allowKeyboardControl
              disabled={props.disabledCustomDate && props.disabledCustomDate}
              margin='dense'
              id='endDate'
              label='End Date'
              value={selectedEndDate || new Date()}
              // shouldDisableDate={disablePrevDates(new Date(selectedStartDate !== null ? new Date(selectedStartDate).getTime() - 864e5 : ''))}
              minDate={selectedStartDate}
              variant='inline'
              inputVariant='outlined'
              format='dd/MM/yyyy'
              onChange={handleEndDateChange}
              InputProps={{
                classes: {
                  input: classes.textFieldFont
                }
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
        <Grid item xs={12}>
          <Button disabled={!!!dateBy} fullWidth variant='contained' disableElevation color='primary' onClick={handleDoneCalendarPopper}>
            Done
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default DateTimeRangePicker;
