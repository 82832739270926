import React from 'react';
import { Divider, Drawer, IconButton, List, ListItem, ListItemText, Toolbar, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import axios from 'axios';
import { Skeleton } from '@material-ui/lab';
import { WEB_MATERIAL_BASE_URL } from 'constants/url';
import { dummyMaterial } from 'constants/DummyData';
import { formatCurrency, jobTypeLabel } from 'utils';
import JobType from 'typings/enum/JobType';

interface Props {
  id: number;
  clear: () => void;
}

const MaterialDrawer = ({ id, clear }: Props) => {
  const abort = axios.CancelToken.source();
  const [data, setData] = React.useState(dummyMaterial);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const getMaterial = async (id: number) => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${WEB_MATERIAL_BASE_URL}/${id}`, { cancelToken: abort.token });
      setData(data || []);
    } catch (error) {
      console.log('error', JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (id > 0) {
      setOpen(true);
      getMaterial(id);
    } else {
      setOpen(false);
    }

    return () => {
      abort.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Drawer
      open={open}
      anchor='right'
      PaperProps={{ style: { width: '35%' } }}
      ModalProps={{ style: { position: 'relative' } }}
      disableBackdropClick
      disableEscapeKeyDown
      disableEnforceFocus
      disableAutoFocus
      hideBackdrop
    >
      <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='h5'>Material</Typography>
        <IconButton
          size='small'
          onClick={() => {
            setOpen(false);
            clear();
          }}
        >
          <Close fontSize='inherit' />
        </IconButton>
      </Toolbar>
      <Divider />

      <Typography variant='h6' gutterBottom style={{ fontSize: '16px', fontWeight: 500, margin: '8px' }}>
        {data.material}
      </Typography>

      <Divider />

      <List>
        {loading ? (
          [1, 2, 3, 4].map(v => (
            <ListItem key={v}>
              <Skeleton variant='text' width={'100%'} />
            </ListItem>
          ))
        ) : !!data.MaterialPrices.length ? (
          data.MaterialPrices.filter(v => ![JobType.DAYWORKS, JobType.TRIPWORKS].includes(v.jobType)).map((val, i) => (
            <ListItem key={i} divider>
              <ListItemText primary={`${jobTypeLabel(val.jobType)} ${val.type || ''}`} secondary={formatCurrency(val.price)} />
            </ListItem>
          ))
        ) : (
          <ListItem>
            <ListItemText primary={'Data Not Available'} primaryTypographyProps={{ align: 'center' }} />
          </ListItem>
        )}
      </List>
    </Drawer>
  );
};

export default MaterialDrawer;
