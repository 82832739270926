import React from 'react';
import axios from 'axios';
import { Theme, makeStyles, Button, CircularProgress, Typography, TextField, Divider, Box } from '@material-ui/core';

import { green } from '@material-ui/core/colors';
import { WEB_MATERIAL_BASE_URL } from 'constants/url';
import useSnackbar from 'hooks/useSnackbar';
import Snackbar from 'components/Snackbar';
import { dummyMaterial } from 'constants/DummyData';
import { DummyMaterial } from '..';
import Dialog from 'components/Dialog';
import JobType from 'typings/enum/JobType';
import InputNumberFormat from 'components/InputNumberFormat';

interface Props {
  open: boolean;
  data: DummyMaterial;
  setData: React.Dispatch<React.SetStateAction<DummyMaterial>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  reload: (cb?: ((key: { [key: string]: any }) => any) | undefined) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700]
    }
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

const MaterialForm: React.FC<Props> = props => {
  const classes = useStyles();
  const { open, setOpen, data, setData } = props;

  const [loading, setLoading] = React.useState<boolean>(false);
  const [processing, setProcessing] = React.useState<boolean>(false);
  const [error, setError] = React.useState({
    material: ''
  });

  const { openSnacbar, snackbar } = useSnackbar();

  const handleSubmit = async () => {
    try {
      setError({ material: '' });
      if (!validation()) return;
      setLoading(true);
      await axios[!!data.id ? 'put' : 'post'](WEB_MATERIAL_BASE_URL, data);
      openSnacbar(!!data.id ? 'Material successfully updated' : 'Material successfull added');
      handleClose();
      props.reload();
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    handleResetInput();
  };

  const handleResetInput = () => {
    setError({ material: '' });
    setData(dummyMaterial);
  };

  const validation = () => {
    let valid = true;

    if (data.material === '' || !data.material.trim()) {
      setError(state => ({ ...state, material: 'Name is required' }));
      valid = false;
    }

    return valid;
  };

  const setValue = (id, jobType, type, value) => {
    setData(p => {
      const index = p.MaterialPrices.findIndex(v => v.jobType === jobType && v.type === type && v.id === id);
      if (index > -1) {
        p.MaterialPrices[index] = {
          id,
          jobType,
          type,
          price: value
        };
      }
      return { ...p };
    });
  };

  React.useEffect(() => {
    if (!data.id) return;

    (() => {
      setProcessing(true);
      axios
        .get(`${WEB_MATERIAL_BASE_URL}/${data.id}`)
        .then(resp => {
          setData({
            ...resp.data,
            MaterialPrices: dummyMaterial.MaterialPrices.reduce(
              (p, n) => {
                const find = resp.data.MaterialPrices.find(v => v.jobType === n.jobType && v.type === n.type);
                if (find) {
                  p.push(find);
                } else {
                  p.push(n);
                }
                return p;
              },
              [] as any
            )
          });
        })
        .finally(() => setProcessing(false));
    })();
  }, [open]);

  return (
    <div>
      <Dialog
      open={open}
      title={`${!!data.id ? 'Edit' : 'Create New'} Material`}
      fullWidth={true}
      maxWidth='sm'
      contentProps={{ dividers: true, style: { display: 'flex', flexDirection: 'column', gap: '16px' } }}
      handleClose={handleClose}
      action={{
        children: (
          <>
            <Button variant='outlined' color='primary' disabled={loading || processing} onClick={handleClose}>
              Cancel
            </Button>
            <div className={classes.wrapper}>
              <Button variant='contained' color='primary' disabled={loading || processing} disableElevation onClick={handleSubmit}>
                {!!data.id ? 'Save change' : 'Add New'}
              </Button>
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          </>
        )
      }}
    >
      {processing ? (
        <Typography component={'div'} variant='caption'>
          Loading...
        </Typography>
      ) : null}

      <Box display='flex' flexDirection='row' style={{ gap: '8px' }}>
        <TextField
          disabled={processing}
          size='small'
          variant='outlined'
          required
          type='text'
          label='Material'
          value={data.material || ''}
          onChange={e => {
            e.persist();
            setData(prev => ({ ...prev, material: e.target.value as string }));
          }}
          error={error.material !== ''}
          helperText={error.material}
          style={{ flex: 1 }}
        />

        <TextField
          disabled={processing}
          size='small'
          variant='outlined'
          label='Order'
          value={data.order || 0}
          onChange={e => {
            e.persist();
            if (Number.isInteger(+e.target.value)) {
              setData(prev => ({ ...prev, order: +e.target.value }));
            }
          }}
          style={{ width: '15%' }}
          InputProps={{ inputProps: { style: { textAlign: 'center' } } }}
        />
      </Box>

      {/* <Typography variant='h6' style={{ fontWeight: 700 }}>
        Dayworks
      </Typography>
      <Divider />

      {data.MaterialPrices?.filter(v => v.jobType === JobType.DAYWORKS).map((val, i) => (
        <TextField
          disabled={processing}
          key={i}
          size='small'
          variant='outlined'
          label='Dayworks Price'
          value={val.price}
          onChange={e => setValue(val.id, val.jobType, val.type, +e.target.value)}
          InputProps={{
            inputComponent: InputNumberFormat as any
          }}
        />
      ))}

      <Typography variant='h6' style={{ fontWeight: 700 }}>
        Tripworks
      </Typography>
      <Divider />

      {data.MaterialPrices?.filter(v => v.jobType === JobType.TRIPWORKS).map((val, i) => (
        <TextField
          disabled={processing}
          key={i}
          size='small'
          variant='outlined'
          label='Tripworks Price'
          value={val.price}
          onChange={e => setValue(val.id, val.jobType, val.type, +e.target.value)}
          InputProps={{
            inputComponent: InputNumberFormat as any
          }}
        />
      ))} */}

      <Typography variant='h6' style={{ fontWeight: 700 }}>
        OU Works
      </Typography>
      <Divider />

      {data.MaterialPrices?.filter(v => v.jobType === JobType.OU_WORKS).map((val, i) => (
        <TextField
          disabled={processing}
          key={i}
          size='small'
          variant='outlined'
          label={`OU Works ${val.type} Price`}
          value={val.price}
          onChange={e => setValue(val.id, val.jobType, val.type, +e.target.value)}
          InputProps={{
            inputComponent: InputNumberFormat as any
          }}
        />
      ))}

    </Dialog>

    <Snackbar {...snackbar} />
    </div>
  );
};

export default MaterialForm;
