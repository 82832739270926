import React, { FC, useEffect } from 'react';
import { Container, makeStyles, Theme, Grid, FormControl, TextField, MenuItem } from '@material-ui/core';

import NumberFormatCustom from 'components/NumberFormatCustom';
import { staticString } from 'utils';

interface Props {
  banks: Select[];
  inputBankId: number;
  inputBankAccountName: string;
  inputBankAccountNumber: string;
  errorBankName: string;
  errorBankAccountName: string;
  errorBankAccountNumber: string;
  merchant?: MerchantModel;
  setInputBankId: React.Dispatch<React.SetStateAction<number>>;
  setInputBankAccountName: React.Dispatch<React.SetStateAction<string>>;
  setInputBankAccountNumber: React.Dispatch<React.SetStateAction<string>>;
  setErrorBankName: React.Dispatch<React.SetStateAction<string>>;
  handleBlurBankAccountName: (inputed: string) => void;
  handleBlurBankAccountNumber: (inputed: string) => void;
  handleInvalidBankName: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  container: {
    '& > :nth-child(n+2)': {
      marginTop: theme.spacing(2)
    }
  }
}));

const BankStep: FC<Props> = props => {
  const classes = useStyles();

  const {
    banks,
    merchant,
    inputBankId,
    inputBankAccountName,
    inputBankAccountNumber,
    errorBankName,
    errorBankAccountName,
    errorBankAccountNumber,
    setInputBankId,
    setInputBankAccountName,
    setInputBankAccountNumber,
    setErrorBankName,
    handleBlurBankAccountName,
    handleBlurBankAccountNumber,
    handleInvalidBankName
  } = props;

  const handleChangeBank = (event: React.ChangeEvent<{ value: any }>) => {
    const selectedBank = event.target.value as number;
    setInputBankId(selectedBank);
    setErrorBankName('');

    if (!selectedBank || selectedBank < 1) {
      handleInvalidBankName();
    }
  };

  useEffect(() => {
    if (!merchant) {
      return;
    }

    const { bankName } = merchant;
    const valueBank = banks.find(bank => {
      if (staticString(bank.name) === staticString(bankName)) {
        return bank;
      } else {
        return false;
      }
    });

    setInputBankId(valueBank ? Number(valueBank.id) : 0);
  }, [merchant, banks, setInputBankId]);

  return (
    <Container maxWidth='lg' className={classes.container}>
      <Grid container direction='row' justify='space-between' alignItems='center' spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControl fullWidth>
            <TextField
              select
              variant='outlined'
              label='Bank Name'
              value={inputBankId}
              helperText={errorBankName}
              error={errorBankName !== ''}
              onChange={handleChangeBank}
            >
              <MenuItem key='bank-0' value={0}>
                <em>None</em>
              </MenuItem>
              {banks && banks.length > 0
                ? banks.map((option, index) => (
                    <MenuItem key={`bank-${index}`} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))
                : ''}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControl fullWidth>
            <TextField
              variant='outlined'
              label='Bank Account Name'
              value={inputBankAccountName}
              helperText={errorBankAccountName}
              error={errorBankAccountName !== ''}
              onChange={event => setInputBankAccountName(event.target.value)}
              onBlur={event => handleBlurBankAccountName(event.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControl fullWidth>
            <TextField
              variant='outlined'
              label='Bank Account No'
              value={inputBankAccountNumber}
              helperText={errorBankAccountNumber}
              error={errorBankAccountNumber !== ''}
              onChange={event => setInputBankAccountNumber(event.target.value.trim())}
              onBlur={event => handleBlurBankAccountNumber(event.target.value.trim())}
              InputProps={{
                inputComponent: NumberFormatCustom as any
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Container>
  );
};

export default BankStep;