import React, { FC, useEffect, useState } from 'react';
import axios from 'axios';
import {
  Theme,
  makeStyles,
  createStyles,
  withStyles,
  WithStyles,
  Button,
  Dialog,
  CircularProgress,
  IconButton,
  Typography,
  TextField,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import { green } from '@material-ui/core/colors';
import NumberFormat from 'react-number-format';
import { ADDITIONAL_REQUEST_BASE_URL } from 'constants/url';
interface Props {
  open: boolean;
  additionalItems: AdditionalTemplates[];
  additional: AdditionalTemplates;
  isEdit: boolean;
  setAdditionalItems: React.Dispatch<React.SetStateAction<AdditionalTemplates[]>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleOpenSnackbar: (type: 'success' | 'error', message: string) => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700]
    }
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
      prefix='$'
    />
  );
}

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h4'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const AdditionalTemplateForm: FC<Props> = props => {
  const classes = useStyles();
  const { open, setOpen, additionalItems, setAdditionalItems, additional, isEdit, handleOpenSnackbar } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState(0);
  const [error, setError] = useState({
    name: ''
  });

  const handleSubmit = async () => {
    try {
      setError({ name: '' });
      if (!validation()) return;
      setLoading(true);

      const { data } = await axios[isEdit ? 'put' : 'post'](ADDITIONAL_REQUEST_BASE_URL, { id: additional.id, name, description, price });
      if (isEdit) {
        additionalItems[additionalItems.findIndex(v => v.id === additional.id)] = data;
      } else {
        setAdditionalItems(state => [data, ...state]);
      }
      setOpen(false);
      handleResetInput();
      handleOpenSnackbar('success', `Additional Items has been ${isEdit ? 'updated' : 'added'}`);
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    handleResetInput();
  };

  const handleResetInput = () => {
    setName('');
    setDescription('');
    setPrice(0);
    setError({ name: '' });
  };

  const validation = () => {
    let valid = true;

    if (name === '' || !name.trim()) {
      setError(state => ({ ...state, name: 'Name is required' }));
      valid = false;
    }

    return valid;
  };

  useEffect(() => {
    if (!isEdit) return;

    setName(additional.name);
    setDescription(additional.description);
    setPrice(additional.price);
    setError({ name: '' });

  }, [additional, isEdit]);

  return (
    <div>
      <Dialog fullWidth={true} maxWidth='sm' onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
        <DialogTitle id='customized-dialog-title' onClose={handleClose}>
          {isEdit ? 'Edit' : 'Create New'} Item
        </DialogTitle>
        <DialogContent dividers style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <TextField
            variant='outlined'
            required
            label='Name'
            type='text'
            name='name'
            value={name}
            onChange={e => {
              setName(e.target.value);
            }}
            error={error.name !== ''}
            helperText={error.name}
          />

          <TextField
            variant='outlined'
            multiline
            rows={4}
            name='description'
            label='Description'
            value={description}
            onChange={e => setDescription(e.target.value)}
          />

          <TextField
            variant='outlined'
            label='Unit Price'
            value={price}
            onChange={e => setPrice(+e.target.value)}
            InputProps={{
              inputComponent: NumberFormatCustom as any
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' color='primary' disabled={loading} onClick={handleClose}>
            Cancel
          </Button>
          <div className={classes.wrapper}>
            <Button variant='contained' color='primary' disabled={loading} onClick={handleSubmit}>
              Finish
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AdditionalTemplateForm;
