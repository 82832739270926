import React from 'react';
import { Box, Button, ButtonProps, Container, makeStyles, Theme, Typography, Breadcrumbs, Link } from '@material-ui/core';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import useRouter from 'hooks/useRouter';
import clsx from 'clsx';
export interface SettingTemplateProps {
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
  rightButton?: React.ReactNode;
  refreshButton?: boolean;
  primaryButton?: boolean | React.ReactNode;
  breadcrumb?: boolean;
  links?: { name: string; links: 'goback' | string; click: boolean }[];
  refreshButtonProps?: ButtonProps;
  primaryButtonProps?: ButtonProps;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '32px'
  },
  rightHeaderPage: {
    display: 'flex',
    gap: '10px',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  iconButton: {
    padding: '8px',
    borderRadius: '5px',
    minWidth: 'unset',
    border: `1.5px solid ${theme.palette.primary.main}`
  },
  content: {
    marginTop: '20px'
  },
  primaryButton: {
    padding: '8px 16px 8px 16px',
    textTransform: 'none'
  },
  backButton: {
    marginTop: '16px'
  },
  subtitle: {
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 400,
    letter: '0.25px',
    color: theme.palette.black[50]
  },
  alert: {
    marginTop: '20px'
  },
  button: {
    padding: '8px 16px 8px 16px',
    marginRight: '16px',
    textTransform: 'none',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 400,
    borderRadius: '5px',
    color: theme.palette.black[50]
  },
  buttonBackground: {
    backgroundColor: '#FEF1D2',
    color: theme.palette.primary.main
  }
}));

const SettingTemplate = ({
  title,
  subtitle,
  breadcrumb,
  links,
  primaryButton,
  refreshButton,
  refreshButtonProps,
  primaryButtonProps,
  ...props
}: SettingTemplateProps) => {
  const classes = useStyles();
  const { history, location } = useRouter();
  document.title = title;

  return (
    <Container className={classes.container} maxWidth={false}>
      <Box display={'flex'} flex={1} flexDirection={'column'}>
        <Box display={'flex'} flexDirection='row' justifyContent='space-between' alignContent='center'>
          <Box>
            <Typography variant='h1' gutterBottom>
              {title}
            </Typography>

            {!breadcrumb && subtitle && (
              <Typography variant='inherit' className={classes.subtitle}>
                {subtitle || ''}
              </Typography>
            )}

            {breadcrumb && (
              <Box>
                <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
                  {links &&
                    links.map((value, i) =>
                      value.click ? (
                        <Link
                          key={'links-' + i}
                          color='inherit'
                          onClick={() => {
                            if (value.links === 'goback') {
                              history.goBack();
                            } else {
                              history.push(value.links);
                            }
                          }}
                        >
                          {value.name}
                        </Link>
                      ) : (
                        <Typography key={'links-' + i} color='textPrimary'>
                          {value.name}
                        </Typography>
                      )
                    )}
                </Breadcrumbs>

                <Button
                  variant='text'
                  onClick={() => {
                    history.goBack();
                  }}
                  className={classes.backButton}
                >
                  <ArrowBackIcon fontSize='small' /> Back
                </Button>
              </Box>
            )}
          </Box>

          <Box className={classes.rightHeaderPage}>
            {refreshButton && (
              <div>
                <Button variant='outlined' disableElevation color='primary' className={classes.iconButton} {...refreshButtonProps}>
                  <AutorenewIcon fontSize='small' />
                </Button>
              </div>
            )}

            {typeof primaryButton === 'boolean' && primaryButton && (
              <div>
                <Button variant='contained' disableElevation color='primary' className={classes.primaryButton} {...primaryButtonProps} />
              </div>
            )}

            {typeof primaryButton !== 'boolean' && primaryButton}
          </Box>
        </Box>

        <Box display='flex' style={{ marginTop: '20px' }}>
          <Button
            variant='outlined'
            className={clsx(classes.button, { [classes.buttonBackground]: location.pathname === '/settings' })}
            color={location.pathname === '/settings' ? 'primary' : 'default'}
            onClick={() => {
              history.push('/settings');
            }}
          >
            General Settings
          </Button>

          <Button
            variant='outlined'
            color={location.pathname === '/settings/materials' ? 'primary' : 'default'}
            className={clsx(classes.button, { [classes.buttonBackground]: location.pathname === '/settings/materials' })}
            onClick={() => {
              history.push('/settings/materials');
            }}
          >
            Material
          </Button>

          <Button
            variant='outlined'
            color={location.pathname === '/settings/tonnages' ? 'primary' : 'default'}
            className={clsx(classes.button, { [classes.buttonBackground]: location.pathname === '/settings/tonnages' })}
            onClick={() => {
              history.push('/settings/tonnages');
            }}
          >
            Tonnage
          </Button>

          <Button
            variant='outlined'
            color={location.pathname === '/settings/equipments' ? 'primary' : 'default'}
            className={clsx(classes.button, { [classes.buttonBackground]: location.pathname === '/settings/equipments' })}
            onClick={() => {
              history.push('/settings/equipments');
            }}
          >
            Equipment
          </Button>

          <Button
            variant='outlined'
            color={location.pathname === '/settings/additional' ? 'primary' : 'default'}
            className={clsx(classes.button, { [classes.buttonBackground]: location.pathname === '/settings/additional' })}
            onClick={() => {
              history.push('/settings/additional');
            }}
          >
            Additional Item
          </Button>

          <Button
            variant='outlined'
            color={location.pathname === '/settings/lampposts' ? 'primary' : 'default'}
            className={clsx(classes.button, { [classes.buttonBackground]: location.pathname === '/settings/lampposts' })}
            onClick={() => {
              history.push('/settings/lampposts');
            }}
          >
            LampPost
          </Button>
        </Box>

        <Box className={classes.content}>{props.children}</Box>
      </Box>
    </Container>
  );
};

export default SettingTemplate;

SettingTemplate.defaultProps = {
  title: '',
  subtitle: '',
  breadcrumb: false,
  refreshButton: true,
  primaryButton: true
};
