import React from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
  Theme,
  Typography,
  makeStyles
} from '@material-ui/core';
import { typeTrx, trxCsv, trxStatus } from 'constants/column';
import { snackCaseToSentence } from 'utils';
import TransactionPaymentMethod from 'typings/enum/TransactionPaymentMethod';
import { Alert, Autocomplete } from '@material-ui/lab';
import { CancelTokenSource } from 'axios';
import JobType from 'typings/enum/JobType';
import TransactionType from 'typings/enum/TransactionType';
import { format } from 'date-fns';

export interface TRXCSVProps {
  total: number;
  open: boolean;
  loading: boolean;
  isEmpty: boolean;
  cancelSource: CancelTokenSource;
  merchants: {
    payingLoading: boolean;
    paidLoading: boolean;
    payingMerchant: { id: number; name: string }[];
    paidMerchant: { id: number; name: string }[];
  };
  columns: {
    column: string[];
    type: string[];
    startDate: string;
    endDate: string;
    status: string[];
    paymentMethod: string[];
    jobType: string[];
    typeDate: string;
    payingMerchantId?: number;
    paidMerchantId?: number;
  };
  limitDate: Date;
  setColumns: React.Dispatch<React.SetStateAction<TRXCSVProps['columns']>>;
  onExport: () => void;
  onClose: () => void;
  onSearch: (keyword: string, type: 'paying' | 'paid') => void;
}

const useStyle = makeStyles((theme: Theme) => ({
  listBorder: {
    padding: '0px 8px',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  type: {
    color: theme.palette.success.main
  },
  outgoing: {
    color: theme.palette.error.main
  },
  optGroup: {
    lineHeight: '24px'
  },
  titleColumn: {
    marginLeft: '10px'
  },
  title: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '11px'
  }
}));

export default ({
  total,
  open,
  columns,
  loading,
  merchants,
  cancelSource,
  isEmpty,
  setColumns,
  onExport,
  onClose,
  onSearch,
  limitDate
}: TRXCSVProps) => {
  const classes = useStyle();
  const onCancel = () => {
    onClose();
  };

  const getLimit = (startDate: string) => {
    const triMonth = new Date(startDate);
    triMonth.setMonth(triMonth.getMonth() + 2);
    return format(new Date(triMonth.getFullYear(), triMonth.getMonth() + 1, 0), 'yyyy-MM-dd');
  };

  return (
    <Dialog open={open} maxWidth='md' fullWidth>
      <DialogTitle className={classes.title} disableTypography>
        Export Transaction
      </DialogTitle>
      <DialogContent dividers style={{ padding: 0 }}>
        <Box flex={1} display='flex' flexDirection={'row'} justifyContent='flex-start'>
          <Box display='flex' flexDirection='column' flexGrow={1} px={2} pt={2}>
            <Box mb={2}>
              <Typography gutterBottom>Type</Typography>

              <Box display='flex' flex={1} flexDirection='row' alignItems='center' justifyContent='flex-start' style={{ gap: '24px' }}>
                <TextField
                  disabled={loading}
                  size='small'
                  fullWidth
                  select
                  value={columns.type}
                  variant='outlined'
                  SelectProps={{
                    renderValue: value =>
                      (columns.type.length > 5
                        ? columns.type
                            .filter(v => ![TransactionType.TOPUP, TransactionType.WITHDRAW].includes(v as TransactionType))
                            .filter((v, i) => i < 5)
                            .concat(['...'])
                        : columns.type
                      )
                        .map(v => snackCaseToSentence(v))
                        .join(', '),
                    multiple: true
                  }}
                  onChange={e => {
                    setColumns(state => ({ ...state, type: (e.target.value as unknown) as string[] }));
                  }}
                  InputProps={{
                    startAdornment: (
                      <ButtonGroup size='small' variant='text' style={{ whiteSpace: 'nowrap', marginRight: '8px' }}>
                        <Button
                          disabled={
                            columns.type.filter(v => ![TransactionType.TOPUP, TransactionType.WITHDRAW].includes(v as TransactionType)).length > 0
                          }
                          onClick={() => {
                            console.log(columns.type);
                            setColumns(state => ({
                              ...state,
                              type: [
                                ...state.type,
                                ...typeTrx
                                  .filter(v => ![TransactionType.TOPUP, TransactionType.WITHDRAW].includes(v as TransactionType))
                                  .filter(v => !columns.type.includes(v))
                              ]
                            }));
                          }}
                        >
                          All
                        </Button>
                        <Button
                          disabled={
                            columns.type.filter(v => ![TransactionType.TOPUP, TransactionType.WITHDRAW].includes(v as TransactionType)).length === 0
                          }
                          onClick={() => {
                            setColumns(state => ({
                              ...state,
                              type: state.type.filter(v => [TransactionType.TOPUP, TransactionType.WITHDRAW].includes(v as TransactionType))
                            }));
                          }}
                        >
                          Clear
                        </Button>
                      </ButtonGroup>
                    )
                  }}
                >
                  {typeTrx
                    .filter(v => ![TransactionType.TOPUP, TransactionType.WITHDRAW].includes(v))
                    .map((vl, i) => (
                      <ListItem key={vl} dense className={classes.listBorder} value={vl}>
                        <ListItemIcon>
                          <Checkbox edge='start' size='small' disableRipple checked={columns.type.some(v => vl === v)} />
                        </ListItemIcon>
                        <ListItemText primary={snackCaseToSentence(vl)} primaryTypographyProps={{ style: { whiteSpace: 'nowrap' } }} />
                      </ListItem>
                    ))}
                </TextField>

                {/* <Box display='flex' flexDirection='row' style={{ gap: 2 }}>
                  <FormControlLabel
                    label='Topup'
                    control={
                      <Checkbox
                        value={TransactionType.TOPUP}
                        checked={columns.type.some(v => v === TransactionType.TOPUP)}
                        onChange={(e, checked) => {
                          setColumns(state => ({
                            ...state,
                            typeDate: checked ? 'transactionDate' : state.type.includes(TransactionType.WITHDRAW) ? 'transactionDate' : 'jobDate',
                            type: checked ? [...state.type, TransactionType.TOPUP] : state.type.filter(v => v !== TransactionType.TOPUP)
                          }));
                        }}
                      />
                    }
                  />

                  <FormControlLabel
                    label='Withdraw'
                    control={
                      <Checkbox
                        value={TransactionType.WITHDRAW}
                        checked={columns.type.some(v => v === TransactionType.WITHDRAW)}
                        onChange={(e, checked) => {
                          setColumns(state => ({
                            ...state,
                            typeDate: checked ? 'transactionDate' : state.type.includes(TransactionType.TOPUP) ? 'transactionDate' : 'jobDate',
                            type: checked ? [...state.type, TransactionType.WITHDRAW] : state.type.filter(v => v !== TransactionType.WITHDRAW)
                          }));
                        }}
                      />
                    }
                  />
                </Box> */}
              </Box>
            </Box>

            <Box mb={2}>
              <Typography gutterBottom>Status</Typography>
              <TextField
                disabled={loading}
                label='Choose'
                size='small'
                fullWidth
                select
                value={columns.status}
                variant='outlined'
                SelectProps={{
                  renderValue: value => columns.status.map(v => snackCaseToSentence(v)).join(', '),
                  multiple: true
                }}
                onChange={e => {
                  setColumns(state => ({ ...state, status: (e.target.value as unknown) as string[] }));
                }}
              >
                {trxStatus.map((vl, i) => (
                  <ListItem key={vl} dense className={classes.listBorder} value={vl}>
                    <ListItemIcon>
                      <Checkbox edge='start' size='small' disableRipple checked={columns.status.some(v => vl === v)} />
                    </ListItemIcon>
                    <ListItemText primary={snackCaseToSentence(vl)} primaryTypographyProps={{ style: { whiteSpace: 'nowrap' } }} />
                  </ListItem>
                ))}
              </TextField>
            </Box>

            <Box mb={2}>
              <Typography gutterBottom>Job Type</Typography>
              <TextField
                disabled={loading}
                label='Choose'
                size='small'
                fullWidth
                select
                value={columns.jobType}
                variant='outlined'
                SelectProps={{
                  renderValue: value => columns.jobType.map(v => snackCaseToSentence(v)).join(', '),
                  multiple: true
                }}
                onChange={e => {
                  setColumns(state => ({ ...state, jobType: (e.target.value as unknown) as string[] }));
                }}
              >
                {Object.keys(JobType).map((vl, i) => (
                  <ListItem key={vl} dense className={classes.listBorder} value={vl}>
                    <ListItemIcon>
                      <Checkbox edge='start' size='small' disableRipple checked={columns.jobType.some(v => vl === v)} />
                    </ListItemIcon>
                    <ListItemText primary={snackCaseToSentence(vl)} primaryTypographyProps={{ style: { whiteSpace: 'nowrap' } }} />
                  </ListItem>
                ))}
              </TextField>
            </Box>

            <Box mb={2}>
              <Typography gutterBottom>Payment Method</Typography>
              <TextField
                disabled={loading}
                label='Choose'
                size='small'
                fullWidth
                select
                value={columns.paymentMethod}
                variant='outlined'
                SelectProps={{
                  renderValue: value => columns.paymentMethod.map(v => snackCaseToSentence(v)).join(', '),
                  multiple: true
                }}
                onChange={e => {
                  setColumns(state => ({ ...state, paymentMethod: (e.target.value as unknown) as string[] }));
                }}
              >
                {Object.keys(TransactionPaymentMethod).map((vl, i) => (
                  <ListItem key={vl} dense className={classes.listBorder} value={vl}>
                    <ListItemIcon>
                      <Checkbox edge='start' size='small' disableRipple checked={columns.paymentMethod.some(v => vl === v)} />
                    </ListItemIcon>
                    <ListItemText primary={snackCaseToSentence(vl)} primaryTypographyProps={{ style: { whiteSpace: 'nowrap' } }} />
                  </ListItem>
                ))}
              </TextField>
            </Box>

            <Box mb={2}>
              <Typography gutterBottom>Date</Typography>

              <Alert severity='warning' variant='outlined'>
                for transaction history export, we limit at least 3 months range
              </Alert>

              <Box
                display='flex'
                flex={1}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                style={{ gap: 8, marginTop: '16px' }}
              >
                <TextField
                  disabled={loading}
                  label='Choose'
                  size='small'
                  fullWidth
                  select
                  value={columns.typeDate}
                  variant='outlined'
                  onChange={e => {
                    setColumns(state => ({ ...state, typeDate: (e.target.value as unknown) as string }));
                  }}
                >
                  <MenuItem value='jobDate'>Job Date</MenuItem>
                  <MenuItem value='transactionDate'>Transaction Date</MenuItem>
                  <MenuItem value='completeDate'>Complete Date</MenuItem>
                </TextField>

                <TextField
                  disabled={loading}
                  label='Start Date'
                  value={columns.startDate}
                  size='small'
                  type='date'
                  fullWidth
                  variant='outlined'
                  InputLabelProps={{ shrink: true }}
                  onChange={e => {
                    e.persist();
                    if (e.target.value) {
                      setColumns(state => ({ ...state, startDate: e.target.value, endDate: getLimit(e.target.value) }));
                    }
                  }}
                />
                <TextField
                  label='End Date'
                  disabled={loading}
                  value={columns.endDate}
                  size='small'
                  type='date'
                  fullWidth
                  variant='outlined'
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ inputProps: { min: columns.startDate, max: getLimit(columns.startDate) } }}
                  onChange={e => {
                    e.persist();
                    if (e.target.value) {
                      const startDate = new Date(columns.startDate);
                      const endDate = new Date(e.target.value);

                      if (startDate.getTime() <= endDate.getTime()) {
                        setColumns(state => ({ ...state, endDate: e.target.value }));
                      }
                    }
                  }}
                />
              </Box>
            </Box>

            <Box mb={2}>
              <Typography gutterBottom>Owner</Typography>
              <Box
                display='flex'
                flex={1}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                style={{ gap: 8, marginTop: '8px' }}
              >
                <Autocomplete
                  fullWidth
                  size='small'
                  getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={option => option.name}
                  options={merchants.payingMerchant}
                  loading={merchants.payingLoading}
                  onChange={(e, v, r) => {
                    if (r === 'clear') {
                      setColumns(state => ({ ...state, payingMerchantId: undefined }));
                    }

                    if (r === 'select-option') {
                      if (v) {
                        setColumns(state => ({ ...state, payingMerchantId: v.id }));
                      }
                    }
                  }}
                  onInputChange={(e, v, r) => {
                    if (r === 'input') {
                      onSearch(v, 'paying');
                    }
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label='Paying Merchant'
                      variant='outlined'
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {merchants.payingLoading ? <CircularProgress color='inherit' size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        )
                      }}
                    />
                  )}
                />
                <Autocomplete
                  fullWidth
                  size='small'
                  getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={option => option.name}
                  options={merchants.paidMerchant}
                  loading={merchants.paidLoading}
                  onInputChange={(e, v, r) => {
                    if (r === 'input') {
                      onSearch(v, 'paid');
                    }
                  }}
                  onChange={(e, v, r) => {
                    if (r === 'clear') {
                      setColumns(state => ({ ...state, paidMerchantId: undefined }));
                    }

                    if (r === 'select-option') {
                      if (v) {
                        setColumns(state => ({ ...state, paidMerchantId: v.id }));
                      }
                    }
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label='Supplier Company'
                      variant='outlined'
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {merchants.paidLoading ? <CircularProgress color='inherit' size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        )
                      }}
                    />
                  )}
                />
              </Box>
            </Box>

            {isEmpty ? <Typography>Data Not Available</Typography> : null}
          </Box>
          <Divider component='div' orientation='vertical' flexItem />

          <Box>
            <List dense style={{ maxHeight: '480px', overflow: 'scroll' }}>
              <ListItem dense className={classes.listBorder}>
                <ListItemText primary='Column Selected' primaryTypographyProps={{ style: { whiteSpace: 'nowrap' } }} />
              </ListItem>

              {trxCsv.map((val, idx) => (
                <ListItem key={idx} dense className={classes.listBorder}>
                  <ListItemIcon>
                    <Checkbox
                      edge='start'
                      size='small'
                      value={val.key}
                      checked={columns.column.some(v => v === val.key)}
                      tabIndex={-1}
                      disableRipple
                      onChange={(e, checked) => {
                        if (columns.column.some(v => val.key === v)) {
                          setColumns(state => ({ ...state, column: state.column.filter(v => v !== val.key) }));
                        } else {
                          setColumns(state => {
                            state.column.splice(idx, 0, val.key);
                            return { ...state };
                          });
                        }
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={val.label} primaryTypographyProps={{ style: { whiteSpace: 'nowrap' } }} />
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} variant='outlined' color='primary' onClick={onCancel}>
          Cancel
        </Button>

        <Button disabled={loading} variant='contained' disableElevation color='primary' onClick={onExport}>
          {loading ? <CircularProgress size={20} color='inherit' /> : 'Export'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
