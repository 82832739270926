import React, { FC } from 'react';
import { Table, TableBody, TableHead, TableFooter, TableRow, TableCell, TableContainer } from '@material-ui/core';

import HeaderRow from 'components/HeaderRow';
import TablePagination from 'components/TablePagination';
import BodyRow from './BodyRow';

import TableLoading from 'components/Table/TableLoading';
import { UseTablePagination } from 'hooks/useTablePagination';
import { Numbering } from '..';

export interface Props extends UseTablePagination<TonnageTemplate> {
  numbering: Numbering;
  setNumbering: React.Dispatch<React.SetStateAction<Numbering>>;
  onDelete: (id: number) => () => void;
  onEdit: (data: TonnageTemplate) => () => void;
}

const TonnageTemplateTable: FC<Props> = ({ onDelete, onEdit, numbering, setNumbering, ...props }) => {
  const updateNumber = (id: number, value: number) => {
    if (Number.isInteger(value)) {
      setNumbering(prev => {
        const index = prev.payload.findIndex(v => v.id === id);
        if (index > -1) {
          prev.payload[index].order = value;
        }
        return { ...prev };
      });
    }
  };

  const getNumber = (id: number) => {
    const find = numbering.payload.find(v => v.id === id);
    if (find) {
      return find.order;
    }
    return 0;
  };

  const isNumbering = +props.query('perPage') === -1;

  return (
    <TableContainer style={{ boxShadow: 'none', borderRadius: 0 }}>
      <Table>
        <TableHead>
          <HeaderRow
            order={props.order}
            orderBy={props.orderBy}
            onRequestSort={(_, value) => {
              props.resetPage();
              props.onOrder(value);
            }}
            height={'0px'}
            headers={[
              {
                id: 'order',
                label: 'Order',
                pT: '16px',
                pB: '16px',
                sort: true,
                cellProps: {
                  style: {
                    whiteSpace: 'nowrap',
                    width: '100px'
                  }
                }
              },
              {
                id: 'tonnage',
                label: 'Tonnage Name',
                cellProps: {
                  style: {
                    whiteSpace: 'nowrap',
                    width: '25%',
                    minWidth: '40%'
                  }
                }
              },
              {
                id: 'price',
                label: 'Price',
                pT: '16px',
                pB: '16px',
                cellProps: {
                  style: {
                    whiteSpace: 'nowrap'
                  }
                }
              },
              {
                id: 'overtimePrice',
                label: 'Overtime Price',
                pT: '16px',
                pB: '16px',
                cellProps: {
                  style: {
                    whiteSpace: 'nowrap'
                  }
                }
              },
              {
                id: 'lastUpdatedBy',
                label: 'Last Updated By'
              },
              {
                id: 'action',
                label: '',
                cellProps: {
                  padding: 'checkbox'
                }
              }
            ]}
          />

          <HeaderRow
            height={'0px'}
            headers={[
              {
                id: 'findOrder'
              },
              {
                id: 'findMaterial',
                isInputText: true,
                value: props.query('tonnageName'),
                handleInputChange: (value: any) => props.setQuery({ tonnageName: value }),
                pT: '0px',
                pB: '0px',
                pL: '16px',
                pR: '8px'
              },
              {
                id: 'findPrice'
              },
              {
                id: 'findJob'
              },
              {
                id: 'findLast'
              },
              {
                id: 'findAction'
              }
            ]}
          />
        </TableHead>

        <TableBody>
          {props.loading ? (
            <TableLoading col={6} />
          ) : props.isEmpty ? (
            <TableRow>
              <TableCell align='center' colSpan={6}>
                Data Not Available.
              </TableCell>
            </TableRow>
          ) : (
            props.data.map((mtr, index) => (
              <BodyRow
                key={index}
                openNumber={isNumbering}
                onDelete={onDelete(mtr.id || 0)}
                onEdit={onEdit(mtr)}
                updateNumber={updateNumber}
                number={getNumber(mtr.id || 0)}
                {...mtr}
              />
            ))
          )}
        </TableBody>
        {!isNumbering ? (
          <TableFooter>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              count={props.count}
              rowsPerPage={props.perPage}
              page={props.page}
              onChangePage={props.onPageChange}
              onChangeRowsPerPage={props.onRowsChange}
            />
          </TableFooter>
        ) : null}
      </Table>
    </TableContainer>
  );
};

export default TonnageTemplateTable;
