import React, { forwardRef, useState, useRef } from 'react';
import axios, { CancelTokenSource } from 'axios';
import TransactionType from 'typings/enum/TransactionType';
import DialogCsvExport, { TRXCSVProps } from 'pages/TransactionsPage/DialogCsvExport';
import { Button, CircularProgress, Menu, MenuItem } from '@material-ui/core';
import { WEB_EXPORT_MERCHANT_BALANCE_URL, WEB_MERCHANT_BASE_URL, WEB_TRANSACTION_BASE_URL, WEB_TRANSACTION_EXPORT_ALL_URL } from 'constants/url';
import { ExpandMore } from '@material-ui/icons';
import { trxCsv } from 'constants/column';
import { format } from 'date-fns';
import { pick } from 'utils';
import UserStatus from 'typings/enum/UserStatus';
import CSVLink from 'components/CSVLink';

interface Props {
  query: any;
  className?: string;
  title?: string;
}

const currentListHeaders = [
  { label: 'Trx ID', key: 'id' },
  { label: 'Date Time', key: 'dateTime' },
  { label: 'Description', key: 'description' },
  { label: 'Payment Method', key: 'paymentMethod' },
  { label: 'Amount', key: 'amount' },
  { label: 'Status', key: 'status' }
];

const allBalanceHeaders = [
  { label: 'Merchant ID', key: 'id' },
  { label: 'Company Name', key: 'companyName' },
  { label: 'Current Balance', key: 'balance' }
];

const typeExport = {
  current: currentListHeaders,
  balance: allBalanceHeaders
};

const TransactionExportButton = forwardRef<any, Props>((props, ref) => {
  const abort: CancelTokenSource = axios.CancelToken.source();

  const limitDate = new Date();
  limitDate.setMonth(limitDate.getMonth() - 3);
  const currentDate = new Date();

  const dummy = {
    column: trxCsv.map(v => v.key),
    type: [],
    status: [],
    jobType: [],
    paymentMethod: [],
    payingMerchantId: undefined,
    paidMerchantId: undefined,
    startDate: format(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), 'yyyy-MM-dd'),
    endDate: format(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0), 'yyyy-MM-dd'),
    typeDate: 'jobDate'
  };

  const csvInstance = useRef<any | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [data, setData] = useState<TransactionCSVModel[] | AllTransactionCSVModel[] | AllMerchantBalanceCSVModel[]>([]);
  const [trigger, setTrigger] = useState({
    loading: false,
    type: 'current' as keyof typeof typeExport
  });

  const [columns, setColumns] = useState<TRXCSVProps['columns']>(dummy);
  const [exprtCSV, setExportCSV] = useState<{ isOpen: boolean; data: any[]; loading: boolean; total: number; isEmpty: boolean }>({
    isOpen: false,
    data: [],
    loading: false,
    total: 0,
    isEmpty: false
  });

  const [merchants, setMerchant] = useState<TRXCSVProps['merchants']>({
    payingLoading: false,
    paidLoading: false,
    payingMerchant: [],
    paidMerchant: []
  });

  const [filename, setFilename] = useState(`current trx ${dummy.startDate} until ${dummy.endDate}.csv`);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCurrentListExport = async () => {
    try {
      setFilename(`current trx ${props.query.startDate || dummy.startDate} until ${props.query.endDate || dummy.endDate}.csv`);
      setAnchorEl(null);
      setTrigger(prev => ({ ...prev, loading: true, type: 'current' }));
      setData([]);
      const { data } = await axios.get(WEB_TRANSACTION_BASE_URL, {
        params: { ...props.query, orderBy: 'createdAt', order: 'desc' },
        cancelToken: abort.token
      });
      setData(prev => [
        ...prev,
        ...data.rows.map((value: any) => {
          let description = '';
          if ([TransactionType.TOPUP, TransactionType.INCREASE_CREDIT].includes(value.type)) {
            description = value.paidMerchantName;
          } else {
            description = value.payingMerchantName;
          }
          return {
            id: value.id,
            dateTime: value.createdAt,
            description: `${value.type} - ${description}`,
            paymentMethod: value.paymentMethod,
            amount: value.amount,
            status: value.status
          };
        })
      ]);

      const timer = setTimeout(() => {
        csvInstance.current.link.click();
        setTrigger(prev => ({ ...prev, loading: false, type: 'current' }));
        clearTimeout(timer);
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const handleBalanceExport = async () => {
    try {
      setAnchorEl(null);
      setFilename(`merchant balances ${format(new Date(), 'yyyy-MM-dd')}.csv`);
      setTrigger(prev => ({ ...prev, loading: true, type: 'balance' }));
      setData([]);
      const { data } = await axios.get(WEB_EXPORT_MERCHANT_BALANCE_URL, { params: props.query, cancelToken: abort.token });
      setData(data);
      const timer = setTimeout(() => {
        csvInstance.current.link.click();
        setTrigger(prev => ({ ...prev, loading: false, type: 'current' }));
        clearTimeout(timer);
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const onExport = async () => {
    try {
      setExportCSV(state => ({ ...state, loading: true, percent: 1, data: [], total: 0, isEmpty: false }));
      setFilename(`advanced trx ${columns.startDate} until ${columns.endDate}.csv`);
      let params: any = {
        startDate: columns.startDate,
        endDate: columns.endDate,
        type: columns.type,
        status: columns.status,
        typeDate: columns.typeDate,
        paymentMethod: columns.paymentMethod,
        jobType: columns.jobType
      };

      if (columns.paidMerchantId) {
        params.paidMerchantId = columns.paidMerchantId;
      }

      if (columns.payingMerchantId) {
        params.payingMerchantId = columns.payingMerchantId;
      }

      const { data } = await axios.post(WEB_TRANSACTION_EXPORT_ALL_URL, params);
      setExportCSV(state => ({ ...state, percent: 25 }));
      if (data.length > 0) {
        if (trxCsv.length === columns.column.length) {
          setExportCSV(state => ({ ...state, data, percent: 0 }));
        } else {
          setExportCSV(state => ({ ...state, total: data.length, data: data.map((val: any) => pick(val, columns.column)) }));
        }

        const timer = setTimeout(() => {
          csvInstance.current.link.click();
          setExportCSV(state => ({ ...state, loading: false }));
          clearTimeout(timer);
        }, 1000);
      } else {
        setExportCSV(state => ({ ...state, data: [], loading: false, isEmpty: true }));
      }
    } catch (error) {
    } finally {
      setExportCSV(state => ({ ...state, loading: false }));
    }
  };

  const onSearch = async (keyword: string, type: 'paying' | 'paid') => {
    try {
      const { data } = await axios.get(WEB_MERCHANT_BASE_URL, {
        params: {
          queryCompany: keyword,
          status: UserStatus.APPROVED,
          s: 0,
          l: 100
        },
        cancelToken: abort.token
      });

      if (type === 'paying') {
        setMerchant(state => ({ ...state, payingMerchant: (data.rows || []).map((v: any) => ({ id: v.id, name: v.companyName })) }));
      }

      if (type === 'paid') {
        setMerchant(state => ({ ...state, paidMerchant: (data.rows || []).map((v: any) => ({ id: v.id, name: v.companyName })) }));
      }
    } catch (error) {
    } finally {
    }
  };

  const onAdvanceExport = () => {
    setExportCSV({ isOpen: true, data: [], loading: false, total: 0, isEmpty: false });
  };

  return (
    <>
      <Button
        disabled={trigger.loading}
        aria-controls='export-menu'
        variant='contained'
        disableElevation
        aria-haspopup='true'
        color='primary'
        onClick={handleClick}
        className={props.className}
        ref={ref}
      >
        {trigger.loading ? (
          <CircularProgress color='inherit' size={24} />
        ) : (
          <>
            {props.title || 'Export CSV'} <ExpandMore fontSize='small' style={{ marginLeft: '8px' }} />
          </>
        )}
      </Button>
      <Menu id='export-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleCurrentListExport}>Export Current List</MenuItem>
        <MenuItem onClick={handleBalanceExport}>Export All Merchant Balances</MenuItem>
        <MenuItem onClick={onAdvanceExport}>Export Advanced</MenuItem>
      </Menu>

      <DialogCsvExport
        cancelSource={abort}
        total={exprtCSV.total}
        open={exprtCSV.isOpen}
        columns={columns}
        loading={exprtCSV.loading}
        isEmpty={exprtCSV.isEmpty}
        merchants={merchants}
        limitDate={limitDate}
        onSearch={onSearch}
        setColumns={setColumns}
        onExport={onExport}
        onClose={() => {
          setColumns(dummy);
          setExportCSV({ isOpen: false, data: [], loading: false, total: 0, isEmpty: false });
        }}
      />

      <CSVLink
        headers={exprtCSV.isOpen ? trxCsv.filter(v => columns.column.includes(v.key)) : typeExport[trigger.type]}
        data={exprtCSV.isOpen ? exprtCSV.data : data}
        filename={filename}
        ref={csvInstance}
      />
    </>
  );
});

export default TransactionExportButton;
