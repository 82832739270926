import React, { FC, useState } from 'react';
import axios from 'axios';
import {
  Theme,
  makeStyles,
  createStyles,
  withStyles,
  WithStyles,
  Button,
  Dialog,
  CircularProgress,
  IconButton,
  Typography,
  TextField,
  MenuItem
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import { green } from '@material-ui/core/colors';
import { WEB_EQUIPMENT_BASE_URL } from 'constants/url';
import { getJobTypeSelect, getVehicleTypeSelect } from 'utils';
import useSnackbar from 'hooks/useSnackbar';
import Snackbar from 'components/Snackbar';
import { dummyEquipment } from 'constants/DummyData';

interface Props {
  open: boolean;
  data: EquipmentTemplate;
  setData: React.Dispatch<React.SetStateAction<EquipmentTemplate>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  reload: (cb?: ((key: { [key: string]: any }) => any) | undefined) => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700]
    }
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h4'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const EquipmentForm: FC<Props> = props => {
  const classes = useStyles();
  const { open, setOpen, data, setData } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState({
    equipment: '',
    jobType: '',
    truckType: ''
  });

  const { openSnacbar, snackbar } = useSnackbar();

  const handleSubmit = async () => {
    try {
      setError({ equipment: '', jobType: '', truckType: '' });
      if (!validation()) return;
      setLoading(true);
      await axios[!!data.id ? 'put' : 'post'](WEB_EQUIPMENT_BASE_URL, data);
      openSnacbar(!!data.id ? 'Equipment successfully updated' : 'Equipment successfull added');
      handleClose();
      props.reload();
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    handleResetInput();
  };

  const handleResetInput = () => {
    setError({ equipment: '', jobType: '', truckType: '' });
    setData(dummyEquipment);
  };

  const validation = () => {
    let valid = true;

    if (data.equipment === '' || !data.equipment.trim()) {
      setError(state => ({ ...state, material: 'Name is required' }));
      valid = false;
    }

    if (data.jobType === '' || !data.jobType.trim()) {
      setError(state => ({ ...state, jobType: 'Job Type is required' }));
      valid = false;
    }

    if (data.truckType === '' || !data.truckType.trim()) {
      setError(state => ({ ...state, truckType: 'Truck Type is required' }));
      valid = false;
    }

    return valid;
  };

  return (
    <div>
      <Dialog fullWidth={true} maxWidth='sm' onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
        <DialogTitle id='customized-dialog-title' onClose={handleClose}>
          {!!data.id ? 'Edit' : 'Create New'} Equipment
        </DialogTitle>
        <DialogContent dividers style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <TextField
            variant='outlined'
            required
            type='text'
            label='Equipment'
            value={data.equipment || ''}
            onChange={e => {
              e.persist();
              setData(prev => ({ ...prev, equipment: e.target.value as string }));
            }}
            error={error.equipment !== ''}
            helperText={error.equipment}
          />
          <TextField
            select
            variant='outlined'
            name='jobType'
            label='Job Type'
            value={data.jobType || '00'}
            onChange={e => {
              setData(prev => ({ ...prev, jobType: e.target.value === '00' ? '' : e.target.value }));
            }}
            error={error.jobType !== ''}
            helperText={error.jobType}
          >
            <MenuItem value='00'>Select Job Type</MenuItem>
            {getJobTypeSelect().map(v => (
              <MenuItem key={v.id} value={v.id}>
                {v.name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            variant='outlined'
            name='truckType'
            label='Truck Type'
            value={data.truckType || '00'}
            onChange={e => {
              setData(prev => ({ ...prev, truckType: e.target.value === '00' ? '' : e.target.value }));
            }}
            error={error.truckType !== ''}
            helperText={error.truckType}
          >
            <MenuItem value='00'>Select Truck Type</MenuItem>
            {getVehicleTypeSelect().map(v => (
              <MenuItem key={v.id} value={v.id}>
                {v.name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            variant='outlined'
            label='Order'
            value={data.order || 0}
            onChange={e => {
              e.persist();
              if (Number.isInteger(+e.target.value)) {
                setData(prev => ({ ...prev, order: +e.target.value }));
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' color='primary' disabled={loading} onClick={handleClose}>
            Cancel
          </Button>
          <div className={classes.wrapper}>
            <Button variant='contained' color='primary' disabled={loading} disableElevation onClick={handleSubmit}>
              {!!data.id ? 'Save change' : 'Add New'}
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </DialogActions>
      </Dialog>

      <Snackbar {...snackbar} />
    </div>
  );
};

export default EquipmentForm;
