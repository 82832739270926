import React, { FC, useState, useCallback, useEffect } from 'react';
import useRouter from 'hooks/useRouter';
import axios, { CancelTokenSource } from 'axios';
import { makeStyles, Theme, Paper, Box, Tabs, Tab, Typography, Button, useMediaQuery } from '@material-ui/core';
import { dummyMerchant, dummyUser } from 'constants/DummyData';
import {
  WEB_MERCHANT_DETAIL_URL,
  WEB_INDIVIDUAL_STATUS_MERCHANT_URL,
  BANKS_BASE_URL,
  SETTINGS_URL,
  GET_MANUAL_TOPUP_MERCHANTS_BASE_URL,
  GET_SETTING_BY_CODE_URL,
  MERCHANTS_BASE_URL
} from 'constants/url';
import { StandardConfirmationDialog } from 'components/AppDialog';
import ActionSnackbar from 'components/ActionSnackbar';
import StatusPrompModal from 'components/StatusPrompModal';
import SupervisorsPage from 'pages/SupervisorsPage';
import DriversPage from 'pages/DriversPage';
import MainTemplate from 'components/Template/MainTemplate';
import CardOwnerBalance from 'components/CardOwnerBalance';
import MerchantInfo from './MerchantInfo';
import TabPanel, { a11yProps } from 'components/TabPanel';
import UserRemarksStatusHistory from 'pages/UserPage/UserRemarksStatusHistory';
import MerchantEdit from './MerchantEdit';
import UserStatus from 'typings/enum/UserStatus';
import MerchantTopup from './MerchantTopup';
import { useApp } from 'contexts/AppContext';
import SettingCode from 'typings/enum/SettingCode';
import MerchantSendEearning from './MerchantSendEearning';
import MerchantDownloadEearning from './MerchantDownloadEearning ';
import MerchantDownloadSpending from './MerchantDownloadSpending';
import MerchantCardCredit from './MerchantCardCredit';
import MerchantCredit from './MerchantCredit';
import { CreditProvider } from 'contexts/CreditContex';
import MerchantCreditPaid from './MerchantCreditPaid';
import MerchantBalanceLogDrawer from './MerchantBalanceLogDrawer/MerchantBalanceLogDrawer';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) => ({
  tabPanel: {
    paddingTop: '32px'
  },
  tabPanelList: {
    paddingTop: '32px'
  },
  cardTab: {
    marginTop: '16px',
    padding: '16px 24px 16px 24px',
    borderRadius: '5px',
    border: '1px solid rgba(46, 91, 255, 0.08)',
    boxShadow: '8px 4px 24px rgba(0, 0, 0, 0.08)'
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  tab: {
    textTransform: 'none',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.15px',
    color: theme.palette.black.A100
  },
  boxRight: {
    [theme.breakpoints.between('lg', 'xl')]: {
      marginRight: '16px',
      width: '74%'
    },
    overflow: 'auto'
  },
  boxLeft: {
    padding: '24px',
    backgroundColor: '#ffffff',
    border: '1px solid rgba(46, 91, 255, 0.08)',
    boxShadow: '8px 4px 24px rgba(0, 0, 0, 0.08)',
    borderRadius: '5px',
    maxHeight: '640px',
    overflow: 'auto',
    [theme.breakpoints.between('lg', 'xl')]: {
      width: '26%'
    }
  },
  titleSummary: {
    marginBottom: '24px'
  }
}));

const MerchantDetailPage: FC = () => {
  const classes = useStyles();
  const { clearAccessTopup, setAccessTopup } = useApp();
  const { history, match } = useRouter();
  const id = Number(match.params.id);
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.between('xs', 'md'));

  const [merchant, setMerchant] = useState<MerchantModel>({ ...dummyMerchant, canWithdraw: true });
  const [merchantUser, setMerchantUser] = useState<UserDetailsModel>(dummyUser);
  const [userStatus, setUserStatus] = useState<UserStatusHistoriesModel[]>([]);
  const [displayName, setDisplayName] = useState<string>('');
  const [banks, setBanks] = useState<Select[]>([]);
  const [canTopup, setCanTopup] = useState(true);
  const [maximumTopup, setMaximumTopup] = useState(10000);
  const [lamdakey, setLamdakey] = useState('');

  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [editable, setEditable] = useState<boolean>(false);
  const [value, setValue] = useState(0);

  const [typePromp, setTypePromp] = useState<string>('');
  const [titlePromp, setTitlePromp] = useState<string>('');
  const [remarksPromp, setRemarksPromp] = useState<string>('');
  const [addressPromp, setAddressPromp] = useState<string>('');
  const [openPromp, setOpenPromp] = useState<boolean>(false);
  const [loadingGetTopUp, setLoadingGetTopUp] = useState(false);
  const [loadingCanWithdraw, setLoadCanWithdraw] = useState(false);
  const [topupPending, setTopupPending] = useState<Pick<TransactionModel, 'id' | 'amount' | 'status' | 'type' | 'createdAt'>[]>([]);

  const [openEnableDialog, setOpenEnableDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openTopup, setOpenTopup] = useState<boolean>(false);
  const [opensSendEarning, setOpenSendEarning] = useState<boolean>(false);
  const [openDownloadEarning, setOpenDownloadEarning] = useState(false);
  const [openDownloadSpending, setOpenDownloadSpending] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');

  const [openBalanceLog, setOpenBalanceLog] = useState(0);

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    setEditable(false);
  };

  const handleStatusOption = async (newStatus: string, remarks?: string, address?: string) => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    setIsLoadingData(true);

    try {
      const { data } = await axios.put(
        WEB_INDIVIDUAL_STATUS_MERCHANT_URL(id),
        { status: newStatus, remarks, address },
        { cancelToken: cancelTokenSource.token }
      );

      if (data) {
        const { User } = data;
        const { displayName, UserStatus } = User;

        setMerchant(data);
        setMerchantUser(User);
        setUserStatus(UserStatus);
        setDisplayName(displayName);
      }

      handleCloseDisabledPromp();
      setOpenEnableDialog(false);
      setIsLoadingData(false);
      handleOpenSnackbar('success', `Owner account has been ${newStatus.toLowerCase()}!`);
      fetchData();
    } catch (err) {
      setIsLoadingData(false);
      if (err && err.data && err.data.errorCode === 999) {
        handleOpenSnackbar('error', 'This merchant has ongoing jobs, cancel or complete all jobs first');
      } else {
        handleOpenSnackbar('error', 'Network error, failed proccess data');
      }
    }
    return () => cancelTokenSource.cancel();
  };

  const handleEnabledConfirm: React.MouseEventHandler<HTMLButtonElement> = () => {
    handleStatusOption(UserStatus.ENABLED, undefined, addressPromp);
  };

  const handleOpenEnableDialog = () => {
    setOpenEnableDialog(true);
  };

  const handleCloseEnableDialog = () => {
    setOpenEnableDialog(false);
  };

  const handleOpenDisabledDialog = () => {
    setOpenPromp(true);
    setTitlePromp('disable');
    setTypePromp(UserStatus.DISABLED);
  };

  const handleOpenDeleteDialog = () => {
    setOpenPromp(true);
    setTitlePromp('deleted');
    setTypePromp(UserStatus.DELETED);
  };

  const handleCloseDisabledPromp = () => {
    setOpenPromp(false);
    setTitlePromp('');
    setRemarksPromp('');
  };

  const handleConfirmDisabledPromp = () => {
    handleStatusOption(typePromp, remarksPromp);
  };

  const handleOpenSnackbar = (type: 'success' | 'error', message: string) => {
    setOpenSnackbar(true);
    setSnackbarMessage(message);
    setSnackbarVarient(type);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
    setSnackbarMessage('');
    setSnackbarVarient('success');
  };

  const fetchData = useCallback(async () => {
    if (!id) {
      return;
    }

    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    setIsLoadingData(true);

    try {
      const { data } = await axios.get(WEB_MERCHANT_DETAIL_URL(id), { cancelToken: cancelTokenSource.token });

      if (data) {
        const { User } = data;
        const { displayName, UserStatus } = User;

        setMerchant(data);
        setMerchantUser(User);
        setUserStatus(UserStatus);
        setDisplayName(displayName);
      }
    } catch (err) {
      console.error('err: ', err);
    }
    setIsLoadingData(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => cancelTokenSource.cancel();
  }, [id]);

  const fetchDataBanks = async () => {
    setIsLoadingData(true);

    try {
      const { data } = await axios.get(BANKS_BASE_URL);
      setBanks(data.Banks);
    } catch (err) {
      console.error('err: ', err);
    }

    setIsLoadingData(false);
    return;
  };

  const fetchManualTopup = async () => {
    try {
      const { data } = await axios.get(GET_MANUAL_TOPUP_MERCHANTS_BASE_URL(id));
      setCanTopup(data.length === 0);
      setTopupPending(data);
    } catch (err) {}
  };

  const fetchSettingCode = async () => {
    try {
      const [{ data: MAX_TOPUP }, { data: LAMBDAKEY }] = await Promise.all([
        axios.get(GET_SETTING_BY_CODE_URL(SettingCode.MAX_TOPUP)),
        axios.get(GET_SETTING_BY_CODE_URL(SettingCode.LAMBDAKEY))
      ]);

      if (MAX_TOPUP) {
        setMaximumTopup(+MAX_TOPUP.value);
      }

      if (LAMBDAKEY) {
        setLamdakey(LAMBDAKEY.value);
      }
    } catch (err) {}
  };

  const onOpenTopup = async () => {
    try {
      setLoadingGetTopUp(true);
      const { data: manualTopup } = await axios.get(GET_MANUAL_TOPUP_MERCHANTS_BASE_URL(id));
      setLoadingGetTopUp(false);
      setOpenTopup(true);
      if (manualTopup.length === 0) {
        const { data } = await axios.get(SETTINGS_URL);
        const setting = data.Settings.find((value: any) => value.code === 'MANUAL_SECRET_KEY');
        if (setting) {
          setAccessTopup({ key: setting.value });
        }
      } else {
        setOpenTopup(false);
        setCanTopup(false);
        setTopupPending(manualTopup);
      }
    } catch (err) {
      console.error('err: ', err);
    }
  };

  const onSwitch = async () => {
    try {
      setLoadCanWithdraw(true);
      await axios.put(`${MERCHANTS_BASE_URL}/withdrawal`, { id });
      setMerchant(state => ({ ...state, canWithdraw: !state.canWithdraw }));
      handleOpenSnackbar('success', `Status withdraw update successfully.`);
    } catch (error) {
    } finally {
      setLoadCanWithdraw(false);
    }
  };

  useEffect(() => {
    fetchDataBanks();
    fetchData();
    fetchManualTopup();
    fetchSettingCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, fetchData]);

  return (
    <CreditProvider>
      <MainTemplate
        title='Owner Detail'
        breadcrumb={true}
        links={[{ name: 'All Owner', links: 'goback', click: true }, { name: displayName, links: '', click: false }]}
        refreshButton={false}
        primaryButton={false}
      >
        <Box display={'flex'} flex={1} flexDirection={isSmall ? 'column' : 'row'} justifyContent={'space-between'}>
          <Box display={'flex'} flexDirection={'column'} flexGrow={1} className={classes.boxRight}>
            {merchantUser.status === 'DISABLED' ? (
              <Alert variant='filled' severity='warning' style={{ marginTop: '8px', marginBottom: '8px' }}>
                This account has been suspended¸
              </Alert>
            ) : null}

            <Box display={'flex'} flexDirection={isSmall ? 'column' : 'row'} style={{ gap: 8 }}>
              <CardOwnerBalance
                disableAccount={merchantUser.status === 'DISABLED'}
                loading={loadingGetTopUp}
                loadingCanWithdraw={loadingCanWithdraw}
                canTopup={canTopup}
                balance={merchant.balance || 0}
                onSwitch={onSwitch}
                canWithdraw={merchant.canWithdraw}
                onClick={() => {
                  if (!canTopup) {
                    history.push(`/owner/topup/${topupPending[0].id}/detail`);
                  } else {
                    onOpenTopup();
                  }
                }}
              />

              <MerchantCardCredit disableAccount={merchantUser.status === 'DISABLED'} />
            </Box>

            <Paper elevation={0} className={classes.cardTab}>
              <Tabs indicatorColor='primary' value={value} onChange={handleChangeTab} className={classes.tabs}>
                <Tab label='General Information' {...a11yProps(0)} className={classes.tab} />
                <Tab label='Supervisors Account' {...a11yProps(1)} className={classes.tab} />
                <Tab label='Drivers Account' {...a11yProps(2)} className={classes.tab} />
              </Tabs>

              <TabPanel value={value} index={0}>
                <div className={classes.tabPanel}>
                  {!editable && (
                    <MerchantInfo
                      {...merchant}
                      userStatus={userStatus}
                      merchantUser={merchantUser}
                      isLoadingData={isLoadingData}
                      onEditable={() => setEditable(state => !state)}
                      onEnableAccount={handleOpenEnableDialog}
                      onDisableAccount={handleOpenDisabledDialog}
                      onDeleteAccount={handleOpenDeleteDialog}
                      onOpenDownloadEarnSummary={() => {
                        setOpenDownloadEarning(true);
                      }}
                      onOpenSendEarnSummary={() => {
                        setOpenSendEarning(true);
                      }}
                      onOpenDownloadSpendingSummary={() => {
                        setOpenDownloadSpending(true);
                      }}
                    />
                  )}
                  {editable && (
                    <MerchantEdit
                      id={id}
                      banks={banks}
                      merchant={merchant}
                      userStatus={userStatus}
                      isLoadingData={isLoadingData}
                      merchantUser={merchantUser}
                      setMerchant={setMerchant}
                      setMerchantUser={setMerchantUser}
                      setEditable={setEditable}
                      setDisplayName={setDisplayName}
                      setIsLoadingData={setIsLoadingData}
                      handleOpenSnackbar={handleOpenSnackbar}
                    />
                  )}
                </div>
              </TabPanel>

              <TabPanel value={value} index={1}>
                <div className={classes.tabPanelList}>
                  <SupervisorsPage noTitle={true} merchantId={id} />
                </div>
              </TabPanel>

              <TabPanel value={value} index={2}>
                <div className={classes.tabPanelList}>
                  <DriversPage noTitle={true} merchantId={id} />
                </div>
              </TabPanel>
            </Paper>

            <StatusPrompModal
              title={titlePromp}
              message={displayName}
              confirmText={titlePromp}
              open={openPromp}
              loading={isLoadingData}
              handleClose={handleCloseDisabledPromp}
              handleConfirm={handleConfirmDisabledPromp}
              remarksPromp={remarksPromp}
              setRemarksPromp={setRemarksPromp}
              addressPromp={addressPromp}
              setAddressPromp={setAddressPromp}
            />

            <StandardConfirmationDialog
              variant='warning'
              titleMessage='Are you sure to enable this account?'
              message='You can make changes at any time'
              confirmButtonText='Enable'
              open={openEnableDialog}
              loading={isLoadingData}
              handleClose={handleCloseEnableDialog}
              onConfirm={handleEnabledConfirm}
            />

            <MerchantTopup
              maximumOption={maximumTopup}
              merchantId={merchant.id}
              open={openTopup}
              onClose={() => {
                setOpenTopup(false);
                clearAccessTopup();
              }}
            />

            <MerchantCredit />
            <MerchantCreditPaid />

            <MerchantSendEearning
              open={opensSendEarning}
              merchantId={merchant.id}
              privateKey={lamdakey}
              onClose={() => {
                setOpenSendEarning(false);
              }}
            />

            <MerchantDownloadEearning
              open={openDownloadEarning}
              merchantId={merchant.id}
              onClose={() => {
                setOpenDownloadEarning(false);
              }}
            />

            <MerchantDownloadSpending
              open={openDownloadSpending}
              merchantId={merchant.id}
              companyName={merchant.companyName || ''}
              onClose={() => {
                setOpenDownloadSpending(false);
              }}
            />

            <ActionSnackbar variant={snackbarVarient} message={snackbarMessage} open={openSnackbar} handleClose={handleCloseSnackbar} />
          </Box>

          <Box flexGrow={1} className={classes.boxLeft}>
            <Typography variant='h4'>Remark Status History</Typography>

            <Button
              variant='text'
              size='small'
              color='primary'
              style={{ padding: 0, textDecoration: 'underline' }}
              onClick={() => setOpenBalanceLog(merchant.UserId)}
            >
              See balance log
            </Button>

            <UserRemarksStatusHistory userStatus={userStatus} />
          </Box>
        </Box>

        <MerchantBalanceLogDrawer
          id={openBalanceLog}
          onClose={() => {
            setOpenBalanceLog(0);
          }}
        />
      </MainTemplate>
    </CreditProvider>
  );
};

export default MerchantDetailPage;
