import React from 'react';
import { Button, TableCell, TableRow, Theme, Typography, makeStyles } from '@material-ui/core';
import TrxStatusChip from 'components/TrxStatusChip';
import { useApp } from 'contexts/AppContext';
import NumberFormat from 'react-number-format';
import TransactionStatus from 'typings/enum/TransactionStatus';
import TransactionType from 'typings/enum/TransactionType';
import { formatId, ucwords } from 'utils';
import useRouter from 'hooks/useRouter';

interface Props extends TransactionList {
  onGetResponse: () => void;
  onProceessFazz: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    padding: '8px 16px 8px 16px',
    marginRight: '16px',
    textTransform: 'none',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 400,
    borderRadius: '5px',
    color: theme.palette.black[50],
    whiteSpace: 'nowrap',
    '&.MuiButton-outlined:hover': {
      backgroundColor: '#FEF1D2',
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main
    }
  }
}));

const BodyRow = (props: Props) => {
  const { history } = useRouter();
  const classes = useStyles();

  let name = '';
  if (props.type === TransactionType.TOPUP || props.type === TransactionType.INCREASE_CREDIT) {
    name = props.paidMerchantName || '';
  } else if (props.type === TransactionType.WITHDRAW || props.type === TransactionType.DECREASE_CREDIT) {
    name = props.payingMerchantName || '';
  } else if (props.type === TransactionType.CREDIT_PAID) {
    name = props.payingMerchantName || '';
  }

  const { date } = useApp();

  const handleClick = () => {
    if ([TransactionType.INCREASE_CREDIT, TransactionType.DECREASE_CREDIT].includes(props.type as TransactionType)) {
      history.push(`/transaction/credit/${props.id}/detail`);
    } else if (props.type === TransactionType.CREDIT_PAID) {
      history.push(`/transaction/credit-payment/${props.id}/detail`);
    } else {
      props.onGetResponse();
    }
  };
  return (
    <TableRow hover>
      <TableCell>
        <Button onClick={handleClick} variant='text' color='primary' size='small'>
          <Typography variant='subtitle2'>{formatId(props.id)}</Typography>
        </Button>
      </TableCell>
      <TableCell style={{ whiteSpace: 'nowrap' }}>{date(props.createdAt)}</TableCell>
      <TableCell style={{ whiteSpace: 'nowrap' }}>
        <Typography variant='subtitle2'>{props.type.replace('_', ' ')}</Typography>
        <Typography style={{ whiteSpace: 'nowrap' }}>
          {[TransactionType.TOPUP, TransactionType.INCREASE_CREDIT, TransactionType.CREDIT_PAID].includes(props.type as TransactionType)
            ? 'to'
            : 'from'}{' '}
          {name}'S{' '}
          {[TransactionType.DECREASE_CREDIT, TransactionType.INCREASE_CREDIT, TransactionType.CREDIT_PAID].includes(props.type as TransactionType)
            ? 'Credit Wallet'
            : 'Wallet'}
        </Typography>
      </TableCell>
      <TableCell align='center'>{ucwords(props.paymentMethod.replace('_', ' ') || 'N/A')}</TableCell>
      <TableCell align='right'>
        {
          <span style={{ fontWeight: 'bold' }}>
            <NumberFormat value={props.amount} displayType='text' thousandSeparator={true} prefix='$' decimalScale={2} fixedDecimalScale={true} />
          </span>
        }
      </TableCell>
      <TableCell align='center'>
        <TrxStatusChip status={props.status as TransactionStatus} label={props.status} />
      </TableCell>

      {process.env.REACT_APP_TRX_PROCESS_BUTTONS === 'show' ? (
        <TableCell>
          <Button
            disabled={
              props.status === 'SETTLED' ||
              props.status === 'EXPIRED' ||
              ![TransactionType.TOPUP, TransactionType.WITHDRAW].includes(props.type as TransactionType)
            }
            color='primary'
            variant='outlined'
            className={classes.button}
            onClick={props.onProceessFazz}
          >
            {props.type === 'TOPUP' ? 'Pay Now' : 'Send Money'}
          </Button>
        </TableCell>
      ) : null}
    </TableRow>
  );
};

export default BodyRow;
