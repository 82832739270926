import React from 'react';
import { Box, Button, CircularProgress, FormHelperText, makeStyles, Paper, Typography } from '@material-ui/core';

import { Add, Visibility } from '@material-ui/icons';
import cardBase from 'images/icon/card-base.svg';
import { formatCurrency } from 'utils';
import { IOSSwitch } from './CustomSwitch';

const useStyles = makeStyles(() => ({
  cardBalance: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '24px',
    padding: '16px 24px 16px 24px',
    borderRadius: '5px',
    border: '1px solid rgba(46, 91, 255, 0.08)',
    boxShadow: '8px 4px 24px rgba(0, 0, 0, 0.08)',
    overflow: 'hidden'
  },
  primaryButton: {
    padding: '8px 16px 8px 16px',
    borderRadius: '5px',
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.25px'
  }
}));

interface CardOwnerBalanceProps {
  balance: number;
  canTopup: boolean;
  loading: boolean;
  disableAccount?: boolean;
  loadingCanWithdraw?: boolean;
  canWithdraw?: boolean;
  onSwitch?: () => void;
  onClick?: () => void;
}

const CardOwnerBalance = ({
  loading,
  balance,
  canTopup,
  canWithdraw,
  loadingCanWithdraw,
  disableAccount,
  onClick,
  onSwitch
}: CardOwnerBalanceProps) => {
  const classes = useStyles();

  return (
    <Paper elevation={0} className={classes.cardBalance}>
      <div>
        <img src={cardBase} alt='card base' />
      </div>
      <Box flexGrow={1}>
        <Typography variant='h5' gutterBottom>
          Total Account Balance
        </Typography>

        <Typography variant='h1' gutterBottom>
          {formatCurrency(balance)}
        </Typography>
      </Box>
      <Box display={'flex'} flexDirection={'column'}>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} mb={0.5}>
          <Box>
            <Typography variant='h6'>Withdraw</Typography>
          </Box>

          <Box>
            {loadingCanWithdraw ? (
              <CircularProgress size={25} />
            ) : (
              <IOSSwitch disabled={disableAccount} value={canWithdraw} checked={canWithdraw} onChange={onSwitch} />
            )}
          </Box>
        </Box>
        <Box>
          {loading ? (
            <CircularProgress />
          ) : (
            <Button
              disabled={disableAccount}
              variant='contained'
              disableElevation
              color='primary'
              className={classes.primaryButton}
              onClick={onClick}
            >
              {!canTopup ? <Visibility style={{ marginRight: '13px' }} /> : <Add style={{ marginRight: '13px' }} />}
              {!canTopup ? 'See Detail' : 'Top Up Balance'}
            </Button>
          )}
        </Box>

        {!canWithdraw ? (
          <Box mt={2}>
            <FormHelperText error={!canWithdraw}>This Company is Unable to Withdraw </FormHelperText>
          </Box>
        ) : null}
      </Box>
    </Paper>
  );
};

export default CardOwnerBalance;
