import axios, { CancelTokenSource } from 'axios';
import { ADDITIONAL_REQUEST_BASE_URL } from 'constants/url';
import React, { FC, useCallback, useEffect, useState } from 'react';
import AdditionalTable from './component/AdditionalTemplateTable';
import { Add } from '@material-ui/icons';
import { dummyAdditonalTemplates } from 'constants/DummyData';
import AdditionalTemplateForm from './component/AdditionalTemplateForm';
import { StandardConfirmationDialog } from 'components/AppDialog';
import SettingTemplate from 'components/Template/SettingTemplate';

const AdditionalTemplatePage: FC = () => {
  const [additionalItems, setAdditionalItems] = useState<AdditionalTemplates[]>([]);
  const [count, setCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [isRefresh, setRefresh] = useState(false)
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('createdAt');
  const [queryId, setQueryId] = useState<string>();
  const [queryName, setQueryName] = useState<string>('');

  const [openFormModal, setOpenFormModal] = useState<boolean>(false);
  const [currentAdditional, setCurrentAdditional] = useState(dummyAdditonalTemplates);

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');

  const fetchData = useCallback(async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    setIsLoadingData(true);

    const params = new URLSearchParams();
    params.append('s', (currentPage * rowsPerPage).toString());
    params.append('l', rowsPerPage.toString());

    if (queryId) {
      params.append('id', queryId);
    }

    if (queryName) {
      params.append('name', queryName);
    }

    if (orderBy) {
      params.append('orderBy', orderBy);
    }

    if (order) {
      params.append('order', order.toUpperCase());
    }

    try {
      const { data } = await axios.get(`${ADDITIONAL_REQUEST_BASE_URL}?${params.toString()}`, { cancelToken: cancelTokenSource.token });

      setAdditionalItems(data.Templates);
      setCount(data.count);
    } catch (err) {
      console.error('err: ', err);
    }
    setIsLoadingData(false);
    return () => cancelTokenSource.cancel();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, currentPage, order, orderBy, queryId, queryName, isRefresh]);

  const handleQueryIdChange = (query: string) => {
    performActionAndRevertPage(setQueryId, query);
  };

  const handleQueryNameChange = (query: string) => {
    performActionAndRevertPage(setQueryName, query);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setCurrentPage(0);
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const performActionAndRevertPage = (action: React.Dispatch<React.SetStateAction<any>>, actionParam: any) => {
    setCurrentPage(0);
    action(actionParam);
  };

  const handleOpenSnackbar = (type: 'success' | 'error', message: string) => {
    setOpenSnackbar(true);
    setSnackbarMessage(message);
    setSnackbarVarient(type);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
    setSnackbarMessage('');
    setSnackbarVarient('success');
  };

  const refreshList = () => {
    setCurrentPage(0);
    setRowsPerPage(10);
    setOrder('desc');
    setOrderBy('createdAt');
    setQueryId('');
    setQueryName('');
    setRefresh(state => !state)
  };

  const handleEdit = (id: number) => () => {
    setCurrentAdditional(additionalItems.find(v => v.id === id) || dummyAdditonalTemplates);
    setOpenFormModal(true);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <SettingTemplate
      title='Setting Additional Template'
      subtitle='Display the data of all additional template'
      refreshButtonProps={{
        onClick: refreshList
      }}
      primaryButtonProps={{
        onClick: () => {
          setOpenFormModal(true);
        },
        children: (
          <>
            <Add fontSize='small' /> <span style={{ marginLeft: '13px' }}> Add New Additonal Item</span>
          </>
        )
      }}
    >
      <AdditionalTable
        isLoadingData={isLoadingData}
        additionalItems={additionalItems}
        order={order}
        orderBy={orderBy}
        count={count}
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
        queryId={queryId}
        queryName={queryName}
        handleQueryIdChange={handleQueryIdChange}
        handleQueryNameChange={handleQueryNameChange}
        handleRequestSort={handleRequestSort}
        handleChangePage={(event, page) => setCurrentPage(page)}
        handleChangeRowsPerPage={event => performActionAndRevertPage(setRowsPerPage, +event.target.value)}
        handleEdit={handleEdit}
      />

      <AdditionalTemplateForm
        open={openFormModal}
        additionalItems={additionalItems}
        additional={currentAdditional}
        isEdit={currentAdditional.id > 0}
        setAdditionalItems={setAdditionalItems}
        setOpen={setOpenFormModal}
        handleOpenSnackbar={handleOpenSnackbar}
      />

      <StandardConfirmationDialog
        variant={snackbarVarient}
        message={snackbarMessage}
        confirmButtonText='Enable'
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        noCancelButton
      />
    </SettingTemplate>
  );
};

export default AdditionalTemplatePage;
