import React, { FC, useEffect, useState } from 'react';
import { Backdrop, CircularProgress, Paper } from '@material-ui/core';
import { WEB_TRANSACTION_CREDIT_DETAIL_URL, WEB_TRANSACTION_PAYMENT_CREDIT_URL } from 'constants/url';
import axios from 'axios';
import MainTemplate from 'components/Template/MainTemplate';
import useRouter from 'hooks/useRouter';
import TopupAlert from './TopupAlert';
import TopupConfirm from './TopupConfirm';
import TopupDetail from './TopupDetail';
import useStyles from './styles';
import { dummyCreditDetail } from 'constants/DummyData';
import TransactionStatus from 'typings/enum/TransactionStatus';
import Snackbar from 'components/Snackbar';
import useSnackbar from 'hooks/useSnackbar';

const MerchantCreditPaymentDetailPage: FC = () => {
  const {
    history,
    match: {
      params: { id }
    }
  } = useRouter();
  const classes = useStyles();
  const { openSnacbar, snackbar } = useSnackbar();
  const [step, setStep] = useState<'DETAIL' | 'CONFIRM'>('DETAIL');
  const [transaction, setTransaction] = useState<CreditDetail>(dummyCreditDetail);
  const [open, setOpen] = useState(false);
  const [variant, setVariant] = useState<'success' | 'warning' | 'info'>('success');
  const [loading, setLoading] = useState({ confirm: false, detail: false, cancel: false });
  const [message, setMessage] = useState('');
  const [otp, setOtp] = useState('');

  const onInput = (value: string) => {
    setOtp(state => {
      state += value;
      return state;
    });
  };

  const onClear = (index: number) => {
    setOtp(state => {
      state = state.slice(0, -1);
      return state;
    });
  };

  const onConfirm = async () => {
    try {
      setOpen(false);
      setMessage('');
      setLoading(prev => ({ ...prev, confirm: true }));
      const { data } = await axios.put(WEB_TRANSACTION_PAYMENT_CREDIT_URL, {
        code: otp.toLocaleUpperCase(),
        id: +id!
      });

      if ([TransactionStatus.SETTLED].includes(data.status)) {
        let timer = setTimeout(() => {
          setOpen(true);
          setOtp('');
          setVariant('success');
          clearTimeout(timer);
        }, 600);
      } else {
        openSnacbar('Transaction has been process');
        setTransaction(prev => ({ ...prev, status: data.status }));
        let timer = setTimeout(() => {
          history.goBack();
          clearTimeout(timer);
        }, 1500);
      }
    } catch (e) {
      if (e && e.status === 400) setMessage('Invalid OTP');
    } finally {
      setLoading(prev => ({ ...prev, confirm: false }));
    }
  };

  const onCloseConfirmation = async () => {
    try {
      setOpen(false);
      setLoading(prev => ({ ...prev, cancel: true }));
      await axios.delete(WEB_TRANSACTION_CREDIT_DETAIL_URL(+id!));
      setTransaction(prev => ({ ...prev, status: TransactionStatus.CANCELLED }));
      openSnacbar('Transaction has been cancelled');
      const timer = setTimeout(() => {
        clearTimeout(timer);
        history.goBack();
      }, 1500);
    } catch (e) {
    } finally {
      setLoading(prev => ({ ...prev, cancel: false }));
    }
  };

  const onCloseSuccess = () => {
    setOpen(false);
    history.goBack();
  };

  const onContinue = () => {
    setOpen(false);
  };

  const onCancel = () => {
    setVariant('warning');
    setOpen(true);
  };

  const onRedirect = () => {
    history.push('/owners');
  };

  const getDetail = async () => {
    try {
      setLoading(prev => ({ ...prev, detail: true }));
      const { data } = await axios.get(WEB_TRANSACTION_CREDIT_DETAIL_URL(+id!));
      setTransaction(data);
    } catch (e) {
    } finally {
      setLoading(prev => ({ ...prev, detail: false }));
    }
  };

  const onBackStep = () => {
    setStep('DETAIL');
  };

  useEffect(() => {
    getDetail();
    window.addEventListener('focus', e => {
      getDetail();
    });

    return () => {
      window.removeEventListener('focus', e => {
        getDetail();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainTemplate
      title='Credit'
      breadcrumb={true}
      links={[
        { name: 'Transaction', links: 'goback', click: true },
        { name: transaction.companyName, links: '', click: false },
        { name: 'Credit', links: '', click: false }
      ]}
      refreshButton={false}
      primaryButton={false}
    >
      <Backdrop open={loading.detail} className={classes.backdrop}>
        <Paper elevation={0} className={classes.boxBackdrop}>
          <CircularProgress size={75} classes={{ circle: classes.iconLoading }} />
        </Paper>
      </Backdrop>
      <div>
        {step === 'DETAIL' ? (
          <TopupDetail transaction={transaction} loading={loading.detail} onClick={() => setStep('CONFIRM')} onCancel={onCancel} />
        ) : (
          <TopupConfirm
            message={message}
            loading={loading.confirm}
            otp={otp}
            status={transaction.status}
            onInput={onInput}
            onClear={onClear}
            onConfirm={onConfirm}
            onBackStep={onBackStep}
          />
        )}
      </div>

      <div>
        <TopupAlert
          loading={loading.cancel}
          open={open}
          variant={variant}
          onContinue={onContinue}
          onCloseConfirmation={onCloseConfirmation}
          onCloseSuccess={onCloseSuccess}
          onRedirect={onRedirect}
        />
      </div>

      <Snackbar {...snackbar} />
    </MainTemplate>
  );
};

export default MerchantCreditPaymentDetailPage;
