import React, { FC, useEffect, useState, useCallback } from 'react';
import axios, { CancelTokenSource } from 'axios';

import {
  makeStyles,
  Theme,
  Grid,
  CircularProgress,
  ButtonGroup,
  Button,
  ButtonBase,
  Typography,
  TextField,
  FormControl,
  MenuItem,
  InputAdornment,
  Box,
  Divider,
  Checkbox,
  FormGroup,
  FormControlLabel,
  CardHeader,
  FormHelperText,
  IconButton
} from '@material-ui/core';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import ViewIcon from '@material-ui/icons/Visibility';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import DescriptionIcon from '@material-ui/icons/Description';
import AlternateEmailOutlinedIcon from '@material-ui/icons/AlternateEmailOutlined';

import FileModal from 'components/FileModal';
import NumberFormatCustom from 'components/NumberFormatCustom';
import MerchantType from 'typings/enum/MerchantType';
import {
  MERCHANTS_BASE_URL,
  GET_CHECK_USER_URL,
  MERCHANTS_TASKS_CHECK_BASE_URL,
  WEB_MERCHANT_DETAIL_URL,
  WEB_ADDITIONAL_CONTACT_CHECK_BASE_URL
} from 'constants/url';
import { getVehicleTypeSelect, isValidEmail, staticString } from 'utils';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { IOSSwitch } from 'components/CustomSwitch';
import CompanyTypeChip from 'components/CompanyTypeChip';
import { useHistory } from 'react-router-dom';
import { Add, Delete } from '@material-ui/icons';

interface Props {
  id: number;
  isLoadingData: boolean;
  merchant: MerchantModel;
  merchantUser: UserDetailsModel;
  userStatus: UserStatusHistoriesModel[];
  setMerchant: React.Dispatch<React.SetStateAction<MerchantModel>>;
  setMerchantUser: React.Dispatch<React.SetStateAction<UserDetailsModel>>;
  setDisplayName: React.Dispatch<React.SetStateAction<string>>;
  setEditable: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoadingData: React.Dispatch<React.SetStateAction<boolean>>;
  handleOpenSnackbar: (type: 'success' | 'error', message: string) => void;
  banks: Select[];
}

const useStyles = makeStyles((theme: Theme) => ({
  buttonLink: {
    cursor: 'pointer',
    color: theme.palette.link.main
  },
  inputAdornmentClass: {
    marginLeft: theme.spacing(2)
  },
  boxCompany: {
    marginBottom: '24px'
  },
  boxPersonal: {
    marginTop: '24px',
    marginBottom: '24px'
  },
  boxBank: {
    marginTop: '24px',
    marginBottom: '24px'
  },
  cardHeader: {
    padding: 0,
    marginBottom: '24px'
  },
  input: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '150%',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.5px',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  label: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '14px',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.6)'
  },
  button: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '150%',
    letterSpacing: '0.25px',
    textTransform: 'none'
  },
  icon: {
    marginRight: '8px',
    width: '24px',
    height: '24px'
  },
  chipSelect: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  inputTag: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '150%',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.5px',
    color: 'rgba(0, 0, 0, 0.87)',
    paddingTop: '12px',
    paddingBottom: '12px'
  }
}));

const MerchantEdit: FC<Props> = props => {
  const classes = useStyles();
  const history = useHistory();

  const qr = new URLSearchParams(history.location.search);
  const opblc = qr.has('roth') ? qr.get('roth') === 'schild' : false;

  const { id, merchant, merchantUser, banks } = props;
  const { isLoadingData, setEditable, setDisplayName, setMerchant, setMerchantUser, setIsLoadingData, handleOpenSnackbar } = props;
  const {
    merchantType,
    companyName,
    companyUen,
    companyGst,
    companyFile,
    companyFileUrl,
    bankName,
    bankAccountName,
    bankAccountNumber,
    balance,
    AdditionalContacts,
    allowSend
  } = merchant;
  const { displayName, contactNumber, email, address, status, ...other } = merchantUser;

  const [inputCompanyName, setInputCompanyName] = useState<string>('');
  const [inputCompanyUen, setInputCompanyUen] = useState<string>('');
  const [inputCompanyGst, setInputCompanyGst] = useState<string>('');
  const [inputCompanyFile, setInputCompanyFile] = useState<string>('');
  const [inputCompanyPhone, setInputCompanyPhone] = useState<string>('');
  const [inputBankId, setInputBankId] = useState<number>(0);
  const [inputBankAccountName, setInputBankAccountName] = useState<string>('');
  const [inputBankAccountNumber, setInputBankAccountNumber] = useState<string>('');
  const [inputDisplayName, setInputDisplayName] = useState<string>('');
  const [inputEmail, setInputEmail] = useState<string>('');
  const [inputContactNumber, setInputContactNumber] = useState<string>('');
  const [inputAddress, setInputAddress] = useState<string>('');
  const [inputCompanyType, setInputCompanyType] = useState<string[]>([]);
  const [inputBalance, setInputBalance] = useState<number>(0);
  const [inputAdditionalEmail, setInputAdditionalEmail] = useState<MerchantModel['AdditionalContacts']>([]);
  const [inputAllowSend, setInputAllowSend] = useState(false);

  const [errorCompanyName, setErrorCompanyName] = useState<string>('');
  const [errorCompanyUen, setErrorCompanyUen] = useState<string>('');
  const [errorCompanyGst, setErrorCompanyGst] = useState<string>('');
  const [errorCompanyFile, setErrorCompanyFile] = useState<string>('');
  const [errorBankName, setErrorBankName] = useState<string>('');
  const [errorBankAccountName, setErrorBankAccountName] = useState<string>('');
  const [errorBankAccountNumber, setErrorBankAccountNumber] = useState<string>('');
  const [errorDisplayName, setErrorDisplayName] = useState<string>('');
  const [errorEmail, setErrorEmail] = useState<string>('');
  const [errorContactNumber, setErrorContactNumber] = useState<string>('');
  const [errorHasTasksProgress, setErrorHasTasksProgress] = useState('');
  const [errorAdditionalEmail, setErrorAdditionalEmail] = useState<string[]>([]);

  const [openFile, setOpenFile] = useState<boolean>(false);
  const [uploadCompanyFile, setUploadCompanyFile] = useState<string>('');
  const [isLoadingEmail, setLoadingEmail] = useState<boolean>(false);
  const [isLoadingContactNumber, setLoadingContactNumber] = useState<boolean>(false);
  const [isLoadingUen, setLoadingUen] = useState<boolean>(false);
  const [isLoadingGst, setLoadingGst] = useState<boolean>(false);
  const [isLoadingCheckGst, setLoadingCheckGst] = useState<boolean>(false);

  const [isCompanyGst, setCompanyGst] = useState<boolean>(!!companyGst);
  const [isGstAsUen, setGstAsUen] = useState<boolean>(companyUen === companyGst || false);

  const handleSubmitForm: any = async () => {
    const valid = await handleFormValidation();
    if (!valid) {
      return;
    }

    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    setIsLoadingData(true);

    try {
      const valueBank = banks!.find(bank => bank.id === inputBankId);
      const dataMerchant: any = {
        merchantType: MerchantType.SUPER_MERCHANT,
        companyName: inputCompanyName,
        companyUen: inputCompanyUen,
        companyGst: inputCompanyGst,
        companyFile: inputCompanyFile,
        companyPhone: inputCompanyPhone,
        bankName: valueBank ? valueBank.name : '',
        bankAccountName: inputBankAccountName,
        bankAccountNumber: inputBankAccountNumber,
        displayName: inputDisplayName,
        contactNumber: inputContactNumber,
        email: inputEmail,
        address: inputAddress,
        types: inputCompanyType,
        additionalContact: inputAdditionalEmail,
        allowSend: inputAllowSend
      };

      if (opblc && balance !== inputBalance) {
        dataMerchant.balance = inputBalance;
      }

      if (uploadCompanyFile) {
        const putUrl = await fecthPutFileUrl(inputCompanyFile);
        const fileName = inputCompanyFile ? inputCompanyFile : '';
        const fileExtension = fileName ? fileName.split('.').pop()! : '';

        const myHeaders = new Headers();
        if (fileExtension === 'pdf') {
          myHeaders.append('Content-Type', `application/${fileExtension}`);
        } else {
          myHeaders.append('Content-Type', `image/${fileExtension}`);
        }

        const config = {
          method: 'PUT',
          headers: myHeaders,
          body: uploadCompanyFile
        };

        const fileUpload = await fetch(putUrl, config);

        if (!fileUpload.ok) {
          handleOpenSnackbar('error', 'Failed upload file!');
          return;
        }
      }

      const { data } = await axios.put(WEB_MERCHANT_DETAIL_URL(id), dataMerchant, { cancelToken: cancelTokenSource.token });
      const { displayName, User } = data;

      setMerchant(data);
      setMerchantUser(User);
      setDisplayName(displayName);
      setEditable(state => !state);
      setIsLoadingData(false);
      handleOpenSnackbar('success', `Owner account has been updated!`);
    } catch (err) {
      console.error('err: ', err);
      setIsLoadingData(false);
      handleOpenSnackbar('error', err?.data.message);
    }

    return () => cancelTokenSource.cancel();
  };

  const handleCancelForm: any = () => {
    setEditable(state => !state);
    handleResetInput();
    handleClearError();
  };

  const handleResetInput = useCallback(() => {
    if (!merchant) {
      return;
    }

    setInputCompanyName(companyName);
    setInputCompanyUen(companyUen);
    setInputCompanyGst(companyGst || '');
    setInputCompanyFile(companyFile);
    setInputCompanyPhone(other.companyPhone || '');
    setInputBankAccountName(bankAccountName);
    setInputBankAccountNumber(bankAccountNumber);
    setInputDisplayName(displayName);
    setInputEmail(email);
    setInputContactNumber(contactNumber);
    setInputAddress(address || '');
    setInputCompanyType((merchant.MerchantTypes || []).map(v => v.type));
    setInputBalance(balance || 0);
    setInputAdditionalEmail(AdditionalContacts || []);
    setInputAllowSend(allowSend || false);

    const valueBank = banks.find(bank => {
      if (staticString(bank.name) === staticString(bankName)) {
        return bank;
      } else {
        return false;
      }
    });

    setInputBankId(valueBank ? Number(valueBank.id) : 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    merchant,
    merchantType,
    companyName,
    other.companyPhone,
    companyUen,
    companyGst,
    companyFile,
    bankAccountName,
    bankAccountNumber,
    displayName,
    balance,
    email,
    contactNumber,
    address,
    bankName,
    banks
  ]);

  const handleClearError = () => {
    setErrorCompanyName('');
    setErrorCompanyUen('');
    setErrorCompanyGst('');
    setErrorCompanyFile('');
    setErrorBankName('');
    setErrorBankAccountName('');
    setErrorBankAccountNumber('');
    setErrorDisplayName('');
    setErrorEmail('');
    setErrorContactNumber('');
    setErrorAdditionalEmail([]);
  };

  const handleFormValidation = async () => {
    let valid = true;

    // if (!inputBankId || inputBankId < 1) {
    //   valid = false;
    //   handleInvalidBankName();
    // }

    if (!inputEmail || !isValidEmail(inputEmail)) {
      valid = false;
      handleInvalidEmail();
    }

    if (!inputDisplayName) {
      valid = false;
      handleInvalidDisplayName();
    }

    if (inputContactNumber.length < 8) {
      valid = false;
      handleInvalidContactNumber();
    }

    if (!inputCompanyName) {
      valid = false;
      handleInvalidCompanyName();
    }

    if (!inputCompanyUen) {
      valid = false;
      handleInvalidCompanyUen();
    }

    // if (!inputBankAccountName) {
    //   valid = false;
    //   handleInvalidBankAccountName();
    // }

    // if (!inputBankAccountNumber) {
    //   valid = false;
    //   handleInvalidBankAccountNumber();
    // }

    if (valid) {
      setLoadingEmail(true);
      const isExistEmail = await isExistValidation('EMAIL', inputEmail);
      if (isExistEmail) {
        valid = false;
        setErrorEmail('Email has been registered');
      }

      setLoadingEmail(false);

      setLoadingContactNumber(true);
      const isExistContact = await isExistValidation('CONTACT', inputContactNumber);
      if (isExistContact) {
        valid = false;
        setErrorContactNumber('Contact number has been registered');
      }

      setLoadingContactNumber(false);

      setLoadingUen(true);
      const isExistUen = await isExistValidation('UEN', inputCompanyUen);
      if (isExistUen) {
        valid = false;
        setErrorCompanyUen('UEN number has been registered');
      }

      setLoadingUen(false);

      if (inputCompanyGst) {
        setLoadingGst(true);
        const isExistGst = await isExistValidation('GST', inputCompanyGst);
        if (isExistGst) {
          valid = false;
          setErrorCompanyGst('GST number has been registered');
        }

        setLoadingGst(false);
      }
    }

    return valid;
  };

  const handleChangeBank = (event: React.ChangeEvent<{ value: any }>) => {
    const selectedBank = event.target.value as number;
    setInputBankId(selectedBank);
    setErrorBankName('');

    // if (!selectedBank || selectedBank < 1) {
    //   handleInvalidBankName();
    // }
  };

  const handleChangeFile = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const originalname = event.target.files[0].name;
      const extention = originalname.split('.').pop();
      const filename = `${Date.now()}.${extention}`;

      if (extention === 'pdf' || extention === 'png' || extention === 'jpg' || extention === 'jpeg') {
        setUploadCompanyFile(event.target.files[0]);
        setInputCompanyFile(filename);
        setErrorCompanyFile('');
      } else {
        setErrorCompanyFile('File extention must be one of *.pdf, *.png, *.jpg, *.jpeg');
      }
    }
  };

  const handleBlurEmail = async (inputed: string) => {
    setErrorEmail('');
    if (!inputed || inputed === '' || !isValidEmail(inputEmail)) {
      handleInvalidEmail();
    }

    setLoadingEmail(true);
    const isExist = await isExistValidation('EMAIL', inputed);
    if (isExist) {
      setErrorEmail('Email has been registered');
    }

    setLoadingEmail(false);
  };

  const handleBlurName = (inputed: string) => {
    setErrorDisplayName('');
    if (!inputed || inputed === '') {
      handleInvalidDisplayName();
    }
  };

  const handleBlurContactNumber = async (inputed: string) => {
    setErrorContactNumber('');
    if (inputed.length < 8) {
      handleInvalidContactNumber();
    }

    setLoadingContactNumber(true);
    const isExist = await isExistValidation('CONTACT', inputed);
    if (isExist) {
      setErrorContactNumber('Contact number has been registered');
    }

    setLoadingContactNumber(false);
  };

  const handleBlurCompanyName = (inputed: string) => {
    setErrorCompanyName('');
    if (!inputed || inputed === '') {
      handleInvalidCompanyName();
    }
  };

  const handleBlurCompanyUen = async (inputed: string) => {
    setErrorCompanyUen('');
    if (!inputed || inputed === '') {
      handleInvalidCompanyUen();
    }

    setLoadingUen(true);
    const isExist = await isExistValidation('UEN', inputed);
    if (isExist) {
      setErrorCompanyUen('UEN number has been registered');
    }

    setLoadingUen(false);
  };

  const handleBlurCompanyGst = async (inputed: string) => {
    setErrorCompanyGst('');
    if (inputed) {
      setLoadingGst(true);
      const isExist = await isExistValidation('GST', inputed);
      if (isExist) {
        setErrorCompanyGst('GST number has been registered');
      }

      setLoadingGst(false);
    }
  };

  const handleBlurBankAccountName = (inputed: string) => {
    setErrorBankAccountName('');
    // if (!inputed || inputed === '') {
    //   handleInvalidBankAccountName();
    // }
  };

  const handleBlurBankAccountNumber = (inputed: string) => {
    setErrorBankAccountNumber('');
    // if (!inputed || inputed === '') {
    //   handleInvalidBankAccountNumber();
    // }
  };

  // const handleInvalidBankName = () => {
  //   setErrorBankName('Please select bank');
  // };

  const handleInvalidEmail = () => {
    setErrorEmail('Please enter valid email');
  };

  const handleInvalidDisplayName = () => {
    setErrorDisplayName('Please enter display name');
  };

  const handleInvalidContactNumber = () => {
    setErrorContactNumber('Please enter contact number 8 digits');
  };

  const handleInvalidCompanyName = () => {
    setErrorCompanyName('Please enter company name');
  };

  const handleInvalidCompanyUen = () => {
    setErrorCompanyUen('Please enter company UEN number');
  };

  // const handleInvalidBankAccountName = () => {
  //   setErrorBankAccountName('Please enter bank account name');
  // };

  // const handleInvalidBankAccountNumber = () => {
  //   setErrorBankAccountNumber('Please enter bank account number');
  // };

  const isExistValidation = async (field: string, value: string) => {
    let exist = false;
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    try {
      const { data } = await axios.get(`${GET_CHECK_USER_URL}?role=MERCHANT&userId=${merchant.UserId}&field=${field}&params=${value}`, {
        cancelToken: cancelTokenSource.token
      });
      exist = data.isExist;
    } catch (err) {
      console.error('err: ', err);
      handleOpenSnackbar('error', err?.data.message);
    }

    return exist;
  };

  const fecthPutFileUrl = async (filename: string) => {
    try {
      const { data } = await axios.get(`${MERCHANTS_BASE_URL}/put-company-file-url?file=${filename}`);

      return data.companyFile;
    } catch (err) {
      console.error('err: ', err);
    }

    return;
  };

  const checkTasks = async () => {
    try {
      setErrorHasTasksProgress('');
      setLoadingCheckGst(true);
      const { data } = await axios.post(MERCHANTS_TASKS_CHECK_BASE_URL, { id });
      setErrorHasTasksProgress(data.isExist ? "Has Pending or Progress Task's can't change GST Number." : '');
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingCheckGst(false);
    }
  };

  const handleBlurAdditionalEmail = (index: number) => async (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    let inputed = e.target.value;
    setErrorAdditionalEmail(p => {
      p[index] = '';
      return [...p];
    });

    const valid = isValidEmail(inputed);

    if (!inputed || inputed === '' || !valid) {
      setErrorAdditionalEmail(p => {
        p[index] = 'Please enter valid email';
        return [...p];
      });
    }

    if (valid) {
      if (inputAdditionalEmail?.find((v, i) => v.value === inputed && i !== index)) {
        setErrorAdditionalEmail(p => {
          p[index] = 'Email has been registered';
          return [...p];
        });
      }

      const idd = (AdditionalContacts || [])[index]?.id || 0;
      axios.post(WEB_ADDITIONAL_CONTACT_CHECK_BASE_URL, { id: idd, email: inputed, MerchantId: id }).then(({ data }) => {
        if (data?.exists) {
          setErrorAdditionalEmail(p => {
            p[index] = 'Email has been registered';
            return [...p];
          });
        }
      });
    }
  };

  const addEmail = () => {
    setInputAdditionalEmail(p => [...p!, { allowSend: true, value: '', type: 'EMAIL' }]);
  };

  const removeEmail = index => () => {
    setInputAdditionalEmail(p => {
     if(p){
      p.splice(index, 1);
      return [...p]
     } else {return []}
    });

    setErrorAdditionalEmail(p => {
      p.splice(index, 1);
      return [...p];
    });
  };

  useEffect(() => {
    if (!isGstAsUen) return;

    setInputCompanyGst(inputCompanyUen);
    handleBlurCompanyGst(inputCompanyUen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGstAsUen]);

  useEffect(() => {
    if (!merchant) {
      return;
    }

    if (id < 1) {
      return;
    }

    handleResetInput();
    checkTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, merchant, merchantUser, handleResetInput]);


  return (
    <Box display={'flex'} flexDirection={'column'} justifyContent='space-event'>
      <Box className={classes.boxCompany}>
        <CardHeader
          title='Company Information'
          titleTypographyProps={{ variant: 'h4' }}
          className={classes.cardHeader}
          action={
            <div>
              <Button variant='text' color='inherit' className={classes.button} onClick={handleCancelForm}>
                <HighlightOffIcon className={classes.icon} />
                Cancel
              </Button>
              <Button variant='text' disabled={isLoadingData || (!!errorAdditionalEmail.length && !errorAdditionalEmail.every(v => !v))} color='primary' className={classes.button} onClick={handleSubmitForm}>
                {isLoadingData ? (
                  <CircularProgress size={24} />
                ) : (
                  <>
                    <CheckCircleIcon className={classes.icon} />
                    Save
                  </>
                )}
              </Button>
            </div>
          }
        />

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              InputLabelProps={{
                className: classes.label
              }}
              fullWidth
              variant='outlined'
              label='Company Name'
              value={inputCompanyName}
              helperText={errorCompanyName}
              error={errorCompanyName !== ''}
              onChange={event => setInputCompanyName(event.target.value)}
              onBlur={event => handleBlurCompanyName(event.target.value)}
              InputProps={{
                inputProps: {
                  className: classes.input
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              select
              label='Company Type'
              value={inputCompanyType}
              InputLabelProps={{ shrink: true }}
              fullWidth
              SelectProps={{
                native: false,
                multiple: true,
                displayEmpty: true,
                renderValue: selected => {
                  if ((selected as any[]).length === 0) {
                    return <em>Select Company Type</em>;
                  }

                  return (
                    <div className={classes.chipSelect}>
                      {(selected as any[]).map(value => (
                        <CompanyTypeChip select key={value} type={value} />
                      ))}
                    </div>
                  );
                }
              }}
              variant='outlined'
              onChange={e => {
                const value = (e.target.value as unknown) as string[];
                if (!value.some(v => v === 'ALL')) {
                  setInputCompanyType(value);
                }
              }}
            >
              <MenuItem
                value='ALL'
                onClick={() => {
                  setInputCompanyType(prev => {
                    return [
                      ...prev,
                      ...[...getVehicleTypeSelect(), { id: 'MERCHANT', name: 'Merchant' }].filter(v => !prev.includes(v.id)).map(v => v.id as string)
                    ];
                  });
                }}
              >
                All Type
              </MenuItem>
              {[...getVehicleTypeSelect(), { id: 'MERCHANT', name: 'Merchant' }].map(val => (
                <MenuItem key={val.id} value={val.id}>
                  {val.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              InputLabelProps={{
                className: classes.label
              }}
              fullWidth
              variant='outlined'
              type='number'
              label='Company Phone'
              value={inputCompanyPhone}
              onChange={event => setInputCompanyPhone(event.target.value.trim())}
              InputProps={{
                inputProps: {
                  className: classes.input
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <input style={{ display: 'none' }} id='button-file' type='file' onChange={event => handleChangeFile(event)} />
              <TextField
                InputLabelProps={{
                  className: classes.label
                }}
                variant='outlined'
                label='ACRA Company'
                value={inputCompanyFile}
                error={errorCompanyFile !== ''}
                helperText={errorCompanyFile}
                onChange={event => setInputCompanyFile(event.target.value)}
                InputProps={{
                  inputProps: {
                    className: classes.input
                  },
                  disabled: true,
                  endAdornment: (
                    <InputAdornment position='end'>
                      <ButtonGroup color='primary' aria-label='outlined primary button group'>
                        <label htmlFor='button-file' style={{ textAlign: 'center', cursor: 'pointer' }}>
                          <ButtonBase focusRipple key={'Upload'} component='span'>
                            <NoteAddIcon className={classes.buttonLink} />
                          </ButtonBase>
                        </label>
                        {inputCompanyFile && (
                          <ButtonBase>
                            <ViewIcon className={classes.buttonLink} onClick={() => setOpenFile(true)} />
                          </ButtonBase>
                        )}
                      </ButtonGroup>
                    </InputAdornment>
                  )
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              InputLabelProps={{
                className: classes.label
              }}
              fullWidth
              variant='outlined'
              label='Company UEN No'
              value={inputCompanyUen}
              helperText={errorCompanyUen}
              error={errorCompanyUen !== ''}
              onChange={event => {
                setInputCompanyUen(event.target.value.trim());
                if (isGstAsUen) {
                  setInputCompanyGst(event.target.value.trim());
                }
              }}
              onBlur={event => handleBlurCompanyUen(event.target.value.trim())}
              InputProps={{
                inputProps: {
                  className: classes.input
                },

                endAdornment: (
                  <InputAdornment position='end'>
                    {isLoadingUen ? <CircularProgress size={20} /> : <DescriptionIcon color='primary' fontSize='small' />}
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid container item xs={6} spacing={2}>
            <Grid item xs={6}>
              <Typography variant='h6'>GST Registered ?</Typography>
              <FormGroup style={{ marginLeft: 10 }}>
                <FormControlLabel
                  control={
                    isLoadingCheckGst ? (
                      <CircularProgress size={20} color='primary' />
                    ) : (
                      <IOSSwitch
                        disabled={!!errorHasTasksProgress}
                        checked={isCompanyGst}
                        onChange={() => {
                          setCompanyGst(!isCompanyGst);
                          setGstAsUen(false);
                          setInputCompanyGst('');
                        }}
                      />
                    )
                  }
                  label={isCompanyGst ? 'Yes' : 'No'}
                />
                <FormHelperText error={!!errorHasTasksProgress}>{errorHasTasksProgress}</FormHelperText>
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              {isCompanyGst && (
                <Grid>
                  <TextField
                    disabled={!!errorHasTasksProgress}
                    InputLabelProps={{
                      className: classes.label
                    }}
                    fullWidth
                    variant='outlined'
                    label='Company GST No'
                    value={inputCompanyGst}
                    helperText={errorCompanyGst}
                    error={errorCompanyGst !== ''}
                    onChange={event => setInputCompanyGst(event.target.value.trim())}
                    onBlur={event => handleBlurCompanyGst(event.target.value.trim())}
                    InputProps={{
                      inputProps: {
                        className: classes.input
                      },
                      endAdornment: (
                        <InputAdornment position='end'>
                          {isLoadingGst ? <CircularProgress size={20} /> : <DescriptionIcon color='primary' fontSize='small' />}
                        </InputAdornment>
                      )
                    }}
                  />
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={isGstAsUen} disabled={!!errorHasTasksProgress} onChange={() => setGstAsUen(!isGstAsUen)} color='primary' />
                      }
                      label='Same as UEN Number'
                    />
                  </FormGroup>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <TextField
              InputLabelProps={{
                className: classes.label
              }}
              fullWidth
              multiline
              rows={4}
              variant='outlined'
              label='Address'
              value={inputAddress}
              onChange={event => setInputAddress(event.target.value)}
              InputProps={{
                inputProps: {
                  className: classes.input
                }
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Divider />

      <Box className={classes.boxPersonal}>
        <CardHeader title='Personal Information' titleTypographyProps={{ variant: 'h4' }} className={classes.cardHeader} />

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              InputLabelProps={{
                className: classes.label
              }}
              fullWidth
              variant='outlined'
              label='Display Name'
              value={inputDisplayName}
              helperText={errorDisplayName}
              error={errorDisplayName !== ''}
              onChange={event => setInputDisplayName(event.target.value)}
              onBlur={event => handleBlurName(event.target.value)}
              InputProps={{
                inputProps: {
                  className: classes.input
                }
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              InputLabelProps={{
                className: classes.label
              }}
              fullWidth
              variant='outlined'
              label='Phone Number'
              value={inputContactNumber}
              helperText={errorContactNumber}
              error={errorContactNumber !== ''}
              onChange={event => setInputContactNumber(event.target.value.replace(/\s/g, '').trim())}
              onBlur={event => handleBlurContactNumber(event.target.value.replace(/\s/g, '').trim())}
              InputProps={{
                inputComponent: NumberFormatCustom as any,
                inputProps: {
                  format: '########',
                  className: classes.input
                },
                startAdornment: (
                  <InputAdornment position='start' className={classes.inputAdornmentClass}>
                    +65
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end'>
                    {isLoadingContactNumber ? <CircularProgress size={20} /> : <PhoneInTalkIcon color='primary' fontSize='small' />}
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              InputLabelProps={{
                className: classes.label
              }}
              fullWidth
              variant='outlined'
              label='Email'
              value={inputEmail}
              helperText={errorEmail}
              error={errorEmail !== ''}
              onChange={event => setInputEmail(event.target.value.trim())}
              onBlur={event => handleBlurEmail(event.target.value.trim())}
              InputProps={{
                inputProps: {
                  className: classes.input
                },
                endAdornment: (
                  <InputAdornment position='end'>
                    {isLoadingEmail ? <CircularProgress size={20} /> : <AlternateEmailOutlinedIcon color='primary' fontSize='small' />}
                  </InputAdornment>
                )
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormControlLabel
              control={<Checkbox color='primary' checked={inputAllowSend} onChange={(e, checked) => setInputAllowSend(checked)} />}
              label='Allow receive email'
            />
          </Grid>
        </Grid>
      </Box>

      <Divider />

      <Box className={classes.boxPersonal}>
        <CardHeader title='Additional Contact' titleTypographyProps={{ variant: 'h4' }} className={classes.cardHeader} />

        <Grid container spacing={3}>
          {inputAdditionalEmail?.map((val, i) => (
            <Grid item container key={i}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  key={i}
                  fullWidth
                  variant='outlined'
                  label='Additional Email'
                  type='email'
                  value={val.value}
                  helperText={errorAdditionalEmail[i] || ''}
                  error={!!(errorAdditionalEmail[i] || '')}
                  onChange={event => {
                    event.persist();
                    setInputAdditionalEmail(p => {
                      p![i].value = String(event.target.value);
                      return [...p!];
                    });
                  }}
                  onBlur={handleBlurAdditionalEmail(i)}
                  InputProps={{
                    endAdornment: (
                      <IconButton size='small' onClick={removeEmail(i)}>
                        <Delete color='error' fontSize='inherit' />
                      </IconButton>
                    )
                  }}
                />
              </Grid>

              <Grid item xs={10} sm={12} md={12} lg={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color='primary'
                      checked={val.allowSend}
                      onChange={(e, checked) => {
                        setInputAdditionalEmail(p => {
                          p![i].allowSend = checked;
                          return [...p!];
                        });
                      }}
                    />
                  }
                  label='Allow receive email'
                />
              </Grid>
            </Grid>
          ))}

          <Grid item xs={12} sm={12} md={1} lg={1}>
            <Box display='flex' flexDirection={'row'} justifyContent={'flex-end'}>
              <Button color='primary' variant='text' onClick={addEmail} disabled={inputAdditionalEmail?.length === 3}>
                <Add fontSize='small' /> Email
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Divider />

      <Box className={classes.boxBank}>
        <CardHeader title='Bank Account' titleTypographyProps={{ variant: 'h4' }} className={classes.cardHeader} />

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              InputLabelProps={{
                className: classes.label
              }}
              fullWidth
              select
              variant='outlined'
              label='Bank Name'
              value={inputBankId}
              helperText={errorBankName}
              error={errorBankName !== ''}
              onChange={handleChangeBank}
              InputProps={{
                inputProps: {
                  className: classes.input
                }
              }}
            >
              <MenuItem key='bank-0' value={0}>
                <em>None</em>
              </MenuItem>
              {banks &&
                banks.map((option, index) => (
                  <MenuItem key={`bank-${index}`} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              InputLabelProps={{
                className: classes.label
              }}
              fullWidth
              variant='outlined'
              label='Bank Account Name'
              value={inputBankAccountName}
              helperText={errorBankAccountName}
              error={errorBankAccountName !== ''}
              onChange={event => setInputBankAccountName(event.target.value)}
              onBlur={event => handleBlurBankAccountName(event.target.value)}
              InputProps={{
                inputProps: {
                  className: classes.input
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              InputLabelProps={{
                className: classes.label
              }}
              fullWidth
              variant='outlined'
              type='number'
              label='Bank Account No'
              value={inputBankAccountNumber}
              helperText={errorBankAccountNumber}
              error={errorBankAccountNumber !== ''}
              onChange={event => setInputBankAccountNumber(event.target.value.trim())}
              onBlur={event => handleBlurBankAccountNumber(event.target.value.trim())}
              InputProps={{
                inputProps: {
                  className: classes.input
                }
              }}
            />
          </Grid>

          {opblc ? (
            <Grid item xs={6}>
              <TextField
                InputLabelProps={{
                  className: classes.label
                }}
                fullWidth
                variant='outlined'
                label='Bank Balance'
                value={inputBalance}
                onChange={event => setInputBalance(+event.target.value)}
                InputProps={{
                  inputComponent: NumberFormatCustom as any,
                  inputProps: {
                    className: classes.input
                  }
                }}
              />
            </Grid>
          ) : null}
        </Grid>
      </Box>

      <FileModal title='ACRA Company file' open={openFile} setOpen={setOpenFile} fileUrl={companyFileUrl || ''} fileName={companyFile} />
    </Box>
  );
};

export default MerchantEdit;
