import React from 'react';
import clsx from 'clsx';
import { Chip, makeStyles } from '@material-ui/core';

import JobStatus from 'typings/enum/JobStatus';

interface Props {
  status: JobStatus;
  label: string;
  minWidth?: string;
  disabled?: boolean;
}

const useStyles = makeStyles({
  chip: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '150%',
    letterSpacing: '0.4px',
    borderRadius: '30px',
    minWidth: '96px',
    height: 'auto'
  },
  available: {
    color: '#29696A',
    backgroundColor: '#D9F7ED',
    border: `1px solid #29696A`
  },
  assigned: {
    color: '#3788D8',
    backgroundColor: '#D7F1FD',
    border: `1px solid #3788D8`
  },
  inProgress: {
    color: '#F6891F',
    backgroundColor: '#FEF1D2',
    border: `1px solid #F6891F`
  },
  canceled: {
    color: '#EB5757',
    backgroundColor: '#FEE9DE',
    border: `1px solid #EB5757`
  },
  expired: {
    color: '#848484',
    backgroundColor: '##F4F4F4',
    border: `1px solid #848484`
  },
  draft: {
    color: '#333333',
    backgroundColor: '#F4F4F4',
    border: `1px solid #333333`
  },
  completed: {
    color: '#4CAF50',
    backgroundColor: '#E7FBDE',
    border: `1px solid #4CAF50`
  },
  label: {
    padding: '5px 15px 5px 15px'
  }
});

export default function StatusChip(props: Props) {
  const classes = useStyles(props);
  const { status, label } = props;
  const chipColor = {
    [classes.available]: status === JobStatus.AVAILABLE,
    [classes.assigned]: status === JobStatus.ASSIGNED,
    [classes.inProgress]: status === JobStatus.IN_PROGRESS,
    [classes.canceled]: status === JobStatus.CANCELLED,
    [classes.expired]: status === JobStatus.EXPIRED,
    [classes.draft]: status === JobStatus.DRAFT,
    [classes.completed]: status === JobStatus.COMPLETED
  };
  return <Chip disabled={props.disabled} label={label} variant='outlined' classes={{ label: classes.label }} className={clsx(classes.chip, chipColor)} />;
}
