import React, { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Theme, makeStyles, TableRow, Link, Chip } from '@material-ui/core';

import BodyCell from 'components/BodyCell';
import { formatId } from 'utils';

interface Props {
  index: number;
  isLoadingData: boolean;
  driver: DriverModel;
  merchantId?: number;
  handleOpenDetailClick: (id: number) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  idLink: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  managerChipRoot: {
    borderRadius: '5px',
    fontWeight: 500,
    fontSize: '12px'
  },
  managerChipLabel: {
    padding: '2px 6px 2px 6px',
    
  }
}));

const BodyRow: FC<Props> = props => {
  const classes = useStyles();
  const { driver, merchantId, isLoadingData, handleOpenDetailClick } = props;
  const { id, vehicleNumber, User, Merchant, individualCompanyName, individualCompanyUen, isManager } = driver;
  const { displayName, contactNumber, email } = User;

  return (
    <>
      {merchantId ? (
        <TableRow hover>
          <BodyCell cellWidth='20%'>{isLoadingData ? <Skeleton width={80} /> : formatId(id)}</BodyCell>
          <BodyCell cellWidth='20%'>
            {isLoadingData ? (
              <Skeleton width={80} />
            ) : (
              <div className={classes.idLink} onClick={() => handleOpenDetailClick(id)}>
                {displayName}&nbsp;
                {isManager ? (
                  <Chip size="small" label="Manager" color="primary" classes={{ outlinedPrimary: classes.managerChipRoot, labelSmall: classes.managerChipLabel }} />
                ) : null}
              </div>
            )}
          </BodyCell>
          <BodyCell cellWidth='20%'>{isLoadingData ? <Skeleton width={80} /> : email}</BodyCell>
          <BodyCell cellWidth='20%'>
            {isLoadingData ? (
              <Skeleton width={80} />
            ) : (
              <Link href={`https://api.whatsapp.com/send?phone=65${contactNumber}`} target='_blank' className={classes.idLink}>
                {contactNumber}
              </Link>
            )}
          </BodyCell>
          <BodyCell cellWidth='20%'>{isLoadingData ? <Skeleton width={80} /> : vehicleNumber}</BodyCell>
        </TableRow>
      ) : (
        <TableRow hover>
          <BodyCell cellWidth='10%'>{isLoadingData ? <Skeleton width={80} /> : formatId(id)}</BodyCell>
          <BodyCell cellWidth='20%'>
            {isLoadingData ? (
              <Skeleton width={80} />
            ) : (
              <div className={classes.idLink} onClick={() => handleOpenDetailClick(id)}>
                {displayName}
              </div>
            )}
          </BodyCell>
          <BodyCell cellWidth='20%'>
            {isLoadingData ? <Skeleton width={80} /> : Merchant ? Merchant.companyName : individualCompanyName + ' (Ind)'}
          </BodyCell>
          <BodyCell cellWidth='20%'>{isLoadingData ? <Skeleton width={80} /> : Merchant ? Merchant.companyUen : individualCompanyUen}</BodyCell>
          <BodyCell cellWidth='15%'>
            {isLoadingData ? (
              <Skeleton width={80} />
            ) : (
              <Link href={`https://api.whatsapp.com/send?phone=65${contactNumber}`} target='_blank' className={classes.idLink}>
                {contactNumber}
              </Link>
            )}
          </BodyCell>
          <BodyCell cellWidth='15%'>{isLoadingData ? <Skeleton width={80} /> : vehicleNumber}</BodyCell>
        </TableRow>
      )}
    </>
  );
};

export default BodyRow;
