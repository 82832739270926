import React, { FC, useState } from 'react';
import axios from 'axios';
import {
  Theme,
  makeStyles,
  createStyles,
  withStyles,
  WithStyles,
  Button,
  Dialog,
  CircularProgress,
  IconButton,
  Typography,
  TextField
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import { green } from '@material-ui/core/colors';
import { WEB_LAMPPOST_BASE_URL } from 'constants/url';
import useSnackbar from 'hooks/useSnackbar';
import Snackbar from 'components/Snackbar';
import { dummyLampPost } from 'constants/DummyData';

interface Props {
  open: boolean;
  data: LampPost;
  setData: React.Dispatch<React.SetStateAction<LampPost>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  reload: (cb?: ((key: { [key: string]: any }) => any) | undefined) => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700]
    }
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h4'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const LampPostForm: FC<Props> = props => {
  const classes = useStyles();
  const { open, setOpen, data, setData } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const { openSnacbar, snackbar } = useSnackbar();

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await axios[!!data.id ? 'put' : 'post'](WEB_LAMPPOST_BASE_URL, data);
      openSnacbar(!!data.id ? 'LampPost successfully updated' : 'LampPost successfull added');
      handleClose();
      props.reload();
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    handleResetInput();
  };

  const handleResetInput = () => {
    setData(dummyLampPost);
  };

  return (
    <div>
      <Dialog fullWidth={true} maxWidth='sm' onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
        <DialogTitle id='customized-dialog-title' onClose={handleClose}>
          {!!data.id ? 'Edit' : 'Create New'} LampPost
        </DialogTitle>
        <DialogContent dividers style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <TextField
            variant='outlined'
            type='text'
            label='StreetName'
            value={data.streetName || ''}
            onChange={e => {
              e.persist();
              setData(prev => ({ ...prev, streetName: (e.target.value as string).toLocaleUpperCase() }));
            }}
          />

          <TextField
            variant='outlined'
            type='text'
            label='LampPost'
            value={data.lampPost || ''}
            onChange={e => {
              e.persist();
              setData(prev => ({ ...prev, lampPost: e.target.value as string }));
            }}
          />

          <TextField
            variant='outlined'
            type='number'
            label='Latitude'
            value={data.latitude || 0}
            onChange={e => {
              e.persist();
              setData(prev => ({ ...prev, latitude: +e.target.value }));
            }}
          />

          <TextField
            variant='outlined'
            type='number'
            label='Longitude'
            value={data.longitude || 0}
            onChange={e => {
              e.persist();
              setData(prev => ({ ...prev, longitude: +e.target.value }));
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' color='primary' disabled={loading} onClick={handleClose}>
            Cancel
          </Button>
          <div className={classes.wrapper}>
            <Button variant='contained' color='primary' disabled={loading} disableElevation onClick={handleSubmit}>
              {!!data.id ? 'Save change' : 'Add New'}
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </DialogActions>
      </Dialog>

      <Snackbar {...snackbar} />
    </div>
  );
};

export default LampPostForm;
