import React, { useState } from 'react';
import topupIcon from 'images/icon/topup.svg';

import { Visibility, VisibilityOff, Close } from '@material-ui/icons';
import { formatCurrency } from 'utils';
import { GET_TOKEN, WEB_TRANSACTION_CREACT_CREDIT_URL } from 'constants/url';
import { useCredit } from 'contexts/CreditContex';

import axios from 'axios';
import clsx from 'clsx';
import useStyles from './styles';
import useSnackbar from 'hooks/useSnackbar';
import TransactionType from 'typings/enum/TransactionType';
import Alert from 'components/DialogAlert/Alert';
import Snackbar from 'components/Snackbar';
import useRouter from 'hooks/useRouter';

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Slide,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel
} from '@material-ui/core';

const nominals = [1000, 5000, 10000, 15000, 20000, 25000];

const MerchantCredit = () => {
  const classes = useStyles();
  const credit = useCredit();
  const { history } = useRouter();
  const { openSnacbar, snackbar } = useSnackbar();

  const [isShowPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [amount, setAmount] = useState(0);
  const [type, setType] = useState(TransactionType.INCREASE_CREDIT);
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState({
    password: false,
    amount: false
  });

  const [errors, setErrors] = useState({
    password: '',
    amount: ''
  });

  const onPasswordConfirm = async () => {
    try {
      setLoading(state => ({ ...state, password: true }));
      setErrors({ password: '', amount: '' });
      if (!password || password === '') {
        setErrors(state => ({ ...state, password: 'Invalid Password' }));
        return;
      }
      await axios.post(GET_TOKEN, {
        password,
        key: credit.data.key
      });

      setStep(1);
    } catch (err) {
      setErrors(state => ({ ...state, password: 'Invalid Password' }));
    } finally {
      setLoading(state => ({ ...state, password: false }));
      setPassword('');
    }
  };

  const onNominalSubmit = async () => {
    try {
      setErrors(state => ({ ...state, amount: '' }));
      setLoading(state => ({ ...state, amount: true }));

      const { data } = await axios.post(WEB_TRANSACTION_CREACT_CREDIT_URL, { amount, merchantId: credit.data.merchantId, type });

      if (data.success) {
        history.push(`/transaction/credit/${data.id}/detail`);
      } else {
        openSnacbar('Opps!, Something wrong please check and try again', { variant: 'warning' });
      }
    } catch (err) {
      setErrors(state => ({ ...state, amount: '' }));
    } finally {
      setLoading(state => ({ ...state, amount: false }));
      setAmount(0);
    }
  };

  const onCloseDialog = () => {
    credit.onClose();
    setPassword('');
    setAmount(0);
    setStep(0);
    setShowPassword(false);
  };

  if (credit.data.hasAdjustPending && !credit.open) {
    return (
      <Alert
        open={credit.data.hasAdjustPending && !credit.open}
        loading={false}
        type='notice'
        title='You still have pending adjust credit'
        message='If you still proceed this adjust credit the pending adjust credit will be cancel. Are You sure want to proceed this? this action cannot be undo'
        close={{
          text: 'Cancel',
          onClick: () => {
            credit.onClose();
          }
        }}
        confirm={{
          text: 'Yes, continue',
          onClick: () => {
            credit.setOpen(true);
          }
        }}
      />
    );
  }

  return (
     <Dialog open={credit.open} maxWidth='lg' className={classes.dialog} disableEscapeKeyDown>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography align='center' variant='h1' style={{ flexGrow: 1 }}>
          Adjust Credit Balance
        </Typography>

        <IconButton aria-label='close' onClick={onCloseDialog} className={classes.iconButton}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {credit.loading.key ? (
          <Box style={{ textAlign: 'center', padding: '16px' }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {step === 0 ? (
              <Slide in={step === 0} unmountOnExit>
                <Box display='flex' flexDirection='column' className={classes.box}>
                  <div className={classes.boxImage}>
                    <img src={topupIcon} alt='image_topup' />
                  </div>

                  <Box>
                    <Typography align='center' variant='h4' gutterBottom>
                      Input your password
                    </Typography>
                    <Typography align='center' variant='body2' gutterBottom>
                      Please input your password below before process top up balance
                    </Typography>
                    <TextField
                      variant='outlined'
                      margin='normal'
                      required
                      fullWidth
                      name='password'
                      type={isShowPassword ? 'text' : 'password'}
                      id='password'
                      value={password}
                      className={classes.inputPassword}
                      autoComplete='current-password'
                      placeholder='Input password here'
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton edge='end' aria-label='toggle password visibility' onClick={event => setShowPassword(!isShowPassword)}>
                              {isShowPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      onChange={e => setPassword(e.target.value)}
                      error={errors.password !== ''}
                      helperText={errors.password}
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          onPasswordConfirm();
                        }
                      }}
                    />
                  </Box>
                </Box>
              </Slide>
            ) : null}

            {step === 1 ? (
              <Slide in={step === 1} unmountOnExit>
                <Box display='flex' flexDirection='column' className={classes.box}>
                  <Typography component={'div'} variant='h6' align='center' gutterBottom>
                    Credit balance remains
                  </Typography>
                  <Typography component={'div'} variant='h3' align='center' gutterBottom>
                    {formatCurrency(credit.data.creditBalance)}
                  </Typography>
                  <Typography component={'div'} variant='subtitle1' color='error' align='center'>
                    Unpaid Balance {formatCurrency(credit.data.totalUsedCredit)}
                  </Typography>

                  <div className={classes.boxNominal}>
                    <label className={classes.labelNominal}>Action :</label>
                    <RadioGroup
                      aria-label='type'
                      name='type'
                      value={type}
                      onChange={e => {
                        setType(e.target.value as TransactionType);
                        setAmount(0);
                      }}
                      row
                    >
                      <FormControlLabel
                        value={TransactionType.INCREASE_CREDIT}
                        control={<Radio color='primary' />}
                        label='Increase Balance'
                        className={clsx(classes.radioIncrease, { [classes.radioActive]: type === TransactionType.INCREASE_CREDIT })}
                      />
                      <FormControlLabel
                        value={TransactionType.DECREASE_CREDIT}
                        control={<Radio />}
                        label='Decrease Balance'
                        className={clsx(classes.radioIncrease, { [classes.radioActive]: type === TransactionType.DECREASE_CREDIT })}
                      />
                    </RadioGroup>
                  </div>

                  <div>
                    <label className={classes.labelNominal}> Choose amount:</label>

                    <div className={classes.boxNominals}>
                      {nominals.map((value, i) => (
                        <Button
                          key={i}
                          disabled={type === TransactionType.DECREASE_CREDIT && credit.data.creditBalance < value}
                          variant='outlined'
                          disableElevation
                          disableFocusRipple
                          disableTouchRipple
                          disableRipple
                          className={clsx(classes.buttonNominal, { [classes.hoverButtonNominal]: value === amount })}
                          onClick={() => setAmount(value)}
                        >
                          {formatCurrency(value)}
                        </Button>
                      ))}
                    </div>
                  </div>
                </Box>
              </Slide>
            ) : null}
          </>
        )}

        <Snackbar {...snackbar} />
        <Divider />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          variant='outlined'
          color='primary'
          disabled={loading.password || loading.amount || credit.loading.key}
          disableElevation
          className={classes.leftButton}
          onClick={onCloseDialog}
        >
          Cancel
        </Button>

        {step === 0 ? (
          <Button
            variant='contained'
            color='primary'
            disabled={loading.password || credit.loading.key}
            disableElevation
            className={classes.rightButton}
            onClick={onPasswordConfirm}
          >
            {loading.password ? <CircularProgress color='inherit' size={24} /> : 'Confirm'}
          </Button>
        ) : null}

        {step === 1 ? (
          <Button
            disabled={amount === 0}
            variant='contained'
            color='primary'
            disableElevation
            className={classes.rightButton}
            onClick={onNominalSubmit}
          >
            {loading.amount ? <CircularProgress color='inherit' size={24} /> : 'Continue'}
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};

export default MerchantCredit;
