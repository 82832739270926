import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      borderRadius: '12px',
      paddingTop: '8px'
    }
  },
  dialogTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  iconButton: {},
  content: {
    width: '635px',
    minWidth: '635px',
    paddingTop: '8px'
  },
  box: {
    paddingBottom: '24px'
  },
  boxImage: {
    textAlign: 'center'
  },
  inputPassword: {
    backgroundColor: 'white'
  },
  actions: {
    padding: '0px 24px 24px 24px'
  },
  rightButton: {
    textTransform: 'none'
  },
  leftButton: {
    border: `1.5px solid ${theme.palette.orange['500']}`,
    borderRadius: '5px',
    textTransform: 'none'
  },
  boxNominal: {
    marginBottom: '24px'
  },
  labelNominal: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '150%',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.15px',
    marginBottom: '8px'
  },
  textHelper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '10px',
    marginTop: '8px'
  },
  boxNominals: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '15px'
  },
  buttonNominal: {
    minWidth: '180px',
    width: '180px',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '150%',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.black.A100,
    padding: '20px 45px 20px 45px',
    borderRadius: '5px'
  },
  hoverButtonNominal: {
    background: '#FEF1D2',
    border: '1px solid #F6891F',
    borderRadius: '5px',
    color: '#F6891F'
  },
  radioIncrease: {
    flexGrow: 1,
    marginLeft: 0,
    border: '1px solid #EAEAEA',
    borderRadius: '5px',
    '& .MuiRadio-root': {
      color: '#EAEAEA'
    },
    '& .MuiTypography-body1': {
      fontSize: '14px',
      fontWeight: 500
    }
  },
  radioDecrease: {
    flexGrow: 1,
    marginLeft: 0,
    border: '1px solid #EAEAEA',
    borderRadius: '5px',
    '& .MuiRadio-root': {
      color: '#EAEAEA'
    },
    '& .MuiTypography-body1': {
      fontSize: '14px',
      fontWeight: 500
    }
  },
  radioActive: {
    backgroundColor: '#FEF1D2',
    '& .MuiRadio-root': {
      color: '#F6891F'
    },
    '& .MuiTypography-body1': {
      fontSize: '14px',
      fontWeight: 500,
      color: '#F6891F'
    }
  }
}));

export default useStyles;
