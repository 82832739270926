import React, { useState } from 'react';
import { Grid, InputAdornment, IconButton, TextField, Button, Theme, withStyles, CircularProgress } from '@material-ui/core';
import { purple } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import NumberFormatCustom from 'components/NumberFormatCustom';

interface Props {
  email: string;
  name: string;
  password: string;
  oldPassword: string;
  confirmPassword: string;
  contactNumber: string;
  role: string;
  isLoadingContactNumber: boolean;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setContactNumber: React.Dispatch<React.SetStateAction<string>>;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  setOldPassword: React.Dispatch<React.SetStateAction<string>>;
  setConfirmPassword: React.Dispatch<React.SetStateAction<string>>;
  setConfirmPasswordError: React.Dispatch<React.SetStateAction<boolean>>;
  setConfirmPasswordErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  setPasswordError: React.Dispatch<React.SetStateAction<boolean>>;
  setPasswordErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  setOldPasswordError: React.Dispatch<React.SetStateAction<boolean>>;
  setOldPasswordErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  nameError: string;
  contactNumberError: boolean;
  contactNumberErrorMessage: string;
  passwordError: boolean;
  passwordErrorMessage: string;
  oldPasswordError: boolean;
  oldPasswordErrorMessage: string;
  confirmPasswordError: boolean;
  confirmPasswordErrorMessage: string;
  isSubmitting: boolean;
  onSubmit: React.FormEventHandler;
  onCancel: React.MouseEventHandler;
  handlePasswordValidation: (password: string) => void;
  handleBlurContactNumber: (inputed: string) => void;
}

const UpdateButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: '#f68920',
    '&:hover': {
      backgroundColor: purple[700]
    }
  }
}))(Button);

const useStyles = makeStyles((theme: Theme) => ({
  controlDiv: {
    '& > :nth-child(n+2)': {
      marginLeft: theme.spacing(3)
    },
    marginTop: theme.spacing(4)
  },
  updateButton: {
    color: '#FFFFFF'
  },
  textFieldFont: {
    height: 18,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2.5)
  },
  inputAdornmentClass: {
    marginLeft: theme.spacing(2)
  }
}));

const EditProfileForm: React.FC<Props> = props => {
  const classes = useStyles();

  const { role } = props;
  const { email, setEmail } = props;
  const { name, setName, nameError } = props;
  const { password, setPassword, passwordError, passwordErrorMessage } = props;
  const { oldPassword, setOldPassword, oldPasswordError, oldPasswordErrorMessage } = props;
  const { confirmPassword, setConfirmPassword, confirmPasswordError, confirmPasswordErrorMessage } = props;
  const { contactNumber, setContactNumber, contactNumberError, contactNumberErrorMessage, isLoadingContactNumber } = props;

  const {
    setConfirmPasswordError,
    setConfirmPasswordErrorMessage,
    setPasswordError,
    setPasswordErrorMessage,
    setOldPasswordError,
    setOldPasswordErrorMessage
  } = props;

  const { isSubmitting, onSubmit, onCancel, handlePasswordValidation, handleBlurContactNumber } = props;

  const [isShowPassword, setShowPassword] = useState<boolean>(false);

  const handlePasswordOnChange = (value: string) => {
    setPassword(value);
    setPasswordError(false);
    setPasswordErrorMessage('');

    if (value) {
      handlePasswordValidation(value);
    }
  };

  const handleConfirmPasswordOnChange = (value: string) => {
    setConfirmPassword(value);
    setConfirmPasswordError(false);
    setConfirmPasswordErrorMessage('');

    if (value) {
      if (value !== password) {
        setConfirmPasswordError(true);
        setConfirmPasswordErrorMessage('Confirm password is not match');
      }
    }
  };

  const handleCurrentPasswordOnChange = (value: string) => {
    setOldPassword(value);
    setOldPasswordError(false);
    setOldPasswordErrorMessage('');

    if (password && !value) {
      setOldPasswordError(true);
      setOldPasswordErrorMessage('Please enter Current Password');
    }
  };

  return (
    <form noValidate>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <TextField variant='outlined' margin='normal' required fullWidth disabled={true} id='role' label='role' value={role} autoComplete='off' />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email'
            disabled={true}
            value={email}
            onChange={event => setEmail(event.target.value)}
            autoComplete='off'
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <TextField
            variant='outlined'
            margin='normal'
            fullWidth
            id='contactNumber'
            label='Contact Number'
            value={contactNumber}
            error={contactNumberError}
            helperText={contactNumberErrorMessage}
            onChange={event => setContactNumber(event.target.value.replace(/\s/g, '').trim())}
            onBlur={event => handleBlurContactNumber(event.target.value.replace(/\s/g, '').trim())}
            autoComplete='off'
            InputProps={{
              classes: {
                input: classes.textFieldFont
              },
              inputComponent: NumberFormatCustom as any,
              startAdornment: (
                <InputAdornment position='start' className={classes.inputAdornmentClass}>
                  +65
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position='end'>
                  {isLoadingContactNumber ? <CircularProgress size={20} /> : <PhoneInTalkIcon color='primary' fontSize='small' />}
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='name'
            label='Name'
            error={nameError !== ''}
            helperText={nameError}
            value={name}
            onChange={event => setName(event.target.value)}
            autoComplete='off'
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='oldpassword'
            label='Current Password'
            type={isShowPassword ? 'text' : 'password'}
            id='oldpassword'
            autoComplete='current-password'
            error={oldPasswordError}
            helperText={oldPasswordErrorMessage}
            onChange={event => handleCurrentPasswordOnChange(event.target.value)}
            value={oldPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton edge='end' aria-label='toggle password visibility' onClick={event => setShowPassword(!isShowPassword)}>
                    {isShowPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='password'
            label='New Password'
            type={isShowPassword ? 'text' : 'password'}
            id='password'
            autoComplete='current-password'
            onChange={event => handlePasswordOnChange(event.target.value)}
            value={password}
            error={passwordError}
            helperText={passwordErrorMessage}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton edge='end' aria-label='toggle password visibility' onClick={event => setShowPassword(!isShowPassword)}>
                    {isShowPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='confirmPassword'
            label='Confirm Password'
            type={isShowPassword ? 'text' : 'password'}
            id='confirmPassword'
            autoComplete='current-password'
            error={confirmPasswordError}
            helperText={confirmPasswordErrorMessage}
            onChange={event => handleConfirmPasswordOnChange(event.target.value)}
            value={confirmPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton edge='end' aria-label='toggle password visibility' onClick={event => setShowPassword(!isShowPassword)}>
                    {isShowPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid container item justify='flex-end' xs={12} sm={12} md={12} lg={12} xl={12} className={classes.controlDiv}>
          <Button variant='contained' onClick={onCancel} disabled={isSubmitting}>
            Cancel
          </Button>
          <UpdateButton onClick={onSubmit} variant='contained' color='primary' className={classes.updateButton} disabled={isSubmitting}>
            Save
          </UpdateButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditProfileForm;
