import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemText,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import { formatCurrency, getPercent, snackCaseToSentence } from 'utils';
import useStyles from './styles';
import JobStatus from 'typings/enum/JobStatus';
import { Info, PriorityHighOutlined } from '@material-ui/icons';

interface Props {
  open: boolean;
  isLoading:boolean;
  isProcess:boolean;
  payload: {
    tasks: number[];
    jobStatus: string;
  };
  jobTasks: JobTask[];
  hasAdditional: boolean;
  totalOvertime?: number;
  totalAdditional?: number;
  totalGstOvertime?: number;
  totalGstAdditional?: number;
  balance: number;
  setStatus: (status: string) => void;
  onClose: () => void;
  onConfirm: () => void;
}

const DialogEdit = ({
  open,
  isProcess,
  isLoading,
  payload,
  jobTasks,
  hasAdditional,
  totalOvertime,
  totalAdditional,
  totalGstOvertime,
  totalGstAdditional,
  balance,
  setStatus,
  onClose,
  onConfirm
}: Props) => {
  const classes = useStyles();
  const firstStatus = payload.tasks.length === 0 ? '' : jobTasks.length > 0 ? jobTasks.find(v => v.id === payload.tasks[0])?.jobTaskStatus || '' : '';

  const renderTask = (tasks: number[], jobTasks: JobTask[]) => {
    return jobTasks
      .filter(v => tasks.includes(v.id))
      .reduce(
        (prev, task) => {
          const temp = {
            driverId: task.DriverId,
            driverName: task.Driver?.User?.displayName || '-',
            vehicleNo: task.Driver?.vehicleNumber || '-',
            totalTask: 1,
            selected: {
              [task.jobTaskStatus]: 1
            }
          };

          const index = prev.findIndex(v => v.driverId === task.DriverId);
          if (index > -1) {
            const current = prev[index];
            const isSelected = current.selected[task.jobTaskStatus];
            if (!!isSelected) {
              prev[index].selected[task.jobTaskStatus] += 1;
            } else {
              prev[index].selected[task.jobTaskStatus] = 1;
            }
            prev[index].totalTask += 1;
          } else {
            prev.push(temp);
          }
          return prev;
        },
        [] as any[]
      );
  };

  const filterStatus = (status: string) => {
    const isProgress = jobTasks.filter(v => payload.tasks.includes(v.id)).some(v => v.jobTaskStatus === JobStatus.IN_PROGRESS);
    const isAssigned = jobTasks.filter(v => payload.tasks.includes(v.id)).some(v => v.jobTaskStatus === JobStatus.ASSIGNED);
    const isAvailable = jobTasks.filter(v => payload.tasks.includes(v.id)).some(v => v.jobTaskStatus === JobStatus.AVAILABLE);
    const isComplete = jobTasks.filter(v => payload.tasks.includes(v.id)).some(v => v.jobTaskStatus === JobStatus.COMPLETED);

    if (isAvailable && isAssigned && isProgress && isComplete) {
      return [JobStatus.CANCELLED];
    } else if (isAvailable && isAssigned) {
      return [JobStatus.CANCELLED];
    } else if (isAvailable && isProgress) {
      return [JobStatus.CANCELLED];
    } else if (isAvailable && isComplete) {
      return [JobStatus.CANCELLED];
    } else if (isAvailable && isAssigned && isProgress) {
      return [JobStatus.CANCELLED];
    } else if (isAvailable && isAssigned && isComplete) {
      return [JobStatus.CANCELLED];
    } else if (isAssigned && isProgress && isComplete) {
      return [JobStatus.CANCELLED];
    } else if (isAssigned && isProgress) {
      return [JobStatus.COMPLETED, JobStatus.AVAILABLE, JobStatus.CANCELLED];
    } else if (isAssigned && isComplete) {
      return [JobStatus.CANCELLED];
    } else {
      if (status === JobStatus.AVAILABLE) {
        return [JobStatus.CANCELLED];
      } else if (status === JobStatus.ASSIGNED) {
        return [JobStatus.COMPLETED, JobStatus.IN_PROGRESS, JobStatus.AVAILABLE, JobStatus.CANCELLED];
      } else if (status === JobStatus.IN_PROGRESS) {
        return [JobStatus.COMPLETED, JobStatus.ASSIGNED, JobStatus.AVAILABLE, JobStatus.CANCELLED];
      } else if (status === JobStatus.COMPLETED) {
        return [JobStatus.CANCELLED];
      } else {
        return [];
      }
    }
  };

  const checkBalance = balance < (totalOvertime || 0) + (totalGstOvertime || 0) + (totalAdditional || 0) + (totalGstAdditional || 0);
  const disableAction = [JobStatus.ASSIGNED, JobStatus.AVAILABLE, JobStatus.CANCELLED].includes(payload.jobStatus as JobStatus);

  return (
    <Dialog open={open} maxWidth='md'>
      <DialogTitle disableTypography style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography component={'div'} variant='h6' style={{ fontWeight: 700 }}>
          Edit Status Task
        </Typography>
      </DialogTitle>

      <DialogContent dividers style={{ width: '800px', padding: '16px' }}>
        <Box display={'flex'} flexDirection={'column'} className={classes.box}>
          <Box>
            <ListItemText
              primary={'Displaying selected task'}
              primaryTypographyProps={{style: {fontWeight: 600}, variant: "h6"}}
              secondary={`${payload.tasks.length} Task Selected from total ${
                jobTasks.filter(v => ![JobStatus.EXPIRED, JobStatus.CANCELLED, JobStatus.DRAFT].includes(v.jobTaskStatus as JobStatus)).length
              } Task`}
            />
           {hasAdditional ?  <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'} style={{ gap: '6px' }}>
              <Info htmlColor='#3788D8' fontSize='small' />
              <Typography component={'div'} style={{ color: '#3788D8' }}>
                Selected task has outstanding additional items and overtime.
              </Typography>
            </Box> : null}
          </Box>
          <Box>
            <Table size='small' className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell component={'th'}>Driver & Company </TableCell>
                  <TableCell component={'th'}>Vehicle No </TableCell>
                  <TableCell component={'th'} align='center'>
                    Total Task
                  </TableCell>
                  <TableCell component={'th'}>Selected Summary </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {renderTask(payload.tasks, jobTasks).map((task, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{task.driverName}</TableCell>
                      <TableCell>{task.vehicleNo}</TableCell>
                      <TableCell align='center'>{task.totalTask}</TableCell>
                      <TableCell>
                        {Object.entries(task.selected)
                          .map(([status, tot]) => `${tot} ${snackCaseToSentence(status)}`)
                          .join(', ')}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>

          {hasAdditional ? (
            <Box>
              <Table size='small'>
                <TableBody>
                  <TableRow>
                    <TableCell>Total Overtime + GST({getPercent(totalGstOvertime || 0, totalOvertime || 0)}%)</TableCell>
                    <TableCell align='right'>
                    {formatCurrency(totalOvertime || 0)}(+{formatCurrency(totalGstOvertime || 0)}) 
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Total Additional Item +  GST({getPercent(totalGstAdditional || 0, totalAdditional || 0)}%)</TableCell>
                    <TableCell align='right'>
                    {formatCurrency(totalAdditional || 0)} (+{formatCurrency(totalGstAdditional || 0)})
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={2} style={{ borderBottom: 0}}>
                      <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'} style={{ gap: '6px' }}>
                        <Info color='error' fontSize='small' />
                        <Typography component={'div'} color='error'>
                          The balance in the JustGo wallet owned by the user is insufficient, kindly ensure to replenish the JustGo wallet credit
                          before proceeding.
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>

                  {checkBalance ? (
                    <TableRow>
                      <TableCell colSpan={3} style={{ borderBottom: 0}}>
                        <PriorityHighOutlined color='error' fontSize='inherit' style={{ marginRight: '2px' }} className={classes.infoIcon} />
                        <Typography component={'span'} color='error'>
                          Merchant credit in e-wallet is not enough, please top up e-wallet first.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              </Table>
            </Box>
          ) : null}

          <Box>
            <ListItemText primary="Change Status" secondary={"Select a status for the designated task"} primaryTypographyProps={{style: {fontWeight: 600}, variant: "h6"}} style={{marginBottom: "16px"}}/>
            <TextField
              select
              value={!!payload.jobStatus ? payload.jobStatus : '00'}
              variant='outlined'
              size='small'
              fullWidth
              label='Status'
              required
              InputLabelProps={{ shrink: true }}
              onChange={e => {
                const value = e.target.value;
                setStatus(value === '00' ? '' : value);
              }}
            >
              <MenuItem value='00'>Select Status</MenuItem>
              {filterStatus(firstStatus).map((v, idx) => (
                <MenuItem key={idx} value={v}>
                  {snackCaseToSentence(v)}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' color='primary' onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isLoading  || (disableAction ? false: checkBalance || !payload.jobStatus || isProcess)} disableElevation variant='contained' color='primary' onClick={onConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogEdit;
