import React, { FC, useCallback, useState, useEffect } from 'react';
import { makeStyles, Theme, Grid, Typography, TextField, Divider, FormControlLabel } from '@material-ui/core';
import LoadingButton from 'components/LoadingButton';
import axios, { CancelTokenSource } from 'axios';
import NumberFormatCustom from 'components/NumberFormatCustom';
import SettingTemplate from 'components/Template/SettingTemplate';
import JobFee from 'typings/enum/JobFee';
import SettingCode from 'typings/enum/SettingCode';
import useSnackbar from 'hooks/useSnackbar';
import Snackbar from 'components/Snackbar';
import { snackCaseToSentence, pick } from 'utils';
import { SETTINGS_URL } from 'constants/url';
import { IOSSwitch } from 'components/CustomSwitch';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  container: {
    '& > :nth-child(n+2)': {
      marginTop: theme.spacing(2)
    }
  },
  title: {
    marginBottom: theme.spacing(1)
  },
  subTitle: {
    color: theme.palette.subtitle.main
  },
  contentContainer: {
    backgroundColor: 'white',
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(2),
    padding: theme.spacing(2)
  },
  divider: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5)
  }
}));

const SettingsPage: FC = () => {
  const classes = useStyles();
  const { openSnacbar, snackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [settings, setSettings] = useState<SettingModel[]>([]);

  const [gst, setGst] = useState<number>(0);
  const [appFee, setAppFee] = useState<number>(0);
  const [minJobPrice, setMinJobPrice] = useState<number>(0);
  const [maxWithdraw, setMaxWithdraw] = useState<number>(0);
  const [minTopup, setMinTopup] = useState<number>(0);
  const [maxTopup, setMaxTopup] = useState<number>(0);
  const [jobFee, setJobFee] = useState<{ [key: string]: number }>({
    DAYWORKS_FEE: 0,
    TRIPWORKS_FEE: 0,
    OU_WORKS_FEE: 0
  });
  const [dayworkPrice, setDayworkPrice] = useState<{ [key: string]: number }>({
    DAYWORKS_MAX_PRICE: 0,
    DAYWORKS_MIN_PRICE: 0
  });
  const [tripwork, setTripwork] = useState({
    TRIPWORKS_PRICE_PER_KM: 0,
    PRICE_DISTANCE_TRIP_1: 0,
    PRICE_DISTANCE_TRIP_2: 0,
    MIN_DISTANCE_TRIP_1: 0,
    MIN_DISTANCE_TRIP_2: 0
  });

  const [creditWallet, setCreditWallet] = useState(0);

  const fetchData = useCallback(async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    setLoading(true);
    try {
      const { data } = await axios.get(SETTINGS_URL, { cancelToken: cancelTokenSource.token });
      setSettings(data.Settings);
      setGst(data.Settings.find((s: any) => s.code === 'GST').value);
      setAppFee(data.Settings.find((s: any) => s.code === 'APP_FEE').value);
      setMinJobPrice(data.Settings.find((s: any) => s.code === 'MIN_JOB_PRICE').value);
      setMaxWithdraw(data.Settings.find((s: any) => s.code === 'MAX_WITHDRAW').value);
      setMinTopup(data.Settings.find((s: any) => s.code === 'MIN_TOPUP').value);
      setMaxTopup(data.Settings.find((s: any) => s.code === 'MAX_TOPUP').value);
      setJobFee(state => ({
        ...state,
        ...data.Settings.filter((s: any) => Object.keys(JobFee).includes(s.code)).reduce(
          (prev: any, next: any) => ({ ...prev, ...{ [next.code]: +next.value } }),
          state
        )
      }));

      setDayworkPrice(state => ({
        ...state,
        ...data.Settings.filter((s: any) => [SettingCode.DAYWORKS_MAX_PRICE, SettingCode.DAYWORKS_MIN_PRICE].includes(s.code)).reduce(
          (prev: any, next: any) => ({ ...prev, ...{ [next.code]: +next.value } }),
          state
        )
      }));

      setTripwork(state => ({
        ...state,
        ...data.Settings.filter((s: any) => Object.keys(state).includes(s.code)).reduce(
          (prev: any, next: any) => ({ ...prev, ...{ [next.code]: +next.value } }),
          state
        )
      }));

      setCreditWallet(Number(data.Settings.find((s: any) => s.code === 'CREDIT_WALLET').value));
    } catch (err) {
      console.error('err: ', err);
    }
    setLoading(false);
    return () => cancelTokenSource.cancel();
  }, []);

  const handleSubmit = async () => {
    const newSettings = settings;
    newSettings[settings.findIndex((s: any) => s.code === 'GST')].value = String(gst);
    newSettings[settings.findIndex((s: any) => s.code === 'APP_FEE')].value = String(appFee);
    newSettings[settings.findIndex((s: any) => s.code === 'MIN_JOB_PRICE')].value = String(minJobPrice);
    newSettings[settings.findIndex((s: any) => s.code === 'MAX_WITHDRAW')].value = String(maxWithdraw);
    newSettings[settings.findIndex((s: any) => s.code === 'MIN_TOPUP')].value = String(minTopup);
    newSettings[settings.findIndex((s: any) => s.code === 'MAX_TOPUP')].value = String(maxTopup);
    newSettings[settings.findIndex((s: any) => s.code === 'CREDIT_WALLET')].value = String(creditWallet);
    Object.entries({
      ...jobFee,
      ...dayworkPrice,
      ...pick(tripwork, [SettingCode.PRICE_DISTANCE_TRIP_1, SettingCode.PRICE_DISTANCE_TRIP_2, SettingCode.TRIPWORKS_PRICE_PER_KM])
    }).forEach(([k, v]) => {
      newSettings[settings.findIndex((s: any) => s.code === k)].value = String(v);
    });

    setLoading(true);
    try {
      const { data } = await axios.post(SETTINGS_URL, { settings: newSettings });
      setSettings(data);
      openSnacbar('Update Success');
    } catch (error) {
      openSnacbar('Update Error', {
        variant: 'error'
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <SettingTemplate title='Setting Page' subtitle='All general settings' primaryButton={false} refreshButton={false}>
      <Grid container direction='row'>
        <Grid container direction='row' item xs={12} sm={12} md={12} lg={12} alignItems='center'>
          <Grid item xs={12} sm={12} md={12} lg={12} className={classes.contentContainer} container spacing={1} direction='row' justify='center'>
            <Grid item xs={6} sm={6} md={6} lg={6} container direction='column' justify='flex-start' alignItems='flex-start'>
              <Typography variant='h5' className={classes.title}>
                GST rate %
              </Typography>
              <Typography variant='body1' className={classes.subTitle}>
                Chargeable sales of goods and services for GST-registered businesses
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} container justify='center' alignItems='center'>
              <TextField
                id='gst'
                value={gst}
                onChange={event => setGst(+event.target.value.trim())}
                label='Input your GST %'
                variant='outlined'
                size='small'
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.divider}>
              <Divider />
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6} container direction='column' justify='flex-start' alignItems='flex-start'>
              <Typography variant='h5' className={classes.title}>
                Platform fee %
              </Typography>
              <Typography variant='body1' className={classes.subTitle}>
                JustGo app service fee charge to consumers for using the platform
              </Typography>
            </Grid>
            {Object.keys(JobFee).map((v: string, i) => (
              <Grid key={i + 1} item xs={2} sm={2} md={2} lg={2} container justify='center' alignItems='center'>
                <TextField
                  id={v}
                  name={v}
                  value={jobFee[v]}
                  onChange={event => {
                    event.persist();
                    if (Number.isInteger(+event.target.value)) {
                      setJobFee(state => ({ ...state, [v]: +event.target.value }));
                    }
                  }}
                  label={`${snackCaseToSentence(v)} (%)`}
                  variant='outlined'
                  size='small'
                  fullWidth={true}
                />
              </Grid>
            ))}
            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.divider}>
              <Divider />
            </Grid>
            {/* Tripwork */}
            <Grid item xs={6} sm={6} md={6} lg={6} container direction='column' justify='flex-start' alignItems='flex-start'>
              <Typography variant='h5' className={classes.title}>
                Minimum price per trip
              </Typography>
              <Typography variant='body1' className={classes.subTitle}>
                Minimum price or trip when create job for tripwork
              </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} container justify='center' alignItems='center'>
              <TextField
                variant='outlined'
                label={`Distance < ${tripwork[SettingCode.MIN_DISTANCE_TRIP_1]} Km`}
                size='small'
                fullWidth={true}
                value={tripwork[SettingCode.PRICE_DISTANCE_TRIP_1]}
                onChange={event => setTripwork(state => ({ ...state, [SettingCode.PRICE_DISTANCE_TRIP_1]: +event.target.value }))}
                InputProps={{
                  inputComponent: NumberFormatCustom as any,
                  inputProps: {
                    prefix: '$',
                    thousandSeparator: true,
                    decimalScale: 2,
                    min: 0
                  }
                }}
              />
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} container justify='center' alignItems='center'>
              <TextField
                variant='outlined'
                label={`Distance < ${tripwork[SettingCode.MIN_DISTANCE_TRIP_2]} Km`}
                size='small'
                fullWidth={true}
                value={tripwork[SettingCode.PRICE_DISTANCE_TRIP_2]}
                onChange={event => setTripwork(state => ({ ...state, [SettingCode.PRICE_DISTANCE_TRIP_2]: +event.target.value }))}
                InputProps={{
                  inputComponent: NumberFormatCustom as any,
                  inputProps: {
                    prefix: '$',
                    thousandSeparator: true,
                    decimalScale: 2,
                    min: 0
                  }
                }}
              />
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} container justify='center' alignItems='center'>
              <TextField
                label={`Distance > ${tripwork[SettingCode.MIN_DISTANCE_TRIP_2]} Km`}
                variant='outlined'
                size='small'
                fullWidth={true}
                value={tripwork[SettingCode.TRIPWORKS_PRICE_PER_KM]}
                onChange={event => setTripwork(state => ({ ...state, [SettingCode.TRIPWORKS_PRICE_PER_KM]: +event.target.value }))}
                InputProps={{
                  endAdornment: '/Km',
                  inputComponent: NumberFormatCustom as any,
                  inputProps: {
                    prefix: '$',
                    thousandSeparator: true,
                    decimalScale: 2,
                    min: 0
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.divider}>
              <Divider />
            </Grid>
            {/** Dayworks */}
            <Grid item xs={6} sm={6} md={6} lg={6} container direction='column' justify='flex-start' alignItems='flex-start'>
              <Typography variant='h5' className={classes.title}>
                Minimum price per vehicle
              </Typography>
              <Typography variant='body1' className={classes.subTitle}>
                Minimum price create job for dayworks
              </Typography>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} container justify='center' alignItems='center'>
              <TextField
                value={dayworkPrice[SettingCode.DAYWORKS_MAX_PRICE]}
                onChange={event => {
                  if (Number.isInteger(+event.target.value)) {
                    setDayworkPrice(state => ({ ...state, ...{ [SettingCode.DAYWORKS_MAX_PRICE]: +event.target.value } }));
                  }
                }}
                label='Day Works for 8 hours'
                variant='outlined'
                size='small'
                fullWidth={true}
                InputProps={{
                  inputComponent: NumberFormatCustom as any,
                  inputProps: {
                    prefix: '$',
                    thousandSeparator: true,
                    decimalScale: 2
                  }
                }}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} container justify='center' alignItems='center'>
              <TextField
                value={dayworkPrice[SettingCode.DAYWORKS_MIN_PRICE]}
                onChange={event => {
                  if (Number.isInteger(+event.target.value)) {
                    setDayworkPrice(state => ({ ...state, ...{ [SettingCode.DAYWORKS_MIN_PRICE]: +event.target.value } }));
                  }
                }}
                label='Day Works for 4 hours'
                variant='outlined'
                size='small'
                fullWidth={true}
                InputProps={{
                  inputComponent: NumberFormatCustom as any,
                  inputProps: {
                    prefix: '$',
                    thousandSeparator: true,
                    decimalScale: 2
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.divider}>
              <Divider />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} container direction='column' justify='flex-start' alignItems='flex-start'>
              <Typography variant='h5' className={classes.title}>
                Maximum e-wallet balance withdrawal
              </Typography>
              <Typography variant='body1' className={classes.subTitle}>
                Withdrawal limit amount for e-wallet
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} container justify='center' alignItems='center'>
              <TextField
                id='maxWithdraw'
                value={maxWithdraw}
                onChange={event => setMaxWithdraw(+event.target.value.trim())}
                label='Input your withdrawal limit'
                variant='outlined'
                size='small'
                fullWidth={true}
                InputProps={{
                  inputComponent: NumberFormatCustom as any,
                  inputProps: {
                    prefix: '$',
                    thousandSeparator: true,
                    decimalScale: 2
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.divider}>
              <Divider />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} container direction='column' justify='flex-start' alignItems='flex-start'>
              <Typography variant='h5' className={classes.title}>
                Minimum e-wallet top up amount
              </Typography>
              <Typography variant='body1' className={classes.subTitle}>
                Minimum top up amount for e-wallet
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} container justify='center' alignItems='center'>
              <TextField
                id='minTopup'
                value={minTopup}
                onChange={event => setMinTopup(+event.target.value.trim())}
                label='Input your min top up amount'
                variant='outlined'
                size='small'
                fullWidth={true}
                InputProps={{
                  inputComponent: NumberFormatCustom as any,
                  inputProps: {
                    prefix: '$',
                    thousandSeparator: true,
                    decimalScale: 2
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.divider}>
              <Divider />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} container direction='column' justify='flex-start' alignItems='flex-start'>
              <Typography variant='h5' className={classes.title}>
                Maximum e-wallet top up amount
              </Typography>
              <Typography variant='body1' className={classes.subTitle}>
                Top up limit amount for e-wallet
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} container justify='center' alignItems='center'>
              <TextField
                id='maxTopup'
                value={maxTopup}
                onChange={event => setMaxTopup(+event.target.value.trim())}
                label='Input your top up  limit'
                variant='outlined'
                size='small'
                fullWidth={true}
                InputProps={{
                  inputComponent: NumberFormatCustom as any,
                  inputProps: {
                    prefix: '$',
                    thousandSeparator: true,
                    decimalScale: 2
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.divider}>
              <Divider />
            </Grid>

            {/* For Experimetal */}
            <Grid item xs={6} sm={6} md={6} lg={6} container direction='column' justify='flex-start' alignItems='flex-start'>
              <Typography variant='h5' className={classes.title}>
                Credit Term
              </Typography>
              <Typography variant='body1' className={classes.subTitle}>
               Enable or disable credit term feature for all users
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} container justify='flex-end' alignItems='center'>
              <FormControlLabel
                label={creditWallet ? 'Active' : 'Inactive'}
                control={<IOSSwitch checked={!!creditWallet} onChange={(e, checked) => setCreditWallet(+checked)} />}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.divider}>
              <Divider />
            </Grid>
            {/* End Experimental */}

            <Grid item xs={12} sm={12} md={12} lg={12} container justify='flex-end' alignItems='center'>
              <LoadingButton delay={0} variant='contained' color='primary' isLoading={loading} onClick={handleSubmit} disableElevation>
                Save Changes
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Snackbar {...snackbar} />
    </SettingTemplate>
  );
};

export default SettingsPage;
