import React, { FC } from 'react';
import clsx from 'clsx';
import { Container, makeStyles, Theme, Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  container: {
    '& > :nth-child(n+2)': {
      marginTop: theme.spacing(2)
    }
  }
}));

const AnalyticsPage: FC = () => {
  const classes = useStyles();

  return (
    <Container maxWidth='lg' className={clsx(classes.root, classes.container)}>
      <Grid container direction='row' justify='space-between'>
        <Grid container direction='row' item xs={8} sm={8} md={8} lg={8} alignItems='center' spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant='h4'>Analytics</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AnalyticsPage;
