import React, { FC } from 'react';
import { allTaskOneDriverType, formatCurrency, getAppFee, getHours, getPercent, rounding } from 'utils';
import { Theme, makeStyles, Table, TableBody, TableHead, TableRow, TableCell, Paper, TableContainer, Typography, Divider } from '@material-ui/core';
import clsx from 'clsx';
import JobType from 'typings/enum/JobType';
import JobStatus from 'typings/enum/JobStatus';
import TruckType from 'typings/enum/TruckType';
import { dummyJobTask } from 'constants/DummyData';

interface Props {
  job: JobModel;
}

const useStyles = makeStyles((theme: Theme) => ({
  rootTabs: {
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
    borderRadius: '5px',
    marginBottom: '24px'
  },
  title: {
    padding: '16px'
  },
  subtotal: {
    paddingRight: 0,
    width: '10%',
    whiteSpace: 'nowrap'
  },
  unitPrice: {
    width: '20%'
  },
  qty: {
    width: '15%'
  },
  disabledBorderBottom: {
    borderBottom: 'none'
  },
  orangeTitle: {
    color: theme.palette.primary.main
  }
}));

const PriceTable: FC<Props> = ({ job }) => {
  const classes = useStyles();
  const tasks = job.JobTasks || [];
  const isDriverGst = !!job.gstAmount && tasks.some(v => !!v.isDriverGst);
  const priceDetails: PriceData[] = [];
  const task = (job.JobTasks && job.JobTasks[0]) || dummyJobTask;
  const selectedStatus = [JobStatus.ASSIGNED, JobStatus.IN_PROGRESS, JobStatus.COMPLETED];

  let price = job.price * (job.jobType === JobType.DAYWORKS ? job.hours / 60 : 1);
  let originalPrice = job.price;
  let subtotal = job.originalAmount || 0;
  let netEarn = job.totalEarn || 0;
  let hours = job.hours / 60;
  let qty = job.totalTask || 0;
  let appFee = task.appFee || 0;
  let gst = task.originalGstTask || 0;
  let totalGstAmount = 0;

  if (allTaskOneDriverType.includes(job.truckType as TruckType)) {
    netEarn = tasks.reduce((a, b) => a + (b.netEarn || 0), 0);
    totalGstAmount = tasks.reduce((a, b) => a + (b.gstTaskAmount || 0), 0);
    appFee = task.originalAppFee || 0;
    qty = tasks.length;
  } else {
    totalGstAmount = task.gstTaskAmount || 0;
    netEarn = task.netEarn || 0;
  }

  const editHours: JobTask[] = tasks.filter(v => selectedStatus.includes(v.jobTaskStatus as JobStatus) && v.additionalStatus === 'CONFIRM');
  const additionals: JobAdditional[] = (job.JobAdditionals || []).filter((v: JobAdditional) => v.status === 'CONFIRM');
  const hasAdditional = additionals.some(v => v.hours > 0)

  priceDetails.push({
    type: 'data',
    qty: job.jobType === JobType.DAYWORKS ? `${qty}(${hours === 4 ? 'Half Day' : 'Full Day'})` : String(qty),
    subtotal: formatCurrency(subtotal - appFee * qty),
    price: `${formatCurrency(price)} ${job.jobType === JobType.DAYWORKS ? `(${formatCurrency(originalPrice)}/hours)` : ''}`,
    appFee: formatCurrency(appFee),
    gst: formatCurrency(gst),
    totalGst: `(+${formatCurrency(gst * qty)})`,
    description: `Total Task`
  });

  if (hasAdditional  && job.truckType === TruckType.LORRY_CRANE) {
    const totalHours = getHours(additionals.reduce((prev, next) => prev + next.hours, 0))
    const hourGst = job.JobAdditionalSummary?.totalGSTOvertime ? job.JobAdditionalSummary?.totalGSTOvertime / totalHours : 0
    const oTFee = task.appFee && task.originalAppFee ? (task.appFee - task.originalAppFee ) * qty / totalHours: 0
    const totalOTFee = oTFee * totalHours

    priceDetails.push({
      type: 'data',
      qty: `${String(additionals.reduce((prev, next) => prev + next.hours, 0) / 60)} Hour(s)`,
      subtotal: formatCurrency((job.JobAdditionalSummary?.totalOverTimePrice || 0) - totalOTFee),
      price: formatCurrency(Number(job.overtimePrice || 0)),
      appFee: formatCurrency(oTFee),
      gst: formatCurrency(hourGst),
      totalGst: formatCurrency(job.JobAdditionalSummary?.totalGSTOvertime || 0),
      description: 'Over Time(s)'
    });
    subtotal += job.JobAdditionalSummary?.totalOverTimePrice || 0;
  }

  if (hasAdditional && job.truckType === TruckType.TRAILER) {
    const taskPercentFee =  getPercent(task.originalAppFee || 0, task.originalEarn || 0);
    additionals.filter(v => v.hours > 0).forEach((adt) => {
    const perHour = getHours(adt.hours)
    const otFee = rounding((getAppFee((task.originalEarn || 0) + adt.totalPrice, taskPercentFee) - appFee) / perHour) 
    const totalFee = otFee * perHour

      priceDetails.push({
        type: 'data',
        appFee: formatCurrency(otFee),
        qty:`${perHour} hour(s)`,
        subtotal: formatCurrency(adt.totalPrice - totalFee),
        price: formatCurrency(adt.hourPrice ),
        description: 'Over Time(s)',
        gst: formatCurrency(adt.gstTotalPrice/perHour),
        totalGst: formatCurrency(adt.gstTotalPrice)
      });
    })
    subtotal += job.JobAdditionalSummary?.totalOverTimePrice || 0;
  }

  if (job.JobAdditionalSummary?.totalItemPrice && job.JobAdditionalSummary?.totalItemPrice > 0) {
    netEarn -= job.JobAdditionalSummary?.totalItemPrice || 0;
    if (isDriverGst) {
      totalGstAmount += job.JobAdditionalSummary?.totalGSTItemPrice;
    }
  }

  if (job.JobAdditionalSummary?.totalGSTOvertime && job.JobAdditionalSummary?.totalGSTOvertime > 0 && isDriverGst) {
    totalGstAmount += job.JobAdditionalSummary.totalGSTOvertime;
  }  

  if (editHours.length > 0) {
    priceDetails.push({
      type: 'title',
      qty: '',
      subtotal: '',
      price: '',
      appFee: formatCurrency(0),
      gst: formatCurrency(0),
      totalGst: '',
      description: 'Edit Hour(s)'
    });
    
    editHours.forEach(v => {
      priceDetails.push({
        type: 'additional',
        qty: `${(v.additionalHour || 0) / 60} Hour(s)`,
        subtotal: formatCurrency(v.additionalAmount || 0),
        price: formatCurrency(v.additionalAmount || 0),
        gst: formatCurrency(job.jobStatus === JobStatus.COMPLETED ? (isDriverGst ?   v.additionalGstAmount || 0 : 0) : v.additionalGstAmount || 0),
        totalGst: `(${v.additionalGstAmount && v.additionalGstAmount > 0 ? '+' : ''} ${formatCurrency(v.additionalGstAmount || 0)})`,
        description: `Task #${v.id}`,
        appFee: '-',
        addType: (v.additionalHour || 0) < 0 ? '+' : '-'
      });
      subtotal += v.additionalAmount || 0;
    });
  }

  return (
    <Paper elevation={0} className={classes.rootTabs}>
      <Typography variant='h4' className={classes.title}>
        Price Detail
      </Typography>
      <Divider />
      <TableContainer style={{ boxShadow: 'none' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell component='th'>Description</TableCell>
              <TableCell component='th' align='center' className={classes.unitPrice}>
                Unit Price
              </TableCell>
              <TableCell component='th' align='center' className={classes.unitPrice}>
                GST {getPercent(job.gstAmount || 0, job.originalAmount || 0)}%
              </TableCell>
              <TableCell component='th' align='center' className={classes.unitPrice}>
                App Fee {job.percentAppFee}%
              </TableCell>
              <TableCell component='th' align='center' className={classes.qty}>
                QTY
              </TableCell>
              <TableCell component='th' align='right' className={classes.subtotal}>
                Subtotal
              </TableCell>
              <TableCell component='th' style={{ maxWidth: '2%', width: '5%' }}>
                &nbsp;
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {priceDetails.map((v, i) =>
              v.type === 'title' ? (
                <TableRow key={i + '-key'}>
                  <TableCell size='small' colSpan={5} style={{ borderBottom: 0 }}>
                    {v.description}
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow key={i + '-key'}>
                  <TableCell
                    size={v.type === 'additional' ? 'small' : 'medium'}
                    style={{
                      ...(v.type === 'additional' && i !== priceDetails.length - 1 ? { borderBottom: 0 } : {}),
                      whiteSpace: 'nowrap',
                      paddingRight: '8px'
                    }}
                  >
                    {v.description}
                  </TableCell>
                  <TableCell
                    size={v.type === 'additional' ? 'small' : 'medium'}
                    style={{
                      ...(v.type === 'additional' && i !== priceDetails.length - 1 ? { borderBottom: 0 } : {}),
                      whiteSpace: 'nowrap',
                      paddingRight: '8px'
                    }}
                    align='right'
                  >
                    {v.price}
                  </TableCell>
                  <TableCell
                    size={v.type === 'additional' ? 'small' : 'medium'}
                    style={{
                      ...(v.type === 'additional' && i !== priceDetails.length - 1 ? { borderBottom: 0 } : {}),
                      whiteSpace: 'nowrap',
                      paddingRight: '8px'
                    }}
                    align='center'
                  >
                    {v.gst}
                  </TableCell>

                  <TableCell
                    size={v.type === 'additional' ? 'small' : 'medium'}
                    style={{
                      ...(v.type === 'additional' && i !== priceDetails.length - 1 ? { borderBottom: 0 } : {}),
                      whiteSpace: 'nowrap',
                      paddingRight: '8px'
                    }}
                    align='center'
                  >
                    {v.type === 'additional' ? '' :v.appFee}
                  </TableCell>
                  <TableCell
                    size={v.type === 'additional' ? 'small' : 'medium'}
                    style={{
                      ...(v.type === 'additional' && i !== priceDetails.length - 1 ? { borderBottom: 0 } : {}),
                      whiteSpace: 'nowrap',
                      paddingRight: '8px'
                    }}
                    align='center'
                  >
                    {v.qty}
                  </TableCell>
                  <TableCell
                    size={v.type === 'additional' ? 'small' : 'medium'}
                    style={{
                      ...(v.type === 'additional' && i !== priceDetails.length - 1 ? { borderBottom: 0 } : {}),
                      whiteSpace: 'nowrap',
                      paddingRight: '8px'
                    }}
                    align='right'
                    className={classes.subtotal}
                  >
                    {v.subtotal}
                  </TableCell>
                  <TableCell
                    size={v.type === 'additional' ? 'small' : 'medium'}
                    style={{
                      ...(v.type === 'additional' && i !== priceDetails.length - 1 ? { borderBottom: 0 } : {}),
                      whiteSpace: 'nowrap',
                      paddingRight: '8px'
                    }}
                  >
                    {isDriverGst ? v.totalGst : null}
                  </TableCell>
                </TableRow>
              )
            )}

            <TableRow>
              <TableCell colSpan={5} size='small' align='right' className={clsx(classes.disabledBorderBottom)}>
                Subtotal {isDriverGst ? `+ GST ${getPercent(job.gstAmount || 0, job.originalAmount || 0)}%` : ''}
              </TableCell>
              <TableCell
                align='right'
                size='small'
                className={clsx(classes.disabledBorderBottom, { [classes.orangeTitle]: !allTaskOneDriverType.includes(job.truckType as TruckType) }, classes.subtotal)}
              >
                <strong>{formatCurrency(netEarn)}</strong>
              </TableCell>
              <TableCell
                align='left'
                size='small'
                style={{ whiteSpace: 'nowrap' }}
                className={clsx(classes.disabledBorderBottom, { [classes.orangeTitle]: !allTaskOneDriverType.includes(job.truckType as TruckType) })}
              >
                {isDriverGst ? (
                  <>
                    <strong>(+{formatCurrency((totalGstAmount || 0) - (job.JobAdditionalSummary?.totalGSTItemPrice || 0))})</strong>
                  </>
                ) : null}
              </TableCell>
            </TableRow>

            {allTaskOneDriverType.includes(job.truckType as TruckType) ? (
              <>
                <TableRow>
                  <TableCell colSpan={4} size='small' className={clsx(classes.disabledBorderBottom)}>
                    &nbsp;
                  </TableCell>
                  <TableCell align='right' style={{ whiteSpace: 'nowrap' }}>
                    Total Additional Item {isDriverGst ? `+ GST ${getPercent(job.gstAmount || 0, job.originalAmount || 0)}%` : ''}
                  </TableCell>
                  <TableCell align='right' className={clsx(classes.subtotal)}>
                    <strong> {formatCurrency(job.JobAdditionalSummary?.totalItemPrice || 0)}</strong>
                  </TableCell>
                  <TableCell align='left' size='small' style={{ whiteSpace: 'nowrap' }} className={clsx(classes.subtotal)}>
                    {isDriverGst && job.JobAdditionalSummary && job.JobAdditionalSummary.totalGSTItemPrice > 0 ? (
                      <>
                        <strong>(+{formatCurrency(job.JobAdditionalSummary?.totalGSTItemPrice || 0)})</strong>
                      </>
                    ) : null}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell colSpan={4} size='small' className={clsx(classes.disabledBorderBottom)}>
                    &nbsp;
                  </TableCell>
                  <TableCell align='right'>Total Earn</TableCell>
                  <TableCell align='right' className={clsx(classes.disabledBorderBottom, classes.orangeTitle, classes.subtotal)}>
                    <strong> {formatCurrency(netEarn + (job.JobAdditionalSummary?.totalItemPrice || 0))}</strong>
                  </TableCell>
                  <TableCell
                    align='left'
                    size='small'
                    style={{ whiteSpace: 'nowrap' }}
                    className={clsx(classes.disabledBorderBottom, classes.orangeTitle, classes.subtotal)}
                  >
                    {isDriverGst ? (
                      <>
                        <strong>(+{formatCurrency(totalGstAmount || 0)})</strong>
                      </>
                    ) : null}
                  </TableCell>
                </TableRow>
              </>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default PriceTable;
