import React, { FC } from 'react';
import MainTemplate from 'components/Template/MainTemplate';
import { WEB_INVOICES_BASE_URL } from 'constants/url';
import InvoiceTable from './InvoiceTable';
import useTablePagination from 'hooks/useTablePagination';
import InvoiceFileModal from './InvoiceFileModal';
import DoFileModal from './DoFileModal';
import { ClickAwayListener } from '@material-ui/core';
import DateTimeRangePicker from 'components/DateTimeRangePicker';
import PositionedPopper from 'components/PositionedPopper';

const InvoicesPage: FC = () => {
  const clearRef = React.useRef<HTMLButtonElement | null>(null);
  const closeRef = React.useRef<HTMLButtonElement | null>(null);

  const [open, setOpen] = React.useState({ invoice: false, do: false });
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [openCalendar, setOpenCalendar] = React.useState(false);
  const [dateRange, setDateRange] = React.useState('');
  const [dateBy, setDateBy] = React.useState('');
  const [invoice, setInvoice] = React.useState(0);
  const [isGst, setIsGst]  =React.useState(false)
  const [invoiceNo, setInvoiceNo] = React.useState('');
  const table = useTablePagination<InvoiceList>(WEB_INVOICES_BASE_URL, {
    selector: resp => resp.rows,
    sort: { order: 'desc', orderBy: 'id' }
  });

  const refreshList = () => {
    table.clear();
    setDateRange('')
    setDateBy('');
    clearRef.current?.click();
    closeRef.current?.click();
  };

  const onOpenInvoice = (id: number, no: string, gst:boolean) => () => {
    setInvoice(id);
    setInvoiceNo(no);
    setOpen(p => ({ ...p, invoice: true }));
    setIsGst(gst)
  };

  const onOpenDo = (id: number, no: string) => () => {
    setInvoice(id);
    setInvoiceNo(no);
    setOpen(p => ({ ...p, do: true }));
  };

  const onCloseInvoiceModal = () => {
    setInvoice(0);
    setInvoiceNo('');
    setOpen({ invoice: false, do: false });
  };

  const handleTimeChange = (query: string) => {
    const splitted = query.split('|');
    const removedTime = splitted.map(dt => dt.substring(0, 10));
    setDateRange(removedTime.join(' ~ '));
    table.setQuery({ startDate: removedTime[0], endDate: removedTime[1] });
  };

  const handleCalendar = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenCalendar(true);
  };

  return (
    <MainTemplate
      title='Invoice List'
      subtitle='All invoice for driver and merchant'
      primaryButton={false}
      refreshButtonProps={{ onClick: refreshList }}
    >
      <InvoiceTable {...table} dateRange={dateRange} handleCalendar={handleCalendar} onOpenInvoice={onOpenInvoice} onOpenDo={onOpenDo} />
      <ClickAwayListener onClickAway={() => setOpenCalendar(false)} mouseEvent='onMouseDown' touchEvent='onTouchStart'>
        <div>
          <PositionedPopper
            open={openCalendar}
            anchorEl={anchorEl}
            placement='bottom'
            containerWidth={200}
            fadeTransition={350}
            keepMounted={openCalendar}
          >
            <DateTimeRangePicker
              mode='date'
              currentValue={dateRange}
              dateBy={dateBy}
              clearRef={clearRef}
              closeRef={closeRef}
              disabledCustomDate={dateBy !== '5'}
              setOpenPopper={setOpenCalendar}
              options={[
                { key: '1', label: 'Today' },
                { key: '2', label: 'Tomorrow' },
                { key: '3', label: 'This Week' },
                { key: '4', label: 'This Month' },
                { key: '5', label: 'Custom Date' }
              ]}
              onChange={handleTimeChange}
              onSelectedChange={value => setDateBy(value)}
              onClear={() => {
                setDateBy('');
              }}
            />
          </PositionedPopper>
        </div>
      </ClickAwayListener>

      <InvoiceFileModal invoiceId={invoice} invoiceNo={invoiceNo} open={open.invoice} isGst={isGst} handleClose={onCloseInvoiceModal} />
      <DoFileModal invoiceId={invoice} invoiceNo={invoiceNo} open={open.do} handleClose={onCloseInvoiceModal} />
    </MainTemplate>
  );
};

export default InvoicesPage;
