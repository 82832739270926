import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      borderRadius: '12px',
      paddingTop: '16px'
    }
  },
  content: {
    minWidth: '363px',
    maxWidth: '443px'
  },
  box: {
    paddingBottom: '24px'
  },
  boxImage: {
    textAlign: 'center',
    marginBottom: '16px'
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0px 0px 24px 0px'
  },
  rightButton: {
    textTransform: 'none'
  },
  leftButton: {
    border: `1.5px solid ${theme.palette.orange['500']}`,
    borderRadius: '5px',
    textTransform: 'none'
  },
  boxNominal: {
    marginBottom: '24px'
  }
}));

export default useStyles;
