import React, { FC } from 'react';
import {
  Container,
  makeStyles,
  Theme,
  Grid,
  FormControl,
  TextField,
  ButtonGroup,
  ButtonBase,
  CircularProgress,
  InputAdornment
} from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import NoteAddIcon from '@material-ui/icons/NoteAdd';

interface Props {
  inputCompanyName: string;
  inputCompanyUen: string;
  inputCompanyFile: string;
  inputVehicleNumber: string;
  inputVehicleLogCard: string;
  errorCompanyName: string;
  errorCompanyUen: string;
  errorCompanyFile: string;
  errorVehicleNumber: string;
  errorVehicleLogCard: string;
  isLoadingUen: boolean;
  setInputCompanyName: React.Dispatch<React.SetStateAction<string>>;
  setInputCompanyUen: React.Dispatch<React.SetStateAction<string>>;
  setInputCompanyFile: React.Dispatch<React.SetStateAction<string>>;
  setInputVehicleNumber: React.Dispatch<React.SetStateAction<string>>;
  setInputVehicleLogCard: React.Dispatch<React.SetStateAction<string>>;
  setUploadCompanyFile: React.Dispatch<React.SetStateAction<string>>;
  setUploadVehicleFile: React.Dispatch<React.SetStateAction<string>>;
  setErrorCompanyFile: React.Dispatch<React.SetStateAction<string>>;
  setErrorVehicleLogCard: React.Dispatch<React.SetStateAction<string>>;
  handleBlurCompanyName: (inputed: string) => void;
  handleBlurCompanyUen: (inputed: string) => void;
  handleBlurVehicleNumber: (inputed: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  container: {
    '& > :nth-child(n+2)': {
      marginTop: theme.spacing(2)
    },
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(2)
    }
  },
  formGroupContainer: {
    paddingRight: theme.spacing(1),
    paddingleft: theme.spacing(1)
  },
  formGroupContainerCenter: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  },
  button: {
    marginLeft: theme.spacing(1)
  },
  buttonLink: {
    cursor: 'pointer',
    color: theme.palette.link.main
  },
  inputLabel: {
    fontWeight: 500
  }
}));

const CompanyStep: FC<Props> = props => {
  const classes = useStyles();

  const {
    inputCompanyName,
    inputCompanyUen,
    inputCompanyFile,
    inputVehicleNumber,
    inputVehicleLogCard,
    isLoadingUen
  } = props;

  const {
    errorCompanyName,
    errorCompanyUen,
    errorCompanyFile,
    errorVehicleNumber,
    errorVehicleLogCard
  } = props;

  const {
    setInputCompanyName,
    setInputCompanyUen,
    setInputCompanyFile,
    setInputVehicleNumber,
    setInputVehicleLogCard,
    setUploadCompanyFile,
    setUploadVehicleFile,
    setErrorCompanyFile,
    setErrorVehicleLogCard,
    handleBlurCompanyName,
    handleBlurCompanyUen,
    handleBlurVehicleNumber
  } = props;

  const handleChangeFile = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const originalname = event.target.files[0].name;
      const extention = originalname.split('.').pop();
      const filename = `${Date.now()}.${extention}`;

      if (extention === 'pdf' || extention === 'png' || extention === 'jpg' || extention === 'jpeg') {
        setUploadCompanyFile(event.target.files[0]);
        setInputCompanyFile(filename);
        setErrorCompanyFile('');
      } else {
        setErrorCompanyFile('File extention must be one of *.pdf, *.png, *.jpg, *.jpeg');
      }
    }
  };

  const handleChangeVehicleFile = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const originalname = event.target.files[0].name;
      const extention = originalname.split('.').pop();
      const filename = `${Date.now()}.${extention}`;

      if (extention === 'pdf' || extention === 'png' || extention === 'jpg' || extention === 'jpeg') {
        setUploadVehicleFile(event.target.files[0]);
        setInputVehicleLogCard(filename);
        setErrorVehicleLogCard('');
      } else {
        setErrorVehicleLogCard('File extention must be one of *.pdf, *.png, *.jpg, *.jpeg');
      }
    }
  };

  return (
    <Container maxWidth='lg' className={classes.container}>
      <Grid container direction='row' justify='space-between' alignItems='center' spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControl fullWidth>
            <TextField
              variant='outlined'
              label='Company Name'
              value={inputCompanyName}
              helperText={errorCompanyName}
              error={errorCompanyName !== ''}
              onChange={event => setInputCompanyName(event.target.value)}
              onBlur={event => handleBlurCompanyName(event.target.value)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControl fullWidth>
            <TextField
              variant='outlined'
              label='Company UEN No'
              value={inputCompanyUen}
              helperText={errorCompanyUen}
              error={errorCompanyUen !== ''}
              onChange={event => setInputCompanyUen(event.target.value.trim())}
              onBlur={event => handleBlurCompanyUen(event.target.value.trim())}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    {isLoadingUen ? <CircularProgress size={20} /> : <DescriptionIcon color='primary' fontSize='small' />}
                  </InputAdornment>
                )
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControl fullWidth>
            <input style={{ display: 'none' }} id='button-file' type='file' onChange={event => handleChangeFile(event)} />
            <TextField
              disabled={true}
              variant='outlined'
              label='ACRA Company'
              value={inputCompanyFile}
              error={errorCompanyFile !== ''}
              helperText={errorCompanyFile}
              onChange={event => setInputCompanyFile(event.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <ButtonGroup color='primary' aria-label='outlined primary button group'>
                      <label htmlFor='button-file' style={{ textAlign: 'center', cursor: 'pointer' }}>
                        <ButtonBase focusRipple key={'Upload'} component='span'>
                          <NoteAddIcon className={classes.buttonLink} />
                        </ButtonBase>
                      </label>
                    </ButtonGroup>
                  </InputAdornment>
                )
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControl fullWidth>
            <TextField
              variant='outlined'
              label='Vehicle Number'
              value={inputVehicleNumber}
              helperText={errorVehicleNumber}
              error={errorVehicleNumber !== ''}
              onChange={event => setInputVehicleNumber(event.target.value.trim())}
              onBlur={event => handleBlurVehicleNumber(event.target.value.trim())}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControl fullWidth>
            <input style={{ display: 'none' }} id='button-vehicle-file' type='file' onChange={event => handleChangeVehicleFile(event)} />
            <TextField
              disabled={true}
              variant='outlined'
              label='Vehicle Log Card'
              value={inputVehicleLogCard}
              error={errorVehicleLogCard !== ''}
              helperText={errorVehicleLogCard}
              onChange={event => setInputVehicleLogCard(event.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <ButtonGroup color='primary' aria-label='outlined primary button group'>
                      <label htmlFor='button-vehicle-file' style={{ textAlign: 'center', cursor: 'pointer' }}>
                        <ButtonBase focusRipple key={'Upload'} component='span'>
                          <NoteAddIcon className={classes.buttonLink} />
                        </ButtonBase>
                      </label>
                    </ButtonGroup>
                  </InputAdornment>
                )
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CompanyStep;
