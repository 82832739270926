import React from 'react';
import {
  Dialog,
  DialogContent,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import { useApp } from 'contexts/AppContext';

interface Props {
  open: boolean;
  taskHistories?: JobTaskHistory[];
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  divNote: {
    marginTop: 25,
    border: '1px grey',
    borderRaduis: 5
  },
  divImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 5,
    marginRight: 20
  },
  image: {
    maxHeight: 150,
    maxWidth: 200,
    cursor: 'pointer'
  },
  tableContainer: {
    maxHeight: 430,
    overflowY: 'scroll'
  },
  table: {
    minWidth: 550
  },
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

export default function JobHistoryModal(props: Props) {
  const classes = useStyles();
  const { date } = useApp();
  const { open, onClose, taskHistories } = props;

  return (
    <Dialog fullWidth maxWidth='sm' onClose={onClose} open={open}>
      <DialogTitle id='dialogTitle'>
        <Typography variant='h4'> Task History</Typography>
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers style={{ padding: 0 }}>
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell>User</TableCell>
                <TableCell>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {taskHistories &&
                taskHistories.length > 0 &&
                taskHistories
                  .sort((a, b) => b.id - a.id)
                  .map((history, index) => (
                    <TableRow key={'history-' + index}>
                      <TableCell component='td' scope='row' size='small'>
                        <Typography variant='body1' style={{ fontWeight: 600 }}>
                          {history.createdBy ? history.User.displayName : ''}
                        </Typography>
                        <Typography variant='body2'>{history.createdBy ? history.User.role : ''}</Typography>
                      </TableCell>
                      <TableCell component='td' scope='row' size='small'>
                        {history.notes.split('<br/>').map((str, i) => (
                          <Typography variant='body1' component='p' style={{ fontWeight: 600, whiteSpace: 'pre-line' }} key={`p-${index}_${i}`}>
                            {str}
                          </Typography>
                        ))}
                        <Typography variant='body2'>{date(history.createdAt)}</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
}
