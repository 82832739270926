import React, { FC, useState } from 'react';
import clsx from 'clsx';
import Skeleton from 'react-loading-skeleton';
import { Theme, makeStyles, TableRow, Typography, IconButton, Collapse, Table, TableCell, TableHead, TableBody } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import JobStatus from 'typings/enum/JobStatus';
import JobNoteModal from '../JobNoteModal';
import JobHistoryModal from '../JobHistoryModal';
import HeaderRow from 'components/HeaderRow';
import StatusChip from 'components/StatusChip';
import BodyCell from 'components/BodyCell';
import { format } from 'date-fns';
import { formatId, ucwords } from 'utils';

interface Props {
  index: number;
  task: JobTask;
  isLoadingData: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  chipType: {
    minWidth: '150px'
  },
  mainText: {
    textDecoration: 'underline',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.17px'
  },
  subText: {
    color: theme.palette.black[50],
    textTransform: 'none',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.17px'
  },
  disableText: {
    textStyle: 'italic',
    color: '#7c7d7e'
  },
  clickableAction: {
    color: '#F6891F',
    cursor: 'pointer'
  },
  nonClickableAction: {
    color: '#DADADA',
    cursor: 'not-allowed'
  }
}));

const BodyRow: FC<Props> = props => {
  const classes = useStyles();

  const { task, isLoadingData } = props;
  const [openSub, setOpenSub] = useState<boolean>(false);
  const [isModalNoteOpen, setisModalNoteOpen] = useState<boolean>(false);
  const [selectedNotes, setSelectedNotes] = useState<JobNote[]>([]);

  const [isModalHistoryOpen, setisModalHistoryOpen] = useState<boolean>(false);
  const [selectedHistories, setSelectedHistories] = useState<JobTaskHistory[]>([]);

  const openNotesModal = (jobNotes?: JobNote[]) => {
    if (jobNotes) {
      setSelectedNotes(jobNotes);
      setisModalNoteOpen(true);
    }
  };

  const closeNoteModal = () => {
    setisModalNoteOpen(false);
    setSelectedNotes([]);
  };

  const openHistoryModal = (taskHistories?: JobTaskHistory[]) => {
    if (taskHistories) {
      setSelectedHistories(taskHistories);
      setisModalHistoryOpen(true);
    }
  };

  const closeHistoryModal = () => {
    setisModalHistoryOpen(false);
    setSelectedHistories([]);
  };

  return (
    <>
      <TableRow hover>
        <BodyCell cellWidth='2%'>
          {isLoadingData ? (
            <Skeleton width={80} />
          ) : (
            <IconButton aria-label='expand row' size='small' onClick={() => setOpenSub(!openSub)}>
              {openSub ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </BodyCell>
        <BodyCell cellWidth='10%'>{isLoadingData ? <Skeleton width={80} /> : formatId(task.id)}</BodyCell>
       
        <BodyCell cellWidth='20%'>
          {isLoadingData ? (
            <Skeleton width={80} />
          ) : task.Driver ? (
            <>
              <Typography variant='body1' className={classes.mainText}>
                {task.driverName || ''}
              </Typography>
              <Typography variant='body1' className={classes.subText}>
                {task.Driver.Merchant ? task.Driver.Merchant.companyName : task.Driver.individualCompanyName}
              </Typography>
            </>
          ) : (
            <span className={classes.disableText}>Not Assigned</span>
          )}
        </BodyCell>
        <BodyCell cellWidth='15%'>
          {isLoadingData ? (
            <Skeleton width={80} />
          ) : task.Driver ? (
            task.Driver.vehicleNumber
          ) : (
            <span className={classes.disableText}>Not Assigned</span>
          )}
        </BodyCell>
        <BodyCell cellWidth='15%'>
          {isLoadingData ? (
            <Skeleton width={80} />
          ) : task.Supervisor ? (
            <div>
              <Typography variant='h6' className={classes.mainText}>
                {task.Supervisor.User?.displayName}
              </Typography>
              <Typography variant='h6' className={classes.subText}>
                ({ucwords(task.Supervisor.User?.role)})
              </Typography>
            </div>
          ) : (
            '-'
          )}
        </BodyCell>
        <BodyCell cellWidth='15%'>
          {isLoadingData ? (
            <Skeleton width={80} />
          ) : (
            <StatusChip status={task.jobTaskStatus as JobStatus} label={ucwords(task.jobTaskStatus)} minWidth='110px' />
          )}
        </BodyCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0, borderBottom: 'none' }} colSpan={7}>
          <Collapse in={openSub} timeout='auto' unmountOnExit>
            <div>
              <Table aria-label='purchases'>
                <TableHead>
                  <HeaderRow
                    height='unset'
                    headers={[
                      { id: 'id', label: 'ID' },
                      { id: 'driver', label: 'Completed At' },
                      { id: 'notes', label: 'Job Notes' },
                      { id: 'history', label: 'Task History' }
                    ]}
                  />
                </TableHead>
                <TableBody>
                  <TableRow key={0}>
                    <BodyCell cellWidth='15%'>#{task.id}</BodyCell>
                    <BodyCell cellWidth='15%'>
                      {task.completedDate ? format(new Date(`${task.completedDate} ${task.completedTime}`), 'dd/MM/yyyy, hh:mm aa') : '-'}
                    </BodyCell>
                    <BodyCell cellWidth='15%'>
                      {task.JobNotes && task.JobNotes.length > 0 ? (
                        <span
                          className={clsx({ [classes.clickableAction]: task.JobNotes, [classes.nonClickableAction]: !task.JobNotes })}
                          onClick={() => {
                            openNotesModal(task.JobNotes);
                          }}
                        >
                          View job notes
                        </span>
                      ) : (
                        <span className={classes.disableText}>job note empty</span>
                      )}
                    </BodyCell>
                    <BodyCell cellWidth='15%'>
                      <span className={classes.clickableAction} onClick={() => openHistoryModal(task.JobTaskHistories)}>
                        View history
                      </span>
                    </BodyCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>

      <JobNoteModal open={isModalNoteOpen} onClose={closeNoteModal} jobNotes={selectedNotes} />
      <JobHistoryModal open={isModalHistoryOpen} onClose={closeHistoryModal} taskHistories={selectedHistories} />
    </>
  );
};

export default BodyRow;
