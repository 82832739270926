import {
  Table,
  TableCell as MUITableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
  Paper,
  TableBody,
  Checkbox,
  ListItemText,
  IconButton,
  MenuItem,
  Menu,
  Button,
  Tooltip,
  Box,
  TextField
} from '@material-ui/core';
import { MoreVert, PriorityHighOutlined } from '@material-ui/icons';
import StatusChip from 'components/StatusChip';
import TableEmpty from 'components/Table/TableEmpty';
import TableLoading from 'components/Table/TableLoading';
import React from 'react';
import JobStatus from 'typings/enum/JobStatus';
import { formatCurrency, formatId, snackCaseToSentence, ucwords } from 'utils';
import { format } from 'date-fns';
import useStyles from './styles';

export interface TaskListProps {
  tasks: JobTask[];
  loading: boolean;
  payload: {
    tasks: number[];
    jobStatus: string;
  };
  hasAdditional: boolean;
  onOpenJobNote: (value?: JobNote[]) => () => void;
  onSelectAll: (currentTask: number[], allTask: JobTask[]) => () => void;
  onSelected: (task: JobTask, checked: boolean) => void;
  onOpenPriceDetail: (id: number, taskId: number) => () => void;
  onOpenHistory: (histories?: JobTaskHistory[]) => () => void;
  onOpenEdit: (id: number) => () => void;
}

const TableCell = withStyles({
  root: {
    whiteSpace: 'nowrap'
  }
})(MUITableCell);

const TaskList = (props: TaskListProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [tempTask, setTempTask] = React.useState(-1);
  const [filter, setFilter] = React.useState({
    id: '',
    driver: '',
    vehicle: '',
    supervisor: '',
    status: ''
  });

  const handleClick = (index: number) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
    setTempTask(index);
  };

  const handleClose = (evt: () => void) => () => {
    setAnchorEl(null);
    setTempTask(-1);
    evt();
  };

  const isDisabled = (status: JobStatus) => {
    if ([JobStatus.EXPIRED, JobStatus.DRAFT, JobStatus.CANCELLED].includes(status)) {
      return true;
    }
    return false;
  };

  const isCheckedAll = () => {
    const clearStatus = [JobStatus.EXPIRED, JobStatus.DRAFT, JobStatus.CANCELLED];
    const taskIds = props.tasks.filter(v => !clearStatus.includes(v.jobTaskStatus as JobStatus)).length;
    return props.payload.tasks.length === 0 ? false : taskIds === props.payload.tasks.length;
  };

  const filterFunction = (values: JobTask[]) => {
    let newArr = values;
    Object.entries(filter).forEach(([_, _val]) => {
      if (_val !== '') {
        newArr = newArr.filter(val => {
          const text = `${val.id} ${val.driverName || ''} ${val.vehicleNumber || ''} ${val.jobTaskStatus || ''}`;
          return text.includes(_val);
        });
      }
    });
    return newArr;
  };

  const canEdit = [JobStatus.AVAILABLE, JobStatus.ASSIGNED, JobStatus.IN_PROGRESS, JobStatus.COMPLETED];
  const driverNames = Array.from(new Set(props.tasks.filter(v => v.DriverId !== null).map(v => v.driverName || '')));
  const driverVehicle = Array.from(new Set(props.tasks.filter(v => v.DriverId !== null).map(v => v.vehicleNumber || '')));

  const jobStatus = [
    { id: JobStatus.DRAFT, name: JobStatus.DRAFT },
    { id: JobStatus.AVAILABLE, name: JobStatus.AVAILABLE },
    { id: JobStatus.ASSIGNED, name: JobStatus.ASSIGNED },
    { id: JobStatus.IN_PROGRESS, name: JobStatus.IN_PROGRESS },
    { id: JobStatus.COMPLETED, name: JobStatus.COMPLETED },
    { id: JobStatus.CANCELLED, name: JobStatus.CANCELLED },
    { id: JobStatus.EXPIRED, name: JobStatus.EXPIRED }
  ];

  return (
    <div style={{ marginBottom: props.payload.tasks.length === 0 ? '0px' : '80px' }}>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell component={'th'} padding='checkbox' align='center'>
                <Checkbox size='small' checked={isCheckedAll()} color='primary' onClick={props.onSelectAll(props.payload.tasks, props.tasks)} />
              </TableCell>
              <TableCell component={'th'}>ID</TableCell>
              <TableCell component={'th'}>Task Amount</TableCell>
              <TableCell component={'th'}>Driver & Company</TableCell>
              <TableCell component={'th'}>Vehicle No.</TableCell>
              <TableCell component={'th'}>Supervisor Name</TableCell>
              <TableCell component={'th'} align='center'>
                Task Status
              </TableCell>
              <TableCell component={'th'}>Completed Date & Time</TableCell>
              <TableCell component={'th'} padding='checkbox'></TableCell>
            </TableRow>

            <TableRow>
              <TableCell component={'th'}></TableCell>
              <TableCell component={'th'}>
                <TextField
                  variant='outlined'
                  margin='dense'
                  fullWidth
                  placeholder='Search ID'
                  onChange={event => {
                    event.persist();
                    const time = setTimeout(() => {
                      setFilter(p => ({ ...p, id: event.target.value || '' }));
                      clearTimeout(time);
                    }, 500);
                  }}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notched
                    }
                  }}
                  classes={{ root: classes.rootInput }}
                  className={classes.textField}
                />
              </TableCell>

              <TableCell component={'th'}></TableCell>

              <TableCell component={'th'}>
                <TextField
                  variant='outlined'
                  margin='dense'
                  select
                  fullWidth
                  value={filter.driver || '00'}
                  onChange={event => {
                    const value = event.target.value;
                    setFilter(p => ({ ...p, driver: value === '00' ? '' : value }));
                  }}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notched
                    }
                  }}
                  classes={{ root: classes.rootInput }}
                  className={classes.textField}
                >
                  <MenuItem value='00'>
                    <em>Select Driver</em>
                  </MenuItem>
                  {driverNames.map((driver, index) => (
                    <MenuItem key={index} value={driver}>
                      {driver}
                    </MenuItem>
                  ))}
                </TextField>
              </TableCell>

              <TableCell component={'th'}>
                <TextField
                  variant='outlined'
                  margin='dense'
                  select
                  fullWidth
                  value={filter.vehicle || '00'}
                  onChange={event => {
                    const value = event.target.value;
                    setFilter(p => ({ ...p, vehicle: value === '00' ? '' : value }));
                  }}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notched
                    }
                  }}
                  classes={{ root: classes.rootInput }}
                  className={classes.textField}
                >
                  <MenuItem value='00'>
                    <em>Select Vehicle</em>
                  </MenuItem>
                  {driverVehicle.map((driver, index) => (
                    <MenuItem key={index} value={driver}>
                      {driver}
                    </MenuItem>
                  ))}
                </TextField>
              </TableCell>

              <TableCell component={'th'}></TableCell>

              <TableCell component={'th'}>
                <TextField
                  variant='outlined'
                  margin='dense'
                  select
                  fullWidth
                  value={filter.status || '00'}
                  onChange={event => {
                    const value = event.target.value;
                    setFilter(p => ({ ...p, status: value === '00' ? '' : value }));
                  }}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notched
                    }
                  }}
                  classes={{ root: classes.rootInput }}
                  className={classes.textField}
                >
                  <MenuItem value='00'>
                    <em>Select Status</em>
                  </MenuItem>
                  {jobStatus.map((sts, index) => (
                    <MenuItem key={index} value={sts.id}>
                      {snackCaseToSentence(sts.name)}
                    </MenuItem>
                  ))}
                </TextField>
              </TableCell>
              <TableCell component={'th'}></TableCell>
              <TableCell component={'th'}></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {props.loading ? (
              <TableLoading col={9} />
            ) : props.tasks.length === 0 ? (
              <TableEmpty col={9} />
            ) : (
              filterFunction(props.tasks).map((task, index) => {
                const disableEdit = !canEdit.includes(task.jobTaskStatus as JobStatus);
                return (
                  <TableRow key={index}>
                    <TableCell size='small' padding='checkbox' align='center'>
                      <Checkbox
                        size='small'
                        color='primary'
                        disabled={isDisabled(task.jobTaskStatus as JobStatus)}
                        value={task.id}
                        checked={props.payload.tasks.includes(task.id)}
                        onChange={(e, checked) => props.onSelected(task, checked)}
                      />
                    </TableCell>
                    <TableCell size='small'>
                    <Box display={"flex"} flexDirection={"column"}>
                      <Box>  <Tooltip
                        color='primary'
                        title={
                          props.hasAdditional
                            ? 'Has Pending Additional OT'
                            : task.additionalStatus && task.additionalStatus === 'PENDING'
                            ? task.additionalAmount
                              ? task.additionalAmount > 0
                                ? 'Add Hour Pending'
                                : 'Reduce Hour Pending'
                              : ''
                            : ''
                        }
                        arrow
                      >
                        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                          <Box>{formatId(task.id)}</Box>
                          <Box>
                            {props.hasAdditional || (task.additionalStatus && task.additionalStatus === 'PENDING') ? (
                              <span>
                                <PriorityHighOutlined fontSize='small' color='primary' className={classes.infoIcon} />
                              </span>
                            ) : null}
                          </Box>
                        </Box>
                      </Tooltip></Box>
                      <Box className={classes.subText}>{task.label || 'N/A'}</Box>
                    </Box>
                    </TableCell>
                    <TableCell size='small' align='right'>
                      {formatCurrency(task.taskEarn)}
                    </TableCell>
                    <TableCell size='small'>
                      {!!task.DriverId ? (
                        <ListItemText
                          primary={task.driverName || 'Not Assigned'}
                          secondary={task.Driver?.Merchant?.companyName || '-'}
                          primaryTypographyProps={{ className: classes.mainText }}
                          secondaryTypographyProps={{ className: classes.subText }}
                        />
                      ) : (
                        'Not Assigned'
                      )}
                    </TableCell>
                    <TableCell size='small'>{task.vehicleNumber || 'Not Assigned'}</TableCell>
                    <TableCell size='small'>{task.Supervisor?.User?.displayName || '-'}</TableCell>
                    <TableCell size='small' align='center'>
                      <StatusChip status={task.jobTaskStatus as JobStatus} label={ucwords(task.jobTaskStatus)} minWidth='110px' />{' '}
                    </TableCell>
                    <TableCell size='small'>
                      <ListItemText
                        primary={task.completedDate ? format(new Date(`${task.completedDate} ${task.completedTime}`), 'dd/MM/yyyy, hh:mm aa') : '-'}
                        secondary={
                          <Button
                            disabled={task.JobNotes?.length === 0}
                            variant='text'
                            size='small'
                            disableElevation
                            color='primary'
                            onClick={props.onOpenJobNote(task.JobNotes)}
                          >
                            View Job Note
                          </Button>
                        }
                      />
                    </TableCell>
                    <TableCell padding='checkbox'>
                      <IconButton size='small' aria-controls={`action-menu-${index}`} aria-haspopup='true' onClick={handleClick(index)}>
                        <MoreVert />
                      </IconButton>

                      <Menu
                        id={`action-menu-${index}`}
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl) && index === tempTask}
                        onClose={() => {
                          setAnchorEl(null);
                        }}
                      >
                        <MenuItem disabled={disableEdit} onClick={handleClose(props.onOpenEdit(task?.id))}>
                          Edit Status
                        </MenuItem>
                        <MenuItem onClick={handleClose(props.onOpenPriceDetail(task.Driver?.MerchantId || 0, task.id))}>View Price Detail</MenuItem>
                        <MenuItem onClick={handleClose(props.onOpenHistory(task.JobTaskHistories))}>View History</MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TaskList;
