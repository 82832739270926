import React, { FC, useEffect, useState } from 'react';
import { Backdrop, CircularProgress, Paper } from '@material-ui/core';
import { GET_TRANSACTION_DETAIL, TRANSACTIONS_CONFIRM_TOPUP_BASE_URL } from 'constants/url';
import { useApp } from 'contexts/AppContext';
import axios from 'axios';
import MainTemplate from 'components/Template/MainTemplate';
import useRouter from 'hooks/useRouter';
import TopupAlert from './TopupAlert';
import TopupConfirm from './TopupConfirm';
import TopupDetail from './TopupDetail';
import useStyles from './styles';
import TransactionStatus from 'typings/enum/TransactionStatus';

const MerchantTopupDetailPage: FC = () => {
  const {
    history,
    match: {
      params: { id }
    }
  } = useRouter();
  const classes = useStyles();
  const { clearAccessTopup, clearSuccess, setAccessTopup, accessTopup, activeTopup, date } = useApp();
  const [step, setStep] = useState<'DETAIL' | 'CONFIRM'>('DETAIL');
  const [transaction, setTransaction] = useState<TransactionModel | undefined>();
  const [open, setOpen] = useState(false);
  const [variant, setVariant] = useState<'success' | 'warning' | 'info'>('success');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [otp, setOtp] = useState('');

  const onInput = (value: string) => {
    setOtp(state => {
      state += value;
      return state;
    });
  };

  const onClear = (index: number) => {
    setOtp(state => {
      state = state.slice(0, -1);
      return state;
    });
  };

  const onConfirm = async () => {
    try {
      setOpen(false);
      setMessage('');
      setLoading(true);
      await axios.post(
        TRANSACTIONS_CONFIRM_TOPUP_BASE_URL,
        {
          otp: otp.toLocaleUpperCase(),
          trxId: +id!
        },
        {
          headers: {
            'ACCESS-KEY': accessTopup.token
          }
        }
      );

      let timer = setTimeout(() => {
        setOpen(true);
        setOtp('');
        setVariant('success');
        clearTimeout(timer);
      }, 600);
    } catch (e) {
      console.log('error', e);
      if (e && e.status === 403) {
        clearAccessTopup();
        history.goBack();
      }

      if (e && e.status === 400) setMessage('Invalid OTP');
    } finally {
      setLoading(false);
    }
  };

  const onCloseConfirmation = () => {
    clearAccessTopup();
    setOpen(false);
    history.goBack();
  };

  const onCloseSuccess = () => {
    clearSuccess();
    setOpen(false);
    history.goBack();
  };

  const onContinue = () => {
    setOpen(false);
  };

  const onCancel = () => {
    setVariant('warning');
    setOpen(true);
  };

  const onRedirect = () => {
    history.push('/owners')
  };

  const getDetail = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(GET_TRANSACTION_DETAIL(Number(id)));
      setAccessTopup({ currentId: id });
      setTransaction({ ...data, createdAt: date(data.createdAt) });
      if (data.status !== TransactionStatus.PENDING) {
        setOpen(true);
        setVariant('info');
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  console.log('active', activeTopup)
  useEffect(() => {
    getDetail();
    window.addEventListener('focus', e => {
      getDetail();
    });

    return () => {
      window.removeEventListener('focus', e => {
        getDetail();
      });
    };
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainTemplate
      title='Owner Lists'
      breadcrumb={true}
      links={[
        { name: 'All Owner', links: 'goback', click: true },
        { name: (transaction && transaction.PaidMerchant?.companyName) || '', links: '', click: false },
        { name: 'Top Up', links: '', click: false }
      ]}
      refreshButton={false}
      primaryButton={false}
    >
      <Backdrop open={loading} className={classes.backdrop}>
        <Paper elevation={0} className={classes.boxBackdrop}>
          <CircularProgress size={75} classes={{ circle: classes.iconLoading }} />
        </Paper>
      </Backdrop>
      <div>
        {step === 'DETAIL' && (
          <TopupDetail transaction={transaction} activeTopup={activeTopup} loading={loading} onClick={() => setStep('CONFIRM')} onCancel={onCancel} />
        )}
        {step === 'CONFIRM' && (
          <TopupConfirm message={message} loading={loading} otp={otp} onInput={onInput} onClear={onClear} onConfirm={onConfirm} />
        )}
      </div>

      <div>
        <TopupAlert
          open={open}
          variant={variant}
          onContinue={onContinue}
          onCloseConfirmation={onCloseConfirmation}
          onCloseSuccess={onCloseSuccess}
          onRedirect={onRedirect}
        />
      </div>
    </MainTemplate>
  );
};

export default MerchantTopupDetailPage;
