import React, { FC, useState } from 'react';
import { Paper, makeStyles, Theme } from '@material-ui/core';
import { format } from 'date-fns';

import JobType from 'typings/enum/JobType';
import MaterialImageCarousel from './MaterialImageCarousel';
import CardContent, { ListColumnProps } from 'components/CardContent';
import TruckType from 'typings/enum/TruckType';
import { allTaskOneDriverType, formatCurrency, ucwords } from 'utils';
import { ErrorRounded } from '@material-ui/icons';

interface Props {
  job: JobModel;
  loading: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  jobInformation: {
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
    borderRadius: '5px',
    marginBottom: '16px',
    marginTop: '16px'
  },
  materialImages: {
    width: 60,
    height: 60,
    marginRight: 10,
    borderRadius: 5,
    cursor: 'pointer'
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.15px',
    paddingLeft: 0
  },
  value: {
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '21px',
    letterSpacing: '0.25px',
    paddingLeft: 0,
    color: theme.palette.black.A100
  }
}));

const JobInformation: FC<Props> = props => {
  const classes = useStyles();
  const [isShowCarousel, setIsShowCarousel] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);

  const showCarousel = (imageIndex: number) => {
    setSelectedImage(imageIndex);
    setIsShowCarousel(true);
  };

  const hideCarousel = () => {
    setIsShowCarousel(false);
  };

  const { job, loading } = props;
  const { jobDate, jobTime, jobType, MaterialImagesBucket, truckType } = job;
  const materialImageUrls = [MaterialImagesBucket.image1Url, MaterialImagesBucket.image2Url, MaterialImagesBucket.image3Url].filter(url => url);
  const dateTimeFormatted = `${format(jobDate ? new Date(`${jobDate.replace(/-/g, '/')} ${jobTime}`) : new Date(), 'dd/MM/yyyy, hh:mm a')}`;
  const truckTypeContent = (job: JobModel): ListColumnProps<JobModel>[][] => {
    const list: ListColumnProps<JobModel>[][] = [[{ title: 'Request Quote', value: data => `${data.hasRequestQuote ? 'Yes' : 'No'}` }], [], []];

    if (allTaskOneDriverType.includes(truckType as TruckType)) {
      if (truckType === TruckType.LORRY_CRANE) {
        list[0].splice(0, 0, {
          title: 'Total Cargo Weight',
          value: data => `${(data.materialWeight || 0).toLocaleString('en-sg')} ${ucwords(data.materialWeightType)}`
        });
        list[0].splice(1, 0, {
          title: 'Lifting distance',
          value: data => `${data.liftingDistance || 0} m`
        });
        list[0].splice(2, 0, { title: 'Require Hoisting on Site', value: data => `${!!data.hoistHeight ? 'Yes' : 'No'}` });
        list[1].push({
          title: 'Dimension of cargo',
          value: data => `${data.lengthCargo || 0}m (L) x ${data.widthCargo || 0}m (W) x ${data.heightCargo}m (H)`
        });
        list[1].push({ title: 'Estimated Height of Hoisting', value: data => `${data.hoistHeight} m` });
        list[2].push({
          title: 'Number Of Pieces',
          value: data => `${data.numberPiece || 0} Pcs`
        });
        list[2].push({
          title: 'Tonnage of lorry crane',
          value: data => `${data.tonnageName ? `${data.tonnageName} with Winch, ${formatCurrency(data.price)}` : '-'}`
        });
      }

      if (truckType === TruckType.TRAILER) {
        list[0].splice(0, 0, {
          title: job.equipment ? 'Total Equipment Weight' : 'Total Cargo Weight',
          value: data =>
            `${(data.equipmentWeight || data.materialWeight || 0).toLocaleString('en-sg')} ${ucwords(
              data.equipmentWeightType || data.materialWeightType
            )}`
        });
        list[1].push({
          title: 'Number Of Pieces',
          value: data => `${data.numberPiece || 0} Pcs`
        });
        list[2].push({
          title: 'Trailer Types',
          value: data => `${data.trailerTypeLabel || '-'}`
        });
      }
    }
    return list;
  };

  const address1 = [
    {
      title: job.lampPost ? 'Lamp Post' : 'Postal Code',
      value: data => (data.lampPost ? data.lampPost : data.postalCode)
    }
  ];

  if (job.isSiteManual) {
    address1.push({
      title: '',
      value: data => (
        <span style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', color: '#F6B91E' }}>
          <span>
            <ErrorRounded fontSize='small' style={{ color: '#F6B91E' }} />
          </span>
          <span>Please take note! Road name/Lamp post not found.</span>
        </span>
      )
    });
  }

  const address2 = [
    {
      title: job.lampPostDestination ? 'Lamp Post' : 'Postal Code',
      value: data => (data.lampPostDestination ? data.lampPostDestination : data.postalCodeDestination)
    }
  ];

  if (job.isDestinationManual) {
    address2.push({
      title: '',
      value: data => (
        <span style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', color: '#F6B91E' }}>
          <span>
            <ErrorRounded fontSize='small' style={{ color: '#F6B91E' }} />
          </span>
          <span>Please take note! Road name/Lamp post not found.</span>
        </span>
      )
    });
  }

  return (
    <Paper className={classes.jobInformation}>
      <CardContent
        loading={loading}
        title='General Information'
        divider={true}
        data={job}
        column={[
          [
            { title: 'Job Owner', value: data => data.Merchant.companyName },
            { title: 'Project Date & Time', value: data => dateTimeFormatted },
            { title: 'Pass Required', value: data => (!!data.pass ? 'Yes' : 'No') },
            { title: 'Transporting Equipment', value: data => (!!data.equipment ? 'Yes' : 'No'), visibility: !(truckType === TruckType.TRAILER) }
          ],
          [
            { title: 'Project Title/Remarks', value: data => data.jobTitle, style: { whiteSpace: 'pre-line' } },
            { title: 'One Day Finished', value: data => (data.oneDayFinish ? 'Yes' : 'No') },
            // { title: 'Scan QR Location', value: data => (data.scanLocation === 'END' ? 'Arrival' : 'Departure') }
            { title: 'Type of OU Works', value: data => ucwords(data.workType || '-'), visibility: !(job.jobType === JobType.OU_WORKS) }
          ],
          [
            {
              title: 'Supervisor Assigned',
              value: data => data.JobSupervisors && data.JobSupervisors.length > 0 && data.JobSupervisors.map(spv => spv.displayName).join(', ')
            },
            {
              title: 'Material',
              value: data => (
                <>
                  {data.equipment || data.materials || '-'}
                  <div style={{ paddingTop: '10px' }}>
                    {MaterialImagesBucket.image1Url ? (
                      <img
                        src={MaterialImagesBucket.image1Url}
                        className={classes.materialImages}
                        alt='material #1'
                        onClick={() => {
                          showCarousel(0);
                        }}
                      />
                    ) : null}
                    {MaterialImagesBucket.image2Url ? (
                      <img
                        src={MaterialImagesBucket.image2Url}
                        className={classes.materialImages}
                        alt='material #2'
                        onClick={() => {
                          showCarousel(1);
                        }}
                      />
                    ) : null}
                    {MaterialImagesBucket.image3Url ? (
                      <img
                        src={MaterialImagesBucket.image3Url}
                        className={classes.materialImages}
                        alt='material #3'
                        onClick={() => {
                          showCarousel(3);
                        }}
                      />
                    ) : null}
                  </div>
                </>
              )
            },
            { title: 'Require Transport', value: data => (data.useTransport ? 'Yes' : 'No'), visibility: !(job.jobType === JobType.OU_WORKS) }
          ]
        ]}
      />

      {allTaskOneDriverType.includes(truckType as TruckType) ? (
        <CardContent
          loading={loading}
          title={truckType === TruckType.LORRY_CRANE ? 'Lorry Crane Detail' : 'Trailer Detail'}
          divider={true}
          data={job}
          column={truckTypeContent(job)}
        />
      ) : null}

      <CardContent
        loading={loading}
        title='Job Address'
        divider={jobType === JobType.TRIPWORKS}
        data={job}
        column={[
          address1,
          [{ title: 'Street Name', value: data => (data.lampPost ? data.streetName : data.siteAddress) }],
          [
            {
              title: 'Remarks',
              value: data => data.remarks
            }
          ]
        ]}
      />
      {jobType === JobType.TRIPWORKS && (
        <CardContent
          loading={loading}
          title='Destination'
          data={job}
          column={[
            address2,
            [{ title: 'Street Name', value: data => (data.lampPostDestination ? data.streetNameDestination : data.siteAddressDestination) }],
            [
              {
                title: 'Remarks',
                value: data => data.remarksDestination
              }
            ]
          ]}
        />
      )}
      <MaterialImageCarousel onClose={hideCarousel} open={isShowCarousel} imageUrls={materialImageUrls} selectedItem={selectedImage} />
    </Paper>
  );
};

export default JobInformation;
