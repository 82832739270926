import React, { useState, useRef, FC } from 'react';
import { makeStyles, Theme, Paper, Grid, Box, ClickAwayListener, InputAdornment, IconButton } from '@material-ui/core';
import { PROCESS_EXFERS_TRX_URL, WEB_TRANSACTION_BASE_URL } from 'constants/url';
import MainTemplate from 'components/Template/MainTemplate';
import useTablePagination from 'hooks/useTablePagination';

import PositionedPopper from 'components/PositionedPopper';
import DateTimeRangePicker from 'components/DateTimeRangePicker';
import TransactionTable from './components/TransactionTable';
import TextFilter from 'components/TextFilter';
import { DateRange, Search } from '@material-ui/icons';
import SelectFilter from 'components/SelectFilter';
import TransactionType from 'typings/enum/TransactionType';

import TransactionExportButton from './components/TransactionExportButton';
import ResponseLogDrawer from './components/ResponseLogDrawer';
import { StandardConfirmationDialog } from 'components/AppDialog';
import Snackbar from 'components/Snackbar';
import useSnackbar from 'hooks/useSnackbar';
import axios from 'axios';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    borderRadius: '5px',
    boxShadow: '8px 4px 24px rgba(0, 0, 0, 0.08)'
  },
  button: {
    padding: '8px 16px 8px 16px',
    marginRight: '16px',
    textTransform: 'none',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 400,
    borderRadius: '5px',
    color: theme.palette.black[50],
    '&.MuiButton-outlined:hover': {
      backgroundColor: '#FEF1D2',
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main
    }
  },
  buttonBackground: {
    backgroundColor: '#FEF1D2',
    color: theme.palette.primary.main
  },
  buttonExport: {
    marginTop: '4px',
    padding: '6px 16px 6px 16px',
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '14px',
    borderRadius: '5px'
  },
  adornment: {
    color: theme.palette.subtitle.main,
    padding: 5
  }
}));

const TransactionList: FC = () => {
  const classes = useStyles();
  const clearRef = useRef<HTMLButtonElement | null>(null);
  const closeRef = useRef<HTMLButtonElement | null>(null);
  const { openSnacbar, snackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [dateRange, setDateRange] = useState('');
  const [dateBy, setDateBy] = useState('');
  const [processFazz, setProcessFazz] = useState({
    type: 'TOPUP',
    id: 0,
    loading: false
  });

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [id, setId] = React.useState(0);

  const table = useTablePagination<TransactionList>(WEB_TRANSACTION_BASE_URL, {
    selector: data => data.rows,
    sort: {
      order: 'desc',
      orderBy: 'createdAt'
    }
  });

  const handleTimeChange = (query: string) => {
    const splitted = query.split('|');
    const removedTime = splitted.map(dt => dt.substring(0, 10));
    setDateRange(removedTime.join(' ~ '));
    table.setQuery({ startDate: removedTime[0], endDate: removedTime[1] });
  };

  const onGetResponse = (id: number) => () => {
    setId(id);
  };

  const onProceessFazz = (data: {type:string, id: number}) => () => {
    setProcessFazz(prev => ({...prev, ...data}))
  }

  const processTrx = async () => {
    setProcessFazz((prev) => ({...prev, loading: true}))
    try {
      await axios.post(PROCESS_EXFERS_TRX_URL(processFazz.id), { action: processFazz.type === 'TOPUP' ? 'topup' : 'withdraw' });
     openSnacbar("")
    } catch (error) {
      console.log(error);
    } finally {
      setProcessFazz((prev) => ({...prev, loading: false, id: 0}))
    }
  };

  const refreshList = () => {
    table.clear();
    setDateRange('');
    setDateBy('');
    clearRef.current?.click();
    closeRef.current?.click();
  };

  const handleCalendarClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsCalendarOpen(true);
  };

  return (
    <MainTemplate
      title='Transactions History'
      subtitle='Displays data of all transactions that have been made'
      primaryButton={false}
      refreshButtonProps={{ onClick: refreshList }}
    >
      <Grid container direction='row' spacing={3} justify='space-between'>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box display='flex' flexDirection={'column'} style={{ gap: '16px' }}>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <div style={{ width: '286px' }}>
                <TextFilter
                  fullWidth={true}
                  value={table.query('keyword')}
                  onChange={event => {
                    table.setQuery({ keyword: event.target.value });
                  }}
                  placeholder='Search by trx id or name'
                  startAdornment={
                    <InputAdornment className={classes.adornment} position='start'>
                      <Search fontSize='small' />
                    </InputAdornment>
                  }
                />
              </div>

              <div style={{ flexGrow: 1, display: 'flex', gap: '8px', justifyContent: 'flex-end', alignItems: 'center' }}>
                <TextFilter
                  value={dateRange}
                  readOnly={true}
                  placeholder='Filter by date'
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton aria-label='toggle date-time-picker-range' onClick={handleCalendarClick}>
                        <DateRange />
                      </IconButton>
                    </InputAdornment>
                  }
                />

                <SelectFilter
                  label='Transaction Type'
                  width='30%'
                  value={Array.isArray(table.query('type')) ? '' : table.query('type')}
                  onChange={event => {
                    const value = event.target.value as string;
                    if (value) {
                      table.setQuery({ type: value });
                    } else {
                      table.setQuery({
                        type: [TransactionType.TOPUP, TransactionType.WITHDRAW, TransactionType.INCREASE_CREDIT, TransactionType.DECREASE_CREDIT, TransactionType.CREDIT_PAID]
                      });
                    }
                  }}
                  options={[
                    { value: TransactionType.TOPUP, label: 'Topup' },
                    { value: TransactionType.WITHDRAW, label: 'Withdraw' },
                    { value: TransactionType.INCREASE_CREDIT, label: 'Increase Credit Balance' },
                    { value: TransactionType.DECREASE_CREDIT, label: 'Deacrease Credit Balance' },
                    { value: TransactionType.CREDIT_PAID, label: 'Credit Payment' }
                  ]}
                />
                <div>
                  <TransactionExportButton title='Export Transactions' query={table.query()}  className={classes.buttonExport} />
                </div>
              </div>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={0} className={classes.wrapper}>
            <TransactionTable {...table} onGetResponse={onGetResponse} onProceessFazz={onProceessFazz} />

            <ClickAwayListener onClickAway={() => setIsCalendarOpen(false)} mouseEvent='onMouseDown' touchEvent='onTouchStart'>
              <div>
                <PositionedPopper open={isCalendarOpen} anchorEl={anchorEl} placement='bottom' containerWidth={200} fadeTransition={350} keepMounted>
                  <DateTimeRangePicker
                    mode='date'
                    dateBy={dateBy}
                    clearRef={clearRef}
                    closeRef={closeRef}
                    disabledCustomDate={dateBy !== '5'}
                    setOpenPopper={setIsCalendarOpen}
                    options={[
                      { key: '1', label: 'Today' },
                      { key: '2', label: 'Tomorrow' },
                      { key: '3', label: 'This Week' },
                      { key: '4', label: 'This Month' },
                      { key: '5', label: 'Custom Date' }
                    ]}
                    onChange={handleTimeChange}
                    onSelectedChange={value => setDateBy(value)}
                    onClear={() => {
                      setDateBy('');
                    }}
                  />
                </PositionedPopper>
              </div>
            </ClickAwayListener>
          </Paper>
        </Grid>
      </Grid>

      <ResponseLogDrawer id={id} onClose={() => setId(0)} />

      {process.env.REACT_APP_TRX_PROCESS_BUTTONS === 'show' ? (
        <StandardConfirmationDialog
          variant='warning'
          titleMessage='Process this transaction?'
          message='This will simulate payment or withdrawal on Xfers'
          confirmButtonText='Confirm'
          open={processFazz.id !== 0}
          loading={processFazz.loading}
          handleClose={() => setProcessFazz({ type: 'TOPUP', id: 0, loading: false })}
          onConfirm={processTrx}
        />
      ) : null}

      <Snackbar {...snackbar} />
    </MainTemplate>
  );
};

export default TransactionList;
