import React, { useRef } from 'react';
import {
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
  Toolbar,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import axios from 'axios';
import { WEB_BALANCE_LOG_MERCHANT_BASE_URL } from 'constants/url';
import { formatCurrency, snackCaseToSentence } from 'utils';
import { format } from 'date-fns';

interface Props {
  id: number;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    paddingBottom: 0,
    paddingTop: 0
  },
  ulBorder: {
    borderBottom: '1px solid #e0e0e0',
    paddingBottom: '8px'
  },
  empty: {
    paddingTop: '16px'
  },
  show: {
    position: 'fixed',
    bottom: 0,
    right: 0
  }
}));

const MerchantBalanceLogDrawer = ({ id, onClose }: Props) => {
  const classes = useStyles();
  const abort = axios.CancelToken.source();
  const ref = useRef<HTMLDivElement | null>(null);
  const lastRef = useRef<HTMLDivElement | null>(null);

  const [data, setData] = React.useState({
    loading: false,
    data: [],
    count: 0,
    page: 0
  });

  const getBalanceLog = async (id: number) => {
    try {
      setData(prev => ({ ...prev, loading: true, data: [] }));
      const {
        data: { rows, count }
      } = await axios.get(WEB_BALANCE_LOG_MERCHANT_BASE_URL(id), { params: { page: 0, perPage: 10 }, cancelToken: abort.token });
      setData(prev => ({ ...prev, data: rows, count: count }));
    } catch (error) {
    } finally {
      setData(prev => ({ ...prev, loading: false }));
    }
  };

  const onNext = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    try {
      const totalPage = Math.ceil(data.count / 10);
      if (data.page < totalPage) {
        setData(prev => ({ ...prev, page: prev.page + 1, loading: true }));
        const {
          data: { rows }
        } = await axios.get(WEB_BALANCE_LOG_MERCHANT_BASE_URL(id), {
          params: { page: data.page + 1, perPage: 10 },
          cancelToken: abort.token
        });
        setData(prev => ({ ...prev, data: [...prev.data, ...rows] as any }));
        if (lastRef.current) {
          lastRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }
    } catch (error) {
    } finally {
      setData(prev => ({ ...prev, loading: false }));
    }
  };

  React.useEffect(() => {
    if (id === 0) {
      return;
    }

    getBalanceLog(id);
    return () => {
      abort.cancel();
    };
 // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const total = Math.ceil(data.count / 10);
  let lastScroll = 0;

  return (
    <Drawer
      open={id > 0}
      anchor='right'
      PaperProps={{
        style: { width: '40%' },
        onScroll: e => {
          if (ref.current) {
            if (e.currentTarget.scrollTop < lastScroll) {
              ref.current.className = classes.show;
            } else {
              ref.current.className = '';
            }
          }
          lastScroll = e.currentTarget.scrollTop;
        }
      }}
      onClose={onClose}
    >
      <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='h5'>Balance Log</Typography>
        <IconButton size='small' onClick={onClose}>
          <Close fontSize='inherit' />
        </IconButton>
      </Toolbar>
      <Divider />

      {data.data.length === 0 ? (
        <Typography align='center' variant='h6' className={classes.empty}>
          Data Not Available
        </Typography>
      ) : null}

      <List dense>
        {data.data.map((value: BalanceLog, index) => (
          <ListItem key={index}>
            <ListItemText
              primary={`${value.TrxId ? `#${value.TrxId} |` : ''} ${snackCaseToSentence(value.action)} | ${formatCurrency(
                value.amount
              )} | ${snackCaseToSentence(value.type)}`}
              secondary={`${formatCurrency(value.currentBalance)} -> ${formatCurrency(value.afterBalance)}`}
              primaryTypographyProps={{ style: { fontWeight: 500, color: '#000' } }}
              secondaryTypographyProps={{ style: { fontSize: '16px', fontWeight: 500 } }}
            />
            <ListItemSecondaryAction style={{ marginTop: '10px' }}>
              <Typography variant='body2'>{format(new Date(value.createdAt), 'dd-MMM-yyyy, HH:mm:ss')}</Typography>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>

      <div ref={ref} style={{ padding: '4px', marginTop: '10px' }}>
        <Button variant='contained' disabled={data.loading || total - 1 === data.page} color='primary' fullWidth disableElevation onClick={onNext}>
          Load More
        </Button>
      </div>

      <div ref={lastRef} />
    </Drawer>
  );
};

export default MerchantBalanceLogDrawer;
