import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import { IconButton, Theme, Modal, Grid, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { StandardConfirmationDialog } from 'components/AppDialog';
import EditProfileForm from './components/EditProfileForm';
import { isStrongPassword } from '../../utils';
import axios, { CancelTokenSource } from 'axios';
import { GET_EDIT_USER_URL, GET_CURRENT_USER_PASSWORD_URL, GET_CHECK_USER_URL } from 'constants/url';

interface Props {
  open: boolean;
  userId: number;
  userDisplayName: string;
  userLoginName: string;
  userContactNumber: string;
  userRole: string;
  handleCancel(): void;
  handleOpenSnackbar(type: 'error' | 'success', msg: string): void;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 4
  },
  headerText: {
    marginBottom: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

const UpdateProfileModal: React.FC<Props> = props => {
  const classes = useStyles();
  let cancelTokenSource: CancelTokenSource;

  const { userId, userDisplayName, userLoginName, userContactNumber, userRole, open, handleCancel, handleOpenSnackbar } = props;

  const [isLoading, setLoading] = useState<boolean>(false);

  const [email, setEmail] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [oldPassword, setOldPassword] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [contactNumber, setContantNumber] = useState<string>('');
  const [role, setRole] = useState<string>('');

  const [nameError, setNameError] = useState<string>('');
  const [contactNumberError, setContactNumberError] = useState<boolean>(false);
  const [contactNumberErrorMessage, setContactNumberErrorMessage] = useState<string>('');
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<string>('');
  const [oldPasswordError, setOldPasswordError] = useState<boolean>(false);
  const [oldPasswordErrorMessage, setOldPasswordErrorMessage] = useState<string>('');
  const [confirmPasswordError, setConfirmPasswordError] = useState<boolean>(false);
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState<string>('');

  const [openSnackbarInvalid, setOpenSnackbarInvalid] = useState<boolean>(false);
  const [isLoadingContactNumber, setLoadingContactNumber] = useState<boolean>(false);

  const resetFormValues = useCallback(() => {
    if (!userId) {
      return;
    }

    const displayName = userDisplayName;
    const email = userLoginName;
    const contactNumber = userContactNumber;
    const role = userRole;

    setEmail(email);
    setName(displayName);
    setContantNumber(contactNumber);
    setRole(role);
  }, [userId, userDisplayName, userLoginName, userContactNumber, userRole]);

  // This to ensure the form value and errors are reset/cleared when selectedUser changes
  // resetFormValues will be modified everytime user changes, due to useCallback
  useEffect(() => {
    resetFormValues();
    clearFormErrors();
  }, [resetFormValues]);

  const clearFormErrors = () => {
    setNameError('');
    setContactNumberError(false);
    setContactNumberErrorMessage('');
    setOldPasswordError(false);
    setOldPasswordErrorMessage('');
    setConfirmPasswordError(false);
    setConfirmPasswordErrorMessage('');
    setPasswordError(false);
    setPasswordErrorMessage('');
  };

  // This is to ensure that the form vale and erors are reset/cleared when user canceled the editing
  const handleOnClose = () => {
    resetFormValues();
    clearFormErrors();
    handleCancel();
  };

  const handleCloseSnackbarInvalid = () => {
    setOpenSnackbarInvalid(false);
  };

  const handleBlurContactNumber = async (inputed: string) => {
    setContactNumberError(false);
    setContactNumberErrorMessage('');
    if (inputed.length < 8) {
      setContactNumberError(true);
      setContactNumberErrorMessage('Please enter contact number must be 8 digits');
    }

    setLoadingContactNumber(true);
    const isExist = await isExistValidation('CONTACT', inputed);
    if (isExist) {
      setContactNumberError(true);
      setContactNumberErrorMessage('Contact number has been registered');
    }

    setLoadingContactNumber(false);
  };

  const validateForm = async () => {
    let ret = true;
    clearFormErrors();

    if (!name || !name.trim()) {
      setNameError('Please enter display name');
      ret = false;
    }

    if (!contactNumber || !contactNumber.trim() || contactNumber.length !== 8) {
      setContactNumberError(true);
      setContactNumberErrorMessage('Please enter contact number must be 8 digits');
      ret = false;
    } else {
      setLoadingContactNumber(true);
      const isExist = await isExistValidation('CONTACT', contactNumber);
      if (isExist) {
        setContactNumberError(true);
        setContactNumberErrorMessage('Contact number has been registered');
      }

      setLoadingContactNumber(false);
    }

    if (password) {
      if (password.length < 8) {
        setPasswordError(true);
        setPasswordErrorMessage('Password must be 8 or more characters');
        ret = false;
      }

      if (!isStrongPassword(password)) {
        setPasswordError(true);
        setPasswordErrorMessage('Please enter password at least containing 1 uppercase letter, 1 number and 1 symbol');
        ret = false;
      }

      if (password !== confirmPassword) {
        setConfirmPasswordError(true);
        setConfirmPasswordErrorMessage('Password and confirm password is different');
        ret = false;
      }

      if (!oldPassword || !oldPassword.trim()) {
        setOldPasswordError(true);
        setOldPasswordErrorMessage('Please enter Current Password');
        ret = false;
      }
    }

    return ret;
  };

  const handlePasswordValidation = (passwordCheck: string) => {
    let returnHandle = true;
    if (!passwordCheck) {
      setPasswordError(true);
      setPasswordErrorMessage('Password can not be empty.');
      returnHandle = false;
    } else if (passwordCheck.length < 8) {
      setPasswordError(true);
      setPasswordErrorMessage('Password at least 8 characters');
      returnHandle = false;
    } else if (!isStrongPassword(passwordCheck)) {
      setPasswordError(true);
      setPasswordErrorMessage('Password at least containing 1 uppercase letter, 1 number and 1 symbol');
      returnHandle = false;
    }

    if (returnHandle) {
      setPasswordError(false);
      setPasswordErrorMessage('');
    }

    return returnHandle;
  };

  const handleCheckUserPassword = async () => {
    let resultHandle = true;
    setOldPasswordError(false);
    setOldPasswordErrorMessage('');

    try {
      const params = new URLSearchParams();
      params.append('password', oldPassword);

      const result = await axios.get(`${GET_CURRENT_USER_PASSWORD_URL}?${params.toString()}`);
      const { data } = result;
      if (data === false) {
        resultHandle = false;
        setOpenSnackbarInvalid(true);
        setOldPasswordError(true);
        setOldPasswordErrorMessage('Incorrect current password');
      }
    } catch (error) {
      console.log('error', error);
      resultHandle = false;
      setOldPasswordError(true);
      setOldPasswordErrorMessage('Failed check Current Password');
    }

    return resultHandle;
  };

  const handleOnSubmit: React.FormEventHandler = async event => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    if (password) {
      const validOldPassword = await handleCheckUserPassword();
      if (!validOldPassword) {
        return;
      }
    }

    setLoading(true);

    try {
      cancelTokenSource = axios.CancelToken.source();
      await axios.put(
        `${GET_EDIT_USER_URL(userId)}`,
        {
          displayName: name,
          email,
          contactNumber,
          newPassword: password ? password : undefined
        },
        { cancelToken: cancelTokenSource.token }
      );

      handleOpenSnackbar('success', 'Update is successful');
      handleCancel();
    } catch (err: any) {
      console.log(err);
      const { errorCode } = err.data;

      if (errorCode === 4) {
        setPasswordError(true);
        setPasswordErrorMessage('Password must contain letters (A-Z and a-z), numbers (1-9) and be 8 or more characters');
      } else {
        handleOpenSnackbar('error', err.data.message);
      }
    }

    setLoading(false);
  };

  const isExistValidation = async (field: string, value: string) => {
    let exist = false;
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    try {
      const { data } = await axios.get(`${GET_CHECK_USER_URL}?role=ADMIN&field=${field}&params=${value}&userId=${userId}`, {
        cancelToken: cancelTokenSource.token
      });

      console.clear();
      console.log(data);
      exist = data.isExist;
    } catch (err: any) {
      console.error('err: ', err);
      handleOpenSnackbar('error', err.data.message);
    }

    return exist;
  };

  return (
    <Modal aria-labelledby='modal-title' open={open} >

      <Grid container item xs={8} sm={8} md={8} lg={5} xl={5} spacing={3} direction='column' className={classes.paper}>
        <Typography variant='h4' id='modal-title' align='left' className={classes.headerText}>
          Edit My Profile
        </Typography>
        <IconButton size='small' className={classes.closeButton} onClick={handleOnClose}>
          <CloseIcon />
        </IconButton>
        <EditProfileForm
          email={email}
          setEmail={setEmail}
          contactNumberError={contactNumberError}
          contactNumberErrorMessage={contactNumberErrorMessage}
          name={name}
          setName={setName}
          nameError={nameError}
          oldPassword={oldPassword}
          setOldPassword={setOldPassword}
          password={password}
          setPassword={setPassword}
          passwordError={passwordError}
          setPasswordError={setPasswordError}
          passwordErrorMessage={passwordErrorMessage}
          setPasswordErrorMessage={setPasswordErrorMessage}
          oldPasswordError={oldPasswordError}
          oldPasswordErrorMessage={oldPasswordErrorMessage}
          setOldPasswordError={setOldPasswordError}
          setOldPasswordErrorMessage={setOldPasswordErrorMessage}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          confirmPasswordError={confirmPasswordError}
          setConfirmPasswordError={setConfirmPasswordError}
          confirmPasswordErrorMessage={confirmPasswordErrorMessage}
          setConfirmPasswordErrorMessage={setConfirmPasswordErrorMessage}
          contactNumber={contactNumber}
          setContactNumber={setContantNumber}
          role={role}
          isSubmitting={isLoading}
          isLoadingContactNumber={isLoadingContactNumber}
          onSubmit={handleOnSubmit}
          onCancel={handleOnClose}
          handleBlurContactNumber={handleBlurContactNumber}
          handlePasswordValidation={handlePasswordValidation}
        />

        <StandardConfirmationDialog
          variant={'error'}
          titleMessage={'Error!'}
          message={'Incorrect Current Password!'}
          open={openSnackbarInvalid}
          handleClose={handleCloseSnackbarInvalid}
          onConfirm={handleCloseSnackbarInvalid}
          noCancelButton={true}
        />
      </Grid>
    </Modal>
  );
};

export default UpdateProfileModal;
