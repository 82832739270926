import React, { useState } from 'react';
import { Button, Box } from '@material-ui/core';
import SupportTemplate from 'components/Template/SupportTemplate';
// eslint-disable-next-line
import axios, { CancelTokenSource } from 'axios';
import { WEB_MERCHANT_BASE_URL } from 'constants/url';
const list = ['Credit Payment', 'Update Merchant Balance'];

const SupportList = () => {
  // eslint-disable-next-line
  const [inSearch, setInSearch] = useState(false);
  // eslint-disable-next-line
  const [merchants, setMerchants] = useState([{ id: 0, text: '' }]);
  let mr = null;

  // eslint-disable-next-line
  const onSearch = async (companyName = '') => {
    try {
      setInSearch(true);
      if (mr !== null) {
        mr.cancel();
      }

      mr = axios.CancelToken.source();
      const { data } = await axios.get(WEB_MERCHANT_BASE_URL, {
        cancelToken: mr.token,
        params: {
          companyName
        }
      });

      setMerchants(p => [
        ...p.filter(v => v.id > 0),
        ...data.rows.map(v => ({ id: v.id, text: v.companyName })).filter(v => !p.map(_p => _p.id).includes(v.id))
      ]);
    } catch (err) {
    } finally {
      setInSearch(false);
    }
  };

  return (
    <SupportTemplate title='Support' breadcrumb={false}>
      <Box display='flex' flexDirection='row' style={{ gap: '16px' }} flexWrap='wrap'>
        {list.map((l, i) => (
          <Button key={i} variant='contained' color='primary' size='large'>
            {l}
          </Button>
        ))}
      </Box>
    </SupportTemplate>
  );
};

export default SupportList;
