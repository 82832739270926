import React, { forwardRef, useState, useEffect } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { CSVDownload } from 'react-csv';
import { Button, CircularProgress, Menu, MenuItem } from '@material-ui/core';

import { TRANSACTIONS_BASE_URL, EXPORT_MERCHANT_BALANCES } from 'constants/url';
import { ExpandMore } from '@material-ui/icons';

interface Props {
  filters?: any;
  sortBy?: string;
  sortDir?: string;
  className?: string;
  onAdvanceExport?: () => void,
  title?:string
}

const currentListHeaders = [
  { label: 'Trx ID', key: 'id' },
  { label: 'Date Time', key: 'dateTime' },
  { label: 'Description', key: 'description' },
  { label: 'Payment Method', key: 'paymentMethod' },
  { label: 'Amount', key: 'amount' },
  { label: 'Status', key: 'status' }
];

const allTrxHeaders = [
  { label: 'Job ID', key: 'jobId' },
  { label: 'Owner', key: 'jobOwner' },
  { label: 'Transaction Type', key: 'trxType' },
  { label: 'Date', key: 'trxDate' },
  { label: 'Job Start Time', key: 'jobTime' },
  { label: 'Job End Time', key: 'jobEndTime' },
  { label: 'Duration', key: 'duration' },
  { label: 'Start Location', key: 'startLocation' },
  { label: 'Destination Location', key: 'destinationLocation' },
  { label: 'Material', key: 'material' },
  { label: 'Vehicle no.', key: 'vehicleNo' },
  { label: 'Driver Name', key: 'driverName' },
  { label: 'Supervisor Name', key: 'supervisorName' },
  { label: 'Price per Task', key: 'pricePerTask' },
  { label: 'Qty (no. of tasks)', key: 'qty' },
  { label: 'Total Price', key: 'totalPrice' },
  { label: 'GST Amount', key: 'gstAmount' },
  { label: 'Grand Total', key: 'grandTotal' },
];

const allBalanceHeaders = [
  { label: 'Merchant ID', key: 'id' },
  { label: 'Company Name', key: 'companyName' },
  { label: 'Current Balance', key: 'balance' },
];

const TransactionExportButton = forwardRef<any, Props>((props, ref) => {
  const { filters, sortBy, sortDir, className, onAdvanceExport } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [data, setData] = useState<TransactionCSVModel[] | AllTransactionCSVModel[] | AllMerchantBalanceCSVModel[]>([]);
  const [exportType, setExportType] = useState('');
  const [isRenderDownload, setIsRenderDownload] = useState(false);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
   if (isRenderDownload) {
    setLoading(false)
     const timeoutID = setTimeout(() => {
      setIsRenderDownload(false);
     }, 300);

     return () => clearTimeout(timeoutID);
   }
  }, [isRenderDownload]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCurrentListExport = async () => {
    setAnchorEl(null);
    setExportType('current');
    setLoading(true);
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    const { data } = await axios.get(TRANSACTIONS_BASE_URL, {
      params: { ...filters, sortBy, sortDir},
      cancelToken: cancelTokenSource.token
    });

    let csvData: TransactionCSVModel[] = [];
    if (data.transactions && data.transactions.length > 0) {
      await Promise.all(
        data.transactions.map(async (value: any) => {
          let description = '';
          if (value.type === 'TOPUP') {
            description = value.PaidMerchant?.companyName;
          } else if (value.type === 'WITHDRAW') {
            description = value.PayingMerchant?.companyName
          }
          csvData.push({
            id: value.id,
            dateTime: value.createdAt,
            description: `${value.type} - ${description}`,
            paymentMethod: value.paymentMethod,
            amount: value.amount,
            status: value.status
          });
        })
      );
    }
    setData(csvData);
    setIsRenderDownload(true);
    setLoading(false);
  };

  // const handleAllTrxExport = async () => {
  //   setAnchorEl(null);
  //   setExportType('all');
  //   setLoading(true);
  //   const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
  //   const { data } = await axios({
  //     url: `${TRANSACTIONS_BASE_URL}/export-all`,
  //     method: 'GET',
  //     responseType: 'blob',
  //     headers: {
  //       Accept: 'text/csv'
  //     },
  //     cancelToken: cancelTokenSource.token
  //   });
  //   const url = window.URL.createObjectURL(new Blob([data]));
  //   const link = document.createElement('a');
  //   link.href = url;
  //   link.setAttribute('download', 'all-transaction.csv');
  //   document.body.appendChild(link);
  //   link.click();
  //   let csvData: AllTransactionCSVModel[] = data;
  //   setData(csvData);
  //   setIsRenderDownload(true);
  //   setLoading(false);
  // };

  const handleBalanceExport = async () => {
    setAnchorEl(null);
    setExportType('balance');
    setLoading(true);
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    const { data } = await axios.get(EXPORT_MERCHANT_BALANCES, {
      cancelToken: cancelTokenSource.token
    });
    let csvData: AllMerchantBalanceCSVModel[] = data;
    setData(csvData);
    setIsRenderDownload(true);
    setLoading(false);
  };

  const headers = exportType === 'current' ? currentListHeaders : (exportType === 'all' ? allTrxHeaders : allBalanceHeaders);
  return (
      <>
        <Button disabled={loading} aria-controls="export-menu" variant='contained'  disableElevation aria-haspopup="true"  color='primary' onClick={handleClick} className={className} ref={ref}>
         {loading  ? <CircularProgress color='inherit' size={24} />: <> {props.title || 'Export CSV'}<ExpandMore fontSize='small' style={{marginLeft: '8px'}} /></>}
        </Button>
        <Menu
          id="export-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleCurrentListExport}>Export Current List</MenuItem>
          {/* <MenuItem onClick={handleAllTrxExport}>Export All Transactions</MenuItem> */}
          <MenuItem onClick={handleBalanceExport}>Export All Merchants Balances</MenuItem>
          {onAdvanceExport ?  <MenuItem onClick={onAdvanceExport}>Export Advanced</MenuItem>: null}
        </Menu>
        {isRenderDownload && (
          <CSVDownload
            headers={headers}
            data={data}
          />
        )}
      </>
    );
});

export default TransactionExportButton;
