import { AppBar, Divider, IconButton, ListItemIcon, makeStyles, Menu, MenuItem, Theme, Toolbar, Typography } from '@material-ui/core';
import IdentifyIcon from '@material-ui/icons/PermIdentityRounded';
import LogoutIcon from '@material-ui/icons/PowerSettingsNewRounded';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import axios from 'axios';
import clsx from 'clsx';
import { green } from '@material-ui/core/colors';
import React, { FC, useContext, useState } from 'react';
import { LOGOUT_URL } from 'constants/url';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import profile from '../images/icon/profile.svg';

import UpdateProfileModal from './ProfileModal';
// import SearchInput from './SearchInput';

import barChart from 'images/icon/bar-chart.svg';
import ActionSnackbar from './ActionSnackbar';
import { getCurrentUserId, getCurrentUserDisplayName, getCurrentUserLoginName, getCurrentUserRole, getCurrentUserContactNumber } from 'selectors';

interface Props {
  open: boolean;
  handleDrawerOpen(): void;
  handleDrawerClose(): void;
}

const { REACT_APP_DRAWER_WIDTH = '220' } = process.env;

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    margin: 10,
    width: 30,
    height: 30,
    backgroundColor: '#FFFFFF',
    border: '2px solid #FFFFFF',
    color: '#f68920'
  },
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  appBar: {
    width: `calc(100% - ${Number(REACT_APP_DRAWER_WIDTH) - 168}px)`,
    backgroundColor: '#FFFFFF',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: REACT_APP_DRAWER_WIDTH,
    width: `calc(100% - ${REACT_APP_DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: 'none'
  },
  title: {
    flexGrow: 1
  },
  ListItemIcon: {
    minWidth: theme.spacing(5)
  },
  logo: {
    width: '3em'
  },
  appTitle: {
    width: '12.5em'
  },
  drawerIcon: {
    padding: 0,
    boderRadius: 0
  },
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  userName: {
    marginLeft: '8px',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    textAlign: 'right'
  }
}));

const AppHeader: FC<Props> = props => {
  const classes = useStyles();
  const { currentUser, unsetCurrentUser } = useContext(CurrentUserContext);

  const userProfileId = getCurrentUserId(currentUser);
  const userLoginName = getCurrentUserLoginName(currentUser);
  const userDisplayName = getCurrentUserDisplayName(currentUser);
  const userContactNumber = getCurrentUserContactNumber(currentUser);
  const userRole = getCurrentUserRole(currentUser);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { open, handleDrawerOpen, handleDrawerClose } = props;

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [msgSnackbar, setMsgSnackbar] = useState<string>('');
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');

  const [openProfileModal, setOpenProfileModal] = useState<boolean>(false);
  const [currentEditingUserProfileId, setCurrentEditingUserProfileId] = useState<number>(0);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenProfileModal = (userProfileId: number): React.MouseEventHandler => () => {
    handleClose();
    setCurrentEditingUserProfileId(userProfileId);
    setOpenProfileModal(true);
  };

  const handleCancelProfileModal = () => {
    setOpenProfileModal(false);
  };

  const handleLogout = async () => {
    handleClose();

    try {
      await axios.post(LOGOUT_URL);
    } catch (err) {
      // do nothing. Log out even if server request failed
    }

    unsetCurrentUser();
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleOpenSnackbar = (type: 'success' | 'error', msg: string) => {
    setOpenSnackbar(true);
    setSnackbarVarient(type);
    setMsgSnackbar(msg);
  };

  // Only call effect if debounced search term changes
  return (
    <AppBar position='absolute' elevation={0} color='inherit' className={clsx(classes.appBar, open && classes.appBarShift)}>
      <Toolbar className={classes.toolbar}>
        <Typography component='h4' variant='h4' color='inherit' className={classes.title}>
          <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen} className={classes.drawerIcon}>
            <img src={barChart} alt='img_btn' />
          </IconButton>

          {/* <SearchInput withBorder={true} /> */}
        </Typography>

        <IconButton size='small' onClick={handleClick}>
          <img src={profile} alt='image_profile' />
        </IconButton>
        <Menu
          id='profile-menu'
          anchorEl={anchorEl}
          keepMounted
          elevation={1}
          getContentAnchorEl={null}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <MenuItem onClick={handleOpenProfileModal(userProfileId)}>
            <ListItemIcon className={classes.ListItemIcon}>
              <IdentifyIcon />
            </ListItemIcon>
            My Profile Settings
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleLogout}>
            <ListItemIcon className={classes.ListItemIcon}>
              <LogoutIcon />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>

        <Typography variant='subtitle2' className={classes.userName}>
          {(currentUser && currentUser.displayName) || ''}
        </Typography>
      </Toolbar>

      <ActionSnackbar
        variant={snackbarVarient}
        message={msgSnackbar}
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        Icon={snackbarVarient === 'success' ? CheckCircleIcon : ErrorIcon}
      />
      <UpdateProfileModal
        open={openProfileModal}
        userId={currentEditingUserProfileId}
        userDisplayName={userDisplayName}
        userLoginName={userLoginName}
        userContactNumber={userContactNumber}
        userRole={userRole}
        handleCancel={handleCancelProfileModal}
        handleOpenSnackbar={handleOpenSnackbar}
      />
    </AppBar>
  );
};

export default AppHeader;
