import React from 'react';
import { Box, Button, IconButton } from '@material-ui/core';
import { Close, RestoreOutlined } from '@material-ui/icons';
import useStyles from './styles';

interface Props {
  open: boolean;
  totalTask: Number;
  onCancel: () => void;
  onOpen: () => void;
}

const DialogBottom = ({ open, totalTask, onCancel, onOpen }: Props) => {
  const classes = useStyles();

  return open ? (
    <Box className={classes.wrapper}>
      <Box>
        <IconButton size='small' onClick={onCancel}>
          <Close fontSize='inherit' htmlColor='white' />
        </IconButton>
      </Box>
      <Box flexGrow={1}>{totalTask} Task Selected</Box>
      <Box>
        <Button variant='text' className={classes.buttonStatus} onClick={onOpen}>
          <RestoreOutlined fontSize='small' style={{ marginRight: '4px' }} htmlColor='white' /> Change Status
        </Button>
      </Box>
    </Box>
  ) : null;
};

export default DialogBottom;
