import React, { Fragment, FC } from 'react';
import { Table, TableBody, TableHead, TableFooter, TableRow, TableCell, InputAdornment, TableContainer } from '@material-ui/core';
import { dummyDriver } from 'constants/DummyData';
import { Search } from '@material-ui/icons';
import HeaderRow from 'components/HeaderRow';
import TablePagination from 'components/TablePagination';
import BodyRow from './BodyRow';
import useRouter from 'hooks/useRouter';
interface Props {
  drivers: DriverModel[];
  count: number;
  currentPage: number;
  rowsPerPage: number;
  order: 'asc' | 'desc';
  orderBy: string;
  isLoadingData: boolean;
  merchantId?: number;
  openDetailModal: boolean;
  queryId?: string;
  queryName: string;
  queryEmail: string;
  queryContact: string;
  queryCompanyName: string;
  queryCompanyUen: string;
  queryVehicleNumber: string;
  handleQueryVehicleChange: (type: string) => void;
  handleQueryIdChange: (type: string) => void;
  handleQueryNameChange: (type: string) => void;
  handleQueryEmailChange: (type: string) => void;
  handleQueryContactChange: (type: string) => void;
  handleQueryCompanyNameChange: (type: string) => void;
  handleQueryCompanyUenChange: (type: string) => void;
  handleRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
  handleChangeRowsPerPage: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  setOpenDetailModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedId: React.Dispatch<React.SetStateAction<number>>;
}

const DriverTable: FC<Props> = props => {
  const { history } = useRouter();
  const { merchantId, drivers, isLoadingData, count, currentPage, rowsPerPage, order, orderBy } = props;
  const {
    handleQueryVehicleChange,
    handleQueryIdChange,
    handleQueryNameChange,
    handleQueryEmailChange,
    handleQueryContactChange,
    handleQueryCompanyNameChange,
    handleQueryCompanyUenChange,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage
  } = props;
  const { queryId, queryName, queryEmail, queryContact, queryCompanyName, queryCompanyUen, queryVehicleNumber } = props;
  const handleOpenDetailClick = (id: number) => {
    history.push(`/driver/${id}`);
  };

  return (
    <TableContainer style={{ boxShadow: 'none', borderRadius: 0 }}>
      <Table size='small'>
        <TableHead>
          <HeaderRow
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            height='unset'
            headers={
              merchantId
                ? [
                    { id: 'id', label: 'ID', sort: true },
                    { id: 'displayName', label: 'Display Name', sort: true },
                    { id: 'email', label: 'Email', sort: true },
                    { id: 'contactNumber', label: 'Phone', sort: true },
                    { id: 'vehicleNumber', label: 'Vehicle No', sort: true }
                  ]
                : [
                    {
                      id: 'id',
                      label: 'ID',
                      sort: true
                    },
                    {
                      id: 'displayName',
                      label: 'Display Name',
                      sort: true
                    },
                    {
                      id: 'companyName',
                      label: 'Company Name',
                      sort: true
                    },
                    {
                      id: 'individualCompanyUen',
                      label: 'Company UEN No',
                      sort: true
                    },
                    {
                      id: 'contactNumber',
                      label: 'Phone',
                      sort: true
                    },
                    {
                      id: 'vehicleNumber',
                      label: 'Vehicle No',
                      sort: true
                    }
                  ]
            }
          />
        </TableHead>
        <TableHead>
          <HeaderRow
            height='0px'
            headers={
              merchantId
                ? [
                    {
                      id: 'findId',
                      isInputText: true,
                      value: queryId,
                      handleInputChange: handleQueryIdChange,
                      pT: '0px',
                      pB: '0px',
                      pL: '16px',
                      pR: '8px',
                      endAdornment: (
                        <InputAdornment position='start'>
                          <Search fontSize='small' color='disabled' />
                        </InputAdornment>
                      )
                    },
                    {
                      id: 'findDisplayName',
                      isInputText: true,
                      value: queryName,
                      handleInputChange: handleQueryNameChange,
                      pT: '0px',
                      pB: '0px',
                      pL: '16px',
                      pR: '8px',
                      endAdornment: (
                        <InputAdornment position='start'>
                          <Search fontSize='small' color='disabled' />
                        </InputAdornment>
                      )
                    },
                    {
                      id: 'findEmail',
                      isInputText: true,
                      value: queryEmail,
                      handleInputChange: handleQueryEmailChange,
                      pT: '0px',
                      pB: '0px',
                      pL: '16px',
                      pR: '8px',
                      endAdornment: (
                        <InputAdornment position='start'>
                          <Search fontSize='small' color='disabled' />
                        </InputAdornment>
                      )
                    },
                    {
                      id: 'findContactNumber',
                      isInputText: true,
                      value: queryContact,
                      handleInputChange: handleQueryContactChange,
                      pT: '0px',
                      pB: '0px',
                      pL: '16px',
                      pR: '8px',
                      endAdornment: (
                        <InputAdornment position='start'>
                          <Search fontSize='small' color='disabled' />
                        </InputAdornment>
                      )
                    },
                    {
                      id: 'findVehicle',
                      isInputText: true,
                      value: queryVehicleNumber,
                      handleInputChange: handleQueryVehicleChange,
                      pT: '0px',
                      pB: '0px',
                      pL: '16px',
                      pR: '8px',
                      endAdornment: (
                        <InputAdornment position='start'>
                          <Search fontSize='small' color='disabled' />
                        </InputAdornment>
                      )
                    }
                  ]
                : [
                    {
                      id: 'findId',
                      isInputText: true,
                      value: queryId,
                      handleInputChange: handleQueryIdChange,
                      pT: '0px',
                      pB: '0px',
                      pL: '16px',
                      pR: '8px',
                      endAdornment: (
                        <InputAdornment position='start'>
                          <Search fontSize='small' color='disabled' />
                        </InputAdornment>
                      )
                    },
                    {
                      id: 'findDisplayName',
                      isInputText: true,
                      value: queryName,
                      handleInputChange: handleQueryNameChange,
                      pT: '0px',
                      pB: '0px',
                      pL: '16px',
                      pR: '8px',
                      endAdornment: (
                        <InputAdornment position='start'>
                          <Search fontSize='small' color='disabled' />
                        </InputAdornment>
                      )
                    },
                    {
                      id: 'findCompanyName',
                      isInputText: true,
                      value: queryCompanyName,
                      handleInputChange: handleQueryCompanyNameChange,
                      pT: '0px',
                      pB: '0px',
                      pL: '16px',
                      pR: '8px',
                      endAdornment: (
                        <InputAdornment position='start'>
                          <Search fontSize='small' color='disabled' />
                        </InputAdornment>
                      )
                    },
                    {
                      id: 'findCompanyUen',
                      isInputText: true,
                      value: queryCompanyUen,
                      handleInputChange: handleQueryCompanyUenChange,
                      pT: '0px',
                      pB: '0px',
                      pL: '16px',
                      pR: '8px',
                      endAdornment: (
                        <InputAdornment position='start'>
                          <Search fontSize='small' color='disabled' />
                        </InputAdornment>
                      )
                    },
                    {
                      id: 'findContactNumber',
                      isInputText: true,
                      value: queryContact,
                      handleInputChange: handleQueryContactChange,
                      pT: '0px',
                      pB: '0px',
                      pL: '16px',
                      pR: '8px',
                      endAdornment: (
                        <InputAdornment position='start'>
                          <Search fontSize='small' color='disabled' />
                        </InputAdornment>
                      )
                    },
                    {
                      id: 'findVehicle',
                      isInputText: true,
                      value: queryVehicleNumber,
                      handleInputChange: handleQueryVehicleChange,
                      pT: '0px',
                      pB: '0px',
                      pL: '16px',
                      pR: '8px',
                      endAdornment: (
                        <InputAdornment position='start'>
                          <Search fontSize='small' color='disabled' />
                        </InputAdornment>
                      )
                    }
                  ]
            }
          />
        </TableHead>
        <TableBody>
          {isLoadingData ? (
            [0, 1, 2, 3].map(index => (
              <BodyRow
                index={index}
                key={index}
                driver={dummyDriver}
                merchantId={merchantId}
                isLoadingData={isLoadingData}
                handleOpenDetailClick={handleOpenDetailClick}
              />
            ))
          ) : drivers && drivers.length > 0 ? (
            drivers.map((value, index) => (
              <BodyRow
                index={index}
                key={index}
                driver={value}
                merchantId={merchantId}
                isLoadingData={isLoadingData}
                handleOpenDetailClick={handleOpenDetailClick}
              />
            ))
          ) : (
            <Fragment>
              <TableRow>
                <TableCell align='center' colSpan={6}>
                  Data Not Available.
                </TableCell>
              </TableRow>
            </Fragment>
          )}
        </TableBody>
        <TableFooter>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            count={count}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default DriverTable;
