import React, { useState } from 'react';
import topupIcon from 'images/icon/topup.svg';

import { Visibility, VisibilityOff, Close, InfoOutlined } from '@material-ui/icons';
import { formatCurrency } from 'utils';
import { GET_TOKEN, WEB_TRANSACTION_PAYMENT_CREDIT_URL } from 'constants/url';
import { useCredit } from 'contexts/CreditContex';

import axios, { CancelTokenSource } from 'axios';
import useStyles from './styles';
import useSnackbar from 'hooks/useSnackbar';
import Alert from 'components/DialogAlert/Alert';
import Snackbar from 'components/Snackbar';
import useRouter from 'hooks/useRouter';

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core';
import NumberFormatCustom from 'components/NumberFormatCustom';

const MerchantCreditPaid = () => {
  const classes = useStyles();
  const abort: CancelTokenSource = axios.CancelToken.source();
  const { payment, ...credit } = useCredit();
  const { history } = useRouter();
  const { openSnacbar, snackbar } = useSnackbar();

  const [isShowPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [amount, setAmount] = useState(0);
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState({
    password: false,
    amount: false
  });

  const [errors, setErrors] = useState({
    password: '',
    amount: ''
  });

  const [notes, setNotes] = useState('');

  const onPasswordConfirm = async () => {
    try {
      setLoading(state => ({ ...state, password: true }));
      setErrors({ password: '', amount: '' });
      if (!password || password === '') {
        setErrors(state => ({ ...state, password: 'Invalid Password' }));
        return;
      }
      await axios.post(
        GET_TOKEN,
        {
          password,
          key: credit.data.key
        },
        { cancelToken: abort.token }
      );

      setStep(1);
    } catch (err) {
      setErrors(state => ({ ...state, password: 'Invalid Password' }));
    } finally {
      setLoading(state => ({ ...state, password: false }));
      setPassword('');
    }
  };

  const onNominalSubmit = async () => {
    try {
      setErrors(state => ({ ...state, amount: '' }));
      setLoading(state => ({ ...state, amount: true }));

      const { data } = await axios.post(
        WEB_TRANSACTION_PAYMENT_CREDIT_URL,
        { amount, merchantId: credit.data.merchantId, notes },
        { cancelToken: abort.token }
      );

      if (data.success) {
        history.push(`/transaction/credit-payment/${data.id}/detail`);
      } else {
        openSnacbar('Opps!, Something wrong please check and try again', { variant: 'warning' });
      }
    } catch (err) {
      setErrors(state => ({ ...state, amount: '' }));
    } finally {
      setLoading(state => ({ ...state, amount: false }));
      setAmount(0);
    }
  };

  const onCloseDialog = () => {
    payment.onClose();
    setPassword('');
    setAmount(0);
    setStep(0);
    setNotes('');
    setShowPassword(false);
  };

  if (credit.data.hasPaymentPending && !payment.open) {
    return (
      <Alert
        open={credit.data.hasPaymentPending && !payment.open}
        loading={false}
        type='notice'
        title='You still have payment'
        message='If you still proceed this payment the pending payment will be cancel. Are You sure want to proceed this? this action cannot be undo'
        close={{
          text: 'Cancel',
          onClick: () => {
            payment.onClose();
          }
        }}
        confirm={{
          text: 'Yes, continue',
          onClick: () => {
            credit.setOpenPayment(true);
          }
        }}
      />
    );
  }

  return (
    <Dialog open={payment.open} maxWidth='lg' className={classes.dialog} disableEscapeKeyDown>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography align='center' variant='h1' style={{ flexGrow: 1 }}>
          Payment Merchant’s Credit Used
        </Typography>

        <IconButton aria-label='close' onClick={onCloseDialog} className={classes.iconButton}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {credit.loading.key ? (
          <Box style={{ textAlign: 'center', padding: '16px' }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {step === 0 ? (
              <Slide in={step === 0} unmountOnExit>
                <Box display='flex' flexDirection='column' className={classes.box}>
                  <div className={classes.boxImage}>
                    <img src={topupIcon} alt='image_topup' />
                  </div>

                  <Box>
                    <Typography align='center' variant='h4' gutterBottom>
                      Input your password
                    </Typography>
                    <Typography align='center' variant='body2' gutterBottom>
                      Please input your password below before process Payment Merchant’s Credit Used
                    </Typography>
                    <TextField
                      variant='outlined'
                      margin='normal'
                      required
                      fullWidth
                      name='password'
                      type={isShowPassword ? 'text' : 'password'}
                      id='password'
                      value={password}
                      className={classes.inputPassword}
                      autoComplete='current-password'
                      placeholder='Input password here'
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton edge='end' aria-label='toggle password visibility' onClick={event => setShowPassword(!isShowPassword)}>
                              {isShowPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      onChange={e => setPassword(e.target.value)}
                      error={errors.password !== ''}
                      helperText={errors.password}
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          onPasswordConfirm();
                        }
                      }}
                    />
                  </Box>
                </Box>
              </Slide>
            ) : null}

            {step === 1 ? (
              <Slide in={step === 1} unmountOnExit>
                <Box display='flex' flexDirection='column' className={classes.box}>
                  <Typography component={'div'} variant='h6' align='center' gutterBottom>
                    Unpaid Credit Used
                  </Typography>
                  <Typography component={'div'} variant='h3' color='error' align='center' gutterBottom>
                    {formatCurrency(credit.data.totalUsedCredit)}
                  </Typography>
                  <Typography component={'div'} variant='subtitle1' align='center'>
                    Credit Balance {formatCurrency(credit.data.creditBalance)}
                  </Typography>

                  <div className={classes.boxNominal}>
                    <label className={classes.labelNominal}>Enter payment amount</label>
                    <TextField
                      fullWidth
                      placeholder='Example: $2,000.00'
                      variant='outlined'
                      value={amount === 0 ? null : amount}
                      InputProps={{
                        inputComponent: NumberFormatCustom as any,
                        inputProps: {
                          prefix: '$',
                          thousandSeparator: true,
                          decimalScale: 2
                        }
                      }}
                      onChange={e => {
                        setAmount(parseFloat(e.target.value) <= credit.data.totalUsedCredit ? parseFloat(e.target.value) : 0);
                      }}
                      error={errors.amount !== ''}
                    />

                    <div className={classes.textHelper}>
                      <InfoOutlined color='primary' fontSize='small' />
                      <div>Upon successful payment, merchant’s Credit Balance will be updated automatically</div>
                    </div>
                  </div>

                  <TextField
                    multiline
                    size='small'
                    label='Notes'
                    value={notes}
                    onChange={e => setNotes(e.target.value)}
                    placeholder='Add note here'
                    InputLabelProps={{ shrink: true }}
                    rows={5}
                  />
                </Box>
              </Slide>
            ) : null}
          </>
        )}

        <Snackbar {...snackbar} />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          variant='outlined'
          color='primary'
          disabled={loading.password || loading.amount || credit.loading.key}
          disableElevation
          className={classes.leftButton}
          onClick={onCloseDialog}
        >
          Cancel
        </Button>

        {step === 0 ? (
          <Button
            variant='contained'
            color='primary'
            disabled={loading.password || credit.loading.key}
            disableElevation
            className={classes.rightButton}
            onClick={onPasswordConfirm}
          >
            {loading.password ? <CircularProgress color='inherit' size={24} /> : 'Confirm'}
          </Button>
        ) : null}

        {step === 1 ? (
          <Button
            disabled={amount === 0}
            variant='contained'
            color='primary'
            disableElevation
            className={classes.rightButton}
            onClick={onNominalSubmit}
          >
            {loading.amount ? <CircularProgress color='inherit' size={24} /> : 'Continue'}
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};

export default MerchantCreditPaid;
