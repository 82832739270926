import React, { FC, useState, useMemo, useCallback, useRef } from 'react';
import axios, { CancelTokenSource } from 'axios';
import SearchIcon from '@material-ui/icons/Search';
import DateRangeIcon from '@material-ui/icons/DateRange';
import NumberFormat from 'react-number-format';
import TextFilter from 'components/TextFilter';
import SelectFilter from 'components/SelectFilter';
import TransactionExportButton from './TransactionExportButton';
import PositionedPopper from 'components/PositionedPopper';
import DateTimeRangePicker from 'components/DateTimeRangePicker';
import TrxStatusChip from 'components/TrxStatusChip';
import DataTable from 'components/DataTable';
import useFilter from 'hooks/useFilter';
import useSort from 'hooks/useSort';
import usePagination from 'hooks/usePagination';
import useFetch from 'hooks/useFetch';
import TransactionStatus from 'typings/enum/TransactionStatus';
import ActionSnackbar from 'components/ActionSnackbar';
import MainTemplate from 'components/Template/MainTemplate';
import DialogCsvExport, { TRXCSVProps } from './DialogCsvExport';
import { makeStyles, Theme, Typography, InputAdornment, IconButton, Button, Box, Paper, ClickAwayListener } from '@material-ui/core';
import { useApp } from 'contexts/AppContext';
import { trxCsv, typeTrx } from 'constants/column';
import { CSVDownload } from 'react-csv';
import { formatId, pick, ucWords } from 'utils';
import { TRANSACTIONS_WEB_BASE_URL, PROCESS_EXFERS_TRX_URL, TRANSACTIONS_BASE_URL, MERCHANTS_BASE_URL } from 'constants/url';
import { StandardConfirmationDialog } from 'components/AppDialog';
import { format } from 'date-fns';
import UserStatus from 'typings/enum/UserStatus';
import TransactionType from 'typings/enum/TransactionType';

const useStyles = makeStyles((theme: Theme) => ({
  adornment: {
    color: theme.palette.subtitle.main,
    padding: 5
  },
  button: {
    border: '1.5px solid #C1C1C1',
    borderRadius: '5px',
    color: '#C1C1C1',
    fontWeight: 500,
    fontSize: '14px',
    '& :hover': {
      color: theme.palette.primary.main
    }
  },
  buttonExport: {
    marginTop: '4px',
    padding: '6px 16px 6px 16px',
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '14px',
    borderRadius: '5px'
  },
  boxFilter: {}
}));

const TransactionsPage: FC = () => {
  const cancelSource: CancelTokenSource = axios.CancelToken.source();
  const classes = useStyles();
  const { date } = useApp();
  let interval: NodeJS.Timeout;
  const clearRef = useRef<HTMLButtonElement | null>(null);
  const closeRef = useRef<HTMLButtonElement | null>(null);
  const currentDate = new Date();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [isProcessingTrx, setIsProcessingTrx] = useState(false);
  const [selectedTrx, setSelectedTrx] = useState<TransactionModel | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [dateFilterValue, setDateFilterValue] = useState('');
  const [trxType, setTrxType] = useState('');

  const limitDate = new Date();
  limitDate.setMonth(limitDate.getMonth() - 3);

  const [columns, setColumns] = useState<TRXCSVProps['columns']>({
    column: trxCsv.map(v => v.key),
    type: typeTrx.filter(v => ![TransactionType.TOPUP, TransactionType.WITHDRAW].includes(v as TransactionType)),
    status: [],
    jobType: [],
    paymentMethod: [],
    payingMerchantId: undefined,
    paidMerchantId: undefined,
    startDate: format(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), 'yyyy-MM-dd'),
    endDate: format(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0), 'yyyy-MM-dd'),
    typeDate: 'jobDate'
  });

  const [exprtCSV, setExportCSV] = useState<{ isOpen: boolean; data: any[]; loading: boolean; percent: number; total: number; isEmpty: boolean }>({
    isOpen: false,
    data: [],
    loading: false,
    percent: 0,
    total: 0,
    isEmpty: false
  });

  const [merchants, setMerchant] = useState<TRXCSVProps['merchants']>({
    payingLoading: false,
    paidLoading: false,
    payingMerchant: [],
    paidMerchant: []
  });

  const { sortBy, sortDir, doSort: onRequestSort } = useSort('createdAt', 'desc');
  const { currentPage, rowsPerPage, count, changePage, changeRowsPerPage, setCount } = usePagination();
  const { filters, doFilter, doMultiFilter } = useFilter({
    keyword: '',
    startDate: '',
    endDate: '',
    type: [TransactionType.TOPUP, TransactionType.DECREASE_CREDIT, TransactionType.INCREASE_CREDIT, TransactionType.DECREASE_CREDIT]
  });
  const [dateBy, setDateBy] = useState('');
  const memoizedFilters = useMemo(() => filters, [filters]);
  const memoizedSetCount = useCallback(setCount, []);
  const memoizedChangePage = useCallback(changePage, []);
  const memoizedChangeRowsPerPage = useCallback(changeRowsPerPage, []);
  const { data, isLoading } = useFetch(
    TRANSACTIONS_WEB_BASE_URL,
    'transactions',
    memoizedFilters,
    currentPage,
    rowsPerPage,
    memoizedSetCount,
    sortBy,
    sortDir
  );

  const handleCalendarClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsCalendarOpen(true);
  };

  const handleTimeChange = (query: string) => {
    const splitted = query.split('|');
    const removedTime = splitted.map(dt => dt.substring(0, 10));
    setDateFilterValue(removedTime.join(' ~ '));
    doMultiFilter({
      startDate: removedTime[0],
      endDate: removedTime[1]
    });
  };

  const refreshList = () => {
    doFilter('keyword', '');
    doFilter('startDate', '');
    doFilter('startDate', '');
    doFilter('type', ['TOPUP', 'WITHDRAW']);
    setDateFilterValue('');
    setDateBy('');
    // clearRef.current?.click();
    // closeRef.current?.click();
  };

  const processTrx = async (trx: TransactionModel | null) => {
    if (!trx) return;
    try {
      setIsProcessingTrx(true);
      await axios.post(PROCESS_EXFERS_TRX_URL(trx.id), { action: trx.type === 'TOPUP' ? 'topup' : 'withdraw' });
      setIsProcessingTrx(false);
      setIsConfirmDialogOpen(false);
      setIsSnackbarOpen(true);
    } catch (error) {
      console.log(error);
    } finally {
      setIsProcessingTrx(false);
      setIsConfirmDialogOpen(false);
    }
  };

  const headers: HeaderProps[] = [
    {
      id: 'id',
      label: 'ID',
      sortable: true,
      width: '5%',
      render: (row: TransactionModel) => <Typography variant='subtitle2'>{formatId(row.id)}</Typography>
    },
    {
      id: 'createdAt',
      label: 'DateTime',
      sortable: true,
      render: (row: TransactionModel) => date(row.createdAt)
    },
    {
      id: 'type',
      label: 'Description',
      sortable: true,
      render: (row: TransactionModel) => {
        let name: string | undefined = '';
        if (row.type === TransactionType.TOPUP || row.type === TransactionType.INCREASE_CREDIT) {
          name = row.PaidMerchant!.companyName;
        } else if (row.type === TransactionType.WITHDRAW || row.type === TransactionType.DECREASE_CREDIT) {
          name = row.PayingMerchant!.companyName;
        }
        return (
          <>
            <Typography variant='subtitle2'>{row.type.replace('_', ' ')}</Typography>
            <Typography>
              {[TransactionType.TOPUP, TransactionType.INCREASE_CREDIT].includes(row.type as TransactionType) ? 'to' : 'from'} {name}'s wallet
            </Typography>
          </>
        );
      }
    },
    {
      id: 'paymentMethod',
      label: 'Payment Method',
      sortable: true,
      align: 'center',
      render: (row: TransactionModel) => (row.paymentMethod ? ucWords(row.paymentMethod.replace('_', ' ')) : 'N/A')
    },
    {
      id: 'amount',
      label: 'Amount',
      sortable: true,
      align: 'right',
      render: (row: TransactionModel) => (
        <span style={{ fontWeight: 'bold' }}>
          <NumberFormat value={row.amount} displayType='text' thousandSeparator={true} prefix='$' decimalScale={2} fixedDecimalScale={true} />
        </span>
      )
    },
    {
      id: 'status',
      label: 'Status',
      sortable: true,
      align: 'center',
      render: (row: TransactionModel) => <TrxStatusChip status={row.status as TransactionStatus} label={row.status} />
    }
  ];

  if (process.env.REACT_APP_TRX_PROCESS_BUTTONS === 'show') {
    headers.push({
      id: 'action',
      label: 'Action',
      sortable: false,
      align: 'center',
      render: (row: TransactionModel) => (
        <Button
          disabled={row.status === 'SETTLED' || row.status === 'EXPIRED'}
          color='primary'
          variant='outlined'
          className={classes.button}
          onClick={() => {
            setSelectedTrx(row);
            setIsConfirmDialogOpen(true);
          }}
        >
          {row.type === 'TOPUP' ? 'Pay Now' : 'Send Money'}
        </Button>
      )
    });
  }

  const onExport = async () => {
    try {
      setExportCSV(state => ({ ...state, loading: true, percent: 1, data: [], total: 0, isEmpty: false }));
      let params: any = {
        startDate: columns.startDate,
        endDate: columns.endDate,
        type: columns.type,
        status: columns.status,
        typeDate: columns.typeDate,
        paymentMethod: columns.paymentMethod,
        jobType: columns.jobType
      };

      if (columns.paidMerchantId) {
        params.paidMerchantId = columns.paidMerchantId;
      }

      if (columns.payingMerchantId) {
        params.payingMerchantId = columns.payingMerchantId;
      }

      const { data } = await axios.post(`${TRANSACTIONS_BASE_URL}/all`, params);
      setExportCSV(state => ({ ...state, percent: 25 }));
      let csv: any[] = [];
      if (data.length > 0) {
        if (trxCsv.length === columns.column.length) {
          setExportCSV(state => ({ ...state, data, percent: 0 }));
        } else {
          setExportCSV(state => ({ ...state, total: data.length }));
          let i = 0;
          interval = setInterval(() => {
            i++;
            if (data[i]) {
              csv.push(pick(data[i], columns.column));
            }

            if (i === data.length) {
              clearInterval(interval);
              setExportCSV(state => ({ ...state, data: csv, loading: false }));
            } else {
              const percent = Math.round(((i + 1) / data.length) * 100) - 25;
              if (percent > 0) {
                setExportCSV(state => ({ ...state, percent: percent + 25 }));
              }
            }
          }, 100);
        }
      } else {
        setExportCSV(state => ({ ...state, data: [], percent: 0, loading: false, isEmpty: true }));
      }
    } catch (error) {
      setExportCSV(state => ({ ...state, loading: false }));
    } finally {
      setExportCSV(state => ({ ...state, loading: false }));
    }
  };

  const onSearch = async (keyword: string, type: 'paying' | 'paid') => {
    try {
      const { data } = await axios.get(MERCHANTS_BASE_URL, {
        params: {
          queryCompany: keyword,
          status: UserStatus.APPROVED,
          s: 0,
          l: 100
        },
        cancelToken: cancelSource.token
      });

      if (type === 'paying') {
        setMerchant(state => ({ ...state, payingMerchant: data.merchants.map((v: any) => ({ id: v.id, name: v.companyName })) }));
      }

      if (type === 'paid') {
        setMerchant(state => ({ ...state, paidMerchant: data.merchants.map((v: any) => ({ id: v.id, name: v.companyName })) }));
      }
    } catch (error) {
    } finally {
    }
  };

  const memoizedHeaders = useMemo(() => headers, [headers]);
  const memoizedData = useMemo(() => data, [data]);

  return (
    <MainTemplate
      title='Transactions History'
      subtitle='Displays data of all transactions that have been made'
      primaryButton={false}
      refreshButtonProps={{ onClick: refreshList }}
    >
      <Box display='flex' flexDirection={'column'} style={{ gap: '16px' }}>
        <Box display='flex' justifyContent='space-between' alignItems='center' className={classes.boxFilter}>
          <div style={{ width: '286px' }}>
            <TextFilter
              fullWidth={true}
              value={memoizedFilters.keyword}
              onChange={event => {
                doFilter('keyword', event.target.value);
              }}
              placeholder='Search by trx id or name'
              startAdornment={
                <InputAdornment className={classes.adornment} position='start'>
                  <SearchIcon fontSize='small' />
                </InputAdornment>
              }
            />
          </div>

          <div style={{ flexGrow: 1, display: 'flex', gap: '8px', justifyContent: 'flex-end', alignItems: 'center' }}>
            <TextFilter
              value={dateFilterValue}
              onChange={() => {}}
              readOnly={true}
              placeholder='Filter by date'
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton aria-label='toggle date-time-picker-range' onClick={handleCalendarClick}>
                    <DateRangeIcon />
                  </IconButton>
                </InputAdornment>
              }
            />

            <SelectFilter
              label='Transaction Type'
              width='30%'
              value={trxType}
              onChange={event => {
                const value = event.target.value as string;
                setTrxType(value);
                if (value) {
                  doFilter('type', value);
                } else {
                  doFilter('type', ['TOPUP', 'WITHDRAW']);
                }
              }}
              options={[
                { value: TransactionType.TOPUP, label: 'Topup' },
                { value: TransactionType.WITHDRAW, label: 'Withdraw' },
                { value: TransactionType.INCREASE_CREDIT, label: 'Increase Credit Balance' },
                { value: TransactionType.DECREASE_CREDIT, label: 'Deacrease Credit Balance' }
              ]}
            />

            <div>
              <TransactionExportButton
                filters={memoizedFilters}
                sortBy={sortBy}
                sortDir={sortDir}
                className={classes.buttonExport}
                onAdvanceExport={() => {
                  setExportCSV({ isOpen: true, data: [], loading: false, percent: 0, total: 0, isEmpty: false });
                }}
              />
            </div>
          </div>
        </Box>

        <Paper elevation={0}>
          <DataTable
            isLoading={isLoading}
            headers={memoizedHeaders}
            data={memoizedData}
            count={count}
            rowsPerPage={rowsPerPage}
            currentPage={currentPage}
            onChangePage={memoizedChangePage}
            onChangeRowsPerPage={memoizedChangeRowsPerPage}
            sortBy={sortBy}
            sortDir={sortDir}
            onRequestSort={onRequestSort}
            showFilterRow={false}
          />
        </Paper>

        <ClickAwayListener onClickAway={() => setIsCalendarOpen(false)} mouseEvent='onMouseDown' touchEvent='onTouchStart'>
          <div>
            <PositionedPopper open={isCalendarOpen} anchorEl={anchorEl} placement='bottom' containerWidth={200} fadeTransition={350} keepMounted>
              <DateTimeRangePicker
                mode='date'
                dateBy={dateBy}
                clearRef={clearRef}
                closeRef={closeRef}
                disabledCustomDate={dateBy !== '5'}
                setOpenPopper={setIsCalendarOpen}
                options={[
                  { key: '1', label: 'Today' },
                  { key: '2', label: 'Tomorrow' },
                  { key: '3', label: 'This Week' },
                  { key: '4', label: 'This Month' },
                  { key: '5', label: 'Custom Date' }
                ]}
                onChange={handleTimeChange}
                onSelectedChange={value => setDateBy(value)}
                onClear={() => {
                  setDateBy('');
                }}
              />
            </PositionedPopper>
          </div>
        </ClickAwayListener>
      </Box>

      {process.env.REACT_APP_TRX_PROCESS_BUTTONS === 'show' ? (
        <>
          <StandardConfirmationDialog
            variant='warning'
            titleMessage='Process this transaction?'
            message='This will simulate payment or withdrawal on Xfers'
            confirmButtonText='Confirm'
            open={isConfirmDialogOpen}
            loading={isProcessingTrx}
            handleClose={() => setIsConfirmDialogOpen(false)}
            onConfirm={() => processTrx(selectedTrx)}
          />
          <ActionSnackbar
            variant='success'
            message='Request sent! Please wait until transaction is settled.'
            open={isSnackbarOpen}
            handleClose={() => {
              setIsSnackbarOpen(false);
            }}
          />
        </>
      ) : null}

      <DialogCsvExport
        cancelSource={cancelSource}
        total={exprtCSV.total}
        open={exprtCSV.isOpen}
        columns={columns}
        loading={exprtCSV.loading}
        isEmpty={exprtCSV.isEmpty}
        merchants={merchants}
        limitDate={limitDate}
        onSearch={onSearch}
        setColumns={setColumns}
        onExport={onExport}
        onClose={() => {
          setColumns({
            column: trxCsv.map(v => v.key),
            type: [],
            status: [],
            jobType: [],
            paymentMethod: [],
            startDate: format(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), 'yyyy-MM-dd'),
            endDate: format(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0), 'yyyy-MM-dd'),
            typeDate: 'jobDate'
          });

          setExportCSV({ isOpen: false, data: [], loading: false, percent: 0, total: 0, isEmpty: false });
        }}
      />
      {exprtCSV.data.length > 0 ? <CSVDownload headers={trxCsv.filter(v => columns.column.includes(v.key))} data={exprtCSV.data} /> : null}
    </MainTemplate>
  );
};

export default TransactionsPage;
