import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import axios, { CancelTokenSource } from 'axios';
import { Box, Theme, Typography } from '@material-ui/core';
import { Switch } from 'react-router';
import { makeStyles } from '@material-ui/styles';

import ConditionalRoute from 'components/ConditionalRoute';
import AppHeader from 'components/AppHeader';
import AppDrawer from 'components/AppDrawer';

import { CurrentUserProvider } from 'contexts/CurrentUserContext';
import { AppProvider } from 'contexts/AppContext';
import { isUserAuthenticated } from 'selectors';
import { attachTokenToHeader, detachTokenFromHeader } from 'utils/AxiosUtils';
import { GET_CURRENT_USER_URL } from 'constants/url';
import router from 'router';

//TEST DISINI
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex'
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '95vh',
    overflow: 'auto',
    background: '#F7F9FC'
  },
  contentLogin: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    background: '#F7F9FC'
  },
  footerPaddingIsLoggedIn: {
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(2)
  }
}));

const App: React.FC = () => {
  const classes = useStyles();

  const [CurrentUserData, setCurrentUserData] = useState<CurrentUser>();
  const [isAuthenticating, setAuthenticating] = useState(true);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const isLoggedIn = isUserAuthenticated(CurrentUserData);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const setCurrentUser = (currentUser: CurrentUser, token: string): void => {
    localStorage.setItem('token', token);
    attachTokenToHeader(token);
    setCurrentUserData(currentUser);
  };

  const unsetCurrentUser = (): void => {
    localStorage.removeItem('token');
    detachTokenFromHeader();
    setCurrentUserData(undefined);
  };

  useEffect(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    const getCurrentUserData = async () => {
      setAuthenticating(true);
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.get(GET_CURRENT_USER_URL, {
            headers: { Authorization: `Bearer ${token}` },
            cancelToken: cancelTokenSource.token
          });
          const currentUser: CurrentUser = response.data;
          setCurrentUser(currentUser, token);
        } catch (err) {
          unsetCurrentUser();
        }
      }
      setAuthenticating(false);
    };
    getCurrentUserData();
    return () => {
      cancelTokenSource.cancel();
    };
  }, []);

  return isAuthenticating ? null : (
    <Box>
      <CurrentUserProvider
        value={{
          currentUser: CurrentUserData,
          setCurrentUser,
          unsetCurrentUser
        }}
      >
        <AppProvider>
          <div className={classes.root}>
            {isLoggedIn && (
              <>
                <AppHeader open={openDrawer} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} />
                <AppDrawer openDrawer={openDrawer} />
              </>
            )}
            <main className={isLoggedIn ? classes.contentLogin : classes.content}>
              {isLoggedIn && <div className={classes.appBarSpacer} />}
              <Switch>
                {router(isLoggedIn).map((route, i) => (
                  <ConditionalRoute key={i} {...route} exact />
                ))}
              </Switch>
            </main>
          </div>
        </AppProvider>
      </CurrentUserProvider>
      {!isLoggedIn && (
        <Typography
          variant={'h6'}
          color='textSecondary'
          align={!isLoggedIn ? 'center' : 'right'}
          className={clsx({ [classes.footerPaddingIsLoggedIn]: isLoggedIn })}
        >
          {'© ' + new Date().getFullYear() + ' JustGO All Rights Reserved'}
        </Typography>
      )}
    </Box>
  );
};

export default App;
