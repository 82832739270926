import React from 'react';
import { makeStyles, Theme, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  condition: () => boolean;
  onClick: () => void;
  label: string;
  to: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  idLink: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  }
}));

export default function DivOrLink({ condition, onClick, to, label }: Props) {
  const classes = useStyles();
  return condition() ?
    (<div className={classes.idLink} onClick={onClick}>{label}</div>) :
    (<Link component={RouterLink} to={to}>{label}</Link>);
}
