import { Box, Button, CircularProgress, Divider, Typography, Paper, CardHeader, FormHelperText } from '@material-ui/core';
import React, { useRef } from 'react';
import topupIcon from 'images/icon/topup.svg';
import useStyles from './styles';
import TransactionStatus from 'typings/enum/TransactionStatus';

interface TopupConfirmProps {
  otp: string;
  loading: boolean;
  message: string;
  status: string;
  onClear: (index: number) => void;
  onInput: (value: string) => void;
  onConfirm: () => void;
  onBackStep: () => void;
}

const TopupConfirm = ({ otp, loading, message, status, onInput, onClear, onConfirm, onBackStep }: TopupConfirmProps) => {
  const classes = useStyles();
  const ref = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <Paper elevation={0} className={classes.wrapper}>
      <CardHeader title='Confirmation Adjust Balance' titleTypographyProps={{ variant: 'h4', align: 'center' }} />
      <Box display='flex' flexDirection='column' className={classes.box}>
        <div className={classes.boxImage}>
          <img src={topupIcon} alt='image_topup' />
        </div>

        <Box className={classes.boxContent}>
          <Typography align='center' variant='h4' gutterBottom>
            Input OTP Verification
          </Typography>
          <Typography align='center' variant='body2' gutterBottom>
            Please input OTP code that we have sent to email to procecss top up balance
          </Typography>

          <FormHelperText style={{ textAlign: 'center', fontWeight: 600 }} error={message !== ''}>
            {message}
          </FormHelperText>

          <div ref={ref} className={classes.boxPin}>
            {[0, 1, 2, 3, 4, 5].map(value => (
              <div key={value}>
                <input
                  ref={inputRef}
                  id={`pin-${value}`}
                  type='text'
                  name={`pin-${value}`}
                  value={otp.split('')[value] || ''}
                  maxLength={1}
                  className={classes.inputPin}
                  autoFocus={false}
                  style={{ textTransform: 'uppercase' }}
                  onChange={e => {
                    onInput(e.target.value);
                  }}
                  onPaste={e => {
                    const dataPaste = e.clipboardData.getData('text');
                    onInput(e.clipboardData.getData('text'));
                    const next = ref.current?.children.item(dataPaste.length <= 1 ? 0 : dataPaste.length - 2)?.firstChild;
                    inputRef.current = next as HTMLInputElement;
                    inputRef.current.focus();
                  }}
                  onKeyUp={e => {
                    if (value !== 5 && e.key !== 'Backspace') {
                      const next = ref.current?.children.item(value + 1)?.firstChild;
                      inputRef.current = next as HTMLInputElement;
                      inputRef.current.focus();
                    }

                    if (e.key === 'Backspace') {
                      onClear(value);
                      if (value !== 0) {
                        const next = ref.current?.children.item(value - 1)?.firstChild;
                        inputRef.current = next as HTMLInputElement;
                        inputRef.current.focus();
                      }
                    }
                  }}
                />
              </div>
            ))}
          </div>

          <Divider />

          <Button
            disabled={otp.length !== 6 || status !== TransactionStatus.PENDING}
            variant='contained'
            color='primary'
            disableElevation
            fullWidth
            className={classes.buttonConfirm}
            onClick={onConfirm}
          >
            {loading ? <CircularProgress size={32} /> : 'Confirm'}
          </Button>

          <Button
            variant='text'
            color='inherit'
            fullWidth
            disableElevation
            disableFocusRipple
            disableRipple
            disableTouchRipple
            onClick={onBackStep}
            className={classes.cancelButton}
          >
            Back
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default TopupConfirm;
