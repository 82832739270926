import React, { FC } from 'react';
import {
  Theme,
  makeStyles,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableContainer,
  InputAdornment,
  Typography,
  Divider,
  TableFooter,
  TablePagination
} from '@material-ui/core';

import HeaderRow from 'components/HeaderRow';
import BodyRow from './components/BodyRow';

import { dummyAdditonalTemplates } from 'constants/DummyData';
import { Search } from '@material-ui/icons';

interface Props {
  additionalItems: AdditionalTemplates[];
  isLoadingData: boolean;
  count: number;
  currentPage: number;
  rowsPerPage: number;
  order: 'asc' | 'desc';
  orderBy: string;
  queryId?: string;
  queryName: string;
  handleEdit: (id: number) => () => void;
  handleQueryIdChange: (type: string) => void;
  handleQueryNameChange: (type: string) => void;
  handleRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
  handleChangeRowsPerPage: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}

const useStyles = makeStyles((theme: Theme) => ({
  rootTabs: {
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
    borderRadius: '5px',
    marginBottom: '24px'
  },
  title: {
    padding: '16px'
  }
}));

const AdditionalTable: FC<Props> = props => {
  const classes = useStyles();
  const { additionalItems, isLoadingData, count, currentPage, rowsPerPage, order, orderBy, queryId, queryName } = props;
  const { handleEdit, handleQueryIdChange, handleQueryNameChange, handleRequestSort, handleChangePage, handleChangeRowsPerPage } = props;

  return (
    <Paper elevation={0} className={classes.rootTabs}>
      <Typography variant='h4' className={classes.title}>
        Additional Items
      </Typography>
      <Divider />
      <TableContainer style={{ boxShadow: 'none' }}>
        <Table size='small'>
          <TableHead>
            <HeaderRow
              height={'0px'}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={[
                { id: 'id', label: 'ID', sort: true, pT: '16px', pB: '16px', cellProps: { style: { whiteSpace: 'nowrap', width: '10%' } } },
                { id: 'name', label: 'Item Name', sort: true, pT: '16px', pB: '16px', cellProps: { style: { whiteSpace: 'nowrap' } } },
                { id: 'description', label: 'Description', pT: '16px', pB: '16px' },

                {
                  id: 'price',
                  label: 'Unit Price',
                  sort: true,
                  pT: '16px',
                  pB: '16px',
                  align: 'center',
                  cellProps: { padding: 'checkbox', style: { whiteSpace: 'nowrap' } }
                },
                {
                  id: 'action',
                  cellProps: { padding: 'checkbox', style: { whiteSpace: 'nowrap' } }
                }
              ]}
            />

            <HeaderRow
              height={'0px'}
              headers={[
                {
                  id: 'findId',
                  isInputText: true,
                  value: queryId,
                  handleInputChange: handleQueryIdChange,
                  pT: '0px',
                  pB: '0px',
                  endAdornment: (
                    <InputAdornment position='start'>
                      <Search fontSize='small' color='disabled' />
                    </InputAdornment>
                  )
                },
                {
                  id: 'findName',
                  isInputText: true,
                  value: queryName,
                  handleInputChange: handleQueryNameChange,
                  pT: '0px',
                  pB: '0px',
                  endAdornment: (
                    <InputAdornment position='start'>
                      <Search fontSize='small' color='disabled' />
                    </InputAdornment>
                  )
                },
                {
                  id: 'findDescription'
                },
                {
                  id: 'findPrice'
                },
                {
                  id: 'findAction'
                }
              ]}
            />
          </TableHead>

          <TableBody>
            {isLoadingData ? (
              [0, 1, 2, 3].map(index => (
                <BodyRow key={index} additionalItems={dummyAdditonalTemplates} isLoadingData={isLoadingData} handleEdit={handleEdit(index)} />
              ))
            ) : additionalItems && additionalItems.length > 0 ? (
              additionalItems.map((value, index) => (
                <BodyRow key={index} additionalItems={value} isLoadingData={isLoadingData} handleEdit={handleEdit(value.id)} />
              ))
            ) : (
              <TableRow>
                <TableCell align='center' colSpan={5}>
                  Data Not Available.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={5}
                rowsPerPageOptions={[5, 10, 15]}
                count={count}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default AdditionalTable;
