import React, { FC } from 'react';
import { Theme, TableRow, Checkbox, TextField, TableCellProps, ListSubheader } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';
import MenuItem from '@material-ui/core/MenuItem';
import HeaderCell from './HeaderCell';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxOutlinedUncompletedIcon from '@material-ui/icons/IndeterminateCheckBox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import clsx from 'clsx';

export interface Header {
  id: string;
  label?: string;
  pT?: string;
  pR?: string;
  pB?: string;
  pL?: string;
  verticalAlign?: 'top' | 'middle' | 'bottom';
  isCheckBox?: boolean;
  isInputText?: boolean;
  isSelect?: boolean;
  isGroupSelect?: boolean;
  selectOption?: Select[];
  checked?: number[];
  rowsPerPage?: number;
  align?: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;
  sort?: boolean;
  handleCheckAll?: () => void;
  handleOptionChange?: (selected: string) => void;
  handleInputChange?: (inputed: string) => void;
  endAdornment?: React.ReactNode;
  readOnly?: boolean;
  value?: string;
  cellProps?: TableCellProps;
}

interface Props {
  headers: Header[];
  height?: number | string;
  order?: 'asc' | 'desc';
  orderBy?: string;
  onRequestSort?: (event: React.MouseEvent<unknown>, property: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  tableRow: (props: Props) => ({
    height: props.height ? props.height : 64
  }),
  checkBox: {
    '&&:hover': {
      backgroundColor: 'transparent'
    }
  },
  checkBoxSize: {
    fontSize: '16px'
  },
  notched: {
    borderRadius: '2.5px'
  },
  rootInput: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
      margin: '8px 0px 8px 0px',
      padding: 0
    }
  },
  textField: {
    '&.MuiFormControl-marginDense': {
      margin: 0
    }
  },
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const HeaderRow: FC<Props> = props => {
  const classes = useStyles(props);
  const { headers, orderBy, order, onRequestSort } = props;

  const renderContent = () => {
    return headers.map(header => {
      const countChecked = header.checked && header.checked.length;
      const totalShouldBeChecked = header.rowsPerPage && header.rowsPerPage;
      const renderCheckBoxIcon = () => {
        if (countChecked !== 0) {
          if (countChecked !== totalShouldBeChecked) {
            return <CheckBoxOutlinedUncompletedIcon className={classes.checkBoxSize} />;
          } else {
            return <CheckBoxIcon className={classes.checkBoxSize} />;
          }
        }
      };

      return (
        <HeaderCell
          key={header.id}
          pL={header.pL}
          pR={header.pR}
          pT={header.pT}
          pB={header.pB}
          align={header.align}
          verticalAlign={header.verticalAlign}
          isCheckBox={header.isCheckBox}
          orderBy={orderBy}
          order={order}
          sort={header.sort}
          id={header.id}
          onRequestSort={onRequestSort!}
          cellProps={header.cellProps}
        >
          {header.isCheckBox ? (
            <Checkbox
              key={header.id}
              icon={<CheckBoxOutlineBlankIcon className={classes.checkBoxSize} />}
              checkedIcon={renderCheckBoxIcon()}
              edge='start'
              color='primary'
              className={classes.checkBox}
              checked={countChecked !== 0 ? true : false}
              tabIndex={-1}
              disableRipple
              onChange={header.handleCheckAll}
            />
          ) : header.isInputText ? (
            <TextField
              fullWidth
              key={header.id}
              variant='outlined'
              margin='dense'
              value={header.value || ''}
              onChange={event => (header.handleInputChange ? header.handleInputChange(event.target.value as string) : '')}
              InputProps={{
                endAdornment: header.endAdornment,
                readOnly: header.readOnly,
                classes: {
                  notchedOutline: classes.notched
                }
              }}
              classes={{ root: classes.rootInput }}
              className={clsx(classes.textField)}
              placeholder='Search...'
            />
          ) : header.isSelect ? (
            <TextField
              key={header.id}
              variant='outlined'
              margin='dense'
              select
              fullWidth
              value={header.value || '00'}
              onChange={event =>
                header.handleOptionChange ? header.handleOptionChange(event.target.value === '00' ? '' : (event.target.value as string)) : ''
              }
              InputProps={{
                endAdornment: header.endAdornment,
                readOnly: header.readOnly,
                classes: {
                  notchedOutline: classes.notched
                }
              }}
              classes={{ root: classes.rootInput }}
              className={clsx(classes.textField)}
            >
              <MenuItem key={`${header.id}-00`} value='00' selected>
                <em>{header.label}</em>
              </MenuItem>
              {header.selectOption && header.selectOption.length > 0
                ? header.selectOption.map((option, index) => (
                    <MenuItem key={`${header.id}-0${index}`} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))
                : ''}
            </TextField>
          ) : header.isGroupSelect ? (
            <TextField
              key={header.id}
              variant='outlined'
              margin='dense'
              select
              fullWidth
              value={header.value || '00'}
              onChange={event =>
                header.handleOptionChange ? header.handleOptionChange(event.target.value === '00' ? '' : (event.target.value as string)) : ''
              }
              InputProps={{
                endAdornment: header.endAdornment,
                readOnly: header.readOnly,
                classes: {
                  notchedOutline: classes.notched
                }
              }}
              classes={{ root: classes.rootInput }}
              className={clsx(classes.textField)}
            >
              <MenuItem key={`${header.id}-00`} value='00' selected>
                <em>{header.label}</em>
              </MenuItem>
              {header.selectOption && header.selectOption.length > 0
                ? header.selectOption.map((option, index) =>
                    option.isHead ? (
                      <ListSubheader style={{ lineHeight: '24px' }} key={`${header.id}-0${index}`}>
                        {option.name}
                      </ListSubheader>
                    ) : (
                      <MenuItem key={`${header.id}-0${index}`} value={option.id}>
                        {option.name}
                      </MenuItem>
                    )
                  )
                : ''}
            </TextField>
          ) : (
            header.label
          )}
        </HeaderCell>
      );
    });
  };
  return <TableRow className={classes.tableRow}>{renderContent()}</TableRow>;
};

export default HeaderRow;
