import React from 'react';
import clsx from 'clsx';
import { Chip, makeStyles } from '@material-ui/core';
import JobType from 'typings/enum/JobType';
import ou from 'images/icon/OU.svg';
import trip from 'images/icon/Trip.svg';
import clock from 'images/icon/clock-fill.svg';
import { jobTypeLabel, workTypeLabel } from 'utils';

interface Props {
  type: JobType;
  workType?: string;
  minWidth?: string;
}

const useStyles = makeStyles({
  chip: {
    minWidth: (props: Props) => props.minWidth || '96px',
    backgroundColor: '#F4F4F4',
    color: '#333333',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '150%',
    letterSpacing: '0.4px',
    borderRadius: '30px',
    border: `1px solid #333333`,
    height: 'auto'
  },
  label: {
    padding: '5px 15px 5px 8px'
  },
  icon: {
    paddingLeft: '11px'
  }
});

export default function JobChip(props: Props) {
  const classes = useStyles(props);
  const { type, workType } = props;

  const label = (value, wt) => {
    if (!!wt) {
      return `${jobTypeLabel(value)} ${workTypeLabel(wt)}`;
    }

    return jobTypeLabel(value);
  };

  return (
    <Chip
      variant='outlined'
      label={label(type, workType)}
      icon={<img src={type === JobType.TRIPWORKS ? trip : type === JobType.DAYWORKS ? clock : ou} alt='' />}
      classes={{ label: classes.label, icon: classes.icon }}
      className={clsx(classes.chip)}
    />
  );
}
