import React, { FC } from 'react';
import { TableCell, TableSortLabel, Theme, makeStyles, TableCellProps } from '@material-ui/core';
import clsx from 'clsx';

interface Props {
  id?: string;
  pL?: string;
  pR?: string;
  pT?: string;
  pB?: string;
  align?: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;
  verticalAlign?: 'top' | 'middle' | 'bottom';
  isCheckBox?: boolean;
  orderBy?: string;
  order?: 'asc' | 'desc';
  sort?: boolean;
  cellProps?: TableCellProps;
  onRequestSort?: (event: React.MouseEvent<unknown>, property: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '150%',
    letterSpacing: '0.5px',
    color: 'rgba(0, 0, 0, 0.87)'
  },

  headerCellText: {
    fontWeight: 500,
    color: theme.palette.grey.A200
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  cellStyle: (props: Props) => ({
    paddingLeft: props.pL === '' || props.pL === null ? theme.spacing(0) : props.pL,
    paddingRight: props.pR === '' || props.pR === null ? theme.spacing(0) : props.pR,
    paddingTop: props.pT === '' || props.pT === null ? theme.spacing(0) : props.pT,
    paddingBottom: props.pB === '' || props.pB === null ? theme.spacing(0) : props.pB,
    verticalAlign: props.verticalAlign === undefined ? 'middle' : props.verticalAlign
  })
}));

const HeaderCell: FC<Props> = props => {
  const { id, pL, pR, pT, pB, order, orderBy, align, sort, onRequestSort, cellProps } = props;
  const classes = useStyles(props);

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort!(event, property);
  };

  return (
    <TableCell align={align} variant='head' className={clsx(classes.root, { [classes.cellStyle]: pL || pR || pT || pB })} {...cellProps}>
      {sort && sort! ? (
        <TableSortLabel active={orderBy === id} direction={orderBy === id ? order : 'asc'} onClick={createSortHandler(id ? id : '')}>
          {props.children}
          {orderBy === id ? <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span> : null}
        </TableSortLabel>
      ) : (
        props.children
      )}
    </TableCell>
  );
};

export default HeaderCell;
