import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  mainText: {
    textDecoration: 'underline',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.17px'
  },
  subText: {
    color: theme.palette.black[50],
    textTransform: 'none',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.17px'
  },
  '@keyframes tilt-shaking': {
    '0%': { transform: 'rotate(0deg)' },
    '25%': { transform: 'rotate(5deg)' },
    '50%': { transform: 'rotate(0eg)' },
    '75%': { transform: 'rotate(-5deg)' },
    '100%': { transform: 'rotate(0deg)' }
  },
  infoIcon: {
    animation: '$tilt-shaking 0.3s infinite',
    fontSize: '16px'
  },
  checkBox: {
    '&&:hover': {
      backgroundColor: 'transparent'
    }
  },
  notched: {
    borderRadius: '2.5px'
  },
  rootInput: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
      margin: '8px 0px 8px 0px',
      padding: 0
    }
  },
  textField: {
    '&.MuiFormControl-marginDense': {
      margin: 0
    }
  },
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  table: {
    '& > thead > tr:nth-child(2) > th': {
      padding: '4px 4px 4px 4px'
    }
  }
}));

export default useStyles;
