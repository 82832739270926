import React, { useState } from 'react';
import { Button, makeStyles, Theme, Menu, MenuItem, useMediaQuery, FormControlLabel, Checkbox, ListItemText } from '@material-ui/core';
import CardContent from 'components/CardContent';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FileModal from 'components/FileModal';
import UserStatus from 'typings/enum/UserStatus';
import { ChevronLeft } from '@material-ui/icons';
import { ccFormat } from 'utils';
import axios from 'axios';
import Alert from 'components/DialogAlert/Alert';
import useAlert from 'hooks/useAlert';
import useSnackbar from 'hooks/useSnackbar';
import Snackbar from 'components/Snackbar';
import { USER_BASE_URL } from 'constants/url';
import CompanyTypeChip from 'components/CompanyTypeChip';

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    marginBottom: '24px'
  },
  button: {
    fontWeight: 510,
    fontSize: '14px',
    lineHeight: '150%',
    letterSpacing: '0.25px',
    textTransform: 'none'
  },
  icon: {
    marginRight: '8px',
    width: '24px',
    height: '24px'
  },
  seeDocument: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '150%',
    letterSpacing: '0.25px',
    textTransform: 'none',
    padding: 0
  },
  action: {
    transform: 'rotate(-90deg)',
    marginLeft: '8px'
  }
}));

export interface MerchantInfoProps extends MerchantModel {
  isLoadingData: boolean;
  merchantUser: UserDetailsModel;
  userStatus: UserStatusHistoriesModel[];
  onEditable: () => void;
  onEnableAccount: () => void;
  onDisableAccount: () => void;
  onDeleteAccount: () => void;
  onOpenDownloadEarnSummary: () => void;
  onOpenSendEarnSummary: () => void;
  onOpenDownloadSpendingSummary: () => void;
}

const MerchantInfo = ({
  onEditable,
  onEnableAccount,
  onDisableAccount,
  onDeleteAccount,
  onOpenDownloadEarnSummary,
  onOpenSendEarnSummary,
  onOpenDownloadSpendingSummary,
  merchantUser,
  userStatus,
  isLoadingData,
  ...props
}: MerchantInfoProps) => {
  const classes = useStyles();
  const { openSnacbar, snackbar } = useSnackbar();
  const alert = useAlert();
  const [openFile, setOpenFile] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.between('xs', 'md'));

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const resetPassword = () => {
    setAnchorEl(null);
    alert.set({
      open: true,
      title: 'Reset Password for Login Dashboard',
      message: 'You will reset the password for this merchant, the password will change to the phone number',
      close: {
        text: 'Cancel',
        onClick: () => {
          alert.reset();
        }
      },
      confirm: {
        text: 'Confirm',
        onClick: async () => {
          try {
            alert.set({ loading: true });
            await axios.get(`${USER_BASE_URL}/merchantpassword?userId=${props.UserId}`);
            openSnacbar('Password han been reset');
          } catch (error) {
            openSnacbar(error.message);
          } finally {
            alert.reset();
          }
        }
      }
    });
  };


  let additionalContacts:any = [[], []];

  if(!!props.AdditionalContacts?.length){
    props.AdditionalContacts.forEach((value) => {
          if(additionalContacts[0] && additionalContacts[0].length < 2){
            additionalContacts[0].push({title: value.value, value: data => value.allowSend ? 'Enable receive email' : 'Unable receive email'})
          } else {
            additionalContacts[1].push({title: value.value, value: data => value.allowSend ? 'Enable receive email' : 'Unable receive email'})

          }
    })
  } else {
    additionalContacts = [[{title: '',value: () => 'Additional contact not available'}]]
  }

  return (
    <>
      <CardContent
        wrapperProps={{ className: classes.box }}
        headerProps={{
          action: (
            <>
              <Button
                disabled={isLoadingData}
                disableElevation
                id='basic-button'
                variant='contained'
                color='primary'
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                Action <ChevronLeft className={classes.action} />
              </Button>
              <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button'
                }}
              >
                <MenuItem onClick={onEditable}>Edit Owners Data</MenuItem>
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    if (merchantUser.status === UserStatus.DISABLED || merchantUser.requestDelete) {
                      onEnableAccount();
                    } else {
                      onDisableAccount();
                    }
                  }}
                >
                  {merchantUser.status === UserStatus.DISABLED || merchantUser.requestDelete ? 'Enable Account' : 'Disable Account'}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    onOpenDownloadSpendingSummary();
                    handleClose();
                  }}
                >
                  Export Spending Summary
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    onOpenDownloadEarnSummary();
                    handleClose();
                  }}
                >
                  Export Earning Summary
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    onOpenSendEarnSummary();
                    handleClose();
                  }}
                >
                  Send Earning Summary
                </MenuItem>
                <MenuItem onClick={resetPassword}>Reset Password for Login Admin Dashboard</MenuItem>
                {merchantUser.requestDelete ? (
                  <MenuItem
                    onClick={() => {
                      onDeleteAccount();
                      handleClose();
                    }}
                  >
                    Delete Account
                  </MenuItem>
                ) : null}
              </Menu>
            </>
          )
        }}
        title='Company Information'
        divider={true}
        data={props}
        direction={isSmall ? 'column' : 'row'}
        column={[
          [
            { title: 'Company Name', value: data => data.companyName },
            {
              title: 'ACRA Company',
              value: data => (
               data.companyFile ?  <Button variant='text' color='primary' size='small' className={classes.seeDocument} onClick={() => setOpenFile(true)}>
               <VisibilityIcon className={classes.icon} /> See Document
             </Button>: 'No documents available'
              )
            },
            { title: 'GST Number', value: data => data.companyGst || '-' }
          ],
          [
            {
              title: 'Company Type',
              value: data =>
                <div style={{display: 'flex', flexDirection: "row", flexWrap: 'wrap', gap: '4px'}}>{!!data.MerchantTypes?.length
                  ?  data.MerchantTypes.map(v =><div key={v.type }>
                    <CompanyTypeChip type={v.type} />
                  </div> )
                  : 'N/A'}</div>
            },
            { title: 'Phone Number', value: data => data.User!.companyPhone || '-' },
            { title: 'UEN Number', value: data => data.companyUen || '-' },
            { title: 'Address', value: data => data.User!.address || '-' }
          ]
        ]}
      />

      <CardContent
        wrapperProps={{ className: classes.box }}
        title='Personal Information'
        divider={true}
        data={props}
        direction={isSmall ? 'column' : 'row'}
        column={[
          [ 
            { title: 'Display Name', value: data => data.User!.displayName || '-' },
          { title: 'Email Address', value: data => data.User!.email || '-' }
        ]
          ,
          [ { title: 'Phone Number', value: data => data.User!.contactNumber || '-' },
          { title: 'Receive Email', value: data => data.allowSend ? 'Yes' : 'No'}
          ]
        ]}
      />

<CardContent
        wrapperProps={{ className: classes.box }}
        title='Additional Contact'
        divider={true}
        data={props}
        direction={isSmall ? 'column' : 'row'}
        column={additionalContacts}
      />

      <CardContent
        divider={true}
        wrapperProps={{ className: classes.box }}
        title='Bank Account'
        data={props}
        direction={isSmall ? 'column' : 'row'}
        column={[
          [{ title: 'Bank Name', value: data => data.bankName || '-' }, { title: 'Account Number', value: data => data.bankAccountNumber || '-' }],
          [{ title: 'Account Name', value: data => data.bankAccountName || '-' }]
        ]}
      />

      <CardContent
        wrapperProps={{ className: classes.box }}
        title='Virtual Account'
        data={props}
        direction={isSmall ? 'column' : 'row'}
        column={[
          [
            { title: 'Bank Name', value: data => data.vaBankName || '-' },
            { title: 'Account Number', value: data => (data.vaNumber ? ccFormat(data.vaNumber) : '-') }
          ],
          [{ title: 'Payee Name', value: data => 'Justgo' }]
        ]}
      />

      <FileModal title='ACRA Company file' open={openFile} setOpen={setOpenFile} fileUrl={props.companyFileUrl || ''} fileName={props.companyFile} />

      <Alert {...alert.alert} />
      <Snackbar {...snackbar} />
    </>
  );
};

export default MerchantInfo;