import JobType from 'typings/enum/JobType';
import TransactionStatus from 'typings/enum/TransactionStatus';

export const dummySelect: Select = {
  id: 0,
  name: ''
};

export const dummySetting: SettingModel = {
  id: 0,
  code: '',
  label: '',
  value: '',
  type: '',
  remarks: '',
  isActive: false
};

export const dummyUserStatus: UserStatusHistoriesModel = {
  id: 0,
  UserId: 0,
  status: '',
  remarks: '',
  createdAt: new Date()
};

export const dummyUser: UserDetailsModel = {
  id: 0,
  loginName: '',
  displayName: '',
  email: '',
  contactNumber: '',
  role: '',
  status: '',
  active: false,
  isDeleted: false,
  UserStatus: [dummyUserStatus]
};

export const dummyMerchant: MerchantModel = {
  id: 0,
  UserId: 0,
  merchantType: '',
  companyName: '',
  companyUen: '',
  companyGst: '',
  companyFile: '',
  bankName: '',
  bankAccountName: '',
  bankAccountNumber: '',
  balance: 0,
  totalSpent: 0,
  User: dummyUser,
  MerchantTypes: [],
  AdditionalContacts: []
};

export const dummyDriver: DriverModel = {
  id: 0,
  UserId: 0,
  MerchantId: 0,
  vehicleNumber: '',
  vehicleLogCard: '',
  individualCompanyName: '',
  individualCompanyUen: '',
  individualCompanyFile: '',
  bankName: '',
  bankAccountName: '',
  bankAccountNumber: '',
  totalBalance: 0,
  totalSpent: 0,
  User: dummyUser,
  Merchant: dummyMerchant
};

export const dummySupervisor: SupervisorModel = {
  id: 0,
  UserId: 0,
  MerchantId: 0,
  User: dummyUser,
  Merchant: dummyMerchant
};

export const dummyJob: JobModel = {
  id: 0,
  MerchantId: 0,
  jobType: '',
  jobStatus: '',
  jobTitle: '',
  jobDate: '',
  jobTime: '',
  price: 0,
  hours: 0,
  totalTrips: 0,
  totalVehicles: 0,
  totalAmount: 0,
  materials: '',
  siteAddress: '',
  postalCode: '',
  disctrict: '',
  lampPost: '',
  remarks: '',
  latitude: 0,
  longitude: 0,
  streetName: '',
  siteAddressDestination: '',
  postalCodeDestination: '',
  disctrictDestination: '',
  lampPostDestination: '',
  streetNameDestination: '',
  remarksDestination: '',
  scanLocation: '',
  pass: '',
  truckType: '',
  Merchant: dummyMerchant,
  createdAt: '',
  updatedAt: new Date(),
  MaterialImagesBucket: {
    image1Url: '',
    image2Url: '',
    image3Url: ''
  }
};

export const dummyJobTask: JobTask = {
  id: 0,
  taskEarn: 0,
  taskHour: 0,
  JobId: 0,
  DriverId: 0,
  SupervisorId: 0,
  jobTaskStatus: '',
  completedDate: '',
  completedTime: '',
  additionalHour: 0,
  additionalAmount: 0,
  additionalStatus: '',
  additionalRemarks: '',
  additionalBy: 0,
  createdAt: new Date(),
  updatedAt: new Date(),
  Driver: dummyDriver,
  Supervisor: dummySupervisor,
  JobNotes: []
};

export const dummyInvoice: InvoiceModel = {
  id: 0,
  JobId: 0,
  toUserId: 0,
  fromUserId: 0,
  invoiceNo: '',
  invoiceDate: new Date(),
  dueDate: new Date(),
  subTotal: 0,
  gstAmount: 0,
  paidAmount: 0,
  Job: dummyJob,
  ToUser: dummyUser,
  createdAt: new Date(),
  updatedAt: new Date()
};

export const dummyJobAdditionalItems: JobAdditionalItem = {
  price: 0,
  totalPrice: 0,
  id: 0,
  name: '',
  totalItem: 0,
  jobAdditionalId: 0
};

export const dummyAdditonalTemplates: AdditionalTemplates = {
  id: 0,
  name: '',
  description: '',
  price: 0
};

export const dummyCreditDetail: CreditDetail = {
  amount: 0,
  id: 0,
  type: '',
  status: TransactionStatus.PENDING,
  confirmedBy: '',
  paymentMethod: '',
  createdAt: new Date().toISOString(),
  companyName: ''
};

export const dummyDriverList: DriverTaskList = {
  displayName: '',
  vehicleNumber: '',
  contactNumber: '',
  id: 0,
  MerchantId: 0,
  companyName: '',
  totalTasks: 0,
  completedTrips: 0,
  tasks: []
};

export const dummyMaterial = {
  price: 0,
  material: '',
  jobType: '',
  order: 0,
  id: 0,
  MaterialPrices: [
    { id: 0, jobType: JobType.DAYWORKS, type: null, price: 0 },
    { id: 0, jobType: JobType.TRIPWORKS, type: null, price: 0 },
    { id: 0, jobType: JobType.OU_WORKS, type: 'DISPOSAL', price: 0 },
    { id: 0, jobType: JobType.OU_WORKS, type: 'PURCHASE', price: 0 }
  ]
};

export const dummyTonnage: TonnageTemplate = {
  id: 0,
  price: 0,
  tonnageName: '',
  overtimePrice: 0,
  order: 0
};

export const dummyEquipment: EquipmentTemplate = {
  id: 0,
  order: 0,
  equipment: '',
  jobType: '',
  truckType: ''
};

export const dummyLampPost: LampPost = {
  latitude: 0,
  longitude: 0,
  lampPost: '',
  streetName: '',
  id: 0
};

export const dummyFormJob: JobForm = {
  id: 0,
  jobDate: '',
  jobTime: ''
};
