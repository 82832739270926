import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  IconButton,
  createStyles,
  withStyles,
  WithStyles,
  makeStyles,
  Theme,
  Grid,
  Typography,
  DialogTitle as MuiDialogTitle,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FileModal from 'components/FileModal';
import logo_empty from 'images/justgo_logo_empty.png';

interface Props {
  open: boolean;
  jobNotes?: JobNote[];
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  divNote: {
    marginTop: 25,
    border: '1px grey',
    borderRaduis: 5
  },
  divImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 5,
    marginRight: 20
  },
  image: {
    maxHeight: 150,
    maxWidth: 200,
    cursor: 'pointer'
  },
}));

const dialogStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

export interface DialogTitleProps extends WithStyles<typeof dialogStyles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(dialogStyles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function JobNoteModal(props: Props) {
  const classes = useStyles();
  const { open, onClose, jobNotes } = props;

  const [isDetailOpen, setIsDetailOpen] = useState<boolean>(false);
  const [currentFile, setCurrentFile] = useState<string>('');
  const [currentFileUrl, setCurrentFileUrl] = useState<string>('');

  const handleOpenDetailFile = (file: string, url: string) => {
    if (file && url) {
      setIsDetailOpen(true);
      setCurrentFile(file);
      setCurrentFileUrl(url);
    }
  };

  return (
    <Dialog fullWidth maxWidth='sm' onClose={onClose} open={open}>
      <DialogTitle id='dialogTitle' onClose={onClose}>
        <Typography variant='h4'>All Job Notes</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container justify='space-between'>
        {jobNotes && jobNotes.length > 0 && jobNotes.map((jobNote, index) => 
          <Grid key={index} container direction='row' justify='flex-end' item xs={12} sm={12} md={12} lg={12} className={classes.divNote}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div className={classes.divImage}>
              {jobNote.image && jobNote.imageUrl ?
                <img onClick={(e) => handleOpenDetailFile(jobNote.image!, jobNote.imageUrl!)} src={jobNote.imageUrl} className={classes.image} alt="note img" />
              :
                <div>
                  <img src={logo_empty} className={classes.image} alt="note img" />
                  <p style={{ textAlign: 'center' }}>sorry, there is no image available</p>
                </div>
              }
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <span>{jobNote.notes ?? '-'}</span>
            </Grid>
          </Grid>
        )}
        </Grid>
      </DialogContent>
      <FileModal title='Job Note Image' open={isDetailOpen} setOpen={setIsDetailOpen} fileUrl={currentFileUrl} fileName={currentFile} />
    </Dialog>
  );
}
