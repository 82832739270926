import React, { FC, useState } from 'react';
import { createStyles, makeStyles, Theme, Typography, List, ListItem, ListItemText } from '@material-ui/core';

import SuccessIcon from '@material-ui/icons/CheckCircle';
import DangerIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Cancel';
import { green, orange, red } from '@material-ui/core/colors';
import { ucwords } from 'utils';
import UserStatus from 'typings/enum/UserStatus';
import { useApp } from 'contexts/AppContext';

interface Props {
  userStatus: UserStatusHistoriesModel[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      overflowX: 'scroll'
    },
    list: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      alignItems: 'flex-start',
      marginBottom: theme.spacing(2),
      gap: '16px'
    },
    listNone: {
      textAlign: 'center',
      color: '#828282'
    },
    successAvatarIcon: {
      fontSize: 20,
      color: green[500]
    },
    warnigAvatarIcon: {
      fontSize: 20,
      color: orange[500]
    },
    dangerAvatarIcon: {
      fontSize: 20,
      color: orange[500]
    },
    errorAvatarIcon: {
      fontSize: 20,
      color: red[500]
    },
    primaryText: {
      fontSize: '14px'
    },
    subPrimaryText: {
      color: '#828282'
    },
    secondText: {
      fontSize: '14px',
      textAlign: 'end'
    },
    subSecondText: {
      color: '#828282',
      textAlign: 'end'
    },
    gutters: {
      paddingLeft: 0,
      paddingRight: 0
    },
    accountStatus: {
      paddingTop: '2px'
    },
    center: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column'
    },
    accountTitle: {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '0.25px'
    },
    account: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  })
);

const UserRemarksStatusHistory: FC<Props> = props => {
  const classes = useStyles();
  const { date } = useApp();
  const { userStatus } = props;
  const [checked, setChecked] = useState([0]);

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <List className={classes.root}>
      {userStatus && userStatus.length > 0 ? (
        userStatus
          .sort((a, b) => b.id - a.id)
          .map((value: UserStatusHistoriesModel, index: number) => {
            return (
              <ListItem
                key={index}
                className={classes.list}
                classes={{ gutters: classes.gutters }}
                role={undefined}
                dense
                button
                onClick={handleToggle(index)}
              >
                <div className={classes.accountStatus}>
                  {value.status === UserStatus.APPROVED && <SuccessIcon className={classes.successAvatarIcon} />}
                  {value.status === UserStatus.ENABLED && <SuccessIcon className={classes.successAvatarIcon} />}
                  {value.status === UserStatus.DISABLED && <DangerIcon className={classes.dangerAvatarIcon} />}
                  {value.status === UserStatus.REJECTED && <ErrorIcon className={classes.errorAvatarIcon} />}
                  {value.status === UserStatus.REQUEST_DELETE && <DangerIcon className={classes.dangerAvatarIcon} />}
                  {value.status === UserStatus.DELETED && <ErrorIcon className={classes.errorAvatarIcon} />}
                </div>

                <div className={classes.center}>
                  <div className={classes.account}>
                    <div className={classes.accountTitle}>Account {ucwords(value.status)}</div>
                    <div>
                      <Typography variant='body1' className={classes.secondText}>
                        {date(value.createdAt, 'dd MMMM yyyy')}
                      </Typography>
                      <Typography variant='body2' className={classes.subSecondText}>
                        {date(value.createdAt, 'hh:mm a')}
                      </Typography>
                    </div>
                  </div>

                  <div>{value.remarks || '-'}</div>
                </div>
              </ListItem>
            );
          })
      ) : (
        <ListItem key={0}>
          <ListItemText primary='  Not found data history.' />
        </ListItem>
      )}
    </List>
  );
};

export default UserRemarksStatusHistory;
