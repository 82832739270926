import React from 'react';
import { Paper, CardHeader, List, ListItem, ListItemText, Divider, Button, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { formatCurrency, snackCaseToSentence, ucwords } from 'utils';
import { format } from 'date-fns';
import useStyles from './styles';
import TransactionStatus from 'typings/enum/TransactionStatus';
import StatusChip from 'components/StatusChip';
import JobStatus from 'typings/enum/JobStatus';

interface TopupDetailProps {
  loading: boolean;
  transaction: CreditDetail;
  onClick: () => void;
  onCancel: () => void;
}

const TopupDetail = ({ transaction, loading, onClick, onCancel }: TopupDetailProps) => {
  const classes = useStyles();
  const date = new Date(transaction.createdAt);
  date.setUTCHours(date.getUTCMinutes() + 8);
  const createdAt = format(date, 'dd MMM yyyy, HH:mm');
  return (
    <Paper elevation={0} className={classes.wrapper}>
      <CardHeader title='Credit Balance Details' titleTypographyProps={{ variant: 'h4', align: 'center' }} />
      <List>
        <ListItem>
          <ListItemText
            className={classes.listItem}
            primaryTypographyProps={{ className: classes.primaryText }}
            secondaryTypographyProps={{ className: classes.secondaryText }}
            primary='Transaction ID'
            secondary={loading ? <Skeleton width={'100%'} /> : `#${transaction.id || 0}`}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            className={classes.listItem}
            primaryTypographyProps={{ className: classes.primaryText }}
            secondaryTypographyProps={{ className: classes.secondaryText }}
            primary='Company Name'
            secondary={loading ? <Skeleton width={'100%'} /> : transaction.companyName}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            className={classes.listItem}
            primaryTypographyProps={{ className: classes.primaryText }}
            secondaryTypographyProps={{ className: classes.secondaryText }}
            primary='Date & Time'
            secondary={loading ? <Skeleton width={'100%'} /> : createdAt}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            className={classes.listItem}
            primaryTypographyProps={{ className: classes.primaryText }}
            secondaryTypographyProps={{ className: classes.secondaryText }}
            primary='Action Type'
            secondary={loading ? <Skeleton width={'100%'} /> : ucwords(transaction.type.replace('_', ' ') || '')}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            className={classes.listItem}
            primaryTypographyProps={{ className: classes.primaryText }}
            secondaryTypographyProps={{ className: classes.secondaryText }}
            primary='Action by'
            secondary={loading ? <Skeleton width={'100%'} /> : ucwords(transaction.confirmedBy || '')}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            className={classes.listItem}
            primaryTypographyProps={{ className: classes.primaryText }}
            secondaryTypographyProps={{ className: classes.amountText }}
            primary='Total Amount'
            secondary={loading ? <Skeleton width={'100%'} /> : formatCurrency(transaction.amount || 0)}
          />
        </ListItem>

        <ListItem>
          <ListItemText
            className={classes.listItem}
            primaryTypographyProps={{ className: classes.primaryText }}
            secondaryTypographyProps={{ className: classes.amountText }}
            primary='Status'
            secondary={
              loading ? (
                <Skeleton width={'100%'} />
              ) : (
                <StatusChip
                  label={snackCaseToSentence(transaction.status)}
                  status={transaction.status === TransactionStatus.SETTLED ? JobStatus.COMPLETED : (transaction.status as JobStatus)}
                />
              )
            }
          />
        </ListItem>
      </List>

      {transaction.status === TransactionStatus.PENDING ? (
        <div className={classes.boxContinue}>
          <Divider className={classes.divider} />

          <Typography variant='body2' gutterBottom className={classes.footerInfo}>
            By pressing "continue" below then you must enter the otp code that we have sent to the email
          </Typography>

          <Button
            disabled={transaction.status !== TransactionStatus.PENDING}
            variant='contained'
            color='primary'
            fullWidth
            disableElevation
            onClick={onClick}
            className={classes.continueButton}
          >
            Continue
          </Button>
          <Button
            disabled={transaction.status !== TransactionStatus.PENDING}
            variant='text'
            color='inherit'
            fullWidth
            disableElevation
            disableFocusRipple
            disableRipple
            disableTouchRipple
            onClick={onCancel}
            className={classes.cancelButton}
          >
            Cancel
          </Button>
        </div>
      ) : null}
    </Paper>
  );
};

export default TopupDetail;
