import React from 'react';
import { TextField } from '@material-ui/core';

interface Props {
  value: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  readOnly?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
}

function TextFilter({ value, onChange, className, startAdornment, endAdornment, readOnly, fullWidth, placeholder }: Props) {
  return (
    <TextField
      variant='outlined'
      margin='dense'
      value={value}
      onChange={onChange}
      className={className}
      placeholder={placeholder}
      fullWidth={fullWidth}
      InputProps={{
        startAdornment,
        endAdornment,
        readOnly
      }}
    />
  );
}

export default TextFilter;
