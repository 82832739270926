import React from 'react';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import topupIcon from 'images/icon/verified.svg';
import error from 'images/icon/errorfilled.svg';
import useStyles from './styles';

interface TopupAlertProps {
  loading: boolean;
  open: boolean;
  variant: 'success' | 'warning' | 'info';
  onCloseSuccess: () => void;
  onCloseConfirmation: () => void;
  onContinue: () => void;
  onRedirect: () => void;
}

const TopupAlert = ({ loading, open, variant, onCloseSuccess, onCloseConfirmation, onContinue, onRedirect }: TopupAlertProps) => {
  const classes = useStyles();

  return (

    <Dialog open={open} maxWidth='lg' className={classes.dialog} disableEscapeKeyDown >

      <DialogContent className={classes.content}>
        <Box display='flex' flexDirection='column' className={classes.box}>
          <div className={classes.boxImage}>
            <img src={variant === 'success' ? topupIcon : error} alt='' />
          </div>

          <Box>
            <Typography align='center' variant='h4' gutterBottom>
              {variant === 'success' && 'Adjust Balance Success'}
              {variant === 'warning' && 'Confirmation'}
              {variant === 'info' && 'Info'}
            </Typography>
            <Typography align='center' variant='body2' gutterBottom>
              {variant === 'success' && 'Congratulation! Your adjust Balance has been successfully done!'}
              {variant === 'warning' && 'Are you sure want to cancel adjust balance?'}
              {variant === 'info' && 'Adjust balance has been procceed'}
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions className={classes.actions}>
        {variant === 'warning' && (
          <>
            <Button variant='outlined' color='primary' disableElevation className={classes.leftButton} onClick={onContinue}>
              Continue
            </Button>

            <Button variant='contained' color='primary' disableElevation className={classes.leftButton} onClick={onCloseConfirmation}>
              {loading ? <CircularProgress size={20} /> : 'Yes Cancel'}
            </Button>
          </>
        )}
        {variant === 'success' && (
          <Button variant='contained' color='primary' disableElevation className={classes.leftButton} onClick={onCloseSuccess}>
            Close Window
          </Button>
        )}

        {variant === 'info' && (
          <Button variant='contained' color='primary' disableElevation className={classes.leftButton} onClick={onRedirect}>
            Close Window
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default TopupAlert;
