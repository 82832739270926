import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import axios, { CancelTokenSource } from 'axios';
import { makeStyles, Theme, Grid, Button, Badge, CircularProgress, Paper } from '@material-ui/core';
import { WEB_MERCHANT_BASE_URL, WEB_MERCHANT_DETAIL_URL, WEB_INDIVIDUAL_STATUS_MERCHANT_URL, WEB_GET_MERCHANT_COUNT_URL } from 'constants/url';
import UserStatus from 'typings/enum/UserStatus';

import MerchantTable from './MerchantTable';
import MerchantDetailModal from './MerchantDetailModal';
import MerchantFormModal from './MerchantFormModal';
import MainTemplate from 'components/Template/MainTemplate';
import useTablePagination from 'hooks/useTablePagination';
import Snackbar from 'components/Snackbar';
import useSnackbar from 'hooks/useSnackbar';

import { Add } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    padding: '8px 16px 8px 16px',
    marginRight: '16px',
    textTransform: 'none',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 400,
    borderRadius: '5px',
    color: theme.palette.black[50]
  },
  buttonBackground: {
    backgroundColor: '#FEF1D2',
    color: theme.palette.primary.main
  },
  badgePending: {
    width: '24px',
    height: '24Px',
    top: '-0.4em',
    right: '-1.0em',
    borderRadius: '100px',
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '20px',
    backgroundColor: theme.palette.error.main
  },
  wrapper: {
    borderRadius: '5px',
    boxShadow: '8px 4px 24px rgba(0, 0, 0, 0.08)'
  }
}));

const MerchantsPage: FC = () => {
  const classes = useStyles();
  const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

  const { openSnacbar, snackbar } = useSnackbar();
  const [merchant, setMerchant] = useState<MerchantModel | undefined>();
  const [selectedId, setSelectedId] = useState<number>(0);
  const [count, setCount] = useState({ pending: 0, requesDelete: 0 });
  const [loading, setLoading] = useState({ detail: false, status: false, pending: false });
  const [openDetailModal, setOpenDetailModal] = useState<boolean>(false);
  const [openFormModal, setOpenFormModal] = useState<boolean>(false);

  const table = useTablePagination<MerchantList>(WEB_MERCHANT_BASE_URL, {
    selector: data => data.rows,
    sort: {
      orderBy: 'id',
      order: 'desc'
    },
    defaultQuery: {
      status: UserStatus.APPROVED
    }
  });

  const fetchCountPending = async () => {
    try {
      setLoading(prev => ({ ...prev, pending: true }));
      const { data } = await axios.get(WEB_GET_MERCHANT_COUNT_URL, { cancelToken: cancelTokenSource.token });
      setCount(data);
    } catch (err) {
    } finally {
      setLoading(prev => ({ ...prev, pending: false }));
    }
  };

  const handleStatusOption = async (newStatus: string, remarks?: string, address?: string) => {
    try {
      setLoading(prev => ({ ...prev, status: true }));
      await axios.put(
        WEB_INDIVIDUAL_STATUS_MERCHANT_URL(selectedId),
        { status: newStatus, remarks, address },
        { cancelToken: cancelTokenSource.token }
      );
      openSnacbar(`Owner account has been ${newStatus.toLowerCase()}!`);
      table.refresh();
    } catch (err) {
      openSnacbar('Network error, failed proccess data', { variant: 'error' });
    } finally {
      setLoading(prev => ({ ...prev, status: false }));
    }
  };

  const handleOpenDetailClick = async (id: number) => {
    setOpenDetailModal(prev => !prev);
    try {
      setLoading(prev => ({ ...prev, detail: true }));
      const { data } = await axios.get(WEB_MERCHANT_DETAIL_URL(id), { cancelToken: cancelTokenSource.token });
      setMerchant(data);
      setSelectedId(id);
    } catch (error) {
    } finally {
      setLoading(prev => ({ ...prev, detail: false }));
    }
  };

  const refreshList = () => {
    table.refresh();
    fetchCountPending();
  };

  useEffect(() => {
    fetchCountPending();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainTemplate
      title='Owner Lists'
      subtitle='Display the data of all registered owners'
      refreshButtonProps={{
        onClick: refreshList
      }}
      primaryButtonProps={{
        onClick: () => {
          setOpenFormModal(true);
        },
        children: (
          <>
            <Add fontSize='small' /> <span style={{ marginLeft: '13px' }}> Add New Owner</span>
          </>
        )
      }}
    >
      <Grid container direction='row' spacing={3} justify='space-between'>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Button
            variant='outlined'
            className={clsx(classes.button, { [classes.buttonBackground]: table.query('status') === UserStatus.APPROVED })}
            color={table.query('status') === UserStatus.APPROVED ? 'primary' : 'default'}
            onClick={() => table.setQuery({ status: UserStatus.APPROVED })}
          >
            All Owners
          </Button>
          <Button
            variant='outlined'
            disabled={table.loading || loading.pending}
            color={table.query('status') === UserStatus.PENDING ? 'primary' : 'default'}
            className={clsx(classes.button, { [classes.buttonBackground]: table.query('status') === UserStatus.PENDING })}
            onClick={() => table.setQuery({ status: UserStatus.PENDING })}
          >
            {loading.pending ? (
              <CircularProgress size={20} />
            ) : (
              <Badge badgeContent={count.pending} color='primary' classes={{ badge: classes.badgePending }}>
                Pending Approval
              </Badge>
            )}
          </Button>
          <Button
            variant='outlined'
            color={table.query('status') === UserStatus.REJECTED ? 'primary' : 'default'}
            className={clsx(classes.button, { [classes.buttonBackground]: table.query('status') === UserStatus.REJECTED })}
            onClick={() => table.setQuery({ status: UserStatus.REJECTED })}
          >
            Rejected
          </Button>
          <Button
            variant='outlined'
            color={table.query('status') === UserStatus.DISABLED ? 'primary' : 'default'}
            className={clsx(classes.button, { [classes.buttonBackground]: table.query('status') === UserStatus.DISABLED })}
            onClick={() => table.setQuery({ status: UserStatus.DISABLED })}
          >
            Disabled
          </Button>
          <Button
            variant='outlined'
            disabled={table.loading || loading.pending}
            color={table.query('status') === UserStatus.REQUEST_DELETE ? 'primary' : 'default'}
            className={clsx(classes.button, { [classes.buttonBackground]: table.query('status') === UserStatus.REQUEST_DELETE })}
            onClick={() => table.setQuery({ status: UserStatus.REQUEST_DELETE })}
          >
            {loading.pending ? (
              <CircularProgress size={20} />
            ) : (
              <Badge badgeContent={count.requesDelete} color='primary' classes={{ badge: classes.badgePending }}>
                Request Delete
              </Badge>
            )}
          </Button>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={0} className={classes.wrapper}>
            <MerchantTable {...table} handleOpenDetailClick={handleOpenDetailClick} />
          </Paper>
        </Grid>
      </Grid>

      <MerchantDetailModal
        open={openDetailModal}
        setOpen={setOpenDetailModal}
        setOpenFormEdit={setOpenFormModal}
        setSelectedId={setSelectedId}
        handleStatusOption={handleStatusOption}
        merchant={merchant}
      />

      <MerchantFormModal
        open={openFormModal}
        setOpen={setOpenFormModal}
        setSelectedId={setSelectedId}
        handleOpenSnackbar={openSnacbar}
        refresh={table.refresh}
        clear={table.clear}
        editedMerchant={merchant}
      />

      <Snackbar {...snackbar} />
    </MainTemplate>
  );
};

export default MerchantsPage;
