import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1
  },
  boxBackdrop: {
    background: '#FFFFFF',
    boxShadow: '8px 4px 24px rgba(0, 0, 0, 0.08)',
    borderRadius: '12px',
    padding: '13px',
    height: '100px',
    width: '100px'
  },
  iconLoading: {
    color: 'conic-gradient(from 180deg at 50% 50%, #F6891F 0deg, rgba(246, 137, 31, 0) 360deg)'
  }
}));

export default useStyles;
