import React from 'react';
import { Box, Button, ButtonProps, Container, makeStyles, Theme, Typography, Breadcrumbs, Link } from '@material-ui/core';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import useRouter from 'hooks/useRouter';
export interface MainTemplateProps {
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
  refreshButton?: boolean;
  primaryButton?: boolean | React.ReactNode;
  breadcrumb?: boolean;
  links?: { name: string; links: 'goback' | string; click: boolean }[];
  refreshButtonProps?: ButtonProps;
  primaryButtonProps?: ButtonProps;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '32px'
  },
  rightHeaderPage: {
    display: 'flex',
    gap: '10px',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  iconButton: {
    padding: '8px',
    borderRadius: '5px',
    minWidth: 'unset',
    border: `1.5px solid ${theme.palette.primary.main}`
  },
  content: {
    marginTop: '20px'
  },
  primaryButton: {
    padding: '8px 16px 8px 16px',
    textTransform: 'none'
  },
  backButton: {
    marginTop: '16px'
  },
  subtitle: {
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 400,
    letter: '0.25px',
    color: theme.palette.black[50]
  },
  alert: {
    marginTop: '20px'
  }
}));

const MainTemplate = ({
  title,
  subtitle,
  breadcrumb,
  links,
  primaryButton,
  refreshButton,
  refreshButtonProps,
  primaryButtonProps,
  ...props
}: MainTemplateProps) => {
  const classes = useStyles();
  const { history } = useRouter();
  document.title = title;
  // const { activeTopup, accessTopup } = useApp();
  return (
    <Container className={classes.container} maxWidth={false}>
      <Box display={'flex'} flex={1} flexDirection={'column'}>
        <Box display={'flex'} flexDirection='row' justifyContent='space-between' alignContent='center'>
          <Box>
            <Typography variant='h1' gutterBottom>
              {title}
            </Typography>

            {!breadcrumb && subtitle && (
              <Typography variant='inherit' className={classes.subtitle}>
                {subtitle || ''}
              </Typography>
            )}

            {breadcrumb && (
              <Box>
                <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
                  {links &&
                    links.map((value, i) =>
                      value.click ? (
                        <Link
                          key={'links-' + i}
                          color='inherit'
                          onClick={() => {
                            if (value.links === 'goback') {
                              history.goBack();
                            } else {
                              history.push(value.links);
                            }
                          }}
                        >
                          {value.name}
                        </Link>
                      ) : (
                        <Typography key={'links-' + i} color='textPrimary'>
                          {value.name}
                        </Typography>
                      )
                    )}
                </Breadcrumbs>

                <Button
                  variant='text'
                  onClick={() => {
                    history.goBack();
                  }}
                  className={classes.backButton}
                >
                  <ArrowBackIcon fontSize='small' /> Back
                </Button>
              </Box>
            )}
          </Box>

          <Box className={classes.rightHeaderPage}>
            {refreshButton && (
              <div>
                <Button variant='outlined' disableElevation color='primary' className={classes.iconButton} {...refreshButtonProps}>
                  <AutorenewIcon fontSize='small' />
                </Button>
              </div>
            )}

            {typeof primaryButton === 'boolean' && primaryButton && (
              <div>
                <Button variant='contained' disableElevation color='primary' className={classes.primaryButton} {...primaryButtonProps} />
              </div>
            )}

            {typeof primaryButton !== 'boolean' && primaryButton ? primaryButton : null}
          </Box>
        </Box>

        {/* {activeTopup && !breadcrumb && (
          <Alert variant='standard' severity='warning' className={classes.alert}>
            You have not completed 1 manual top up,{' '}
            <a
              href='#'
              onClick={e => {
                e.preventDefault();
                history.push(`/owner/topup/${accessTopup.currentId}/detail`);
              }}
            >
              See detail!
            </a>
          </Alert>
        )} */}

        <Box className={classes.content}>{props.children}</Box>
      </Box>
    </Container>
  );
};

export default MainTemplate;

MainTemplate.defaultProps = {
  title: '',
  subtitle: '',
  breadcrumb: false,
  refreshButton: true,
  primaryButton: true
};
