import React, { useState, useEffect, FC } from 'react';
import axios from 'axios';
import { makeStyles, Theme, Paper, Button, Grid, Box, Typography, Divider, CircularProgress } from '@material-ui/core';
import { WEB_MATERIAL_NUMBERING_BASE_URL, WEB_MATERIAL_BASE_URL } from 'constants/url';
import useTablePagination from 'hooks/useTablePagination';
import SettingTemplate from 'components/Template/SettingTemplate';
import MaterialTemplateTable from './components/MaterialTemplateTable';
import MaterialForm from './components/MaterialForm';
import { Add } from '@material-ui/icons';
import { dummyMaterial } from 'constants/DummyData';
import useSnackbar from 'hooks/useSnackbar';
import Snackbar from 'components/Snackbar';
import MaterialDrawer from './components/MaterialDrawer';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    borderRadius: '5px',
    boxShadow: '8px 4px 24px rgba(0, 0, 0, 0.08)'
  },
  button: {
    padding: '8px 16px 8px 16px',
    marginRight: '16px',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 400,
    borderRadius: '5px',
    color: theme.palette.black[50],
    '&.MuiButton-outlined:hover': {
      backgroundColor: '#FEF1D2',
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main
    }
  },
  buttonBackground: {
    backgroundColor: '#FEF1D2',
    color: theme.palette.primary.main
  },
  title: {
    padding: '16px'
  }
}));

export type Numbering = {
  payload: { id: number; order: number }[];
  loading: boolean;
};

export type DummyMaterial = typeof dummyMaterial;

const MaterialTemplatePage: FC = () => {
  const classes = useStyles();

  const { openSnacbar, snackbar } = useSnackbar();
  const [openFormModal, setOpenFormModal] = useState(false);
  const [data, setData] = useState<DummyMaterial>(dummyMaterial);
  const [selectedId, setSelectedId] = useState(0);
  const [numbering, setNumbering] = useState<Numbering>({
    payload: [],
    loading: false
  });

  const table = useTablePagination<MaterialTemplate>(WEB_MATERIAL_BASE_URL, {
    selector: data => data.rows,
    sort: {
      order: 'asc',
      orderBy: 'order'
    }
  });

  const refreshList = () => {
    table.clear();
  };

  const onEdit = (value: DummyMaterial) => () => {
    setData(value);
    setOpenFormModal(true);
    setSelectedId(0);
  };

  const onDelete = (id: number) => async () => {
    try {
      await axios.delete(`${WEB_MATERIAL_BASE_URL}/${id}`);
      openSnacbar('Material successfully deleted');
      table.refresh();
    } catch (e) {
      openSnacbar(JSON.stringify(e), { variant: 'error' });
    } finally {
    }
  };

  const onDetail = (id: number) => () => {
    setSelectedId(id);
  };

  const openNumber = () => {
    setNumbering(prev => ({ ...prev, payload: [] }));
    table.refresh(p => ({ perPage: p.perPage === -1 ? 10 : -1 }));
  };

  const onNumbering = async () => {
    try {
      setNumbering(prev => ({ ...prev, loading: true }));
      await axios.put(WEB_MATERIAL_NUMBERING_BASE_URL, numbering.payload);
      openSnacbar('Material successfully updated');
      setNumbering(prev => ({ ...prev, payload: [] }));
      table.clear();
    } catch (e) {
      openSnacbar(JSON.stringify(e), { variant: 'error' });
    } finally {
      setNumbering(prev => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    if (+table.query('perPage') !== -1) return;
    setNumbering(prev => ({ ...prev, payload: table.data.map(v => ({ id: v.id || 0, order: v.order })) }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.query('perPage'), table.data]);

  const isNumbering = +table.query('perPage') === -1;
  return (
    <SettingTemplate
      title='Material List'
      subtitle='Display the data of either Material'
      refreshButtonProps={{ onClick: refreshList }}
      primaryButtonProps={{
        onClick: () => {
          setOpenFormModal(true);
        },
        children: (
          <>
            <Add fontSize='small' /> <span style={{ marginLeft: '13px' }}> Add New Material</span>
          </>
        )
      }}
    >
      <Grid container direction='row' spacing={3} justify='space-between'>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Box display='flex' justifyContent='flex-start' alignItems='center' style={{ gap: '8px' }}></Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={0} className={classes.wrapper}>
            <Box display={'flex'} flexDirection={'row'} justifyContent='space-between' alignItems={'center'} style={{ paddingRight: '16px' }}>
              <Typography component={'div'} variant='h4' className={classes.title}>
                Material
              </Typography>

              <Box>
                <Button disabled={numbering.loading} variant='outlined' size='small' color='primary' onClick={openNumber}>
                  {isNumbering ? 'Close' : 'Update Number'}
                </Button>
                {'  '}
                {isNumbering ? (
                  <Button disabled={numbering.loading} variant='contained' size='small' color='primary' disableElevation onClick={onNumbering}>
                    {numbering.loading ? <CircularProgress size={20} /> : 'Save'}
                  </Button>
                ) : null}
              </Box>
            </Box>

            <Divider />

            <MaterialTemplateTable
              {...table}
              onDelete={onDelete}
              onDetail={onDetail}
              onEdit={onEdit}
              numbering={numbering}
              setNumbering={setNumbering}
            />
          </Paper>
        </Grid>
      </Grid>

      <MaterialForm open={openFormModal} data={data} setData={setData} setOpen={setOpenFormModal} reload={table.refresh} />
      <MaterialDrawer id={selectedId} clear={() => setSelectedId(0)} />
      <Snackbar {...snackbar} />
    </SettingTemplate>
  );
};

export default MaterialTemplatePage;
