import React, { FC } from 'react';
import {
  Container,
  withStyles,
  makeStyles,
  Theme,
  Grid,
  FormControl,
  TextField,
  ButtonGroup,
  ButtonBase,
  InputAdornment,
  CircularProgress,
  Tooltip,
  MenuItem,
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  IconButton
} from '@material-ui/core';

import DescriptionIcon from '@material-ui/icons/Description';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import NumberFormatCustom from 'components/NumberFormatCustom';
import { getVehicleTypeSelect } from 'utils';
import CompanyTypeChip from 'components/CompanyTypeChip';
import { Add, Delete } from '@material-ui/icons';
// import { Visibility, VisibilityOff } from '@material-ui/icons';
// import MerchantType from 'typings/enum/MerchantType';
// import infoIcon from 'images/icon/info.svg';
// import { ucwords } from 'utils';

interface Props {
  merchant?: MerchantModel;
  isLoadingEmail: boolean;
  isLoadingContactNumber: boolean;
  isLoadingUen: boolean;
  isLoadingGst: boolean;
  inputMerchantType: string;
  inputCompanyName: string;
  inputCompanyUen: string;
  inputCompanyGst: string;
  inputCompanyFile: string;
  inputDisplayName: string;
  inputEmail: string;
  inputContactNumber: string;
  inputPassword: string;
  inputConfirmPassword: string;
  inputCompanyType: string[];
  inputAdditionalEmail: string[];
  inputAllowSend: boolean;
  errorCompanyName: string;
  errorCompanyUen: string;
  errorCompanyGst: string;
  errorCompanyFile: string;
  errorDisplayName: string;
  errorEmail: string;
  errorContactNumber: string;
  errorPassword: string;
  errorConfirmPassword: string;
  errorAdditionalEmail: string[];
  setInputMerchantType: React.Dispatch<React.SetStateAction<string>>;
  setInputCompanyName: React.Dispatch<React.SetStateAction<string>>;
  setInputCompanyUen: React.Dispatch<React.SetStateAction<string>>;
  setInputCompanyGst: React.Dispatch<React.SetStateAction<string>>;
  setInputCompanyFile: React.Dispatch<React.SetStateAction<string>>;
  setInputDisplayName: React.Dispatch<React.SetStateAction<string>>;
  setInputEmail: React.Dispatch<React.SetStateAction<string>>;
  setInputContactNumber: React.Dispatch<React.SetStateAction<string>>;
  setInputPassword: React.Dispatch<React.SetStateAction<string>>;
  setInputConfirmPassword: React.Dispatch<React.SetStateAction<string>>;
  setUploadCompanyFile: React.Dispatch<React.SetStateAction<string>>;
  setInputCompanyType: React.Dispatch<React.SetStateAction<string[]>>;
  setInputAdditionalEmail: React.Dispatch<React.SetStateAction<string[]>>;
  setInputAllowSend: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorCompanyFile: React.Dispatch<React.SetStateAction<string>>;
  setErrorAdditionalEmail: React.Dispatch<React.SetStateAction<string[]>>;
  handleBlurName: (inputed: string) => void;
  handleBlurEmail: (inputed: string) => void;
  handleBlurContactNumber: (inputed: string) => void;
  handleBlurCompanyName: (inputed: string) => void;
  handleBlurCompanyUen: (inputed: string) => void;
  handleBlurCompanyGst: (inputed: string) => void;
  handleBlurPassword: (inputed: string) => void;
  handleBlurConfirmPassword: (inputed: string) => void;
  handleBlurAdditionalEmail: (index: number) => (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => Promise<void>;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  container: {
    '& > :nth-child(n+2)': {
      marginTop: theme.spacing(2)
    },
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(2)
    }
  },
  formGroupContainer: {
    paddingRight: theme.spacing(1),
    paddingleft: theme.spacing(1)
  },
  formGroupContainerCenter: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  },
  tooltipInfo: {
    verticalAlign: 'middle',
    marginLeft: '5px'
  },
  button: {
    marginLeft: theme.spacing(1)
  },
  buttonLink: {
    cursor: 'pointer',
    color: theme.palette.link.main
  },
  inputLabel: {
    fontWeight: 500
  },
  inputAdornmentClass: {
    marginLeft: theme.spacing(2)
  },
  chipSelect: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  inputTag: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '150%',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.5px',
    color: 'rgba(0, 0, 0, 0.87)',
    paddingTop: '12px',
    paddingBottom: '12px'
  }
}));
// eslint-disable-next-line
const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    maxWidth: 500
  }
}))(Tooltip);

const CompanyStep: FC<Props> = props => {
  const classes = useStyles();

  const {
    inputCompanyName,
    inputCompanyUen,
    inputCompanyGst,
    inputCompanyFile,
    inputDisplayName,
    inputEmail,
    inputContactNumber,
    inputCompanyType,
    inputAdditionalEmail,
    inputAllowSend
  } = props;

  const {
    errorCompanyName,
    errorCompanyUen,
    errorCompanyGst,
    errorCompanyFile,
    errorDisplayName,
    errorEmail,
    errorContactNumber,
    errorAdditionalEmail
  } = props;

  const {
    setInputMerchantType,
    setInputCompanyName,
    setInputCompanyUen,
    setInputCompanyGst,
    setInputCompanyFile,
    setInputDisplayName,
    setInputEmail,
    setInputContactNumber,
    setUploadCompanyFile,
    setErrorCompanyFile,
    setErrorAdditionalEmail,
    setInputCompanyType,
    setInputAdditionalEmail,
    setInputAllowSend
  } = props;

  const {
    handleBlurName,
    handleBlurEmail,
    handleBlurContactNumber,
    handleBlurCompanyName,
    handleBlurCompanyUen,
    handleBlurCompanyGst,
    handleBlurAdditionalEmail
  } = props;

  // eslint-disable-next-line
  const { isLoadingEmail, isLoadingContactNumber, isLoadingUen, isLoadingGst } = props;

  // const [showPassword, setShowPassword] = useState<boolean>(false);

  // eslint-disable-next-line
  const handleChangeType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputMerchantType((event.target as HTMLInputElement).value);
  };

  const handleChangeFile = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const originalname = event.target.files[0].name;
      const extention = originalname.split('.').pop();
      const filename = `${Date.now()}.${extention}`;

      if (extention === 'pdf' || extention === 'png' || extention === 'jpg' || extention === 'jpeg') {
        setUploadCompanyFile(event.target.files[0]);
        setInputCompanyFile(filename);
        setErrorCompanyFile('');
      } else {
        setErrorCompanyFile('File extention must be one of *.pdf, *.png, *.jpg, *.jpeg');
      }
    }
  };

  const addEmail = () => {
    setInputAdditionalEmail(p => [...p, '']);
  };

  const removeEmail = index => () => {
    setInputAdditionalEmail(p => {
      p.splice(index, 1);
      return [...p];
    });

    setInputAllowSend(!(inputAdditionalEmail.length === 0));
    setErrorAdditionalEmail(p => {
      p.splice(index, 1);
      return [...p];
    });
  };

  return (
    <Container maxWidth='lg' className={classes.container}>
      <Grid container direction='row' justify='space-between' alignItems='center' spacing={1}>
        {/* //# Note: Since not exist user merchant driver owner hide this option #//
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControl component='fieldset'>
            <FormLabel component='legend'>Choose Account Type</FormLabel>
            <RadioGroup
              row
              aria-label='type'
              name='merchantType'
              value={inputMerchantType || MerchantType.SUPER_MERCHANT}
              onChange={handleChangeType}
            >
              <FormControlLabel
                control={<Radio color='primary' />}
                value={MerchantType.SUPER_MERCHANT}
                label={
                  <>
                    <span>{ucwords(MerchantType.MERCHANT)}</span>
                    <LightTooltip
                      arrow
                      placement='bottom-start'
                      title='You have Drivers & Supervisors registered under your company and able to post jobs, add new Drivers & Supervisors, view all your Drivers & Supervisor jobs'
                      className={classes.tooltipInfo}
                    >
                      <img src={infoIcon} alt='info1' />
                    </LightTooltip>
                  </>
                }
              />
              <FormControlLabel
                control={<Radio color='primary' />}
                value={MerchantType.DRIVER_OWNER}
                label={
                  <>
                    <span>{ucwords(MerchantType.DRIVER_OWNER)}</span>
                    <LightTooltip
                      arrow
                      placement='bottom-start'
                      title='You have Drivers registered under your company able to add Drivers and view all your Drivers job'
                      className={classes.tooltipInfo}
                    >
                      <img src={infoIcon} alt='info3' />
                    </LightTooltip>
                  </>
                }
              />
            </RadioGroup>
          </FormControl>
        </Grid> 
        */}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControl fullWidth>
            <TextField
              variant='outlined'
              label='Display Name'
              value={inputDisplayName}
              helperText={errorDisplayName}
              error={errorDisplayName !== ''}
              onChange={event => setInputDisplayName(event.target.value)}
              onBlur={event => handleBlurName(event.target.value)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} style={{ padding: '4px' }}>
          <TextField
            fullWidth
            variant='outlined'
            label='Phone No'
            value={inputContactNumber}
            helperText={errorContactNumber}
            error={errorContactNumber !== ''}
            onChange={event => setInputContactNumber(event.target.value.replace(/\s/g, '').trim())}
            onBlur={event => handleBlurContactNumber(event.target.value.replace(/\s/g, '').trim())}
            InputProps={{
              inputComponent: NumberFormatCustom as any,
              inputProps: {
                format: '########'
              },
              startAdornment: (
                <InputAdornment position='start' className={classes.inputAdornmentClass}>
                  +65
                </InputAdornment>
              ),
              endAdornment: <InputAdornment position='end'>{isLoadingContactNumber ? <CircularProgress size={20} /> : null}</InputAdornment>
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            fullWidth
            variant='outlined'
            label='Email'
            type='email'
            value={inputEmail}
            helperText={errorEmail}
            error={errorEmail !== ''}
            onChange={event => setInputEmail(event.target.value.trim())}
            onBlur={event => handleBlurEmail(event.target.value.trim())}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControl fullWidth>
            <TextField
              variant='outlined'
              label='Company Name'
              value={inputCompanyName}
              helperText={errorCompanyName}
              error={errorCompanyName !== ''}
              onChange={event => setInputCompanyName(event.target.value)}
              onBlur={event => handleBlurCompanyName(event.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControl fullWidth>
            <TextField
              variant='outlined'
              label='Company UEN No'
              value={inputCompanyUen}
              helperText={errorCompanyUen}
              error={errorCompanyUen !== ''}
              onChange={event => setInputCompanyUen(event.target.value.trim())}
              onBlur={event => handleBlurCompanyUen(event.target.value.trim())}
              inputProps={{ maxLength: 10 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    {isLoadingUen ? <CircularProgress size={20} /> : <DescriptionIcon color='primary' fontSize='small' />}
                  </InputAdornment>
                )
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControl fullWidth>
            <TextField
              variant='outlined'
              label='Company GST No'
              value={inputCompanyGst}
              helperText={errorCompanyGst}
              error={errorCompanyGst !== ''}
              onChange={event => setInputCompanyGst(event.target.value.trim())}
              onBlur={event => handleBlurCompanyGst(event.target.value.trim())}
              inputProps={{ maxLength: 10 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    {isLoadingGst ? <CircularProgress size={20} /> : <DescriptionIcon color='primary' fontSize='small' />}
                  </InputAdornment>
                )
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControl fullWidth>
            <input style={{ display: 'none' }} id='button-file' type='file' onChange={event => handleChangeFile(event)} />
            <TextField
              disabled={true}
              variant='outlined'
              label='ACRA Company'
              value={inputCompanyFile}
              error={errorCompanyFile !== ''}
              helperText={errorCompanyFile}
              onChange={event => setInputCompanyFile(event.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <ButtonGroup color='primary' aria-label='outlined primary button group'>
                      <label htmlFor='button-file' style={{ textAlign: 'center', cursor: 'pointer' }}>
                        <ButtonBase focusRipple key={'Upload'} component='span'>
                          <NoteAddIcon className={classes.buttonLink} />
                        </ButtonBase>
                      </label>
                    </ButtonGroup>
                  </InputAdornment>
                )
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            select
            label='Company Type'
            value={inputCompanyType}
            InputLabelProps={{ shrink: true }}
            fullWidth
            SelectProps={{
              native: false,
              multiple: true,
              displayEmpty: true,
              renderValue: selected => {
                if ((selected as any[]).length === 0) {
                  return <em>Select Company Type</em>;
                }

                return (
                  <div className={classes.chipSelect}>
                    {(selected as any[]).map(value => (
                      <CompanyTypeChip select key={value} type={value} />
                    ))}
                  </div>
                );
              }
            }}
            variant='outlined'
            onChange={e => {
              const value = (e.target.value as unknown) as string[];
              if (!value.some(v => v === 'ALL')) {
                setInputCompanyType(value);
              }
            }}
          >
            <MenuItem
              value='ALL'
              onClick={() => {
                setInputCompanyType(prev => {
                  return [
                    ...prev,
                    ...[...getVehicleTypeSelect(), { id: 'MERCHANT', name: 'Merchant' }].filter(v => !prev.includes(v.id)).map(v => v.id as string)
                  ];
                });
              }}
            >
              All Vehicle Type
            </MenuItem>
            {[...getVehicleTypeSelect(), { id: 'MERCHANT', name: 'Merchant' }].map(val => (
              <MenuItem key={val.id} value={val.id}>
                {val.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        {/* //# Note: Since user merchant login by contact number and otp this no need #//
        {merchant ? (
          ''
        ) : (
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth>
              <TextField
                variant='outlined'
                label='Password'
                type={showPassword ? 'text' : 'password'}
                value={inputPassword}
                helperText={errorPassword}
                error={errorPassword !== ''}
                onChange={event => setInputPassword(event.target.value.trim())}
                onBlur={event => handleBlurPassword(event.target.value.trim())}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton edge='end' aria-label='toggle password visibility' onClick={event => setShowPassword(!showPassword)}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </FormControl>
          </Grid>
        )}

        {merchant ? (
          ''
        ) : (
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth>
              <TextField
                variant='outlined'
                label='Confirm Password'
                type={showPassword ? 'text' : 'password'}
                value={inputConfirmPassword}
                helperText={errorConfirmPassword}
                error={errorConfirmPassword !== ''}
                onChange={event => setInputConfirmPassword(event.target.value.trim())}
                onBlur={event => handleBlurConfirmPassword(event.target.value.trim())}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton edge='end' aria-label='toggle password visibility' onClick={event => setShowPassword(!showPassword)}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </FormControl>
          </Grid>
        )} 
        */}
      </Grid>
    </Container>
  );
};

export default CompanyStep;
