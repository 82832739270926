import React, { FC, useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  createStyles,
  withStyles,
  WithStyles,
  makeStyles,
  Theme,
  Grid,
  Typography,
  Button,
  Link,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

import StatusPrompModal from 'components/StatusPrompModal';
import FileModal from 'components/FileModal';
import UserRemarksStatusHistory from 'pages/UserPage/UserRemarksStatusHistory';
import UserStatus from 'typings/enum/UserStatus';
import MerchantType from 'typings/enum/MerchantType';
import { dummyUserStatus } from 'constants/DummyData';
import { ucwords } from 'utils';

interface Props {
  open: boolean;
  merchant?: MerchantModel;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenFormEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedId: React.Dispatch<React.SetStateAction<number>>;
  handleStatusOption: (status: string, remarks?: string, address?: string) => Promise<void>;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 4
  },
  itemText: {
    color: '#828282',
    fontSize: '13px'
  },
  itemSecondary: {
    fontWeight: 600,
    fontSize: '13px'
  },
  linkText: {
    color: theme.palette.link.main
  }
}));

const dummyUserStatusHistories = [dummyUserStatus];

const dialogStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

export interface DialogTitleProps extends WithStyles<typeof dialogStyles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(dialogStyles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const MerchantDetailModal: FC<Props> = props => {
  const classes = useStyles();
  const { merchant, open, setOpen, setOpenFormEdit, setSelectedId, handleStatusOption } = props;

  const [merchantType, setMerchantType] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const [companyUen, setCompanyUen] = useState<string>('');
  const [companyGst, setCompanyGst] = useState<string>('');
  const [companyPhone, setCompanyPhone] = useState<string>('');
  const [companyFile, setCompanyFile] = useState<string>('');
  const [companyFileUrl, setCompanyFileUrl] = useState<string>('');
  const [bankName, setBankName] = useState<string>('');
  const [bankAccountName, setBankAccountName] = useState<string>('');
  const [bankAccountNumber, setBankAccountNumber] = useState<string>('');
  const [displayName, setDisplayName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [contactNumber, setContactNumber] = useState<string>('');
  const [userStatus, setUserStatus] = useState<string>('');

  const [typePromp, setTypePromp] = useState<string>('');
  const [titlePromp, setTitlePromp] = useState<string>('');
  const [remarksPromp, setRemarksPromp] = useState<string>('');
  const [addressPromp, setAddressPromp] = useState<string>('');
  const [openPromp, setOpenPromp] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [openFile, setOpenFile] = useState<boolean>(false);
  const [userStatusHistory, setUserStatusHistory] = useState<UserStatusHistoriesModel[]>(dummyUserStatusHistories);

  const handleClose = () => {
    setSelectedId(0);
    setOpen(false);
    setLoading(false);
  };

  const handleEdit = () => {
    setOpen(false);
    setOpenFormEdit(true);
  };

  const handleReject = () => {
    setOpenPromp(true);
    setTitlePromp('reject');
    setTypePromp(UserStatus.REJECTED);
  };

  const handleApprove = () => {
    setOpenPromp(true);
    setTitlePromp('approve');
    setTypePromp(UserStatus.APPROVED);
  };

  const handleClosePromp = () => {
    setOpenPromp(false);
    setLoading(false);
    setTitlePromp('');
    setRemarksPromp('');
    setAddressPromp('');
  };

  const handleConfirmPromp = async () => {
    setLoading(true);
    await handleStatusOption(typePromp, remarksPromp, addressPromp);
    handleClosePromp();
    handleClose();
  };

  useEffect(() => {
    if (!merchant) {
      return;
    }

    const {
      merchantType,
      companyName,
      companyUen,
      companyGst,
      companyFile,
      companyFileUrl,
      bankName,
      bankAccountName,
      bankAccountNumber,
      User,
      UserStatus
    } = merchant;
    const { displayName, contactNumber, email, address, status } = User;

    setMerchantType(merchantType);
    setCompanyName(companyName);
    setCompanyUen(companyUen);
    setCompanyGst(companyGst || '');
    setCompanyFile(companyFile);
    setCompanyFileUrl(companyFileUrl || '');
    setAddress(address || '');
    setBankName(bankName);
    setBankAccountName(bankAccountName);
    setBankAccountNumber(bankAccountNumber);
    setDisplayName(displayName);
    setEmail(email);
    setContactNumber(contactNumber);
    setUserStatus(status);
    setUserStatusHistory(UserStatus!);
    setCompanyPhone(User.companyPhone || '');
  }, [merchant]);

  return (
    <div>
      <Dialog fullWidth maxWidth='sm' onClose={handleClose} open={open}>
        <DialogTitle id='dialogTitle' onClose={handleClose}>
          <Typography variant='h4'>Owner Details</Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container direction='row' justify='space-between'>
            <Grid container direction='row' item xs={12} sm={12} md={12} lg={12} alignItems='center' spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant='h5' color='primary'>
                  Personal Information
                </Typography>
                <List component='div' dense={true}>
                  <ListItem>
                    <ListItemText className={classes.itemText}>Account Type</ListItemText>
                    <ListItemSecondaryAction className={classes.itemSecondary}>
                      {ucwords(merchantType === MerchantType.SUPER_MERCHANT ? MerchantType.MERCHANT : merchantType)}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemText className={classes.itemText}>Owner Name</ListItemText>
                    <ListItemSecondaryAction className={classes.itemSecondary}>{displayName}</ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemText className={classes.itemText}>Email</ListItemText>
                    <ListItemSecondaryAction className={classes.itemSecondary}>{email}</ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemText className={classes.itemText}>Phone No.</ListItemText>
                    <ListItemSecondaryAction className={classes.itemSecondary}>+65 {contactNumber}</ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemText className={classes.itemText}>Address</ListItemText>
                    <ListItemSecondaryAction className={classes.itemSecondary} style={{ width: 200, textAlign: 'right', lineHeight: 'initial' }}>
                      {address}
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant='h5' color='primary'>
                  Company Information
                </Typography>
                <List component='div' dense={true}>
                  <ListItem>
                    <ListItemText className={classes.itemText}>Company Name</ListItemText>
                    <ListItemSecondaryAction className={classes.itemSecondary}>{companyName || '-'}</ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemText className={classes.itemText}>Company Phone.</ListItemText>
                    <ListItemSecondaryAction className={classes.itemSecondary}>{companyPhone || '-'}</ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemText className={classes.itemText}>Company UEN No.</ListItemText>
                    <ListItemSecondaryAction className={classes.itemSecondary}>{companyUen || '-'}</ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemText className={classes.itemText}>Company GST No.</ListItemText>
                    <ListItemSecondaryAction className={classes.itemSecondary}>{companyGst || '-'}</ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemText className={classes.itemText}>ACRA Company</ListItemText>
                    <ListItemSecondaryAction className={classes.itemSecondary}>
                      {companyFile ? (
                        <Link href='#' onClick={() => setOpenFile(true)} className={classes.linkText}>
                          {companyFile}
                        </Link>
                      ) : (
                        '-'
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant='h5' color='primary'>
                  Bank Account
                </Typography>
                <List component='div' dense={true}>
                  <ListItem>
                    <ListItemText className={classes.itemText}>Bank Name</ListItemText>
                    <ListItemSecondaryAction className={classes.itemSecondary}>{bankName || '-'}</ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemText className={classes.itemText}>Bank Account Name</ListItemText>
                    <ListItemSecondaryAction className={classes.itemSecondary}>{bankAccountName || '-'}</ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemText className={classes.itemText}>Bank Account No.</ListItemText>
                    <ListItemSecondaryAction className={classes.itemSecondary}>{bankAccountNumber || '-'}</ListItemSecondaryAction>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant='h6'>Remarks Status History</Typography>
                <UserRemarksStatusHistory userStatus={userStatusHistory} />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' color='primary' onClick={() => (userStatus === UserStatus.REJECTED ? handleEdit() : handleReject())}>
            {userStatus === UserStatus.REJECTED ? 'Edit Data' : 'Reject'}
          </Button>
          <Button variant='contained' color='primary' onClick={() => handleApprove()}>
            Approve
          </Button>
        </DialogActions>
      </Dialog>
      <StatusPrompModal
        title={titlePromp}
        message={displayName}
        confirmText={titlePromp}
        open={openPromp}
        loading={loading}
        handleClose={handleClosePromp}
        handleConfirm={handleConfirmPromp}
        remarksPromp={remarksPromp}
        setRemarksPromp={setRemarksPromp}
        addressPromp={addressPromp}
        setAddressPromp={setAddressPromp}
      />
      <FileModal title='ACRA Company file' open={openFile} setOpen={setOpenFile} fileUrl={companyFileUrl} fileName={companyFile} />
    </div>
  );
};

export default MerchantDetailModal;
