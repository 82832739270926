import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Paper, Typography } from '@material-ui/core';
import { formatCurrency } from 'utils';
import { IOSSwitch } from 'components/CustomSwitch';
import { useHistory, useParams } from 'react-router-dom';
import { WEB_GET_MERCHANT_CREDIT_URL, WEB_JOB_CREDIT_PROGRESS_BASE_URL } from 'constants/url';
import { useCredit } from 'contexts/CreditContex';
import useStyles from './styles';
import axios, { CancelTokenSource } from 'axios';
import cardBase from 'images/icon/card-base.svg';
import useSnackbar from 'hooks/useSnackbar';
import Snackbar from 'components/Snackbar';

const MerchantCardCredit = ({disableAccount}: {disableAccount?:boolean}) => {
  const abort: CancelTokenSource = axios.CancelToken.source();
  const classes = useStyles();
  const { id } = useParams<Record<string, string>>();
  const history = useHistory()
  const { onOpen, setData, loading: load, payment } = useCredit();
  const { openSnacbar, snackbar } = useSnackbar();

  const [loading, setLoading] = useState({ detail: false, status: false, progress: false });
  const [totalProgress, setTotalProgress] = useState(0)
  const [credit, setCredit] = useState({
    id: +id,
    canCredit: false,
    creditBalance: 0,
    totalUsedCredit: 0
  });

  const getCredit = async () => {
    try {
      setLoading(prev => ({ ...prev, detail: true }));
      const { data } = await axios.get(WEB_GET_MERCHANT_CREDIT_URL(+id), { cancelToken: abort.token });
      setCredit(data);
      setData({ creditBalance: data.creditBalance, totalUsedCredit: data.totalUsedCredit });
    } catch (err) {
    } finally {
      setLoading(prev => ({ ...prev, detail: false }));
    }
  };

  const getCreditTotalProgress = async () => {
    try {
      setLoading(prev => ({ ...prev, progress: true }));
      const { data } = await axios.get(WEB_JOB_CREDIT_PROGRESS_BASE_URL(+id), { cancelToken: abort.token });
      setTotalProgress(data?.total || 0)
    } catch (err) {
    } finally {
      setLoading(prev => ({ ...prev, progress: false }));
    }
  };

  const putStatus = async () => {
    try {
      setLoading(prev => ({ ...prev, status: true }));
      const { data } = await axios.put(WEB_GET_MERCHANT_CREDIT_URL(+id), {}, { cancelToken: abort.token });
      setCredit(prev => ({ ...prev, ...data }));
      openSnacbar('Status credit has been changed');
      getCredit();
    } catch (err) {
    } finally {
      setLoading(prev => ({ ...prev, status: false }));
    }
  };

  useEffect(() => {
    getCredit();
    getCreditTotalProgress();

    return () => {
      abort.cancel();
    };
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const query = new URLSearchParams(history.location.search)
  const openPayment = query.has('hell') ? query.get('hell') === 'boy' : false

  return (
    <Paper elevation={0} className={classes.root}>
      <Box className={classes.cardBalance}>
        <div>
          <img src={cardBase} alt='card base' />
        </div>
        <Box flexGrow={1}>
          <Typography variant='h5' gutterBottom>
            Credit Used
          </Typography>

          <Typography variant='h1'>{formatCurrency(credit.totalUsedCredit)}</Typography>

          <Typography variant='h6' color={'error'} style={{ fontWeight: 500 }}>
            Credit Balance {formatCurrency(credit.creditBalance)}
          </Typography>
        </Box>
        <Box display={'flex'} flexDirection={'column'}>
          <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} mb={0.5}>
            <Box>
              <IOSSwitch disabled={disableAccount} value={credit.canCredit} checked={credit.canCredit} onClick={putStatus} />
            </Box>
            <Typography variant='h5' component={'div'} style={{ minWidth: '60px' }} align='center'>
              {loading.status ? 'Process' : credit.canCredit ? 'Active' : 'Inactive'}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.buttonContainer}>
        <Button
          disabled={disableAccount || load.key || !credit.canCredit}
          variant='contained'
          disableElevation
          color='primary'
          fullWidth
          className={classes.primaryButton}
          onClick={onOpen(+id)}
        >
          {load.key ? <CircularProgress size={20} /> : ' Adjust Balance'}
        </Button>

        <Button
          disabled={ openPayment ? (disableAccount || credit.totalUsedCredit === 0) : (disableAccount || totalProgress > 0 ||load.key || credit.totalUsedCredit === 0) }
          variant='contained'
          disableElevation
          color='secondary'
          fullWidth
          className={classes.primaryButton}
          onClick={payment.onOpen(+id)}
        >
          Credit Payment
        </Button>
      </Box>

      <Snackbar {...snackbar} />
    </Paper>
  );
};

export default MerchantCardCredit;
