enum SettingCode {
  GST = 'GST',
  APP_FEE = 'APP_FEE',
  DAYWORKS_FEE = 'DAYWORKS_FEE',
  TRIPWORKS_FEE = 'TRIPWORKS_FEE',
  OU_WORKS_FEE = 'OU_WORKS_FEE',
  DAYWORKS_MAX_HOUR = 'DAYWORKS_MAX_HOUR',
  DAYWORKS_MIN_HOUR = 'DAYWORKS_IN_HOUR',
  DAYWORKS_MAX_PRICE = 'DAYWORKS_MAX_PRICE',
  DAYWORKS_MIN_PRICE = 'DAYWORKS_MIN_PRICE',
  MIN_JOB_PRICE = 'MIN_JOB_PRICE',
  MIN_JOB_AMOUNT = 'MIN_JOB_AMOUNT',
  MAX_TOPUP = 'MAX_TOPUP',
  MIN_TOPUP = 'MIN_TOPUP',
  MAX_BALANCE = 'MAX_BALANCE',
  MIN_BALANCE = 'MIN_BALANCE',
  MIN_WITHDRAW = 'MIN_WITHDRAW',
  MAX_WITHDRAW = 'MAX_WITHDRAW',
  DRIVER_CANCEL_DEDUCTION_1 = 'DRIVER_CANCEL_DEDUCTION_1',
  DRIVER_CANCEL_DEDUCTION_2 = 'DRIVER_CANCEL_DEDUCTION_2',
  DRIVER_CANCEL_DEDUCTION_3 = 'DRIVER_CANCEL_DEDUCTION_3',
  DRIVER_CANCEL_DEDUCTION_4 = 'DRIVER_CANCEL_DEDUCTION_4',
  MERCHANT_CANCEL_DEDUCTION_1 = 'MERCHANT_CANCEL_DEDUCTION_1',
  MERCHANT_CANCEL_DEDUCTION_2 = 'MERCHANT_CANCEL_DEDUCTION_2',
  MERCHANT_CANCEL_DEDUCTION_3 = 'MERCHANT_CANCEL_DEDUCTION_3',
  LAMBDAKEY = 'LAMBDAKEY',
  LORRY_CRANE_HOURS = 'LORRY_CRANE_HOURS',
  TRIPWORKS_PRICE_PER_KM = 'TRIPWORKS_PRICE_PER_KM',
  PRICE_DISTANCE_TRIP_1 = 'PRICE_DISTANCE_TRIP_1',
  PRICE_DISTANCE_TRIP_2 = 'PRICE_DISTANCE_TRIP_2',
  MIN_DISTANCE_TRIP_1 = 'MIN_DISTANCE_TRIP_1',
  MIN_DISTANCE_TRIP_2 = 'MIN_DISTANCE_TRIP_2'
}

export default SettingCode;
