import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Slide,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core';
import React, { useState } from 'react';
import topupIcon from 'images/icon/topup.svg';
import { Visibility, VisibilityOff, InfoOutlined, Close } from '@material-ui/icons';
import { formatCurrency } from 'utils';
import { GET_TOKEN, TRANSACTIONS_CREATE_TOPUP_BASE_URL } from 'constants/url';
import { addMinutes } from 'date-fns';
import { useApp } from 'contexts/AppContext';
import axios from 'axios';
import NumberFormatCustom from 'components/NumberFormatCustom';
import clsx from 'clsx';
import useStyles from './styles';
import useRouter from 'hooks/useRouter';

const nominals = [100, 200, 500, 1000, 2000, 5000];

interface MerchantTopupProps {
  maximumOption: number;
  open: boolean;
  merchantId: number;
  onClose: () => void;
}

const MerchantTopup = ({ open, merchantId, maximumOption, onClose }: MerchantTopupProps) => {
  const classes = useStyles();
  const { history } = useRouter();
  const { accessTopup, setAccessTopup } = useApp();
  const [isShowPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState({
    password: false,
    amount: false
  });

  const [errors, setErrors] = useState({
    password: '',
    amount: ''
  });

  const onPasswordConfirm = async () => {
    try {
      setLoading(state => ({ ...state, password: true }));
      setErrors({ password: '', amount: '' });
      if (!password || password === '') {
        setErrors(state => ({ ...state, password: 'Invalid Password' }));
        return;
      }
      const { data } = await axios.post(GET_TOKEN, {
        password,
        key: accessTopup.key
      });
      setAccessTopup({ token: data.token, expireAt: addMinutes(new Date(), 10).toString(), step: 1, merchantId, key: '' });
      setPassword('');
    } catch (err) {
      console.log('err', err);
      setErrors(state => ({ ...state, password: 'Invalid Password' }));
    } finally {
      setLoading(state => ({ ...state, password: false }));
    }
  };

  const onNominalSubmit = async () => {
    try {
      setErrors(state => ({ ...state, amount: '' }));
      setLoading(state => ({ ...state, amount: true }));

      if (amount < 1 || amount > maximumOption) {
        setErrors(state => ({ ...state, amount: `Minimum top up is $1.00 and maximum top up is ${formatCurrency(maximumOption)}` }));
        return;
      }

      const { data } = await axios.post(
        TRANSACTIONS_CREATE_TOPUP_BASE_URL,
        { amount, merchantId },
        {
          headers: {
            'ACCESS-KEY': accessTopup.token
          }
        }
      );
      setAccessTopup({ currentId: data.id });
      setAmount(0);
      history.push(`/owner/topup/${data.id}/detail`);
    } catch (err) {
      setErrors(state => ({ ...state, amount: '' }));
    } finally {
      setLoading(state => ({ ...state, amount: false }));
    }
  };

  const onCloseDialog = () => {
    onClose();
    setPassword('');
    setAmount(0);
  };

  return (
    <Dialog open={open} maxWidth='lg' className={classes.dialog} disableEscapeKeyDown >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography align='center' variant='h1' style={{ flexGrow: 1 }}>
          Top Up Merchant’s Balance
        </Typography>

        <IconButton aria-label='close' onClick={onCloseDialog} className={classes.iconButton}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {accessTopup.step === 0 && (
          <Box display='flex' flexDirection='column' className={classes.box}>
            <div className={classes.boxImage}>
              <img src={topupIcon} alt='image_topup' />
            </div>

            <Box>
              <Typography align='center' variant='h4' gutterBottom>
                Input your password
              </Typography>
              <Typography align='center' variant='body2' gutterBottom>
                Please input your password below before process top up balance
              </Typography>
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                name='password'
                type={isShowPassword ? 'text' : 'password'}
                id='password'
                value={password}
                className={classes.inputPassword}
                autoComplete='current-password'
                placeholder='Input password here'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton edge='end' aria-label='toggle password visibility' onClick={event => setShowPassword(!isShowPassword)}>
                        {isShowPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                onChange={e => setPassword(e.target.value)}
                error={errors.password !== ''}
                helperText={errors.password}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    onPasswordConfirm();
                  }
                }}
              />
            </Box>
          </Box>
        )}

        {accessTopup.step === 1 && (
          <Slide in={accessTopup.step === 1} unmountOnExit>
            <Box display='flex' flexDirection='column' className={classes.box}>
              <div className={classes.boxNominal}>
                <label className={classes.labelNominal}>Enter top up amount</label>
                <TextField
                  fullWidth
                  placeholder='Example: $2,000.00'
                  variant='outlined'
                  value={amount === 0 ? null : amount}
                  InputProps={{
                    inputComponent: NumberFormatCustom as any,
                    inputProps: {
                      prefix: '$',
                      thousandSeparator: true,
                      decimalScale: 2
                    }
                  }}
                  onChange={e => setAmount(+e.target.value)}
                  error={errors.amount !== ''}
                />

                <div className={classes.textHelper}>
                  <InfoOutlined color='primary' fontSize='small' />
                  <div>Minimum top up is $1.00 and maximum top up is {formatCurrency(maximumOption)}</div>
                </div>
              </div>

              <div>
                <label className={classes.labelNominal}>Or choose top up amount</label>

                <div className={classes.boxNominals}>
                  {nominals.map((value, i) => (
                    <Button
                      key={i}
                      variant='outlined'
                      disableElevation
                      disableFocusRipple
                      disableTouchRipple
                      disableRipple
                      className={clsx(classes.buttonNominal, { [classes.hoverButtonNominal]: value === amount })}
                      onClick={() => setAmount(value)}
                    >
                      {formatCurrency(value)}
                    </Button>
                  ))}
                </div>
              </div>
            </Box>
          </Slide>
        )}
        <Divider />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          variant='outlined'
          color='primary'
          disabled={loading.password || loading.amount}
          disableElevation
          className={classes.leftButton}
          onClick={onCloseDialog}
        >
          Cancel
        </Button>
        {accessTopup.step === 0 && (
          <Button
            variant='contained'
            color='primary'
            disabled={loading.password}
            disableElevation
            className={classes.rightButton}
            onClick={onPasswordConfirm}
          >
            {loading.password && <CircularProgress color='inherit' size={24} />}
            {!loading.password && 'Confirm'}
          </Button>
        )}

        {accessTopup.step === 1 && (
          <Button
            disabled={amount === 0}
            variant='contained'
            color='primary'
            disableElevation
            className={classes.rightButton}
            onClick={onNominalSubmit}
          >
            {loading.amount && <CircularProgress color='inherit' size={24} />}
            {!loading.amount && 'Top Up'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default MerchantTopup;
