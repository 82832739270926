import React, { useState } from 'react';
import {
    Theme,
    makeStyles,
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
    Typography,
    TextField
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Error';
import { orange, green } from '@material-ui/core/colors';
import { ucWords } from 'utils';

interface Props {
  title: string;
  message: string;
  confirmText: string;
  remarksPromp: string;
  addressPromp: string;
  open: boolean;
  loading?: boolean;
  handleConfirm(): void;
  handleClose(): void;
  setRemarksPromp: React.Dispatch<React.SetStateAction<string>>;
  setAddressPromp: React.Dispatch<React.SetStateAction<string>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  warnigAvatarIcon: {
    fontSize: 60,
    color: orange[500]
  },
  titleText: {
    paddingBottom: 4,
    color: 'black'
  },
  messageText: {
    paddingBottom: theme.spacing(4)
  },
  nameText: {
    color: '#F6891F'
  },
  inputRemarks: {
    minHeight: '100px'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

const StatusPrompModal: React.FC<Props> = props => {
  const classes = useStyles();
  const [addressError, setAddressError] = useState(false);
  const [helperText, setHelperText] = useState('');

  const { title, message, confirmText, open, loading, handleClose, handleConfirm, remarksPromp, addressPromp, setRemarksPromp, setAddressPromp } = props;

  const handleSubmit = () => {
    if (title === 'approve' && !addressPromp) {
      setAddressError(true);
      setHelperText('Full address can not be empty');
    } else {
      handleConfirm();
    }
  };

  const nextTitle = title === 'deleted' ? 'delete' : `${title} creation of`;
  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>
          <Grid container justify='center' alignItems='center'>
            <WarningIcon className={classes.warnigAvatarIcon} />
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.titleText} style={{ minWidth: '400px' }}>
            <Typography variant='h3' align={'center'}>
              Do you want to {nextTitle}
            </Typography>
            <Typography variant='h3' align={'center'}>
              <span className={classes.nameText}>{message}</span> account?
            </Typography>
          </DialogContentText>
          {title !== 'approve' ? (
            <>
              <DialogContentText className={classes.messageText}>
                <Typography variant='body1' align={'center'}>
                  Put your reason here (optional)
                </Typography>
              </DialogContentText>
              <TextField
                autoFocus
                multiline
                fullWidth
                variant='outlined'
                margin='dense'
                type='text'
                id='remarks'
                label='Remarks'
                rows={4}
                value={remarksPromp}
                onChange={event => setRemarksPromp(event.target.value)}
              />
            </>
          ) : (
            <>
              <DialogContentText className={classes.messageText} style={{ padding: 0, margin: 0 }}>
                <Typography variant='body1' align={'center'}>
                  Input user address here
                </Typography>
              </DialogContentText>
              <TextField
                autoFocus
                multiline
                fullWidth
                error={addressError}
                variant='outlined'
                margin='dense'
                type='text'
                id='address'
                label='Full Address'
                helperText={helperText}
                rows={4}
                value={addressPromp}
                onChange={event => setAddressPromp(event.target.value)}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={loading} color='primary'>
            Cancel
          </Button>

          <div className={classes.wrapper}>
            <Button onClick={handleSubmit} disabled={loading} variant='contained' color='primary'>
              {ucWords(confirmText)}
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default StatusPrompModal;
