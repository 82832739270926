import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';
import { SETTINGS_URL, WEB_TRANSACTION_CHECK_CREDIT_URL } from 'constants/url';
import TransactionType from 'typings/enum/TransactionType';

const dummy = {
  merchantId: 0,
  key: '',
  creditBalance: 0,
  totalUsedCredit: 0,
  hasAdjustPending: false,
  hasPaymentPending: false
};

const CreditContex = createContext({
  data: dummy,
  loading: {
    key: false
  },
  open: false,
  payment: {
    open: false,
    onOpen: (merchantId?: number) => () => {},
    onClose() {}
  },
  onOpen: (merchantId?: number) => () => {},
  onClose() {},
  setData(value: Partial<typeof dummy>) {},
  setOpen: (open: boolean) => {},
  setOpenPayment: (open: boolean) => {}
});

const CreditProvider = ({ children }: any) => {
  const [open, setOpen] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);
  const [data, setData] = useState(dummy);
  const [loading, setLoading] = useState({ key: false });

  const onOpen = (merchantId = 0) => async () => {
    try {
      setLoading(prev => ({ ...prev, key: true }));
      await Promise.all([axios.get(SETTINGS_URL), axios.get(WEB_TRANSACTION_CHECK_CREDIT_URL, { params: { merchantId } })]).then(
        ([{ data: value }, { data: list }]) => {
          const setting = (value?.Settings || []).find((value: any) => value.code === 'MANUAL_SECRET_KEY');
          const hasPending = (list || []).length > 0 ? true : false;
          setData(prev => ({ ...prev, merchantId, key: setting?.value || '', hasAdjustPending: hasPending }));
          setOpen(hasPending ? false : true);
        }
      );
    } catch (err) {
    } finally {
      setLoading(prev => ({ ...prev, key: false }));
    }
  };

  const onOpenPayment = (merchantId = 0) => async () => {
    try {
      setLoading(prev => ({ ...prev, key: true }));
      await Promise.all([axios.get(SETTINGS_URL), axios.get(WEB_TRANSACTION_CHECK_CREDIT_URL, { params: { merchantId, type: [TransactionType.CREDIT_PAID] } })]).then(
        ([{ data: value }, { data: list }]) => {
          const setting = (value?.Settings || []).find((value: any) => value.code === 'MANUAL_SECRET_KEY');
          const hasPending = (list || []).length > 0 ? true : false;
          setData(prev => ({ ...prev, merchantId, key: setting?.value || '', hasPaymentPending: hasPending }));
          setOpenPayment(hasPending ? false : true);
        }
      );
    } catch (err) {
    } finally {
      setLoading(prev => ({ ...prev, key: false }));
    }
  };

  const onClosePayment = () => {
    setOpenPayment(false);
    setData(({ totalUsedCredit }) => ({ ...dummy, totalUsedCredit }));
  };

  const onClose = () => {
    setOpen(false);
    setData(({ totalUsedCredit, creditBalance }) => ({ ...dummy, totalUsedCredit, creditBalance }));
  };

  const handleData = (value: Partial<typeof data>) => {
    setData(prev => ({ ...prev, ...value }));
  };

  return (
    <CreditContex.Provider
      value={{
        open,
        data,
        loading,
        payment: { open: openPayment, onOpen: onOpenPayment, onClose: onClosePayment },
        setOpen: (val: boolean) => setOpen(val),
        setOpenPayment: (val: boolean) => setOpenPayment(val),
        onOpen,
        onClose,
        setData: handleData
      }}
    >
      {children}
    </CreditContex.Provider>
  );
};

const useCredit = () => {
  const context = useContext(CreditContex);
  if (context === undefined) {
    throw new Error('useCredit must be used within a CreditProvider');
  }
  return context;
};

export { CreditProvider, useCredit };
