import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '620px',
    margin: '0 auto',
    padding: '16px 8px 0px 8px',
    borderRadius: '5px',
    boxShadow: '8px 4px 24px rgba(0, 0, 0, 0.08)'
  },
  box: {
    marginTop: '16px',
    paddingBottom: '24px'
  },
  boxContent: {
    paddingRight: '24px',
    paddingLeft: '24px'
  },
  boxImage: {
    textAlign: 'center',
    marginBottom: '16px'
  },
  boxPin: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: '24px',
    marginBottom: '24px'
  },
  inputPin: {
    width: '72px',
    height: '72px',
    background: '#FFFFFF',
    border: '1px solid #C1C1C1',
    borderRadius: '5px',
    textAlign: 'center',
    fontSize: '24px',
    lineHeight: '150%',
    fontWeight: 700
  },
  buttonConfirm: {
    marginTop: '24px',
    borderRadius: '5px',
    textTransform: 'none'
  },
  hoverButtonNominal: {
    background: '#FEF1D2',
    border: '1px solid #F6891F',
    borderRadius: '5px',
    color: '#F6891F'
  },
  cancelButton: {
    textTransform: 'none',
    textDecoration: 'underline',
    marginTop: '4px',
    padding: 0
  }
}));

export default useStyles;
