import React, { FC } from 'react';
import { ListItemText, TableRow, Link } from '@material-ui/core';
import { formatId, truckTypeLabel, ucwords } from 'utils';
import { Link as RouterLink } from 'react-router-dom';
import BodyCell from 'components/BodyCell';
import StatusChip from 'components/StatusChip';
import JobChip from 'components/JobChip';
import NumberFormat from 'react-number-format';
import JobStatus from 'typings/enum/JobStatus';
import useStyles from './style';
import JobType from 'typings/enum/JobType';
import { format } from 'date-fns';

interface Props {
  index: number;
  task: TaskDriver;
}

const BodyRow: FC<Props> = props => {
  const classes = useStyles();
  const dateTimeFormatted = `${format(new Date(`${props.task.jobDate.replace(/-/g, '/')} ${props.task.jobTime}`), 'dd/MM/yyyy, hh:mm a')}`;
  return (
    <TableRow hover>
      <BodyCell cellWidth='12%'>{formatId(props.task.JobId)}</BodyCell>
      <BodyCell cellWidth='30%' noWrap>
        <Link component={RouterLink} to={`/job/${props.task.JobId}/${props.task.suppId}/driver`} target={''}>
          <ListItemText
            primary={props.task.companyName}
            primaryTypographyProps={{ className: classes.mainText }}
            secondary={`${props.task.jobTitle || '-'}`}
            secondaryTypographyProps={{ className: classes.subText }}
          />
        </Link>
      </BodyCell>
      <BodyCell align='center' cellWidth='15%'>
        <JobChip type={props.task.jobType as JobType} workType={props.task.workType} />
      </BodyCell>
      <BodyCell align='center'>{truckTypeLabel(props.task.truckType || '')}</BodyCell>

      <BodyCell align='center' cellWidth='15%' noWrap>
        {dateTimeFormatted}
      </BodyCell>
      <BodyCell cellWidth='25%' noWrap>
        <ListItemText
          primary={props.task.driverName}
          primaryTypographyProps={{ className: classes.mainText }}
          secondary={`${props.task.vehicleNumber}`}
          secondaryTypographyProps={{ className: classes.subText }}
        />
      </BodyCell>
      <BodyCell cellWidth='15%' align='center'>
        <StatusChip status={props.task.jobTaskStatus as JobStatus} label={ucwords(props.task.jobTaskStatus)} minWidth='110px' />
      </BodyCell>
      <BodyCell cellWidth='18%' align='right'>
        <NumberFormat
          value={props.task.netEarn || 0}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'$'}
          decimalScale={2}
          fixedDecimalScale={true}
          className={classes.price}
        />
      </BodyCell>
    </TableRow>
  );
};

export default BodyRow;
