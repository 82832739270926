import React, { FC, useEffect, useState, useCallback } from 'react';
import axios, { CancelTokenSource } from 'axios';
import {
  Theme,
  makeStyles,
  createStyles,
  withStyles,
  WithStyles,
  Button,
  Dialog,
  CircularProgress,
  IconButton,
  Typography,
  Stepper,
  Step,
  StepLabel,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import { green } from '@material-ui/core/colors';

import { dummySelect } from 'constants/DummyData';
import { DRIVERS_BASE_URL, MERCHANTS_BASE_URL, INDIVIDUAL_DRIVER_URL, BANKS_BASE_URL, GET_CHECK_USER_URL } from 'constants/url';
import { isValidEmail, isStrongPassword } from 'utils';
import UserStatus from 'typings/enum/UserStatus';

import PersonalStep from './components/PersonalStep';
import CompanyStep from './components/CompanyStep';
import BankStep from './components/BankStep';

interface Props {
  open: boolean;
  status: string;
  drivers: DriverModel[];
  editedDriver?: DriverModel;
  setDrivers: React.Dispatch<React.SetStateAction<DriverModel[]>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedId: React.Dispatch<React.SetStateAction<number>>;
  handleOpenSnackbar: (type: 'success' | 'error', message: string) => void;
  revertDefaultPage: () => void;
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700]
    }
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const dummySelects: Select[] = [dummySelect];

const DriverFormModal: FC<Props> = props => {
  const classes = useStyles();

  const {
    open,
    setOpen,
    status,
    drivers,
    setDrivers,
    setSelectedId,
    editedDriver,
    revertDefaultPage,
    handleOpenSnackbar
  } = props;

  const steps = ['Personal information', 'Company & Vehicle', 'Bank Account'];
  const [loading, setLoading] = useState<boolean>(false);
  const [isLoadingEmail, setLoadingEmail] = useState<boolean>(false);
  const [isLoadingContactNumber, setLoadingContactNumber] = useState<boolean>(false);
  const [isLoadingUen, setLoadingUen] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [banks, setBanks] = useState<Select[]>(dummySelects);
  const [uploadCompanyFile, setUploadCompanyFile] = useState<string>('');
  const [uploadVehicleFile, setUploadVehicleFile] = useState<string>('');

  const [inputCompanyName, setInputCompanyName] = useState<string>('');
  const [inputCompanyUen, setInputCompanyUen] = useState<string>('');
  const [inputCompanyFile, setInputCompanyFile] = useState<string>('');
  const [inputVehicleNumber, setInputVehicleNumber] = useState<string>('');
  const [inputVehicleLogCard, setInputVehicleLogCard] = useState<string>('');
  const [inputDisplayName, setInputDisplayName] = useState<string>('');
  const [inputEmail, setInputEmail] = useState<string>('');
  const [inputAddress, setInputAddress] = useState<string>('');
  const [inputContactNumber, setInputContactNumber] = useState<string>('');
  const [inputPassword, setInputPassword] = useState<string>('');
  const [inputConfirmPassword, setInputConfirmPassword] = useState<string>('');
  const [inputBankId, setInputBankId] = useState<number>(0);
  const [inputBankAccountName, setInputBankAccountName] = useState<string>('');
  const [inputBankAccountNumber, setInputBankAccountNumber] = useState<string>('');

  const [errorCompanyName, setErrorCompanyName] = useState<string>('');
  const [errorCompanyUen, setErrorCompanyUen] = useState<string>('');
  const [errorCompanyFile, setErrorCompanyFile] = useState<string>('');
  const [errorVehicleNumber, setErrorVehicleNumber] = useState<string>('');
  const [errorVehicleLogCard, setErrorVehicleLogCard] = useState<string>('');
  const [errorDisplayName, setErrorDisplayName] = useState<string>('');
  const [errorEmail, setErrorEmail] = useState<string>('');
  const [errorContactNumber, setErrorContactNumber] = useState<string>('');
  const [errorPassword, setErrorPassword] = useState<string>('');
  const [errorConfirmPassword, setErrorConfirmPassword] = useState<string>('');
  const [errorBankName, setErrorBankName] = useState<string>('');
  const [errorBankAccountName, setErrorBankAccountName] = useState<string>('');
  const [errorBankAccountNumber, setErrorBankAccountNumber] = useState<string>('');

  const getStepContent = (step: number) => {
    switch (step) {
      case 1:
        return (
          <CompanyStep
            inputCompanyName={inputCompanyName}
            inputCompanyUen={inputCompanyUen}
            inputCompanyFile={inputCompanyFile}
            inputVehicleNumber={inputVehicleNumber}
            inputVehicleLogCard={inputVehicleLogCard}
            errorCompanyName={errorCompanyName}
            errorCompanyUen={errorCompanyUen}
            errorCompanyFile={errorCompanyFile}
            errorVehicleNumber={errorVehicleNumber}
            errorVehicleLogCard={errorVehicleLogCard}
            setInputCompanyName={setInputCompanyName}
            setInputCompanyUen={setInputCompanyUen}
            setInputCompanyFile={setInputCompanyFile}
            setInputVehicleNumber={setInputVehicleNumber}
            setInputVehicleLogCard={setInputVehicleLogCard}
            setUploadCompanyFile={setUploadCompanyFile}
            setUploadVehicleFile={setUploadVehicleFile}
            setErrorCompanyFile={setErrorCompanyFile}
            setErrorVehicleLogCard={setErrorVehicleLogCard}
            handleBlurCompanyName={handleBlurCompanyName}
            handleBlurCompanyUen={handleBlurCompanyUen}
            handleBlurVehicleNumber={handleBlurVehicleNumber}
            isLoadingUen={isLoadingUen}
          />
        );
      case 2:
        return (
          <BankStep
            banks={banks}
            driver={editedDriver}
            inputBankId={inputBankId}
            inputBankAccountName={inputBankAccountName}
            inputBankAccountNumber={inputBankAccountNumber}
            errorBankName={errorBankName}
            errorBankAccountName={errorBankAccountName}
            errorBankAccountNumber={errorBankAccountNumber}
            setInputBankId={setInputBankId}
            setInputBankAccountName={setInputBankAccountName}
            setInputBankAccountNumber={setInputBankAccountNumber}
            setErrorBankName={setErrorBankName}
            handleBlurBankAccountName={handleBlurBankAccountName}
            handleBlurBankAccountNumber={handleBlurBankAccountNumber}
            handleInvalidBankName={handleInvalidBankName}
          />
        );
      default:
        return (
          <PersonalStep
            driver={editedDriver}
            inputDisplayName={inputDisplayName}
            inputEmail={inputEmail}
            inputAddress={inputAddress}
            inputContactNumber={inputContactNumber}
            inputPassword={inputPassword}
            inputConfirmPassword={inputConfirmPassword}
            errorDisplayName={errorDisplayName}
            errorEmail={errorEmail}
            errorContactNumber={errorContactNumber}
            errorPassword={errorPassword}
            errorConfirmPassword={errorConfirmPassword}
            setInputDisplayName={setInputDisplayName}
            setInputEmail={setInputEmail}
            setInputAddress={setInputAddress}
            setInputContactNumber={setInputContactNumber}
            setInputPassword={setInputPassword}
            setInputConfirmPassword={setInputConfirmPassword}
            handleBlurName={handleBlurName}
            handleBlurEmail={handleBlurEmail}
            handleBlurContactNumber={handleBlurContactNumber}
            handleBlurPassword={handleBlurPassword}
            handleBlurConfirmPassword={handleBlurConfirmPassword}
            isLoadingEmail={isLoadingEmail}
            isLoadingContactNumber={isLoadingContactNumber}
          />
        );
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleNext = async () => {
    if (activeStep === 0) {
      const valid = await handlePersonalFormValidation();
      if (!valid) {
        return;
      }
    }

    if (activeStep === 1) {
      const valid = await handleCompanyFormValidation();
      if (!valid) {
        return;
      }
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleClose = () => {
    setOpen(false);
    setActiveStep(0);
    setSelectedId(0);
    handlePersonalFormClearError();
    handleCompanyFormClearError();
    handleBankFormClearError();
  };

  const handleSubmit: any = async () => {
    if (!handleBankFormValidation()) {
      return;
    }

    setLoading(true);
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    try {
      const valueBank = banks!.find(bank => bank.id === inputBankId);

      const newDriver: any = {
        companyName: inputCompanyName,
        companyUen: inputCompanyUen,
        companyFile: inputCompanyFile,
        vehicleNumber: inputVehicleNumber,
        vehicleLogCard: inputVehicleLogCard,
        bankName: valueBank ? valueBank.name : '',
        bankAccountName: inputBankAccountName,
        bankAccountNumber: inputBankAccountNumber,
        displayName: inputDisplayName,
        contactNumber: inputContactNumber,
        email: inputEmail,
        address: inputAddress,
        password: inputPassword
      };

      if (uploadCompanyFile) {
        const putUrl = await fecthPutCompanyFileUrl(inputCompanyFile);

        const fileName = inputCompanyFile ? inputCompanyFile : '';
        const fileExtension = fileName ? fileName.split('.').pop()! : '';

        const myHeaders = new Headers();
        if (fileExtension === 'pdf') {
          myHeaders.append('Content-Type', `application/${fileExtension}`);
        } else {
          myHeaders.append('Content-Type', `image/${fileExtension}`);
        }

        const config = {
          method: 'PUT',
          headers: myHeaders,
          body: uploadCompanyFile
        };

        const fileUpload = await fetch(putUrl, config);

        if (!fileUpload.ok) {
          handleOpenSnackbar('error', 'Failed upload company file!');
          setLoading(false);
          return;
        }
      }

      if (uploadVehicleFile) {
        const putUrl = await fecthPutVehicleFileUrl(inputVehicleLogCard);

        const fileName = inputVehicleLogCard ? inputVehicleLogCard : '';
        const fileExtension = fileName ? fileName.split('.').pop()! : '';

        const myHeaders = new Headers();
        if (fileExtension === 'pdf') {
          myHeaders.append('Content-Type', `application/${fileExtension}`);
        } else {
          myHeaders.append('Content-Type', `image/${fileExtension}`);
        }

        const config = {
          method: 'PUT',
          headers: myHeaders,
          body: uploadVehicleFile
        };

        const fileUpload = await fetch(putUrl, config);

        if (!fileUpload.ok) {
          handleOpenSnackbar('error', 'Failed upload vehicle file!');
          setLoading(false);
          return;
        }
      }

      if (editedDriver) {
        const { data } = await axios.put(INDIVIDUAL_DRIVER_URL(editedDriver.id), newDriver, { cancelToken: cancelTokenSource.token });

        const index = drivers.findIndex((data: DriverModel) => data.id === editedDriver.id);
        drivers[index] = { ...data };
        setDrivers([...drivers]);
        handleOpenSnackbar('success', 'Driver account has been updated!');
      } else {
        const { data } = await axios.post(DRIVERS_BASE_URL, newDriver, { cancelToken: cancelTokenSource.token });

        if (data && status === UserStatus.APPROVED) {
          setDrivers([{ ...data }, ...drivers]);
        } else {
          revertDefaultPage();
        }

        handleOpenSnackbar('success', 'Driver account has been created!');
      }

      handleClose();
      setLoading(false);
    } catch (err: any) {
      console.error('err: ', err);
      setLoading(false);
      handleOpenSnackbar('error', err.data.message);
    }

    return () => cancelTokenSource.cancel();
  };

  const handleResetInput = useCallback(() => {
    setInputCompanyName('');
    setInputCompanyUen('');
    setInputCompanyFile('');
    setInputVehicleNumber('');
    setInputVehicleLogCard('');
    setInputDisplayName('');
    setInputEmail('');
    setInputAddress('');
    setInputContactNumber('');
    setInputBankAccountName('');
    setInputBankAccountNumber('');
    setInputBankId(0);
  }, []);

  const handleResetInputEdited = useCallback(() => {
    if (!editedDriver) {
      return
    }
    
    const { individualCompanyName, individualCompanyUen, individualCompanyFile, vehicleNumber, vehicleLogCard, bankAccountName, bankAccountNumber, User } = editedDriver;
    const { displayName, contactNumber, email, address } = User;
    
    setInputCompanyName(individualCompanyName);
    setInputCompanyUen(individualCompanyUen);
    setInputCompanyFile(individualCompanyFile);
    setInputVehicleNumber(vehicleNumber);
    setInputVehicleLogCard(vehicleLogCard);
    setInputDisplayName(displayName);
    setInputEmail(email);
    setInputAddress(address || '');
    setInputContactNumber(contactNumber);
    setInputBankAccountName(bankAccountName);
    setInputBankAccountNumber(bankAccountNumber);
    setActiveStep(0);
    handlePersonalFormClearError();
    handleCompanyFormClearError();
    handleBankFormClearError();
  }, [editedDriver]);

  const handleBankFormClearError = () => {
    setErrorBankName('');
    setErrorBankAccountName('');
    setErrorBankAccountNumber('');
  };

  const handlePersonalFormClearError = () => {
    setErrorDisplayName('');
    setErrorEmail('');
    setErrorContactNumber('');
    setErrorPassword('');
    setErrorConfirmPassword('');
  };

  const handleCompanyFormClearError = () => {
    setErrorCompanyName('');
    setErrorCompanyUen('');
    setErrorCompanyFile('');
  };

  const handlePersonalFormValidation = async () => {
    let valid = true;

    if (!inputEmail || !isValidEmail(inputEmail)) {
      valid = false;
      handleInvalidEmail();
    }

    if (!inputDisplayName) {
      valid = false;
      handleInvalidDisplayName();
    }

    if (inputContactNumber.length < 8) {
      valid = false;
      handleInvalidContactNumber();
    }

    if (!editedDriver) {
      if (!inputPassword || !isStrongPassword(inputPassword)) {
        valid = false;
        handleInvalidPassword();
      }

      if (!inputConfirmPassword || inputConfirmPassword !== inputPassword) {
        valid = false;
        handleInvalidConfirmPassword();
      }
    }

    if (valid) {
      setLoadingEmail(true);
      const isExistEmail = await isExistValidation('EMAIL', inputEmail);
      if (isExistEmail) {
        valid = false;
        setErrorEmail('Email has been registered');
      }

      setLoadingEmail(false);

      setLoadingContactNumber(true);
      const isExistContact = await isExistValidation('CONTACT', inputContactNumber);
      if (isExistContact) {
        valid = false;
        setErrorContactNumber('Contact number has been registered');
      }

      setLoadingContactNumber(false);
    }

    return valid;
  };

  const handleCompanyFormValidation = async () => {
    let valid = true;

    if (!inputCompanyName) {
      valid = false;
      handleInvalidCompanyName();
    }

    if (!inputCompanyUen) {
      valid = false;
      handleInvalidCompanyUen();
    }

    if (!inputCompanyFile) {
      valid = false;
      handleInvalidCompanyFile();
    }

    if (!inputVehicleNumber) {
      valid = false;
      handleInvalidVehicleNumber();
    }

    if (!inputVehicleLogCard) {
      valid = false;
      handleInvalidVehicleLogCard();
    }

    if (valid) {
      setLoadingUen(true);
      const isExistUen = await isExistValidation('UEN', inputCompanyUen);
      if (isExistUen) {
        valid = false;
        setErrorCompanyUen('UEN number has been registered');
      }

      setLoadingUen(false);
    }

    return valid;
  };

  const handleBankFormValidation = () => {
    let valid = true;

    if (!inputBankId || inputBankId < 1) {
      valid = false;
      handleInvalidBankName();
    }

    if (!inputBankAccountName) {
      valid = false;
      handleInvalidBankAccountName();
    }

    if (!inputBankAccountNumber) {
      valid = false;
      handleInvalidBankAccountNumber();
    }

    return valid;
  };

  const handleBlurEmail = async (inputed: string) => {
    setErrorEmail('');
    if (!inputed || inputed === '' || !isValidEmail(inputEmail)) {
      handleInvalidEmail();
    }

    setLoadingEmail(true);
    const isExist = await isExistValidation('EMAIL', inputed);
    if (isExist) {
      setErrorEmail('Email has been registered');
    }

    setLoadingEmail(false);
  };

  const handleBlurName = (inputed: string) => {
    setErrorDisplayName('');
    if (!inputed || inputed === '') {
      handleInvalidDisplayName();
    }
  };

  const handleBlurContactNumber = async (inputed: string) => {
    setErrorContactNumber('');
    if (inputed.length < 8) {
      handleInvalidContactNumber();
    }

    setLoadingContactNumber(true);
    const isExist = await isExistValidation('CONTACT', inputed);
    if (isExist) {
      setErrorContactNumber('Contact number has been registered');
    }

    setLoadingContactNumber(false);
  };

  const handleBlurCompanyName = (inputed: string) => {
    setErrorCompanyName('');
    if (!inputed || inputed === '') {
      handleInvalidCompanyName();
    }
  };

  const handleBlurCompanyUen = async (inputed: string) => {
    setErrorCompanyUen('');
    if (!inputed || inputed === '') {
      handleInvalidCompanyUen();
    }

    setLoadingUen(true);
    const isExist = await isExistValidation('UEN', inputed);
    if (isExist) {
      setErrorCompanyUen('UEN number has been registered');
    }

    setLoadingUen(false);
  };

  const handleBlurVehicleNumber = (inputed: string) => {
    setErrorVehicleNumber('');
    if (!inputed || inputed === '') {
      handleInvalidVehicleNumber();
    }
  };

  const handleBlurPassword = (inputed: string) => {
    setErrorPassword('');
    if (!inputed || inputed === '' || !isStrongPassword(inputed)) {
      handleInvalidPassword();
    }

    if (inputConfirmPassword) {
      setErrorConfirmPassword('');
      if (inputed !== inputConfirmPassword) {
        handleInvalidConfirmPassword();
      }
    }
  };

  const handleBlurConfirmPassword = (inputed: string) => {
    setErrorConfirmPassword('');
    if (!inputed || inputed === '' || inputed !== inputPassword) {
      handleInvalidConfirmPassword();
    }
  };

  const handleBlurBankAccountName = (inputed: string) => {
    setErrorBankAccountName('');
    if (!inputed || inputed === '') {
      handleInvalidBankAccountName();
    }
  };

  const handleBlurBankAccountNumber = (inputed: string) => {
    setErrorBankAccountNumber('');
    if (!inputed || inputed === '') {
      handleInvalidBankAccountNumber();
    }
  };

  const handleInvalidEmail = () => {
    setErrorEmail('Please enter valid email');
  };

  const handleInvalidDisplayName = () => {
    setErrorDisplayName('Please enter display name');
  };

  const handleInvalidContactNumber = () => {
    setErrorContactNumber('Please enter contact number 8 digits');
  };

  const handleInvalidCompanyName = () => {
    setErrorCompanyName('Please enter company name');
  };

  const handleInvalidCompanyUen = () => {
    setErrorCompanyUen('Please enter company UEN number');
  };

  const handleInvalidCompanyFile = () => {
    setErrorCompanyFile('Please select ACRA file');
  };

  const handleInvalidPassword = () => {
    setErrorPassword('Please enter password at least 8 digits containing 1 uppercase letter, 1 number and 1 symbol');
  };

  const handleInvalidConfirmPassword = () => {
    setErrorConfirmPassword('Please enter valid confirm password');
  };

  const handleInvalidBankName = () => {
    setErrorBankName('Please select bank');
  };

  const handleInvalidBankAccountName = () => {
    setErrorBankAccountName('Please enter bank account name');
  };

  const handleInvalidBankAccountNumber = () => {
    setErrorBankAccountNumber('Please enter bank account number');
  };

  const handleInvalidVehicleNumber = () => {
    setErrorVehicleNumber('Please enter vehicle number');
  };

  const handleInvalidVehicleLogCard = () => {
    setErrorVehicleLogCard('Please select vehicle log card file');
  };

  const fetchDataBanks = useCallback(async () => {
    if (!open) {
      return;
    }

    try {
      const { data } = await axios.get(BANKS_BASE_URL);

      setBanks(data.Banks);
    } catch (err) {
      console.error('err: ', err);
    }

    return;
  }, [open]);

  const fecthPutCompanyFileUrl = async (filename: string) => {
    try {
      const { data } = await axios.get(`${MERCHANTS_BASE_URL}/put-company-file-url?file=${filename}`);

      return data.companyFile;
    } catch (err) {
      console.error('err: ', err);
    }

    return;
  };

  const fecthPutVehicleFileUrl = async (filename: string) => {
    try {
      const { data } = await axios.get(`${DRIVERS_BASE_URL}/put-vehicle-file-url?file=${filename}`);

      return data.vehicleLogCardFile;
    } catch (err) {
      console.error('err: ', err);
    }

    return;
  };

  const isExistValidation = async (field: string, value: string) => {
    let exist = false;
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    try {
      const userId = editedDriver ? `&userId=${editedDriver.UserId}` : '';
      const { data } = await axios.get(`${GET_CHECK_USER_URL}?role=DRIVER&field=${field}&params=${value}${userId}`, {
        cancelToken: cancelTokenSource.token
      });

      console.clear();
      console.log(data);
      exist = data.isExist;
    } catch (err: any) {
      console.error('err: ', err);
      handleOpenSnackbar('error', err.data.message);
    }

    return exist;
  };

  useEffect(() => {
    fetchDataBanks();
  }, [fetchDataBanks]);

  useEffect(() => {
    if (!open) {
      return;
    }

    if (!editedDriver) {
      handleResetInput();
      return;
    }

    handleResetInputEdited();
  }, [open, editedDriver, handleResetInput, handleResetInputEdited]);

  return (
    <div>
      <Dialog fullWidth={true} maxWidth='sm' onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
        <DialogTitle id='customized-dialog-title' onClose={handleClose}>
          <Typography variant='h4'>Create New Driver</Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: { optional?: React.ReactNode } = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <Typography>{getStepContent(activeStep)}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' color='primary' disabled={loading} onClick={activeStep === 0 ? handleClose : handleBack}>
            {activeStep === 0 ? 'Cancel' : 'Back'}
          </Button>
          <div className={classes.wrapper}>
            <Button variant='contained' color='primary' disabled={loading} onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DriverFormModal;