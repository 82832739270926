import React from 'react';
import { CircularProgress, Divider, Drawer, IconButton, Toolbar, Typography } from '@material-ui/core';
import { Close, Delete } from '@material-ui/icons';
import axios from 'axios';
import { Skeleton } from '@material-ui/lab';
import { WEB_LAMPPOST_BASE_URL } from 'constants/url';
import Snackbar from 'components/Snackbar';
import useSnackbar from 'hooks/useSnackbar';

interface Props {
  streetName: string;
  onClose: () => void;
}

const LampPostDrawer = ({ streetName, onClose }: Props) => {
  const abort = axios.CancelToken.source();
  const { openSnacbar, snackbar } = useSnackbar();
  const [data, setData] = React.useState<LampPost[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [loadingDelete, setLoadingDelete] = React.useState(-1);

  const getLampPost = async (value: string) => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${WEB_LAMPPOST_BASE_URL}/code`, { params: { streetName: value }, cancelToken: abort.token });
      setData(data.LampPosts || []);
    } catch (error) {
      console.log('error', JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  };

  const onDelete = (lampPost: string, streetName: string, index: number) => async () => {
    try {
      setLoadingDelete(index);
      await axios.post(`${WEB_LAMPPOST_BASE_URL}/delete`, {
        lampPost,
        streetName
      });
      setData(p => [...p.filter(v => v.lampPost !== lampPost)]);
      openSnacbar('LampPost successfully deleted');
    } catch (e) {
      openSnacbar(JSON.stringify(e), { variant: 'error' });
    } finally {
      setLoadingDelete(-1);
    }
  };

  React.useEffect(() => {
    if (streetName === '') {
      return;
    }

    getLampPost(streetName);
    return () => {
      abort.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streetName]);

  return (
    <Drawer open={!!streetName} anchor='right' PaperProps={{ style: { width: '45%' } }} onClose={onClose}>
      <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='h5'>LampPost</Typography>
        <IconButton size='small' onClick={onClose}>
          <Close fontSize='inherit' />
        </IconButton>
      </Toolbar>
      <Divider />

      <Typography variant='h5' gutterBottom style={{ margin: '8px' }}>
        {streetName}
      </Typography>

      <Divider />

      <table style={{ width: '100%', padding: '8px' }}>
        <thead>
          <tr>
            <th>LampPost</th>
            <th>Latitude</th>
            <th>Longitude</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan={3} style={{ padding: '4px' }}>
                <Skeleton width={'100%'} variant='text' />
              </td>
            </tr>
          ) : (
            data.map((value, i) => (
              <tr key={i}>
                <td style={{ padding: '8px' }}>{value.lampPost}</td>
                <td style={{ textAlign: 'right' }}>{value.latitude}</td>
                <td style={{ textAlign: 'right' }}>{value.longitude}</td>
                <td style={{ textAlign: 'center' }}>
                  {loadingDelete === i ? (
                    <CircularProgress size={20} />
                  ) : (
                    <IconButton size='small' color='primary' onClick={onDelete(value.lampPost, streetName, i)}>
                      <Delete fontSize='inherit' />
                    </IconButton>
                  )}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      <Snackbar {...snackbar} />
    </Drawer>
  );
};

export default LampPostDrawer;
