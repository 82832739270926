import { Box, Button, CircularProgress, Dialog, Typography } from '@material-ui/core';
import { Error, Warning } from '@material-ui/icons';
import React, { FC } from 'react';

export interface AlertProps {
  open: boolean;
  title: string;
  loading: boolean;
  message: string;
  type: 'notice' | 'danger';
  close?: {
    text?: string;
    onClick?: () => void
  };
  confirm: {
    text?: string
    onClick?: () => void
  };
}

const Alert:FC<AlertProps> = (props) => {
  return (
    <Dialog open={props.open} aria-describedby={'alert-' + props.type || 'notice'} aria-labelledby={'alert-' + props.type || 'notice'} maxWidth="xs">
      <Box style={{display: "flex", flexDirection: "column", justifyContent: "center", padding: "8px"}}>
        <Box style={{textAlign: "center"}}>
          {props.type === 'notice' ? (
            <Error fontSize='large' style={{ color: '#F2994A' }} />
          ) : props.type === 'danger' ? (
            <Warning fontSize='large' style={{ color: '#EB5757' }} />
          ) : null}
        </Box>
        <Box style={{ marginTop: 0, marginBottom: 8 }}>
          <Typography variant='subtitle1' align={'center'}>
            {props.title ? props.title : null}
          </Typography>

          <Typography variant='body2' >
            {props.message ? props.message : null}
          </Typography>
        </Box>
        <Box style={{textAlign: "center"}}>
          {props.close ? (
            <Button disableElevation size='small' disabled={props.loading} variant='text' color='inherit' onClick={props.close.onClick} style={{marginRight: "8px"}}>
              {props.close?.text || 'Close'}
            </Button>
          ) : null}

          {props.confirm ? (
            <Button
            disableElevation
              size='small'
              disabled={props.loading}
              variant='contained'
              color='primary'
              onClick={props.confirm.onClick}>
              {props.loading ? <CircularProgress size={20} /> : props.confirm?.text || 'Confirm'}
            </Button>
          ) : null}
        </Box>
      </Box>
    </Dialog>
  );
};

export default Alert;
