import React, { FC } from 'react';
import { Table, TableBody, TableHead, TableFooter, TableRow, TableCell, TableContainer } from '@material-ui/core';

import HeaderRow from 'components/HeaderRow';
import TablePagination from 'components/TablePagination';
import BodyRow from './components/BodyRow';

import TableLoading from 'components/Table/TableLoading';
import { UseTablePagination } from 'hooks/useTablePagination';
import { getVehicleTypeSelect } from 'utils';

interface Props extends UseTablePagination<MerchantList> {
  handleOpenDetailClick: (id: number) => void;
}

const MerchantTable: FC<Props> = ({ handleOpenDetailClick, ...props }) => {
  return (
    <TableContainer style={{ boxShadow: 'none', borderRadius: 0 }}>
      <Table size='small'>
        <TableHead>
          <HeaderRow
            order={props.order}
            orderBy={props.orderBy}
            onRequestSort={(_, value) => {
              props.resetPage();
              props.onOrder(value);
            }}
            height={'0px'}
            headers={[
              {
                id: 'id',
                label: 'ID',
                sort: true,
                pT: '16px',
                pB: '16px'
              },
              {
                id: 'companyName',
                label: 'Display Name & Company Name',
                sort: true,
                pT: '16px',
                pB: '16px'
              },
              { id: 'merchantType', label: 'Company Type' },
              {
                id: 'companyUen',
                label: 'Company UEN',
                sort: true,
                pT: '16px',
                pB: '16px'
              },
              {
                id: 'companyGst',
                label: 'GST No.',
                sort: true,
                pT: '16px',
                pB: '16px'
              },
              {
                id: 'contactNumber',
                label: 'Phone',
                sort: true,
                pT: '16px',
                pB: '16px'
              }
            ]}
          />

          <HeaderRow
            height={'0px'}
            headers={[
              {
                id: 'findId',
                isInputText: true,
                value: props.query('id'),
                handleInputChange: value => props.setQuery({ id: value }),
                pT: '0px',
                pB: '0px',
                pL: '16px',
                pR: '8px'
              },
              {
                id: 'findCompanyName',
                isInputText: true,
                value: props.query('companyName'),
                handleInputChange: value => props.setQuery({ companyName: value }),
                pT: '0px',
                pB: '0px',
                pL: '16px',
                pR: '8px'
              },
              {
                id: 'findType',
                isSelect: true,
                label: 'Company Type',
                value: props.query('type'),
                selectOption: [...getVehicleTypeSelect(), { id: 'MERCHANT', name: 'Merchant' }],
                handleOptionChange: (value: any) => props.setQuery({ type: value }),
                pT: '0px',
                pB: '0px',
                pL: '16px',
                pR: '8px',
                cellProps: {
                  style: {
                    minWidth: '175px'
                  }
                }
              },
              {
                id: 'findCompanyUen',
                isInputText: true,
                value: props.query('companyUen'),
                handleInputChange: value => props.setQuery({ companyUen: value }),
                pT: '0px',
                pB: '0px',
                pL: '16px',
                pR: '8px'
              },
              {
                id: 'findCompanyGst',
                isInputText: true,
                value: props.query('companyGst'),
                handleInputChange: value => props.setQuery({ companyGst: value }),
                pT: '0px',
                pB: '0px',
                pL: '16px',
                pR: '8px'
              },
              {
                id: 'findContactNumber',
                isInputText: true,
                value: props.query('contactNumber'),
                handleInputChange: value => props.setQuery({ contactNumber: value }),
                pT: '0px',
                pB: '0px',
                pL: '16px',
                pR: '8px'
              }
            ]}
          />
        </TableHead>

        <TableBody>
          {props.loading ? (
            <TableLoading col={6} />
          ) : props.isEmpty ? (
            <TableRow>
              <TableCell align='center' colSpan={6}>
                Data Not Available.
              </TableCell>
            </TableRow>
          ) : (
            props.data.map((merchant, index) => <BodyRow key={index} {...merchant} handleOpenDetailClick={handleOpenDetailClick} />)
          )}
        </TableBody>
        <TableFooter>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            count={props.count}
            rowsPerPage={props.perPage}
            page={props.page}
            onChangePage={props.onPageChange}
            onChangeRowsPerPage={props.onRowsChange}
          />
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default MerchantTable;
