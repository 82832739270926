import { format } from 'date-fns';
import JobStatus from 'typings/enum/JobStatus';
import JobType from 'typings/enum/JobType';
import TransactionPaymentMethod from 'typings/enum/TransactionPaymentMethod';
import TruckType from 'typings/enum/TruckType';
import WorkType from 'typings/enum/WorkType';

export const isValidEmail = (email: string): boolean => {
  // eslint-disable-next-line
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase());
};

export const isStrongPassword = (password: string): boolean => {
  // eslint-disable-next-line
  const regularExpression = /^(?=.{8,})(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+=,.:;?<>|~{}`\[\]/])/;

  return regularExpression.test(String(password));
};

export const ucwords = (str?: string) => {
  if (str) {
    return (str + '')
      .toLowerCase()
      .replace(/_/g, ' ')
      .replace(/^([a-z])|\s+([a-z])/g, function($1) {
        return $1.toUpperCase();
      });
  } else {
    return '';
  }
};

export const staticString = (str: string) => {
  return str ? str.toUpperCase().replace(/ /g, '_') : '';
};

export const min = format(new Date(), 'yyyy-MM-dd') + 'T00:00';

export const ucWords = (text: string): string => text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

export function formatId(id: number) {
  return '#' + String(id).padStart(3, '0');
}

export const formatCurrency = (number: number) => {
  return new Intl.NumberFormat('en-SG', {
    style: 'currency',
    currency: 'SGD'
  }).format(number);
};

export const getPercent = (discount: number, original: number): number => {
  if (discount === 0 || original === 0) {
    return 0;
  }
  return Math.round(Number((discount / original).toFixed(2)) * 100);
};

export const snackCaseToSentence = (text: string) => {
  return text
    .split('_')
    .map(v => ucWords(v))
    .join(' ');
};

export const ccFormat = (value: string) => {
  let v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
  let matches = v.match(/\d{4,16}/g);
  let match = (matches && matches[0]) || '';
  let parts: any[] = [];
  for (let i = 0, len = match.length; i < len; i += 4) {
    parts.push(match.substring(i, i + 4));
  }
  if (parts.length) {
    return parts.join(' ');
  } else {
    return value;
  }
};

export const jobTypeLabel = (label: string) => {
  return (
    {
      [JobType.DAYWORKS]: 'Dayworks',
      [JobType.OU_WORKS]: 'OU Works',
      [JobType.TRIPWORKS]: 'Tripworks'
    }[label] || '-'
  );
};

export const truckTypeLabel = (label: string) => {
  return (
    {
      [TruckType.LORRY_CRANE]: 'Lorry Crane',
      [TruckType.TIPPER]: 'Tipper',
      [TruckType.TMA]: 'TMA',
      [TruckType.TRAILER]: 'Trailer',
      MERCHANT: 'Merchant'
    }[label] || 'Tipper'
  );
};

export const workTypeLabel = (label: string) => {
  return (
    {
      [WorkType.DISPOSAL]: 'Disposal',
      [WorkType.PURCHASE]: 'Purchase'
    }[label] || 'N/A'
  );
};

export const pick = (obj: any, arr: string[]) => {
  return Object.fromEntries(Object.entries(obj).filter(([k, _]) => (arr.length > 0 ? arr.includes(k) : true)));
};

export const isFloat = (x: string) => {
  var rgx = /^[0-9]*\.?[0-9]*$/;
  return x.match(rgx);
};

export const allTaskOneDriverType = [TruckType.LORRY_CRANE, TruckType.TRAILER];

export const listMonths = [
  { month: 1, name: 'January' },
  { month: 2, name: 'February' },
  { month: 3, name: 'March' },
  { month: 4, name: 'April' },
  { month: 5, name: 'May' },
  { month: 6, name: 'June' },
  { month: 7, name: 'July' },
  { month: 8, name: 'August' },
  { month: 9, name: 'September' },
  { month: 10, name: 'October' },
  { month: 11, name: 'November' },
  { month: 12, name: 'December' }
];

export const listYears = (): number[] => {
  const startYear = 2022;
  const currentYear = new Date().getFullYear();
  const years: number[] = [];

  for (let i = startYear; i <= currentYear; i++) {
    years.push(i);
  }

  return years;
};

export const getHours = (minutes?: number) => {
  if (!minutes) return 0;
  return minutes / 60;
};

export const getAppFee = (total: number, percent: number): number => {
  return total * (percent / 100);
};

export const rounding = (number: number): number => {
  return String(number).split('.').length > 1 ? Math.floor(number * 100) / 100 : number;
};

export const jobPayments = [{ [TransactionPaymentMethod.JUSTGO_WALLET]: 'Justgo Wallet' }, { [TransactionPaymentMethod.CREDIT_WALLET]: 'Credit' }];

export const getJobTypeSelect = () => {
  const jobType = [
    { id: JobType.DAYWORKS, name: jobTypeLabel(JobType.DAYWORKS) },
    { id: JobType.TRIPWORKS, name: jobTypeLabel(JobType.TRIPWORKS) },
    { id: JobType.OU_WORKS, name: jobTypeLabel(JobType.OU_WORKS) }
  ];

  return jobType;
};

export const getVehicleTypeSelect = () => {
  const truckTypes = [
    { id: TruckType.TIPPER, name: truckTypeLabel(TruckType.TIPPER) },
    { id: TruckType.TMA, name: truckTypeLabel(TruckType.TMA) },
    { id: TruckType.LORRY_CRANE, name: truckTypeLabel(TruckType.LORRY_CRANE) },
    { id: TruckType.TRAILER, name: truckTypeLabel(TruckType.TRAILER) }
  ];

  return truckTypes;
};

export const getJobStatusSelect = () => {
  const jobStatus = [
    { id: JobStatus.DRAFT, name: JobStatus.DRAFT },
    { id: JobStatus.AVAILABLE, name: JobStatus.AVAILABLE },
    { id: JobStatus.ASSIGNED, name: JobStatus.ASSIGNED },
    { id: JobStatus.IN_PROGRESS, name: JobStatus.IN_PROGRESS },
    { id: JobStatus.COMPLETED, name: JobStatus.COMPLETED },
    { id: JobStatus.CANCELLED, name: JobStatus.CANCELLED },
    { id: JobStatus.EXPIRED, name: JobStatus.EXPIRED }
  ];

  return jobStatus;
};

export const getPaymentMethodSelect = () => {
  const selectPayment = [
    { id: TransactionPaymentMethod.JUSTGO_WALLET, name: 'Justgo Wallet' },
    { id: TransactionPaymentMethod.CREDIT_WALLET, name: 'Credit' }
  ];

  return selectPayment;
};

export const getWorkTypeSelect = () => {
  const truckTypes = [
    { id: WorkType.DISPOSAL, name: workTypeLabel(WorkType.DISPOSAL) },
    { id: WorkType.PURCHASE, name: workTypeLabel(WorkType.PURCHASE) }
  ];

  return truckTypes;
};

export const getGroupJobTypeSelect = () => {
  const jobType = [
    { id: JobType.DAYWORKS, name: jobTypeLabel(JobType.DAYWORKS) },
    { id: JobType.TRIPWORKS, name: jobTypeLabel(JobType.TRIPWORKS) },
    { id: JobType.OU_WORKS, name: jobTypeLabel(JobType.OU_WORKS), isHead: true },
    { id: `${JobType.OU_WORKS}_${WorkType.DISPOSAL}`, name: `${jobTypeLabel(JobType.OU_WORKS)} ${workTypeLabel(WorkType.DISPOSAL)}` },
    { id: `${JobType.OU_WORKS}_${WorkType.PURCHASE}`, name: `${jobTypeLabel(JobType.OU_WORKS)} ${workTypeLabel(WorkType.PURCHASE)}` },
    { id: `${JobType.OU_WORKS}_ALL`, name: `${jobTypeLabel(JobType.OU_WORKS)} All` }
  ];

  return jobType;
};
