import React from 'react';
import {
  DialogActions,
  DialogActionsProps,
  DialogContent,
  DialogContentProps,
  DialogProps,
  DialogTitle,
  IconButton,
  Dialog as MUIDialog,
  Theme,
  Typography,
  makeStyles
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';

export interface Dialog extends DialogProps {
  title: string;
  action?: DialogActionsProps;
  contentProps?: DialogContentProps;
  handleClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  dialogTitle: { margin: 0, padding: '8px' },
  closeButton: { position: 'absolute', right: theme.spacing(1), top: theme.spacing(1), color: theme.palette.grey[500] },
  title: {
    marginRight: '32px',
    fontWeight: 600
  }
}));

const Dialog = ({ title, action, contentProps, handleClose, ...props }: Dialog) => {
  const classes = useStyles();
  return (
    <MUIDialog maxWidth='lg' onClose={handleClose} {...props}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography variant='h5' className={classes.title}>
          {title}
        </Typography>
        {handleClose ? (
          <IconButton size='small' onClick={handleClose} className={classes.closeButton}>
            <Close fontSize='inherit' />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent dividers {...contentProps}>
        {props.children}
      </DialogContent>
      {action ? <DialogActions {...action} /> : null}
    </MUIDialog>
  );
};

export default Dialog;
