import LoginPage from 'pages/LoginPage';
import NotFoundPage from 'pages/NotFoundPage';
import ThankYouPage from 'pages/ThankYouPage';
import ForgotPasswordPage from 'pages/ForgotPasswordPage';
import ResetPasswordPage from 'pages/ResetPasswordPage';
import AccountVerificationPage from 'pages/AccountVerifcationPage';

import MerchantDetailPage from 'pages/MerchantsPage/MerchantDetailPage';
import MerchantsPage from 'pages/MerchantsPage';
import DriversPage from 'pages/DriversPage';
import JobDetailPage from 'pages/JobsPage/JobDetailPage';
import JobList from 'pages/JobsPage/JobList';
import TransactionsPage from 'pages/TransactionsPage';
import InvoicesPage from 'pages/InvoicesPage';
import AnalyticsPage from 'pages/AnalyticsPage';
import SettingsPage from 'pages/SettingsPage';
import AdditionalTemplatePage from 'pages/AdditionalTemplatePage';

import { Props as RoutesProps } from 'components/ConditionalRoute';
import MerchantTopupDetailPage from 'pages/MerchantsPage/MerchantTopupDetailPage';
import DriverDetailPage from 'pages/DriversPage/DriverDetailPage';
import JobDriverDetailPage from 'pages/JobsPage/JobDriverDetailPage';
import MerchantCreditDetailPage from 'pages/MerchantsPage/MerchantCreditDetailPage';
import MerchantCreditPaymentDetailPage from 'pages/MerchantsPage/MerchantCreditPaymentDetailPage';
import TransactionList from 'pages/TransactionsPage/TransactionList';
import MaterialTemplatePage from 'pages/MaterialTremplatePage';
import TonnageTemplatePage from 'pages/TonnageTemplatePage';
import EquipmentTemplatePage from 'pages/EquipmentTemplatePage';
import SupportPage from 'pages/SupportPage/SupportList';
import LampPostPage from 'pages/LampoPostPage';

const protectedPage = (isLoggedIn: boolean): RoutesProps[] => {
  return [
    { path: '/settings', component: SettingsPage, routeCondition: isLoggedIn },
    { path: '/settings/additional', component: AdditionalTemplatePage, routeCondition: isLoggedIn },
    { path: '/settings/materials', component: MaterialTemplatePage, routeCondition: isLoggedIn },
    { path: '/settings/tonnages', component: TonnageTemplatePage, routeCondition: isLoggedIn },
    { path: '/settings/equipments', component: EquipmentTemplatePage, routeCondition: isLoggedIn },
    { path: '/settings/lampposts', component: LampPostPage, routeCondition: isLoggedIn },
    { path: '/owners', component: MerchantsPage, routeCondition: isLoggedIn },
    { path: '/owner/:id', component: MerchantDetailPage, routeCondition: isLoggedIn },
    { path: '/owner/topup/:id/detail', component: MerchantTopupDetailPage, routeCondition: isLoggedIn },
    { path: '/drivers', component: DriversPage, routeCondition: isLoggedIn },
    { path: '/driver/:id', component: DriverDetailPage, routeCondition: isLoggedIn },
    { path: '/jobs', component: JobList, routeCondition: isLoggedIn },
    { path: '/job/:id/:merchant/driver', component: JobDriverDetailPage, routeCondition: isLoggedIn },
    { path: '/job/:id', component: JobDetailPage, routeCondition: isLoggedIn },
    { path: '/invoices', component: InvoicesPage, routeCondition: isLoggedIn },
    { path: '/transactions/old', component: TransactionsPage, routeCondition: isLoggedIn },
    { path: '/transactions', component: TransactionList, routeCondition: isLoggedIn },
    { path: '/transaction/credit/:id/detail', component: MerchantCreditDetailPage, routeCondition: isLoggedIn },
    { path: '/transaction/credit-payment/:id/detail', component: MerchantCreditPaymentDetailPage, routeCondition: isLoggedIn },
    { path: '/analytics', component: AnalyticsPage, routeCondition: isLoggedIn },
    { path: '/supports', component: SupportPage, routeCondition: isLoggedIn }
  ];
};

const router = (isLoggedIn: boolean): RoutesProps[] => {
  return [
    { path: '/', component: LoginPage, routeCondition: !isLoggedIn, redirectTo: '/owners' },
    { path: '/forgotpassword', component: ForgotPasswordPage, routeCondition: !isLoggedIn, redirectTo: '/thankyou' },
    { path: '/thankyou', component: ThankYouPage, routeCondition: !isLoggedIn },
    { path: '/resetpassword', component: ResetPasswordPage, routeCondition: !isLoggedIn },
    { path: '/activation', component: AccountVerificationPage, routeCondition: !isLoggedIn },
    ...protectedPage(isLoggedIn),
    { path: '*', component: NotFoundPage }
  ];
};

export default router;
