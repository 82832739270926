import React, { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import { TableCell, TableRow } from '@material-ui/core';
import BodyCell from 'components/BodyCell';
import { formatCurrency, formatId } from 'utils';

interface Props {
  items: JobAdditionalItem;
  isLoadingData: boolean;
  hasAdditional?: boolean;
}

const BodyRow: FC<Props> = props => {
  const { isLoadingData, items } = props;

  return (
    <TableRow hover>
      <BodyCell>{isLoadingData ? <Skeleton /> : formatId(items.id)}</BodyCell>
      <BodyCell>{isLoadingData ? <Skeleton /> : items.name}</BodyCell>
      <BodyCell>{isLoadingData ? <Skeleton /> : items.description}</BodyCell>
      <BodyCell align='center'>{isLoadingData ? <Skeleton /> : items.totalItem}</BodyCell>
      <TableCell align='right' style={{ paddingRight: 0 }}>
        {isLoadingData ? <Skeleton /> : formatCurrency(items.price)}
      </TableCell>
      <TableCell align='right' style={{ paddingRight: 0 }}>
        {isLoadingData ? <Skeleton /> : formatCurrency(items.totalPrice)}
      </TableCell>
      <TableCell></TableCell>
    </TableRow>
  );
};

export default BodyRow;
