import React, { FC, useState, useCallback, useEffect } from 'react';
import axios, { CancelTokenSource } from 'axios';

import { DRIVERS_BASE_URL, INDIVIDUAL_STATUS_DRIVER_URL } from 'constants/url';
import UserStatus from 'typings/enum/UserStatus';

import ActionSnackbar from 'components/ActionSnackbar';
import DriverTable from './DriverTable';
import DriverDetailModal from './DriverDetailModal';
import DriverFormModal from './DriverFormModal';

interface Props {
  noTitle?: boolean;
  merchantId?: number;
}

const DriversPage: FC<Props> = props => {
  const { merchantId } = props;

  const [drivers, setDrivers] = useState<DriverModel[]>([]);
  const [selectedId, setSelectedId] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

  const [status, setStatus] = useState<string>('');
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('createdAt');
  const [queryId, setQueryId] = useState<string>();
  const [queryName, setQueryName] = useState<string>('');
  const [queryEmail, setQueryEmail] = useState<string>('');
  const [queryContact, setQueryContact] = useState<string>('');
  const [queryCompanyName, setQueryCompanyName] = useState<string>('');
  const [queryCompanyUen, setQueryCompanyUen] = useState<string>('');
  const [queryVehicleNumber, setQueryVehicleNumber] = useState<string>('');

  const [openDetailModal, setOpenDetailModal] = useState<boolean>(false);
  const [openFormModal, setOpenFormModal] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');

  const fetchData = useCallback(async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    setIsLoadingData(true);

    const params = new URLSearchParams();
    params.append('s', (currentPage * rowsPerPage).toString());
    params.append('l', rowsPerPage.toString());
    params.append('status', status);

    if (merchantId) {
      params.append('merchantId', String(merchantId));
    }

    if (queryId) {
      params.append('queryId', queryId);
    }

    if (queryName) {
      params.append('queryName', queryName);
    }

    if (queryEmail) {
      params.append('queryEmail', queryEmail);
    }

    if (queryContact) {
      params.append('queryContact', queryContact);
    }

    if (queryVehicleNumber) {
      params.append('queryVehicleNumber', queryVehicleNumber);
    }

    if (queryCompanyName) {
      params.append('queryCompanyName', queryCompanyName);
    }

    if (queryCompanyUen) {
      params.append('queryCompanyUen', queryCompanyUen);
    }

    if (orderBy) {
      params.append('orderBy', orderBy);
    }

    if (order) {
      params.append('order', order.toUpperCase());
    }

    try {
      const { data } = await axios.get(`${DRIVERS_BASE_URL}?${params.toString()}`, { cancelToken: cancelTokenSource.token });

      setDrivers(data.Drivers);
      setCount(data.count);
      await fetchCountPending();
    } catch (err) {
      console.error('err: ', err);
    }
    setIsLoadingData(false);
    return () => cancelTokenSource.cancel();
  }, [
    rowsPerPage,
    currentPage,
    order,
    orderBy,
    status,
    merchantId,
    queryId,
    queryName,
    queryEmail,
    queryContact,
    queryVehicleNumber,
    queryCompanyName,
    queryCompanyUen
  ]);

  const fetchCountPending = async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    setIsLoadingData(true);

    try {
      await axios.get(`${DRIVERS_BASE_URL}/count-pending`, { cancelToken: cancelTokenSource.token });
    } catch (err) {
      console.error('err: ', err);
    }
    setIsLoadingData(false);
    return () => cancelTokenSource.cancel();
  };

  const handleStatusOption = async (newStatus: string, remarks?: string, address?: string) => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    try {
      await axios.put(
        INDIVIDUAL_STATUS_DRIVER_URL(selectedId),
        { status: newStatus, remarks, address: newStatus === UserStatus.APPROVED ? address : undefined },
        { cancelToken: cancelTokenSource.token }
      );

      setDrivers(drivers.filter(driver => driver.id !== selectedId));
      performActionAndRevertPage(setStatus, status);
      handleOpenSnackbar('success', `Driver account has been ${newStatus.toLowerCase()}!`);
    } catch (err) {
      console.error('err: ', err);
      handleOpenSnackbar('error', 'Network error, failed proccess data');
    }

    return () => cancelTokenSource.cancel();
  };

  const handleQueryVehicleChange = (query: string) => {
    performActionAndRevertPage(setQueryVehicleNumber, query);
  };

  const handleQueryIdChange = (query: string) => {
    performActionAndRevertPage(setQueryId, query);
  };

  const handleQueryNameChange = (query: string) => {
    performActionAndRevertPage(setQueryName, query);
  };

  const handleQueryEmailChange = (query: string) => {
    performActionAndRevertPage(setQueryEmail, query);
  };

  const handleQueryContactChange = (query: string) => {
    performActionAndRevertPage(setQueryContact, query);
  };

  const handleQueryCompanyNameChange = (query: string) => {
    performActionAndRevertPage(setQueryCompanyName, query);
  };

  const handleQueryCompanyUenChange = (query: string) => {
    performActionAndRevertPage(setQueryCompanyUen, query);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setCurrentPage(0);
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const revertDefaultPage = () => {
    performActionAndRevertPage(setStatus, UserStatus.APPROVED);
  };

  const performActionAndRevertPage = (action: React.Dispatch<React.SetStateAction<any>>, actionParam: any) => {
    setCurrentPage(0);
    action(actionParam);
  };

  const handleOpenSnackbar = (type: 'success' | 'error', message: string) => {
    setOpenSnackbar(true);
    setSnackbarMessage(message);
    setSnackbarVarient(type);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
    setSnackbarMessage('');
    setSnackbarVarient('success');
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <DriverTable
        isLoadingData={isLoadingData}
        count={count}
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
        order={order}
        orderBy={orderBy}
        queryId={queryId}
        queryName={queryName}
        queryEmail={queryEmail}
        queryContact={queryContact}
        queryCompanyName={queryCompanyName}
        queryCompanyUen={queryCompanyUen}
        queryVehicleNumber={queryVehicleNumber}
        drivers={drivers}
        merchantId={merchantId}
        openDetailModal={openDetailModal}
        setOpenDetailModal={setOpenDetailModal}
        setSelectedId={setSelectedId}
        handleQueryVehicleChange={handleQueryVehicleChange}
        handleQueryIdChange={handleQueryIdChange}
        handleQueryNameChange={handleQueryNameChange}
        handleQueryEmailChange={handleQueryEmailChange}
        handleQueryContactChange={handleQueryContactChange}
        handleQueryCompanyNameChange={handleQueryCompanyNameChange}
        handleQueryCompanyUenChange={handleQueryCompanyUenChange}
        handleRequestSort={handleRequestSort}
        handleChangePage={(event, page) => setCurrentPage(page)}
        handleChangeRowsPerPage={event => performActionAndRevertPage(setRowsPerPage, +event.target.value)}
      />

      <DriverDetailModal
        open={openDetailModal}
        setOpen={setOpenDetailModal}
        setSelectedId={setSelectedId}
        setOpenFormEdit={setOpenFormModal}
        handleStatusOption={handleStatusOption}
        driver={drivers.find((data: DriverModel) => data.id === selectedId)}
      />

      <DriverFormModal
        status={status}
        open={openFormModal}
        setOpen={setOpenFormModal}
        drivers={drivers}
        setDrivers={setDrivers}
        setSelectedId={setSelectedId}
        editedDriver={drivers.find((data: DriverModel) => data.id === selectedId)}
        handleOpenSnackbar={handleOpenSnackbar}
        revertDefaultPage={revertDefaultPage}
      />

      <ActionSnackbar variant={snackbarVarient} message={snackbarMessage} open={openSnackbar} handleClose={handleCloseSnackbar} />
    </>
  );
};

export default DriversPage;
