import React from 'react';
import DivOrLink from 'components/DivOrLink';

import BodyCell from 'components/BodyCell';
import UserStatus from 'typings/enum/UserStatus';

import { formatId } from 'utils';
import { Box, TableCell, TableRow, Typography, makeStyles } from '@material-ui/core';
import CompanyTypeChip from 'components/CompanyTypeChip';

interface Props extends MerchantList {
  handleOpenDetailClick: (id: number) => void;
}

const useStyles = makeStyles(theme => ({
boxChip: {
  display: 'flex',
  gap: '1px'
},
  chips: {
    left: 0,
    background: '#FEF7D1',
    borderRadius: '6px',
    '& > span': {
      fontWeight: 400
    }
  }
}));

const BodyRow = (props: Props) => {
  const classes = useStyles();
  const { id, displayName, companyName, companyUen, companyGst, contactNumber, status, MerchantTypes, handleOpenDetailClick } = props;
  return (
    <TableRow hover>
      <BodyCell>
        {formatId(id)}
      </BodyCell>
      <BodyCell>
        <Box display={'flex'} flexDirection={'column'}>
          <Box>
            <DivOrLink
              condition={() => {
                return status === UserStatus.PENDING || status === UserStatus.REJECTED;
              }}
              onClick={() => {
                handleOpenDetailClick(id);
              }}
              to={`/owner/${id}`}
              label={displayName}
            />
          </Box>
          <Typography>{companyName}</Typography>
        </Box>
      </BodyCell>
      <TableCell>
      <div className={classes.boxChip}>
      {!!MerchantTypes?.length ? 
            MerchantTypes.map(v => (
              <CompanyTypeChip  key={v.type}  type={v.type} />
            ))
         : (
          'N/A'
        )}
      </div>
      </TableCell>
      <BodyCell align='center'>{companyUen}</BodyCell>
      <BodyCell align='center'>{companyGst}</BodyCell>
      <BodyCell align='center'>{contactNumber}</BodyCell>
    </TableRow>
  );
};

export default BodyRow;
