import React, { FC, useState, useEffect } from 'react';
import useRouter from 'hooks/useRouter';
import axios, { CancelTokenSource } from 'axios';

import { Button, ButtonGroup, CircularProgress, Dialog, DialogTitle, Divider, IconButton, Typography } from '@material-ui/core';
import { dummyJob, dummyFormJob } from 'constants/DummyData';
import { INDIVIDUAL_JOB_URL, EXPORT_JOB_URL, INDIVIDUAL_MERCHANT_URL, WEB_JOB_TASK_EDIT_STATUS_BASE_URL, WEB_JOB_BASE_URL } from 'constants/url';
import { Close, GetApp } from '@material-ui/icons';

import InfoCard from './components/InfoCard';
import JobInformation from './components/JobInfomation';

import MainTemplate from 'components/Template/MainTemplate';
import AdditionalTable from './components/AdditionalTable';
import TruckType from 'typings/enum/TruckType';
import PriceTable from './components/PriceTable';
import { allTaskOneDriverType } from 'utils';
import TaskList from './components/TaskList';
import DialogEdit from './components/DialogEdit';
import DialogBottom from './components/DialogBottom';
import JobStatus from 'typings/enum/JobStatus';
import useSnackbar from 'hooks/useSnackbar';
import Snackbar from 'components/Snackbar';
import JobNoteModal from './components/JobNoteModal';
import { StandardConfirmationDialog } from 'components/AppDialog';
import JobHistoryModal from './components/JobHistoryModal';
import EditJob from './components/EditJob';

const message = {
  [JobStatus.CANCELLED]: `Are you sure you want to change the status of this task to "Cancel"?`,
  [JobStatus.ASSIGNED]: `Are you sure you want to change the status of this task to "Assigned"?`,
  [JobStatus.AVAILABLE]: `Are you sure you want to change the status of this task to "Available"?`,
  [JobStatus.COMPLETED]: `Are you sure you want to change the status of this task to "Complete"?`
};

const JobDetailPage: FC = () => {
  const { match } = useRouter();
  const id = Number(match.params.id);
  const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
  const { openSnacbar, snackbar } = useSnackbar();

  const [job, setJob] = useState<JobModel>(dummyJob);
  const [tasks, setTasks] = useState<JobTask[]>([]);
  const [additionalItems, setAdditionalItems] = useState<(JobAdditionalItem & { status: string })[]>([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [hasAdditional, setHasAdditional] = useState(false);
  const [balance, setBalance] = useState(0);
  const [isPorcess, setProcess] = useState(false);
  const [form, setForm] = useState(dummyFormJob);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [processing, setProcesing] = useState(false);

  const [dialogPrice, setDialogPrice] = useState({
    merchantId: 0,
    open: false,
    taskId: 0
  });

  const [taskHistory, setTaskHistory] = React.useState({
    open: false,
    list: [] as JobTaskHistory[]
  });

  const [openEdit, setOpenEdit] = useState({
    edit: false,
    bottom: false
  });
  const [openBottom, setOpenBottom] = useState(false);
  const [payload, setPayload] = useState({
    tasks: [] as number[],
    jobStatus: ''
  });
  const [singlePayload, setSinglePayload] = useState({
    tasks: [] as number[],
    jobStatus: ''
  });

  const [openJobNote, setOpenJobNote] = useState(false);
  const [jobNote, setJobNote] = useState<JobNote[] | undefined>();

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [remarks, setRemarks] = useState('');

  const handleExportClick = async () => {
    setIsExporting(true);
    try {
      const response = await axios({
        url: EXPORT_JOB_URL(id),
        method: 'GET',
        responseType: 'blob'
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'job.pdf');
      document.body.appendChild(link);
      link.click();
      setIsExporting(false);
    } catch (err) {
      console.log(err);
      setIsExporting(false);
    }
  };

  const fetchData = async () => {
    try {
      setIsLoadingData(true);
      setAdditionalItems([]);
      const { data } = await axios.get(`${INDIVIDUAL_JOB_URL(id)}?withTasksHistory=1`, { cancelToken: cancelTokenSource.token });
      if (data) {
        let tasks: JobTask[] = [];
        setJob(data);

        if (allTaskOneDriverType.includes(data.truckType) && data.JobTasks.length > 1) {
          tasks.push({ ...data.JobTasks[0], taskEarn: data.totalAmount });
        } else {
          tasks = data.JobTasks.sort((a, b) => a.id - b.id);
        }

        setTasks(tasks);
        setHasAdditional(data.JobAdditionals.some((v: JobAdditional) => v.status === 'PENDING'));
        setAdditionalItems(
          data.JobAdditionals.filter((v: JobAdditional) => ['CONFIRM', 'PENDING'].includes(v.status)).flatMap((v: JobAdditional) =>
            (v.JobAdditionalItems || []).map(_v => ({ ..._v, status: v.status }))
          )
        );
        await getMerchant(data.MerchantId, data.useCredit);
      }
    } catch (err) {
      console.error('err: ', err);
    } finally {
      setIsLoadingData(false);
    }
  };

  const refreshPage = () => {
    fetchData();
  };

  const onOpenPriceDetail = (id: number, taskId: number) => () => {
    setDialogPrice(state => ({ ...state, merchantId: id, open: !state.open, taskId }));
  };

  const onOpenHistory = (histories?: JobTaskHistory[]) => () => {
    if (histories) {
      setTaskHistory({ open: true, list: histories });
    }
  };

  const onOpenEdit = (id: number) => () => {
    setOpenEdit(p => ({ ...p, edit: true }));
    setSinglePayload(p => ({ ...p, tasks: [id] }));
    setRemarks('');
    getMerchant();
  };

  const onOpenJobNote = (value?: JobNote[]) => () => {
    if (value) {
      setOpenJobNote(true);
      setJobNote(value);
    }
  };

  const onCloseJobNote = () => {
    setOpenJobNote(false);
    setJobNote(undefined);
  };

  const onSelectAll = (currentTask: number[], allTask: JobTask[]) => () => {
    const clearStatus = [JobStatus.EXPIRED, JobStatus.DRAFT, JobStatus.CANCELLED];
    const isChecked = allTask.filter(v => !clearStatus.includes(v.jobTaskStatus as JobStatus)).length === currentTask.length;
    let newTask = allTask
      .filter(v => !clearStatus.includes(v.jobTaskStatus as JobStatus))
      .filter(v => !currentTask.includes(v.id))
      .map(v => v.id);

    if (isChecked) {
      currentTask = [];
      newTask = [];
    }

    setPayload(p => ({ ...p, tasks: [...currentTask, ...newTask] }));
    setOpenBottom(newTask.length > 0);
  };

  const onSelected = (task: JobTask, checked: boolean) => {
    if (checked) {
      if (payload.tasks.length === 0) {
        setOpenBottom(true);
      }
    } else {
      if (payload.tasks.length === 1) {
        setOpenBottom(false);
      }
    }

    setPayload(p => {
      if (checked) {
        const isExist = p.tasks.some(v => v === task.id);
        if (!isExist) {
          p.tasks.push(task.id);
        }
      } else {
        p.tasks = p.tasks.filter(v => v !== task.id);
      }
      return { ...p };
    });
  };

  const onReview = async () => {
    setOpenConfirmation(true);
  };

  const onConfirm = async () => {
    try {
      setProcess(true);
      await axios.put(WEB_JOB_TASK_EDIT_STATUS_BASE_URL(job.id), { ...(singlePayload.tasks.length > 0 ? singlePayload : payload), remarks });
      openSnacbar('Update Status Successfully');
      setOpenConfirmation(false);
      setOpenEdit({ edit: false, bottom: false });
      setOpenBottom(false);
      setPayload({ tasks: [], jobStatus: '' });
      setSinglePayload({ tasks: [], jobStatus: '' });
      setRemarks('');
    } catch (error) {
      openSnacbar(error && error.data && error.data.message ? error.data.message : JSON.stringify(error), { variant: 'error' });
    } finally {
      fetchData();
      setProcess(false);
    }
  };

  const getMerchant = async (id?: number, useCredit?: boolean) => {
    try {
      const { data } = await axios.get(INDIVIDUAL_MERCHANT_URL(id || job.MerchantId));
      setBalance(
        Number(
          useCredit === undefined
            ? job.useCredit
              ? data.creditBalance || 0
              : data.balance || 0
            : useCredit
            ? data.creditBalance || 0
            : data.balance || 0
        )
      );
    } catch (error) {
    } finally {
    }
  };

  const onSubmitEdit = async () => {
    try {
      setProcesing(true);
      await axios.put(WEB_JOB_BASE_URL, form);
      openSnacbar('Job updated successfully');
      setForm(dummyFormJob);
      fetchData();
    } catch (err) {
      openSnacbar('Job update error', { variant: 'error' });
    } finally {
      setProcesing(false);
      setOpenEditForm(false);
    }
  };

  useEffect(() => {
    if (!id) return;
    fetchData();
    window.addEventListener('focus', e => {
      fetchData();
    });

    return () => {
      window.removeEventListener('focus', e => {
        fetchData();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isShow = allTaskOneDriverType.includes(job.truckType as TruckType);
  const statusChange = singlePayload.jobStatus || payload.jobStatus;
  const jobAdd = (job.JobAdditionals || []).reduce(
    (p, n) => {
      if (n.status === 'PENDING') {
        p.totalOvertime += n.totalPrice;
        p.totalGstOvertime += n.gstTotalPrice;
        p.totalAdditional += n.totalItemPrice;
        p.totalGstAdditional += n.gstItemPrice;
      }
      return { ...p };
    },
    { totalOvertime: 0, totalGstOvertime: 0, totalAdditional: 0, totalGstAdditional: 0 }
  );

  return (
    <MainTemplate
      title='Job Details'
      breadcrumb={true}
      refreshButtonProps={{
        onClick: refreshPage
      }}
      primaryButton={
        <ButtonGroup>
          <Button
            style={{ padding: '8px 16px 8px 16px' }}
            disableElevation
            variant='outlined'
            color='primary'
            onClick={() => {
              setOpenEditForm(true);
              setForm({
                id: job.id,
                jobDate: job.jobDate,
                jobTime: job.jobTime
              });
            }}
          >
            Edit Job
          </Button>
          <Button disableElevation variant='contained' color='primary' style={{ padding: '8px 16px 8px 16px' }} onClick={handleExportClick}>
            {isExporting ? (
              <CircularProgress color='inherit' size={24} />
            ) : (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <GetApp fontSize='small' style={{ marginRight: '13px' }} /> <span>Export Job</span>
              </div>
            )}
          </Button>
        </ButtonGroup>
      }
      links={[{ click: true, name: 'All Jobs', links: 'goback' }, { click: false, name: `#${id}`, links: '' }]}
    >
      <InfoCard job={job} isLoading={isLoadingData} />
      <JobInformation loading={isLoadingData} job={job} />
      <AdditionalTable show={isShow} job={job} isLoadingData={isLoadingData} additionalItems={additionalItems} />
      <TaskList
        loading={isLoadingData}
        tasks={tasks}
        payload={singlePayload.tasks.length === 0 ? payload : singlePayload}
        hasAdditional={hasAdditional}
        onOpenJobNote={onOpenJobNote}
        onOpenPriceDetail={onOpenPriceDetail}
        onOpenHistory={onOpenHistory}
        onOpenEdit={onOpenEdit}
        onSelectAll={onSelectAll}
        onSelected={onSelected}
      />

      <Dialog
        open={dialogPrice.open}
        maxWidth='lg'
        onClose={() => {
          setDialogPrice(state => ({ ...state, merchantId: 0, open: !state.open, taskId: 0 }));
        }}
      >
        <DialogTitle disableTypography>
          <Typography variant='h6' style={{ fontWeight: 700 }}>
            Price Detail
          </Typography>

          <IconButton
            size='small'
            aria-label='close'
            onClick={() => {
              setDialogPrice(state => ({ ...state, merchantId: 0, open: !state.open }));
            }}
            style={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
          >
            <Close fontSize='inherit' />
          </IconButton>
        </DialogTitle>
        <Divider />
        <PriceTable job={job} merchantId={dialogPrice.merchantId} taskId={dialogPrice.taskId} />
      </Dialog>

      <DialogEdit
        open={openEdit.edit}
        isProcess={isPorcess}
        isLoading={isLoadingData}
        payload={singlePayload}
        jobTasks={tasks}
        setStatus={status => setSinglePayload(prev => ({ ...prev, jobStatus: status }))}
        hasAdditional={hasAdditional}
        balance={balance}
        {...jobAdd}
        onClose={() => {
          setOpenEdit(p => ({ ...p, edit: false }));
          setSinglePayload({ tasks: [], jobStatus: '' });
        }}
        onConfirm={onReview}
      />

      <DialogEdit
        open={openEdit.bottom}
        isProcess={isPorcess}
        isLoading={isLoadingData}
        payload={payload}
        jobTasks={tasks}
        hasAdditional={hasAdditional}
        balance={balance}
        {...jobAdd}
        setStatus={status => setPayload(prev => ({ ...prev, jobStatus: status }))}
        onClose={() => {
          setOpenEdit(p => ({ ...p, bottom: false }));
          setPayload(p => ({ ...p, jobStatus: '' }));
        }}
        onConfirm={onReview}
      />

      <DialogBottom
        open={singlePayload.tasks.length === 0 ? openBottom : false}
        totalTask={payload.tasks.length}
        onOpen={() => {
          setOpenEdit(p => ({ ...p, bottom: true }));
          setRemarks('');
          getMerchant();
        }}
        onCancel={() => {
          setPayload({ tasks: [], jobStatus: '' });
          setOpenBottom(false);
        }}
      />

      <JobNoteModal open={openJobNote} jobNotes={jobNote} onClose={onCloseJobNote} />
      <JobHistoryModal open={taskHistory.open} onClose={() => setTaskHistory({ open: false, list: [] })} taskHistories={taskHistory.list} />

      <EditJob
        open={openEditForm}
        processing={processing}
        form={form}
        setValue={value => setForm(p => ({ ...p, ...value }))}
        handleClose={() => {
          setOpenEditForm(false);
          setForm(dummyFormJob);
        }}
        onSubmit={onSubmitEdit}
      />

      <StandardConfirmationDialog
        variant='warning'
        message=''
        titleMessage={message[statusChange as keyof typeof message] || `Are you sure want to change this task's status?`}
        confirmButtonText='Confirm'
        open={openConfirmation}
        loading={isPorcess}
        useRemarks={statusChange === JobStatus.CANCELLED}
        remarksPromp={remarks}
        handleRemarksPromp={setRemarks}
        remarksPrompLabel='Put reason here (optional)'
        handleClose={() => setOpenConfirmation(false)}
        onConfirm={onConfirm}
      />

      <Snackbar {...snackbar} />
    </MainTemplate>
  );
};

export default JobDetailPage;
