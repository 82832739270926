import TransactionStatus from 'typings/enum/TransactionStatus';
import TransactionType from 'typings/enum/TransactionType';

export const trxCsv = [
  { label: 'Trx ID', key: 'trx_id' },
  { label: 'Payment Method', key: 'payment_method' },
  { label: 'Original Type', key: 'original_trx_type' },
  { label: 'Task ID', key: 'task_id' },
  { label: 'Job Type', key: 'job_type' },
  { label: 'Job ID', key: 'job_id' },
  { label: 'Owner', key: 'job_owner' },
  { label: 'Transaction Type', key: 'trx_type' },
  { label: 'Transaction Date', key: 'trx_date' },
  { label: 'Transaction Status', key: 'transaction_status' },

  { label: 'Job Date', key: 'job_date' },
  { label: 'Job Start Time', key: 'job_start_time' },
  { label: 'Job End Time', key: 'job_end_time' },
  { label: 'Complete Date', key: 'task_completed_date' },
  { label: 'Duration', key: 'duration' },
  { label: 'Start Location', key: 'start_location' },
  { label: 'Destination Location', key: 'destination_location' },
  { label: 'Materials', key: 'materials' },
  { label: 'Vehicle no.', key: 'vehicle_no' },
  { label: 'Driver Name', key: 'driver_name' },
  { label: 'Supplier Company', key: 'supplier_company' },
  { label: 'Supervisor Name', key: 'supervisor_name' },
  { label: 'Price per Task', key: 'price_per_task' },
  { label: 'Additional Item', key: 'additional_item_price' },
  // { label: 'Job Status', key: 'job_status' },
  // { label: 'Task Status', key: 'task_status' },
  { label: 'Qty', key: 'qty' },
  { label: 'Total Price', key: 'total_price' },
  { label: 'App Fee', key: 'platform_fee' },
  { label: 'GST Amount', key: 'gst_amount' },
  { label: 'Grand Total', key: 'grand_total' }
];

export const typeTrx = [
  TransactionType.TOPUP,
  TransactionType.WITHDRAW,
  TransactionType.TASK_COMPLETED,
  TransactionType.TASK_EXPIRED,
  TransactionType.TASK_CANCELLED,
  // TransactionType.GST_TASK_COMPLETED,
  // TransactionType.GST_TASK_EXPIRED,
  // TransactionType.GST_TASK_CANCELLED,
  // TransactionType.GST_JOB_UNPUBLISHED,
  // TransactionType.GST_JOB_EXPIRED,
  // TransactionType.GST_JOB_CANCELLED,
  TransactionType.JOB_UNPUBLISHED,
  TransactionType.JOB_CANCELLED,
  TransactionType.JOB_EXPIRED,
  TransactionType.CANCEL_EDIT_TASK_HOUR,
  TransactionType.REFUND_EDIT_TASK_HOUR,
  TransactionType.IN_EDIT_TASK_HOUR,
  TransactionType.JOB_CREATED,
  // TransactionType.GST_JOB_CREATED,
  TransactionType.OUT_EDIT_TASK_HOUR,
  TransactionType.REVERT_EDIT_TASK_HOUR,
  TransactionType.JOB_CONFIRM_ADDITIONAL
  // TransactionType.GST_JOB_CONFIRM_ADDITIONAL
];

export const outgoingType = [];

export const trxStatus = [
  TransactionStatus.CANCELLED,
  TransactionStatus.PENDING,
  TransactionStatus.FAILED,
  TransactionStatus.SETTLED,
  TransactionStatus.EXPIRED
];
