import React from 'react';
import { Paper, CardHeader, List, ListItem, ListItemText, Divider, Button, Typography, FormHelperText } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { formatCurrency, ucwords } from 'utils';
import useStyles from './styles';

interface TopupDetailProps {
  loading: boolean;
  transaction?: TransactionModel;
  activeTopup: boolean;
  onClick: () => void;
  onCancel: () => void;
}

const TopupDetail = ({ transaction, loading, activeTopup, onClick, onCancel }: TopupDetailProps) => {
  const classes = useStyles();
  return (
    <Paper elevation={0} className={classes.wrapper}>
      <CardHeader title='Top Up Details' titleTypographyProps={{ variant: 'h4', align: 'center' }} />
      <List>
        <ListItem>
          <ListItemText
            className={classes.listItem}
            primaryTypographyProps={{ className: classes.primaryText }}
            secondaryTypographyProps={{ className: classes.secondaryText }}
            primary='Transaction ID'
            secondary={loading ? <Skeleton width={'100%'} /> : `#${(transaction && transaction.id) || 0}`}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            className={classes.listItem}
            primaryTypographyProps={{ className: classes.primaryText }}
            secondaryTypographyProps={{ className: classes.secondaryText }}
            primary='Company Name'
            secondary={loading ? <Skeleton width={'100%'} /> : (transaction && transaction.PaidMerchant!.companyName) || '-'}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            className={classes.listItem}
            primaryTypographyProps={{ className: classes.primaryText }}
            secondaryTypographyProps={{ className: classes.secondaryText }}
            primary='Date & Time'
            secondary={loading ? <Skeleton width={'100%'} /> : transaction && transaction.createdAt}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            className={classes.listItem}
            primaryTypographyProps={{ className: classes.primaryText }}
            secondaryTypographyProps={{ className: classes.secondaryText }}
            primary='Payment Method'
            secondary={loading ? <Skeleton width={'100%'} /> : ucwords((transaction && transaction.paymentMethod.replace('_', ' ')) || '')}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            className={classes.listItem}
            primaryTypographyProps={{ className: classes.primaryText }}
            secondaryTypographyProps={{ className: classes.amountText }}
            primary='Total Amount'
            secondary={loading ? <Skeleton width={'100%'} /> : formatCurrency((transaction && transaction.amount) || 0)}
          />
        </ListItem>
      </List>

      <div className={classes.boxContinue}>
        <Divider className={classes.divider} />

        <Typography variant='body2' gutterBottom className={classes.footerInfo}>
          By pressing "continue" below then you must enter the otp code that we have sent to the email
        </Typography>

        <Button
          disabled={!activeTopup}
          variant='contained'
          color='primary'
          fullWidth
          disableElevation
          onClick={onClick}
          className={classes.continueButton}
        >
          Continue
        </Button>
        <Button
          variant='text'
          color='inherit'
          fullWidth
          disableElevation
          disableFocusRipple
          disableRipple
          disableTouchRipple
          onClick={onCancel}
          className={classes.cancelButton}
        >
          Cancel Top Up
        </Button>
        <FormHelperText error={!activeTopup}>{!activeTopup && 'The time to top up is over.'}</FormHelperText>
      </div>
    </Paper>
  );
};

export default TopupDetail;
