import React from 'react';
import { Button, TableCell, TableRow } from '@material-ui/core';

interface Props extends LampPost {
  onOpen: () => void;
  onDelete?: () => void;
}

const BodyRow = (props: Props) => {
  return (
    <TableRow hover>
      <TableCell>
        <Button onClick={props.onOpen} variant='text' color='primary'>
          {props.streetName || 'N/A'}
        </Button>
      </TableCell>
      {/* <TableCell style={{ whiteSpace: 'nowrap' }}>
        <IconButton size='small' onClick={props.onDelete}>
          <Delete fontSize='inherit' />
        </IconButton>
      </TableCell> */}
    </TableRow>
  );
};

export default BodyRow;
