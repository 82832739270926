import { Button, TextField } from '@material-ui/core';
import Dialog from 'components/Dialog';
import React from 'react';

export type EditJobProps = {
  open: boolean;
  form: JobForm;
  processing: boolean;
  setValue: (value: any) => void;
  handleClose: () => void;
  onSubmit: () => void;
};

const EditJob = (props: EditJobProps) => {
  return (
    <Dialog
      open={props.open}
      maxWidth='md'
      title='Edit Job'
      handleClose={props.handleClose}
      contentProps={{ style: { display: 'flex', flexDirection: 'column', gap: '16px', width: '400px' } }}
      action={{
        children: (
          <>
            <Button size='small' disabled={props.processing} disableElevation variant='outlined' color='primary' onClick={props.handleClose}>
              Cancel
            </Button>
            <Button
              size='small'
              disabled={props.processing || Object.values(props.form).some(v => !v)}
              disableElevation
              variant='contained'
              color='primary'
              onClick={props.onSubmit}
            >
              {props.processing ? 'loading...' : 'Yes, confirm'}
            </Button>
          </>
        )
      }}
    >
      <TextField
        size='small'
        fullWidth
        label='Job Date'
        value={props.form.jobDate}
        variant='outlined'
        type='date'
        InputLabelProps={{ shrink: true }}
        onChange={e => props.setValue({ jobDate: e.target.value })}
      />

      <TextField
        size='small'
        fullWidth
        label='Job Time'
        value={props.form.jobTime}
        variant='outlined'
        type='time'
        InputLabelProps={{ shrink: true }}
        onChange={e => props.setValue({ jobTime: e.target.value })}
      />
    </Dialog>
  );
};

export default EditJob;
