import React, { FC, useState, useEffect, useCallback } from 'react';
import { Theme, makeStyles, Grid, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

interface Props {
  title: string;
  fileUrl: string;
  fileName: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  imageFile: {
    maxWidth: '80%',
    margin: 'auto 0'
  },
  loadingRoot: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  },
  framePdf: {
    minHeight: '150px'
  }
}));

const FileModal: FC<Props> = props => {
  const classes = useStyles();

  const { title, fileName, fileUrl, open, setOpen } = props;
  const ext = fileName ? fileName.split('.').pop() : '';

  const [loading, setLoading] = useState<boolean>(false);
  const [currentFile, setCurrentFile] = useState<string>('');

  const fetchFile = useCallback(async () => {
    if (!fileName || !fileUrl) {
      return;
    }

    if (!open) {
      return;
    }

    try {
      setLoading(true);
      const response = await fetch(fileUrl);
      const blob = await response.blob();

      const blobUrl = URL.createObjectURL(blob);
      setCurrentFile(blobUrl);
    } catch (error) {
      console.clear();
      console.log(error);
    }

    setLoading(false);
  }, [open, fileName, fileUrl]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = currentFile;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  };

  useEffect(() => {
    fetchFile();
  }, [fetchFile]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth='md'
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        {loading ? (
          <Grid container justify='center' alignItems='center'>
            <CircularProgress color='secondary' size={80} />
          </Grid>
        ) : (
          <DialogContentText id='alert-dialog-description'>
            {ext === 'pdf' && currentFile ? (
              <iframe title='file' className={classes.framePdf} style={{ width: '100%', minHeight: '500px' }} src={currentFile}></iframe>
            ) : currentFile ? (
              <Grid component={'span'} container justify='center' alignItems='center'>
                <img src={currentFile} alt='' className={classes.imageFile} />
              </Grid>
            ) : (
              '-'
            )}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          Close
        </Button>
        <Button onClick={handleDownload} color='primary' variant='contained'>
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileModal;
