import React, { FC, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  Theme,
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Collapse,
  Box,
  IconButton,
  Link
} from '@material-ui/core';

import { ucwords, formatId } from 'utils';
import BodyCell from 'components/BodyCell';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import SupervisorChip from 'components/SupervisorChip';

interface Props {
  index: number;
  isLoadingData: boolean;
  jobId?: number;
  supervisor: SupervisorModel;
  handleDetailClick: (supervisor: SupervisorModel) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  idLink: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  }
}));

const BodyRow: FC<Props> = props => {
  const classes = useStyles();
  const { supervisor, isLoadingData, jobId, handleDetailClick } = props;
  const { id, User, Jobs } = supervisor;
  const { displayName, contactNumber, email, role } = User;
  const JobTasks = Jobs ? Jobs[0].JobTasks : [];
  const [openDetail, setOpenDetail] = useState<boolean>(false);

  return (
    <>
      <TableRow hover onClick={() => setOpenDetail(!openDetail)}>
        {jobId && (
          <TableCell>
            <IconButton aria-label='expand row' size='small' onClick={() => setOpenDetail(!openDetail)}>
              {openDetail ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
        <BodyCell cellWidth='15%'>{isLoadingData ? <Skeleton width={80} /> : formatId(id)}</BodyCell>
        <BodyCell cellWidth='20%'>
          {isLoadingData ?
            <Skeleton width={80} /> :
            <div
              className={classes.idLink}
              onClick={() => handleDetailClick(supervisor)}
            >
              {displayName}
            </div>
          }
        </BodyCell>
        <BodyCell cellWidth='20%'>
          {isLoadingData ? <Skeleton width={80} /> : <SupervisorChip  label={ucwords(role)} />}
        </BodyCell>
        <BodyCell cellWidth='20%'>{isLoadingData ? <Skeleton width={80} /> : email}</BodyCell>
        <BodyCell cellWidth='20%'>{
          isLoadingData ? (
            <Skeleton width={80} /> 
          ) : ( 
            <Link href={`https://api.whatsapp.com/send?phone=65${contactNumber}`} target="_blank" className={classes.idLink}>{contactNumber}</Link>
          )}</BodyCell>
      </TableRow>
      {jobId &&
       ( <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={openDetail} timeout='auto' unmountOnExit>
            <Box margin={1}>
              <Table size='small' aria-label='supervisor sub table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Trip</TableCell>
                    <TableCell>Driver</TableCell>
                    <TableCell>Completed at</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {JobTasks?.map((jobTask) => jobTask.SupervisorId === id && (
                    <TableRow key={`sub-row-${jobTask.id}`}>
                      <TableCell>{`Trip ${jobTask.id}`}</TableCell>
                      <TableCell>{jobTask.Driver?.User?.displayName}</TableCell>
                      <TableCell>{jobTask.completedDate ? `${jobTask.completedDate}, ${jobTask.completedTime}` : '-'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>)
}
    </>
  );
};

export default BodyRow;
