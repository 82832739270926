import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: '620px',
    margin: '0 auto',
    padding: '16px 8px 24px 8px',
    borderRadius: '5px',
    boxShadow: '8px 4px 24px rgba(0, 0, 0, 0.08)'
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  primaryText: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '150%',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.5px',
    color: theme.palette.black['50']
  },
  secondaryText: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '150%',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.5px',
    color: theme.palette.black.A100
  },
  amountText: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '23%',
    letterSpacing: '0.5px',
    color: theme.palette.black.A100
  },
  divider: {},
  footerInfo: {
    marginBottom: '16px',
    marginTop: '16px'
  },
  boxContinue: {
    padding: '24px 16px 0px 16px'
  },
  continueButton: {
    textTransform: 'none'
  },
  cancelButton: {
    textTransform: 'none',
    textDecoration: 'underline',
    marginTop: '4px',
    padding: 0
  },
  
  
}));

export default useStyles;
