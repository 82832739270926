import React, { FC } from 'react';
import NumberFormat from 'react-number-format';
import Skeleton from 'react-loading-skeleton';
import { IconButton, TableRow } from '@material-ui/core';
import BodyCell from 'components/BodyCell';
import { formatId } from 'utils';
import { Edit } from '@material-ui/icons';

interface Props {
  additionalItems: AdditionalTemplates;
  isLoadingData: boolean;
  handleEdit: () => void;
}

const BodyRow: FC<Props> = props => {
  const { isLoadingData, additionalItems, handleEdit } = props;

  return (
    <TableRow hover>
      <BodyCell>{isLoadingData ? <Skeleton /> : formatId(additionalItems.id)}</BodyCell>
      <BodyCell>{isLoadingData ? <Skeleton /> : additionalItems.name}</BodyCell>
      <BodyCell>{isLoadingData ? <Skeleton /> : additionalItems.description}</BodyCell>
      <BodyCell align='right'>
        {isLoadingData ? (
          <Skeleton />
        ) : (
          <NumberFormat
            value={additionalItems.price}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        )}
      </BodyCell>
      <BodyCell align='right'>
        {isLoadingData ? (
          <Skeleton />
        ) : (
          <IconButton size='small' color='primary' onClick={handleEdit}>
            <Edit />
          </IconButton>
        )}
      </BodyCell>
    </TableRow>
  );
};

export default BodyRow;
