import React from 'react';
import clsx from 'clsx';
import { Chip, makeStyles } from '@material-ui/core';
import TruckType from 'typings/enum/TruckType';
import { truckTypeLabel } from 'utils';

interface Props {
  type: string;
  select?: boolean;
}

const tmpColor = {
  [TruckType.LORRY_CRANE]: {
    backgroundColor: '#FEF7D1',
    color: '#F6891F',
    border: '1px solid #F6891F'
  },
  [TruckType.TRAILER]: {
    backgroundColor: '#FEF7D1',
    color: '#B14E0F',
    border: '1px solid #B14E0F'
  },
  [TruckType.TMA]: {
    backgroundColor: '#FEF7D1',
    color: '#F6B91E',
    border: '1px solid #F6B91E'
  },
  [TruckType.TIPPER]: {
    backgroundColor: '#FEF7D1',
    color: '#8E5D09',
    border: '1px solid #8E5D09'
  },
  MERCHANT: {
    backgroundColor: '#D9F7ED',
    color: '#29696A',
    border: '1px solid #29696A'
  }
};

const useStyles = makeStyles({
  chip: props => ({
    minWidth: '96px',

    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '150%',
    letterSpacing: '0.4px',
    borderRadius: '30px',

    height: 'auto',
    ...tmpColor[props.type]
  }),
  label: {
    padding: (props: Props) => (props.select ? '0.5px' : '5px 15px 5px 8px')
  },
  icon: {
    paddingLeft: '11px'
  }
});

export default function CompanyTypeChip(props: Props) {
  const classes = useStyles(props);

  return (
    <Chip size='small' variant='outlined' label={truckTypeLabel(props.type)} classes={{ label: classes.label }} className={clsx(classes.chip)} />
  );
}
