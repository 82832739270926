import React from 'react';
import { Box, Divider, Drawer, IconButton, List, ListItem, ListItemText, Theme, Toolbar, Typography, makeStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import axios from 'axios';
import { Skeleton } from '@material-ui/lab';
import { WEB_BALANCE_LOG_DETAIL_BASE_URL, WEB_WEBHOOK_DETAIL_LIST_BASE_URL } from 'constants/url';
import { formatCurrency } from 'utils';
import clsx from 'clsx';

interface Props {
  id: number;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    paddingBottom: 0,
    paddingTop: 0
  },
  ulBorder: {
    borderBottom: '1px solid #e0e0e0',
    paddingBottom: '8px'
  },
  empty: {
    paddingTop: '16px'
  }
}));

const ResponseLogDrawer = ({ id, onClose }: Props) => {
  const classes = useStyles();
  const abort = axios.CancelToken.source();
  const [data, setData] = React.useState({
    loading: false,
    loadingBalance: false,
    response: [],
    balance: undefined
  });

  const parseWord = (text: string): string => {
    const result = text.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  };

  const parse = (value: any) => {
    return Object.entries(value).map(([key, val], i) => {
      if (!val) {
        return <li key={i}>{parseWord(key)}: </li>;
      } else if (typeof val === 'object') {
        return (
          <li key={i}>
            <p>{parseWord(key)}</p>
            <ul>{parse(val)}</ul>
          </li>
        );
      } else {
        return (
          <li key={i}>
            {parseWord(key)} : {val}
          </li>
        );
      }
    });
  };

  const getResponseLog = async (id: number) => {
    try {
      setData(prev => ({ ...prev, loading: true, response: [] }));
      const { data } = await axios.get(WEB_WEBHOOK_DETAIL_LIST_BASE_URL(id), { cancelToken: abort.token });
      setData(prev => ({ ...prev, response: data }));
    } catch (error) {
    } finally {
      setData(prev => ({ ...prev, loading: false }));
    }
  };

  const getBalanceLog = async (id: number) => {
    try {
      setData(prev => ({ ...prev, loadingBalance: true, data: [] }));
      const { data } = await axios.get(WEB_BALANCE_LOG_DETAIL_BASE_URL(id), { cancelToken: abort.token });
      setData(prev => ({ ...prev, balance: data }));
    } catch (error) {
    } finally {
      setData(prev => ({ ...prev, loadingBalance: false }));
    }
  };

  React.useEffect(() => {
    if (id === 0) {
      return;
    }

    getResponseLog(id);
    getBalanceLog(id);
    return () => {
      abort.cancel();
    };
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const balance = data.balance ? ((data.balance as unknown) as BalanceLog) : undefined;

  return (
    <Drawer open={id > 0} anchor='right' PaperProps={{ style: { width: '35%' } }} onClose={onClose}>
      <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='h5'>Response Log</Typography>
        <IconButton size='small' onClick={onClose}>
          <Close fontSize='inherit' />
        </IconButton>
      </Toolbar>
      <Divider />

      {data.loading ? (
        <Box padding={4}>
          <Skeleton variant='text' width={'100%'} />
          <Skeleton variant='text' width={'80%'} />
          <Skeleton variant='text' width={'70%'} />
        </Box>
      ) : !!data.response.length ? (
        data.response.map((value, idx) => (
          <ul key={idx} className={clsx({ [classes.ulBorder]: idx !== data.response.length - 1 })}>
            {parse(value)}
          </ul>
        ))
      ) : (
        <Typography align='center' variant='h6' className={classes.empty}>
          Data Not Available
        </Typography>
      )}

      <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='h5'>Balance Log</Typography>
      </Toolbar>
      <Divider />

      {data.loadingBalance ? (
        <Box padding={4}>
          <Skeleton variant='text' width={'100%'} />
          <Skeleton variant='text' width={'80%'} />
          <Skeleton variant='text' width={'70%'} />
        </Box>
      ) : balance ? (
        <List>
          <ListItem className={classes.listItem}>
            <ListItemText primary='Type' secondary={balance.type} />
          </ListItem>

          <ListItem className={classes.listItem}>
            <ListItemText primary='Current Balance' secondary={formatCurrency(balance.currentBalance)} />
          </ListItem>

          <ListItem className={classes.listItem}>
            <ListItemText primary='Amount' secondary={formatCurrency(balance.amount)} />
          </ListItem>

          <ListItem className={classes.listItem}>
            <ListItemText primary='After Balance' secondary={formatCurrency(balance.afterBalance)} />
          </ListItem>

          <ListItem className={classes.listItem}>
            <ListItemText primary='Action' secondary={balance.action} />
          </ListItem>

          <ListItem className={classes.listItem}>
            <ListItemText primary='LOG Action' secondary={balance.logAction} />
          </ListItem>

          <ListItem className={classes.listItem}>
            <ListItemText primary='Created AT' secondary={balance.createdAt} />
          </ListItem>
        </List>
      ) : (
        <Typography align='center' variant='h6' className={classes.empty}>
          Data Not Available
        </Typography>
      )}
    </Drawer>
  );
};

export default ResponseLogDrawer;
