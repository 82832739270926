enum UserStatus {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    DISABLED = 'DISABLED',
    ENABLED = 'ENABLED',
    REQUEST_DELETE = 'REQUEST_DELETE', // just FE for tab not exist on BE use requestDelete = boolean
    DELETED = 'DELETED' // just FE for tab not exist on BE use deleted = boolean
};

export default UserStatus;
