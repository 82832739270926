import React, { useState, FC } from 'react';
import axios from 'axios';
import { makeStyles, Theme, Paper, Grid, Box, Typography, Divider } from '@material-ui/core';
import { WEB_LAMPPOST_BASE_URL } from 'constants/url';
import useTablePagination from 'hooks/useTablePagination';
import SettingTemplate from 'components/Template/SettingTemplate';
import LampPostTable from './components/LampPostTable';
import LampPostForm from './components/LampPostForm';
import { Add } from '@material-ui/icons';
import { dummyLampPost } from 'constants/DummyData';
import useSnackbar from 'hooks/useSnackbar';
import Snackbar from 'components/Snackbar';
import LampPostDrawer from './components/LampPostDrawer';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    borderRadius: '5px',
    boxShadow: '8px 4px 24px rgba(0, 0, 0, 0.08)'
  },
  button: {
    padding: '8px 16px 8px 16px',
    marginRight: '16px',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 400,
    borderRadius: '5px',
    color: theme.palette.black[50],
    '&.MuiButton-outlined:hover': {
      backgroundColor: '#FEF1D2',
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main
    }
  },
  buttonBackground: {
    backgroundColor: '#FEF1D2',
    color: theme.palette.primary.main
  },
  title: {
    padding: '16px'
  }
}));

export type Numbering = {
  payload: { id: number; order: number }[];
  loading: boolean;
};

const LampPostPage: FC = () => {
  const classes = useStyles();

  const { openSnacbar, snackbar } = useSnackbar();
  const [streetName, setStreetName] = useState('');
  const [openFormModal, setOpenFormModal] = useState(false);
  const [data, setData] = useState(dummyLampPost);

  const table = useTablePagination<LampPost>(WEB_LAMPPOST_BASE_URL, {
    selector: data => data.rows,
    sort: {
      order: 'asc',
      orderBy: 'order'
    }
  });

  const refreshList = () => {
    table.clear();
  };

  const onOpen = (streetName: string) => () => {
    setStreetName(streetName);
  };

  const onDelete = (id: number) => async () => {
    try {
      await axios.delete(`${WEB_LAMPPOST_BASE_URL}/${id}`);
      openSnacbar('LampPost successfully deleted');
      table.refresh();
    } catch (e) {
      openSnacbar(JSON.stringify(e), { variant: 'error' });
    } finally {
    }
  };

  return (
    <SettingTemplate
      title='LampPost List'
      subtitle='Display the data of either LampPost'
      refreshButtonProps={{ onClick: refreshList }}
      primaryButtonProps={{
        onClick: () => setOpenFormModal(true),
        children: (
          <>
            <Add fontSize='small' /> <span style={{ marginLeft: '13px' }}> Add New LampPost</span>
          </>
        )
      }}
    >
      <Grid container direction='row' spacing={3} justify='space-between'>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Box display='flex' justifyContent='flex-start' alignItems='center' style={{ gap: '8px' }}></Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={0} className={classes.wrapper}>
            <Box display={'flex'} flexDirection={'row'} justifyContent='space-between' alignItems={'center'} style={{ paddingRight: '16px' }}>
              <Typography component={'div'} variant='h4' className={classes.title}>
                Lamp Post
              </Typography>
            </Box>

            <Divider />

            <LampPostTable {...table} onDelete={onDelete} onOpen={onOpen} />
          </Paper>
        </Grid>
      </Grid>

      <LampPostForm open={openFormModal} data={data} setData={setData} setOpen={setOpenFormModal} reload={table.refresh} />
      <LampPostDrawer streetName={streetName} onClose={onOpen('')} />
      <Snackbar {...snackbar} />
    </SettingTemplate>
  );
};

export default LampPostPage;
