import React from 'react';
import { IconButton, ListItemText, TableCell, TableRow, TextField } from '@material-ui/core';

import { formatCurrency } from 'utils';
import { Delete, Edit } from '@material-ui/icons';
import { format } from 'date-fns';

interface Props extends TonnageTemplate {
  number: number;
  openNumber?: boolean;
  onDelete?: () => void;
  onEdit?: () => void;
  updateNumber: (index: number, value: number) => void;
}

const BodyRow = (props: Props) => {
  return (
    <TableRow hover>
      <TableCell>
        {props.openNumber ? (
          <TextField
            size='small'
            variant='outlined'
            value={props.number}
            fullWidth
            InputProps={{ inputProps: { style: { textAlign: 'center' } } }}
            onChange={e => props.updateNumber(props.id || 0, +e.target.value)}
          />
        ) : (
          props.order || 'N/A'
        )}
      </TableCell>
      <TableCell>{props.tonnageName || 'N/A'}</TableCell>
      <TableCell>{formatCurrency(props.price || 0)}</TableCell>
      <TableCell>{formatCurrency(props.overtimePrice || 0)}</TableCell>
      <TableCell>
        <ListItemText
          primary={props.lastUpdatedBy || 'N/A'}
          secondary={props.updatedAt ? format(new Date(props.updatedAt), 'dd/MM/yyyy HH:mm:ss') : 'N/A'}
        />
      </TableCell>

      <TableCell style={{ whiteSpace: 'nowrap' }}>
        <IconButton size='small' onClick={props.onEdit}>
          <Edit fontSize='inherit' />
        </IconButton>
        <IconButton size='small' onClick={props.onDelete}>
          <Delete fontSize='inherit' />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default BodyRow;
