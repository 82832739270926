import React from 'react';
import { RouteComponentProps, RouteProps, Redirect, Route } from 'react-router';

export interface Props extends RouteProps {
  routeCondition?: boolean;
  redirectTo?: string;
}

const ConditionalRoute: React.FC<Props> = props => {
  const { component: Component, path } = props;

  return (
    <Route
      path={path}
      render={(renderProps: RouteComponentProps<any>) => {
        if ( props.routeCondition === undefined && Component) {
          return <Component {...renderProps} />;
        }

        if (props.routeCondition) {
          if (!Component) {
            return null;
          }
          return <Component {...renderProps} />;
        }

        return <Redirect to={props.redirectTo || '/'} />;
      }}
    />
  );
};

export default ConditionalRoute;
