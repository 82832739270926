import React, { FC, useState } from 'react';
import {
  Container,
  makeStyles,
  Theme,
  Grid,
  FormControl,
  TextField,
  InputAdornment,
  CircularProgress,
  IconButton
} from '@material-ui/core';

import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import AlternateEmailOutlinedIcon from '@material-ui/icons/AlternateEmailOutlined';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import NumberFormatCustom from 'components/NumberFormatCustom';

interface Props {
  driver?: DriverModel;
  inputDisplayName: string;
  inputEmail: string;
  inputAddress: string;
  inputContactNumber: string;
  inputPassword: string;
  inputConfirmPassword: string;
  errorDisplayName: string;
  errorEmail: string;
  errorContactNumber: string;
  errorPassword: string;
  errorConfirmPassword: string;
  isLoadingEmail: boolean;
  isLoadingContactNumber: boolean;
  setInputDisplayName: React.Dispatch<React.SetStateAction<string>>;
  setInputEmail: React.Dispatch<React.SetStateAction<string>>;
  setInputAddress: React.Dispatch<React.SetStateAction<string>>;
  setInputContactNumber: React.Dispatch<React.SetStateAction<string>>;
  setInputPassword: React.Dispatch<React.SetStateAction<string>>;
  setInputConfirmPassword: React.Dispatch<React.SetStateAction<string>>;
  handleBlurName: (inputed: string) => void;
  handleBlurEmail: (inputed: string) => void;
  handleBlurContactNumber: (inputed: string) => void;
  handleBlurPassword: (inputed: string) => void;
  handleBlurConfirmPassword: (inputed: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  container: {
    '& > :nth-child(n+2)': {
      marginTop: theme.spacing(2)
    }
  },
  inputAdornmentClass: {
    marginLeft: theme.spacing(2)
  }
}));

const PersonalStep: FC<Props> = props => {
  const classes = useStyles();

  const {
    driver,
    inputDisplayName,
    inputEmail,
    inputAddress,
    inputContactNumber,
    inputPassword,
    inputConfirmPassword,
    isLoadingEmail,
    isLoadingContactNumber
  } = props;

  const {
    errorDisplayName,
    errorEmail,
    errorContactNumber,
    errorPassword,
    errorConfirmPassword
  } = props;

  const {
    setInputDisplayName,
    setInputEmail,
    setInputAddress,
    setInputContactNumber,
    setInputPassword,
    setInputConfirmPassword
  } = props;

  const {
    handleBlurName,
    handleBlurEmail,
    handleBlurContactNumber,
    handleBlurPassword,
    handleBlurConfirmPassword
  } = props;

  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <Container maxWidth='lg' className={classes.container}>
      <Grid container direction='row' justify='space-between' alignItems='center' spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControl fullWidth>
            <TextField
              variant='outlined'
              label='Display Name'
              value={inputDisplayName}
              helperText={errorDisplayName}
              error={errorDisplayName !== ''}
              onChange={event => setInputDisplayName(event.target.value)}
              onBlur={event => handleBlurName(event.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControl fullWidth>
            <TextField
              variant='outlined'
              label='Email'
              value={inputEmail}
              helperText={errorEmail}
              error={errorEmail !== ''}
              onChange={event => setInputEmail(event.target.value.trim())}
              onBlur={event => handleBlurEmail(event.target.value.trim())}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    {isLoadingEmail ? <CircularProgress size={20} /> : <AlternateEmailOutlinedIcon color='primary' fontSize='small' />}
                  </InputAdornment>
                )
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControl fullWidth>
            <TextField
              variant='outlined'
              label='Phone No'
              disabled={true}
              value={inputContactNumber}
              helperText={errorContactNumber}
              error={errorContactNumber !== ''}
              onChange={event => setInputContactNumber(event.target.value.replace(/\s/g, '').trim())}
              onBlur={event => handleBlurContactNumber(event.target.value.replace(/\s/g, '').trim())}
              InputProps={{
                inputComponent: NumberFormatCustom as any,
                inputProps: {
                  format: '########'
                },
                startAdornment: (
                  <InputAdornment position='start' className={classes.inputAdornmentClass}>
                    +65
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end'>
                    {isLoadingContactNumber ? <CircularProgress size={20} /> : <PhoneInTalkIcon color='primary' fontSize='small' />}
                  </InputAdornment>
                )
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControl fullWidth>
            <TextField multiline variant='outlined' label='Address' value={inputAddress} onChange={event => setInputAddress(event.target.value)} />
          </FormControl>
        </Grid>

        {driver ? (
          ''
        ) : (
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth>
              <TextField
                variant='outlined'
                label='Password'
                type={showPassword ? 'text' : 'password'}
                value={inputPassword}
                helperText={errorPassword}
                error={errorPassword !== ''}
                onChange={event => setInputPassword(event.target.value.trim())}
                onBlur={event => handleBlurPassword(event.target.value.trim())}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton edge='end' aria-label='toggle password visibility' onClick={event => setShowPassword(!showPassword)}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </FormControl>
          </Grid>
        )}

        {driver ? (
          ''
        ) : (
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth>
              <TextField
                variant='outlined'
                label='Confirm Password'
                type={showPassword ? 'text' : 'password'}
                value={inputConfirmPassword}
                helperText={errorConfirmPassword}
                error={errorConfirmPassword !== ''}
                onChange={event => setInputConfirmPassword(event.target.value.trim())}
                onBlur={event => handleBlurConfirmPassword(event.target.value.trim())}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton edge='end' aria-label='toggle password visibility' onClick={event => setShowPassword(!showPassword)}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </FormControl>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default PersonalStep;
