import { useReducer } from 'react';

type SingleType = {
  type: 'change',
  field: string,
  value: any
}

type MultiType = {
  type: 'multi',
  value: Record<string, string>,
}

function useFilter(filterState: any) {
  function reducer(state: any, action: SingleType | MultiType) {
    switch (action.type) {
      case 'change':
        return { ...state, [action.field]: action.value };
      case 'multi':
        return { ...state, ...action.value };
      default:
        return state;
    }
  }
  const [state, dispatch] = useReducer(reducer, filterState);

  const doFilter = (field: string, value: any) => {
    dispatch({ type: 'change', field, value });
  };

  const doMultiFilter = (value: Record<string, string>) => {
    dispatch({ type: 'multi', value });
  };

  return {
    filters: state,
    doFilter,
    doMultiFilter,
  };
}

export default useFilter;
